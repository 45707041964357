import React from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import Header from '../components/header'
import { MdAddCircle } from 'react-icons/md';

const InstitutionUploadList = () => {

  return (
    <React.Fragment>
      <div className="dashboard">
        <Header />
        <div className="container dashboard-view">
          <div className="white-block p-3 white-box-wide">
              <Row>
                  <Col>
                      <h5>Upload Residences</h5>
                  </Col>
                  <Col className='text-end'>
                      <span>
                          <Button className="icon-btn"><MdAddCircle /></Button>
                      </span>
                  </Col>
              </Row>
              <hr></hr>
              <div className="institution-list-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Date created</th>
                        <th>Modified by</th>
                        <th>Shared</th>
                        <th>Modified</th>
                        <th>File Size</th>
                        <th>Owner</th>
                      </tr>
                    </thead>
                    <tbody>
                      
                      <tr>
                        <td>
                          <ul>
                            <li><h5>Group Header(2)</h5></li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>Business FYI</td>
                        <td></td>
                        <td>Mona Kane</td>
                        <td>Private</td>
                        <td>2 days ago</td>
                        <td>1.29 MB</td>
                        <td>Tim Deboar</td>
                      </tr>
                      <tr>
                        <td>Business FYI19</td>
                        <td></td>
                        <td>Mona Kane</td>
                        <td>Private</td>
                        <td>2 days ago</td>
                        <td>1.29 MB</td>
                        <td>Tim Deboar</td>
                      </tr>
                      <tr>
                        <td>
                          <ul>
                            <li><h5>Group Header(12)</h5>
                              <ul>
                                <li><h5>Group Header(8)</h5>
                                  <ul>
                                    <li><h5>Group Header(4)</h5></li>
                                  </ul>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td className="padd-left-60">Business FYI</td>
                        <td></td>
                        <td>Mona Kane</td>
                        <td>Private</td>
                        <td>2 days ago</td>
                        <td>1.29 MB</td>
                        <td>Tim Deboar</td>
                      </tr>
                      <tr>
                        <td className="padd-left-60">Business FYI</td>
                        <td></td>
                        <td>Mona Kane</td>
                        <td>Private</td>
                        <td>2 days ago</td>
                        <td>1.29 MB</td>
                        <td>Tim Deboar</td>
                      </tr>
                      <tr>
                        <td>
                          <ul>
                            <li><h5>Group Header(2)</h5></li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>Business FYI</td>
                        <td></td>
                        <td>Mona Kane</td>
                        <td>Private</td>
                        <td>2 days ago</td>
                        <td>1.29 MB</td>
                        <td>Tim Deboar</td>
                      </tr>
                      <tr>
                        <td>Business FYI19</td>
                        <td></td>
                        <td>Mona Kane</td>
                        <td>Private</td>
                        <td>2 days ago</td>
                        <td>1.29 MB</td>
                        <td>Tim Deboar</td>
                      </tr>
                    </tbody>
                  </table>
              </div>
            
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default InstitutionUploadList