import { Alert, AlertProps, Button, colors } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

type AlertAction = {
  label: string;
  onClick: () => void;
};

type Props = AlertProps & {
  message: React.ReactNode;
  actionButton?: AlertAction;
};

function CustomAlert({ severity = 'error', message, actionButton }: Props) {
  const [open, setOpen] = React.useState(true);
  return open ? (
    <Alert
      sx={{ px: { xs: 1, lg: 8 } }}
      severity={severity}
      action={
        <>
          <Button
            sx={{
              display: { xs: 'none', lg: 'inline-flex' },
              minHeight: 'unset',
              color: colors.grey[600],
              fontSize: 14,
            }}
            size="small"
            onClick={() => setOpen(false)}>
            Dismiss
          </Button>
          {actionButton && (
            <Button
              sx={{
                minHeight: 'unset',
                color: '#6941C6',
                fontSize: 14,
                whiteSpace: 'nowrap',
              }}
              size="small"
              onClick={() => {
                actionButton.onClick();
                setOpen(false);
              }}>
              {actionButton.label}
            </Button>
          )}
          <IconButton size="small" onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </>
      }>
      {message}
    </Alert>
  ) : null;
}

export default CustomAlert;
