import {Outlet, Navigate} from 'react-router-dom';
import { useAuthStatus } from '../_common/hooks/authHook';
const PrivateRoutes = () => {
    const isAuth = useAuthStatus();
    return (
        isAuth?<Outlet/>:<Navigate to="/entry-type" />
    )

}

export default  PrivateRoutes;