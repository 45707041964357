import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import React, { useEffect, useState } from "react";
import TextField from "../../../../components/TextField";
import PageActionsBar from "../../PageActionsBar";
import SectionHeader from "../../SectionHeader";
import { useFormContext, useWatch } from "react-hook-form";
import Select from "../../../../components/Select";
import { useAppLoader } from "../../../../../../_common/hooks/common/appLoaderHook";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import { TApProfileDetailsResponse } from "../../../../types/TApProfileDetailsResponse";
import { IApiResponse } from "../../../../types/IApiResponse";
import { API } from "../../../../services/API";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../components/Notify";
import {
  EXECUTE_REQUEST_PATH,
  NUMBERS_ONLY_REGEX,
  PROVINCE_OPTIONS,
} from "../../../../constants";
import CircleLoader from "../../../../components/CircleLoader";
import GoogleAutocomplete from "../../../../components/GoogleAutocomplete";
import { TAddressDetailsForm } from "../../../../types/shared";
import { UTILS } from "../../../../../../utils";

function Addresses() {
  const { register, handleSubmit, reset, setValue, watch, control } =
    useFormContext<TAddressDetailsForm>();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [addressDetails, setAddressDetails] =
    useState<TApProfileDetailsResponse["ProfileDetails"]["address"]>();
  const { showLoader, hideLoader } = useAppLoader();
  const currentUser = useCurrentUser();

  // Store initial form values
  const [initialValues, setInitialValues] =
    useState<TAddressDetailsForm | null>(null);
  const [isEdited, setIsEdited] = useState(false); // Track if form is edited
  const [termTypeEdit, settermTypeEdit] = useState(false);

  // Watch form values for changes
  const formValues = useWatch({ control });

  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult | null>(null);

  const handlePlaceSelected = (
    place: google.maps.places.PlaceResult | null
  ) => {
    setSelectedPlace(place);
    function getLongNameByType(
      components: google.maps.GeocoderAddressComponent[],
      type: string
    ): string | undefined {
      const component = components.find((comp) =>
        comp.types.some((t) => t.startsWith(type))
      );
      return component?.long_name || "";
    }

    // Check if address_components is defined
    if (place?.address_components) {
      // Example usage to get the postal code
      const streetNumber = getLongNameByType(
        place.address_components,
        "street_number"
      );
      const route = getLongNameByType(place.address_components, "route");

      const locality = getLongNameByType(place.address_components, "locality");

      const sublocalitylevel1 = getLongNameByType(
        place.address_components,
        "sublocality_level_1"
      );

      const administrativeAreaLevel2 = getLongNameByType(
        place.address_components,
        "administrative_area_level_2"
      );
      const administrativeAreaLevel1 = getLongNameByType(
        place.address_components,
        "administrative_area_level_1"
      );
      const postalCode = getLongNameByType(
        place.address_components,
        "postal_code"
      );

      const combinedStreetName = UTILS.cleanAddressString(
        streetNumber,
        route,
        locality,
        sublocalitylevel1
      );

      setValue("StreetNumber", streetNumber || "");
      setValue("StreetName", combinedStreetName || "");
      setValue("City", administrativeAreaLevel2 || "");
      setValue("Suburb", locality || "");
      setValue("PostalCode", postalCode || "");
      setValue("Province", administrativeAreaLevel1 || "");
    }
  };

  const getUserDetails = async () => {
    if (!currentUser) {
      return;
    }
    try {
      showLoader();
      const response: IApiResponse<TApProfileDetailsResponse> = await API.post(
        EXECUTE_REQUEST_PATH,
        {
          entityName: "Supplier",
          requestName: "RetrieveProfileDetailsReq",
          inputParamters: {
            RecordId: currentUser.supplierId,
          },
        }
      );
      if (!response.isSuccess) {
        showErrorAlert(response.clientMessage);
        return;
      }

      const profileDetails = response.outputParameters.ProfileDetails;

      const initialFormValues: TAddressDetailsForm = {
        StreetNumber: profileDetails.address.streetNumber,
        StreetName: profileDetails.address.streetName,
        City: profileDetails.address.city,
        Suburb: profileDetails.address.suburb,
        PostalCode: profileDetails.address.postalCode,
        Province: profileDetails.address.province,
        addressId: profileDetails.address.addressId,
      };

      setInitialValues(initialFormValues); // Save initial values
      reset(initialFormValues); // Reset the form with the fetched values

      setAddressDetails(response.outputParameters.ProfileDetails.address);
    } catch (e) {
      showErrorAlert("An error occurred while fetching your details");
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    setSelectedPlace(null);
    getUserDetails();
  }, []);

  // Check if the form has been edited
  useEffect(() => {
    if (initialValues) {
      const isFormEdited =
        JSON.stringify(formValues) !== JSON.stringify(initialValues);
      setIsEdited(isFormEdited); // Set form as edited if values have changed
    }
  }, [formValues, initialValues]);

  const onSubmit = async (data: TAddressDetailsForm) => {
    try {
      if (!currentUser) {
        return;
      }
      setIsSubmitting(true);
      // We make an API call for each individual page because a user could land on the page/tab
      // directly via URL. In future we will migrate to Redux ToolKit where caching and state would be handled

      const response = await API.post(EXECUTE_REQUEST_PATH, {
        entityName: "Address",
        recordId: addressDetails?.addressId,
        requestName: "UpsertRecordReq",
        inputParamters: {
          Entity: {
            ...data,
            RelatedObjectId: currentUser.supplierId,
            RelatedObjectIdObjectTypeCode: "Supplier",
          },
        },
      });
      if (!response.isSuccess) {
        throw new Error("An error occurred while updating your details");
      }

      showSuccessAlert("Profile updated successfully");
      setInitialValues(data); // Reset initial values after successful save
      setIsEdited(false); // Mark form as not edited
    } catch (e) {
      showErrorAlert("An error occurred while updating your details");
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleOnSaveClick = () => handleSubmit(onSubmit)();

  const handleOnCancelClick = () => {
    if (initialValues) {
      reset(initialValues); // Reset form to the initial values
      setIsEdited(false); // Mark form as not edited
    }
  };
  return (
    <>
      <PageActionsBar
        title="Address information"
        subtitle="View your address details here."
        onSaveClick={handleOnSaveClick}
        disabled={isSubmitting}
      />
      <Grid item xs={12} py={2}>
        <Divider />
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} md={4}></Grid>
        <Grid
          item
          xs={12}
          md={7}
          display="flex"
          flexDirection="column"
          rowGap={2}
        >
          <Box>
            <GoogleAutocomplete onPlaceSelected={handlePlaceSelected} />
          </Box>
          <TextField
            label="Street Number"
            size="small"
            placeholder="90 Bekker Road"
            {...register("StreetNumber", { required: true })}
          />
          <TextField
            label="Street Address"
            size="small"
            placeholder="Hetford Office Park"
            {...register("StreetName", { required: true })}
          />
          <TextField
            label="City"
            size="small"
            placeholder="Johannesburg"
            {...register("City", { required: true })}
          />
          <Grid container item xs={12}>
            <Grid item xs={12} md={9}>
              <TextField
                label="Suburb"
                {...register("Suburb", { required: true })}
                size="small"
                placeholder="Waterfall"
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ pl: 2 }}>
              <TextField
                label="Postal Code"
                type="number"
                size="small"
                placeholder="0129"
                {...register("PostalCode", {
                  required: true,
                  pattern: NUMBERS_ONLY_REGEX,
                })}
              />
            </Grid>
          </Grid>

          <Select
            label="Province"
            options={PROVINCE_OPTIONS}
            size="small"
            {...register("Province", { required: true })}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} py={2}>
        <Divider />
      </Grid>
      <Grid item xs={12} py={2}>
        <Divider />
      </Grid>
      <Grid textAlign="right" item xs={12}>
        <Box display="inline-flex" gap={1}>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            disabled={!isEdited || isSubmitting} // Enable Cancel only if the form is edited
            onClick={handleOnCancelClick}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            type="button"
            color="primary"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircleLoader /> : undefined}
            onClick={handleOnSaveClick}
          >
            Save
          </Button>
        </Box>
      </Grid>
    </>
  );
}

export default Addresses;
