import React, { useEffect, useMemo, useState } from "react";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook"; 
import { Row, Col, Button } from "react-bootstrap";
import IntroSlider from "../components/introSlider";
import {
    Controller,
    FieldValues,
    SubmitHandler,
    useForm,
    useWatch,
} from "react-hook-form";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { toast } from "react-hot-toast";
import Select from "react-select";

import * as saIdParser from "south-african-id-parser";
import { FormHelperText } from "@mui/material";
import { EMAIL_VALIDATION_REGEX, PHONE_NUMBER_REGEX } from "../ui/v2/constants";

type FormValues = {
    email: string;
    channel: { value: string; label: string };
};

const options = [
    { value: "RSAID", label: "South African ID" },
    { value: "EMAIL", label: "Email Address" },
    { value: "MOBILE", label: "Mobile" },
];

export default function ForgotPassword() {
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useAppLoader(); 
    const commonApi = useCommonApi();
    const [send, setSend] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        reset,
    } = useForm<FormValues>({
        mode: "all",
    });
    const [searchParams] = useSearchParams();
    const type = searchParams.get("type");

    const onSubmit: SubmitHandler<FormValues> = (data) => {
        showLoader();
        commonApi.post(
            {
                entityName: "ExternalLogon",
                requestName: "ForgotPasswordExecuteRequest",
                inputParamters: {
                    ForgotPassword: {
                        Username: data.email,
                        Channel: data.channel.value,
                    },
                },
            },
            (message: string, resp: any) => {
                hideLoader();
                toast.success(message);
                setSend(true);
            },
            (message: string) => {
                hideLoader();
                toast.error(message);
            }
        );
    };

    const selectedChannel = useWatch({ control, name: "channel" }); 

    const getUserLabel = () => {
        switch (selectedChannel?.value) {
            case "RSAID":
                return "South African ID";
            case "EMAIL":
                return "Email Address";
            case "MOBILE":
                return "Mobile";
            default:
                return "Username";
        }
    };

    const selectedChannelValue = useMemo(() => {
        return selectedChannel?.value || options[0].value;
    }, [selectedChannel?.value])

    const validateUser = (value: string) => {
        const { isValid: isIdValid } = saIdParser.parse(value); 
        if (
            selectedChannelValue === "RSAID" &&
            !isIdValid
        ) {
            return "Please enter a valid South African ID.";
        }
        if(selectedChannelValue === "EMAIL" && !value.match(EMAIL_VALIDATION_REGEX)){
            return "Please enter a valid Email Address.";
        }
        if(selectedChannelValue === "MOBILE" && value.length > 12 && !value.match(PHONE_NUMBER_REGEX)){
            return "Please enter a valid Mobile Number.";
        }
        return true;
    };

    useEffect(() => {
        setValue('email', '', {shouldValidate: true})
    }, [selectedChannelValue])
 
    const location = useLocation();
  
    useEffect(() => {
      if (location.state && location.state.userLoginDetails) {
        console.log("state", location.state.userLoginDetails);
        const userLoginDetails = location.state.userLoginDetails;
        if (userLoginDetails.type) {
            const matchingType = options.find((option) => option.value === userLoginDetails.type);
            if(matchingType){ 
                reset({
                    channel: matchingType,
                    email: userLoginDetails.value
                })
            }
        }

      } 
    }, [location.state]);

    return (
        <React.Fragment>
            <div className="register_page login_page">
                <div className="register_left">
                    <div className="mob_logo">
                        <a href="/entry-type" className="logo_left">
                            <img src="/assets/img/logo.png" />
                        </a>
                    </div>
                    <div className="logo_txt">Forgot Password</div>

                    {send ? (
                        <div style={{ textAlign: "center" }}>
                            <p className="text-center">Please check your mail.</p>
                            <Button
                                variant="primary"
                                className="w-50 mt-2 "
                                type="submit"
                                onClick={() => {
                                    navigate("/entry-type");
                                }}
                            >
                                Login
                            </Button>
                        </div>
                    ) : (
                        <form
                            className="register_tab"
                            onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
                        >
                            <label htmlFor="exampleFormControlInput1" className="">
                                Select:
                            </label>

                            <Controller
                                name={"channel"}
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { name, value, onChange, onBlur } }) => {
                                    return (
                                        <>
                                            <Select
                                                name={name}
                                                className={
                                                    "form-control p-0" +
                                                    (errors[name] ? " is-invalid" : "")
                                                }
                                                options={options}
                                                placeholder={"username"}
                                                onChange={onChange}
                                                value={value}
                                                defaultValue={value}
                                            />
                                            {errors[name] && <FormHelperText error>{errors[name]?.message}</FormHelperText>}
                                        </>
                                    );
                                }}
                            />
                            <div className="mb-2">
                                <label
                                    htmlFor="exampleFormControlInput1"
                                    className={
                                        "form-label" + (errors?.email ? " is-invalid" : "")
                                    }
                                >
                                    Enter {getUserLabel()}:
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    maxLength={selectedChannelValue === "RSAID" ? 13 : 100}
                                    placeholder=""
                                    {...register("email", {
                                        required: true,
                                        validate: validateUser,
                                    })}
                                />
                                {errors?.email && (
                                    <FormHelperText error> {errors.email.message}
                                    </FormHelperText>
                                )}
                            </div>
                            <div className="">
                                {type === "s" ? (
                                    <a
                                        href="/login?type=s"
                                        style={{
                                            display: "flex",
                                            justifyContent: "end",
                                            width: "60px",
                                            marginLeft: "auto",
                                        }}
                                    >
                                        Sign In
                                    </a>
                                ) : (
                                    <a
                                        href="/login?type=p"
                                        style={{
                                            display: "flex",
                                            justifyContent: "end",
                                            width: "60px",
                                            marginLeft: "auto",
                                        }}
                                    >
                                        Sign In
                                    </a>
                                )}
                            </div>
                            <div className="mobbottom_fixbtn">
                                <Row className="mb-2">
                                    <Col
                                        md={12}
                                        className="text-end small forgot-password-btn"
                                        style={{ textAlign: "start" }}
                                    >
                                        <Button
                                            variant="primary"
                                            className="w-50 mt-2"
                                            type="submit"
                                        >
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </form>
                    )}
                </div>
                <IntroSlider />
            </div>
        </React.Fragment>
    );
}
