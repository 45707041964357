import { InfoOutlined } from "@mui/icons-material";
import { Alert, AlertProps, Typography } from "@mui/material";
import React from "react";
import { ThemeColors } from "../../styles/theme";
import { EMPTY_OBJECT } from "../../constants";

function OutlinedBanner({
  message,
  noBorder,
  subMessage,
  sx,
}: {
  message: string | React.ReactNode;
  noBorder?: boolean;
  subMessage?: string;
  sx?: AlertProps["sx"]; 
}) {
  const customSx = sx || EMPTY_OBJECT;
  return (
    <Alert
      variant="outlined"
      icon={<InfoOutlined />}
      severity="warning"
      sx={{
        borderColor: noBorder ? "transparent" : "#D0D5DD",
        fontWeight: 600,
        fontSize: 14,
        lineHeight: "20px",
        color: "#344054",
        borderRadius: 2,
        ...customSx,
      }}
    >
      {message}
      {subMessage && (
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: 14,
            color: ThemeColors.textTertiary,
          }}
        >
          {subMessage}
        </Typography>
      )}
    </Alert>
  );
}

export default OutlinedBanner;
