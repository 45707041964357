import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Container, Row, Col, Button } from 'react-bootstrap';

interface incomingProps {
    onSubmitEntityProviderForm: any;
    userDetails: any;
}

export default function UpdateEntityProviderForm({ onSubmitEntityProviderForm, userDetails }: incomingProps) {

    const { register, handleSubmit, formState: { errors, isDirty }, reset } = useForm();

    const onSubmit = (data: any) => {
        if (isDirty) {
            let params: any = {
                ...data
            }
            onSubmitEntityProviderForm(params)
        } else {
            onSubmitEntityProviderForm()
        }
    }

    useEffect(() => {
        if(userDetails){
            reset({
                TradingName: userDetails?.name,
                RegistrationName: userDetails?.registrationName,
                IDNumber: userDetails?.idNumber,
                VATNumber: userDetails?.vatNumber,
                Mobile: userDetails?.mobile,
                Telephone: userDetails?.telephone,
                Email: userDetails?.email
            })
        }
    }, [userDetails])


    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="tab_scrollcontent">
                    <div className="mb-2">
                        <label htmlFor="exampleFormControlInput1" className={"form-label" + (errors?.TradingName ? ' is-invalid' : '')}>Trading Name</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="" {...register("TradingName", { required: true, maxLength: 80 })} />
                        {errors?.TradingName ? <div className="invalid-feedback">Required</div> : null}
                    </div>
                    <div className="mb-2">
                        <label htmlFor="exampleFormControlInput2" className={"form-label" + (errors?.RegistrationName ? ' is-invalid' : '')}>Registered Name</label>
                        <input type="text" className="form-control" id="exampleFormControlInput2" placeholder="" {...register("RegistrationName", { required: true, maxLength: 80 })} />
                        {errors?.RegistrationName ? <div className="invalid-feedback">Required</div> : null}
                    </div>
                    {/* <div className="mb-2">
                        <label htmlFor="exampleFormControlInput3" className={"form-label" + (errors?.RegistrationNumber ? ' is-invalid' : '')}>Registred number</label>
                        <input type="text" className="form-control" id="exampleFormControlInput3" placeholder="" {...register("RegistrationNumber", { required: true, maxLength: 80 })} />
                        {errors?.RegistrationNumber ? <div className="invalid-feedback">Required</div> : null}
                    </div> */}

                    <div className="mb-2">
                        <label htmlFor="exampleFormControlInput34" className={"form-label" + (errors?.IDNumber ? ' is-invalid' : '')}>Registration Number</label>
                        <input type="text" className="form-control" id="exampleFormControlInput34" placeholder="" {...register("IDNumber", { required: true, maxLength: 80 })} />
                        {errors?.IDNumber ? <div className="invalid-feedback">Required</div> : null}
                    </div>
                    <div className="mb-2">
                        <label htmlFor="exampleFormControlInput4" className={"form-label" + (errors?.VATNumber ? ' is-invalid' : '')}>VAT Number</label>
                        <input type="text" className="form-control" id="exampleFormControlInput4" placeholder="" {...register("VATNumber", { required: false, maxLength: 80 })} />
                        {/* {errors?.VATNumber ? <div className="invalid-feedback">Required</div> : null} */}
                    </div>
                    <div className="mb-2">
                        <label htmlFor="exampleFormControlInput5" className={"form-label" + (errors?.Mobile ? ' is-invalid' : '')}>Mobile</label>
                        <input type="text" className="form-control" id="exampleFormControlInput5" placeholder=""  {...register("Mobile", { required: true, maxLength: 80 })} />
                        {errors?.Mobile ? <div className="invalid-feedback">Required</div> : null}
                    </div>

                    <div className="mb-2">
                        <label htmlFor="exampleFormControlInput7" className={"form-label" + (errors?.Email ? ' is-invalid' : '')}>Email</label>
                        <input type="text" className="form-control" id="exampleFormControlInput7" placeholder="" {...register("Email", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
                        {errors?.Email ? <div className="invalid-feedback">
                            {errors.Email.type === "required" ? 'Required' : null}
                            {errors.Email.type === "pattern" ? 'Invalid email address' : null}
                        </div> : null}
                    </div>
                </div>

                <Row className="align-items-center">
                    <Col md={6}>
                        <div className="mobbottom_fixbtn">
                            <button className="btn btn-primary w-50 mt-2" type="submit">Next</button>
                        </div>
                    </Col>
                </Row>

            </form>

        </React.Fragment>
    );
}
