import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  CircularProgressProps,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  MobileStepper,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ThemeColors } from "../../../styles/theme";
import {
  ArrowBack,
  ArrowOutward,
  CheckCircleOutline,
  ChevronRight,
  Close,
  MailOutline,
} from "@mui/icons-material";
import { URLS } from "../../../../../_config";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { css } from "@emotion/css";
import avatar1 from "../../../../../images/Rectangle 176.png";
import avatar2 from "../../../../../images/Rectangle 177.png";
import avatar3 from "../../../../../images/Rectangle 178.png";
import avatar4 from "../../../../../images/Rectangle 182.png";
import avatar5 from "../../../../../images/Rectangle 184.png";
import avatar6 from "../../../../../images/Rectangle 185.png";
import avatar7 from "../../../../../images/Rectangle 186.png";
import avatar8 from "../../../../../images/Rectangle 183.png";
import useSelfieDialog from "../useSelfieDialog";
import ResponsiveGridSpacer from "../../../components/ResponsiveGridSpacer";
import useRoomInspectionWizardDialog from "../useRoomInspectionWizardDialog";
import { API } from "../../../services/API";
import { EXECUTE_REQUEST_PATH } from "../../../constants";
import { IApiResponse } from "../../../types/IApiResponse";
import useCurrentUser from "../../../hooks/useCurrentUser";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../components/Notify";
import CircleLoader from "../../../components/CircleLoader";
import { UTILS } from "../../../../../utils";
import { SELFIE_DOCUMENT_TYPE } from "../../../../../constants";
import InitialForm from "../InitialForm";
import { FormProvider, useForm } from "react-hook-form";
import OtpInput from "../../../components/OtpInput";

const OTP_LENGTH = 4;

const images = [
  avatar4,
  avatar8,
  avatar5,
  avatar1,
  avatar2,
  avatar3,
  avatar6,
  avatar7,
];

const FeatureIcon = ({ icon }: { icon: "mail" | "checkmark" }) => (
  <Box sx={{ border: "1px solid #EAECF0", p: 1, borderRadius: 2 }}>
    {icon === "mail" ? <MailOutline /> : <CheckCircleOutline />}
  </Box>
);

const FeatureText = ({ children }: { children: string }) => (
  <Typography
    align="center"
    sx={{
      color: "#101828",
      fontSize: 24,
      fontFamily: "Inter",
      weight: 600,
      lineHeight: "32px",
      my: 1,
    }}
  >
    {children}
  </Typography>
);

const FeatureSubText = ({ children }: { children: string }) => (
  <Typography
    align="center"
    sx={{
      color: "#475467",
      fontSize: 16,
      fontFamily: "Inter",
      weight: 600,
      lineHeight: "24px",
      my: 1,
    }}
  >
    {children}
  </Typography>
);

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          textAlign="center"
          color="text.secondary"
        >
          <Box sx={{ fontSize: 8, display: "inline" }} component="span">
            Uploading
          </Box>
          <br />
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const steps = [
  {
    label: 1,
    actionText: "Enter code manually",
    content: () => (
      <>
        <Grid item xs={12}>
          <Box display="flex" p={3} justifyContent="center">
            <FeatureIcon icon="mail" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FeatureText>Verify Your Check-In with a Quick OTP!</FeatureText>
        </Grid>
        <Grid item xs={12}>
          <FeatureSubText>
            Ready to check in? Just enter the OTP sent to your phone ending in
            **** to confirm it's you. Quick and easy!
          </FeatureSubText>
        </Grid>
      </>
    ),
  },
  {
    label: 2,
    actionText: "Verify email",
    showOtpInput: true,
    content: ({ otpMessage }: { otpMessage?: string }) => (
      <>
        <Grid item xs={12}>
          <Box display="flex" p={3} justifyContent="center">
            <FeatureIcon icon="mail" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FeatureText>Verify Your Check-In with a Quick OTP!</FeatureText>
        </Grid>
        <Grid item xs={12}>
          <FeatureSubText>
            {otpMessage ||
              "Ready to check in? Just enter the OTP sent to your phone ending in **** to confirm it's you. Quick and easy!"}
          </FeatureSubText>
        </Grid>
      </>
    ),
    footer: (
      <>
        <Grid item xs={12}></Grid>
      </>
    ),
  },
  {
    label: 3,
    actionText: "Continue to Check-In",
    content: () => (
      <>
        <Grid item xs={12}>
          <Box display="flex" p={3} justifyContent="center">
            <FeatureIcon icon="checkmark" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FeatureText>Verified.</FeatureText>
        </Grid>
        <Grid item xs={12}>
          <FeatureSubText>
            Now, let's continue to the check-in and get this show on the road!
          </FeatureSubText>
        </Grid>
      </>
    ),
  },
  {
    label: 4,
    actionText: "Start Section",
    content: () => <InitialForm />,
  },
];


function VerifyOtpFlow() {
  const theme = useTheme();
  const methods = useForm();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isWorking, setIsWorking] = useState(false);
  const [otpMessage, setOtpMessage] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [otp, setOtp] = useState("");
  const currentUser = useCurrentUser();

  const handleNextClick = async () => {
    if (activeStep === steps.length - 1) {
      handleClickOpen();
      return;
    }
    if (!currentUser) {
      console.debug("No current user set");
      return;
    }
    if (activeStep === 0) {
      setIsWorking(true);
      try {
        // Call API to get OTP
        const response: IApiResponse<any> = await API.post(
          EXECUTE_REQUEST_PATH,
          {
              entityName: currentUser.relatedObjectIdObjectTypeCode,
              requestName: "GeneratOTPExecuteRequest",
              RecordId: currentUser?.supplierId||currentUser.recordId,
          }
        );
        if (!response.isSuccess) {
          showErrorAlert("Failed to confirm OTP");
          return;
        }
        setOtpMessage(response.clientMessage);
        setActiveStep(1);
      } catch (e) {
        console.error(e);
        showErrorAlert("An error occurred");
      } finally {
        setIsWorking(false);
      }
    } else if (activeStep === 1) {
      try {
        // Call API to get OTP
        const response: IApiResponse<any> = await API.post(
          EXECUTE_REQUEST_PATH,
          {
            entityName: "Employee",
            requestName: "ConfirmOTPExecuteRequest",
            recordId: currentUser.recordId,
            inputParamters: {
              OTPInformation: {
                OTPNo: otp,
              },
            },
          }
        );
        if (!response.isSuccess) {
          showErrorAlert("Failed to confirm OTP");
          return;
        }
        setOtpMessage(response.clientMessage);
        setActiveStep(2);
      } catch (e) {
        console.error(e);
        showErrorAlert("An error occurred");
      } finally {
        setIsWorking(false);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleOnOtpChange = (_: string) => setOtp(_);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (e: unknown, reason?: string) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setOpen(false);
  };

  const [progress, setProgress] = React.useState(14);

  const [uploadState, setUploadState] = useState<{
    state: "idle" | "uploading" | "success";
  }>({ state: "idle" });

  const { id: checkInId } = useParams<{ id: string }>();

  const progressTimerRef = useRef<any>();

  useEffect(() => {
    if (uploadState.state === "uploading") {
      progressTimerRef.current = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 97 ? 97 : prevProgress + 1
        );
      }, 800);
      return () => {
        clearInterval(progressTimerRef.current);
      };
    } else if (uploadState.state === "success") {
      setProgress(97);
      if (progressTimerRef.current) {
        clearInterval(progressTimerRef.current);
      }
    }
  }, [uploadState.state]);

  const navigate = useNavigate();

  const uploadSelfie = async (imageSrc: string) => {
    try {
      const values: Record<string, unknown> = methods.getValues();
      setUploadState({ state: "uploading" });
      const response: IApiResponse<unknown> = await API.post(
        EXECUTE_REQUEST_PATH,
        {
          entityName: "CheckIn",
          requestName: "UpsertRecordReq",
          recordId: checkInId,
          inputParamters: {
            Entity: {
              CheckInDate: new Date().toISOString(),
              RoomName: values.roomName,
              CapacityId: values.roomType,
              RoomSize: values.roomSize,
              FloorNumber: values.floorNumber,
            },
            Documents: [
              {
                FileName: "selfie",
                FileExtention: "jpg",
                DocumentTypeId: SELFIE_DOCUMENT_TYPE,
                FileContent: UTILS.extractBase64FromDataUrl(imageSrc),
              },
            ],
          },
        }
      );
      if (response.isSuccess) {
        setUploadState({ state: "success" });
      } else {
        showErrorAlert("An error occurred");
        setUploadState({ state: "idle" });
      }
    } catch (e) {
      console.error(e);
      showErrorAlert("An error occurred");
      setUploadState({ state: "idle" });
    }
  };

  const {
    close: closeSelfieDialog,
    open: openSelfieDialog,
    component: selfieDialogComponent,
  } = useSelfieDialog({
    title: "Verify your identity",
    onClose: (imageSrc: string | null) => {
      if (!imageSrc) {
        return;
      }
      uploadSelfie(imageSrc);
    },
  });

  const {
    open: openRoomInspectionDialog,
    component: roomInspectionDialogComponent,
  } = useRoomInspectionWizardDialog({
    onCompleted: async () => {
      try {
        const response: IApiResponse<unknown> = await API.post(
          EXECUTE_REQUEST_PATH,
          {
            entityName: "CheckIn",
            requestName: "UpsertRecordReq",
            recordId: checkInId,
            inputParamters: {
              Entity: {
                CheckInStatusId: 1003,
              },
            },
          }
        );
        if (response.isSuccess) {
          setUploadState({ state: "success" });
          showSuccessAlert("Check in completed successfully").then(() => {
            navigate(URLS.STUDENT.CHECK_IN_LISTING);
          });
        } else {
          showErrorAlert("An error occurred");
          setUploadState({ state: "idle" });
        }
      } catch (e) {
        console.log(e);
      }
    },
    checkInId,
  });

  const getContentStyle = (): any => {
    switch (uploadState.state) {
      case "uploading":
        return {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        };
      case "success":
        return {
          display: "flex",
          flexDirection: "column",
          background: ThemeColors.primaryOrange,
          color: ThemeColors.white,
        };
      default:
        return undefined;
    }
  };

  const continueToInspection = () => {
    closeSelfieDialog();
    openRoomInspectionDialog();
  };

  return (
    <>
      <Box display="flex" justifyContent="center">
        <MobileStepper
          variant="dots"
          steps={steps.length}
          position="static"
          sx={{ maxWidth: 180 }}
          activeStep={activeStep}
          backButton={
            <Typography fontSize={14}>
              Step {activeStep + 1} of {steps.length} &nbsp;
            </Typography>
          }
          nextButton={<></>}
        ></MobileStepper>
      </Box>
      <Grid container>
        <ResponsiveGridSpacer width={4} />
        <Grid container item xs={122} md={4}>
          <FormProvider {...methods}>
            <>{steps[activeStep].content({ otpMessage })}</>
          </FormProvider>
          {steps[activeStep].showOtpInput && (
            <Grid item xs={12}>
              <OtpInput onChange={handleOnOtpChange} length={OTP_LENGTH} />
            </Grid>
          )}
          <Grid item xs={12} sx={{ py: 4 }}>
            <Button
              variant="contained"
              endIcon={isWorking ? <CircleLoader /> : <ChevronRight />}
              onClick={handleNextClick}
              disabled={
                isWorking ||
                (steps[activeStep].showOtpInput && otp.length < OTP_LENGTH)
              }
              fullWidth
            >
              {steps[activeStep].actionText || "Next"}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              component={Link}
              to={`${URLS.STUDENT.CHECK_IN_LISTING} `}
              variant="text"
              color="secondary"
              startIcon={<ArrowBack />}
              fullWidth
            >
              Back to Applications
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
        <AppBar
          sx={{ position: "relative" }}
          elevation={0}
          color={uploadState.state === "success" ? "primary" : "transparent"}
        >
          <Toolbar>
            {uploadState.state !== "uploading" && (
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <Close />
              </IconButton>
            )}
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              &nbsp;
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent sx={getContentStyle()}>
          {uploadState.state === "uploading" && (
            <DialogContentText sx={{ display: "flex" }}>
              <CircularProgressWithLabel value={progress} size={90} />
            </DialogContentText>
          )}
          {uploadState.state === "success" && (
            <>
              <Typography
                color="inherit"
                sx={{
                  color: ThemeColors.white,
                  fontSize: 36,
                  lineHeight: "44px",
                  fontFamily: "Inter",
                  fontWeight: 600,
                  my: 3,
                }}
              >
                Sweet! We're Verifying you now
              </Typography>
              <Typography sx={{ color: ThemeColors.white, pb: 10 }}>
                We'll let you know when the check in is completed, This usually
                takes between 5 minutes to an hour but sometimes takes up to 48
                hours
              </Typography>
              <Box
                flex={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  pb: 4,
                }}
              >
                <Box sx={{ background: ThemeColors.white, borderRadius: 1 }}>
                  <Button
                    endIcon={<ArrowOutward />}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={continueToInspection}
                  >
                    Continue to inspection
                  </Button>
                </Box>
              </Box>
            </>
          )}
          {uploadState.state === "idle" && (
            <DialogContentText>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: 36,
                  lineHeight: "44px",
                  color: "#344054",
                  mb: 5,
                }}
              >
                Take a selfie
              </Typography>
              <Typography>
                Take a quick selfie to confirm your identity and complete the
                check-in process effortlessly!
              </Typography>
              <Box sx={{ mx: -3, my: 2, overflow: "clip" }}>
                <Grid container sx={{ mr: -4 }}>
                  {images.map((img) => (
                    <Grid item xs={3} container sx={{ justifyContent: "end" }}>
                      <Box
                        sx={{
                          height: 70,
                          width: 80,
                          display: "flex",
                          justifyContent: "end",
                        }}
                        pb={1}
                      >
                        <img
                          src={img}
                          alt=""
                          className={css({
                            height: "100%",
                            objectFit: "contain",
                            marginRight: 3,
                          })}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Typography
                sx={{
                  my: 5,
                  pl: 4,
                  pr: 8,
                  color: "#344054",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "24px",
                }}
              >
                Snap a flawless selfie to complete your verification process!
              </Typography>
              <Button
                variant="contained"
                fullWidth
                endIcon={<ArrowOutward />}
                onClick={openSelfieDialog}
              >
                Continue
              </Button>
            </DialogContentText>
          )}
        </DialogContent>
      </Dialog>
      {selfieDialogComponent}
      {roomInspectionDialogComponent}
    </>
  );
}

export default VerifyOtpFlow;
