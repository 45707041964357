import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { MdAddCircle } from 'react-icons/md';
import Header from "./student/components/header";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import { useResidenceApi } from "../_common/hooks/api/residenceApiHook";
import { APP_URL, URLS } from "../_config";
import LeftPanel from "../components/leftPanel";

export default function PaymentInfo() {
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useAppLoader();
    const commonApi = useCommonApi();
    const residenceApi = useResidenceApi();
    const residenceID = sessionStorage.getItem('residenceID');
    const [amount, setAmount] = useState<any>(0)
    const [payFastData, setPayFastData] = useState<any>(null);
    const payFastForm = useRef<any>();

    const  regrade:any = sessionStorage.getItem('regrade');

    const createInvoice = (type: string) => {
        showLoader();
        commonApi.post({
            "entityName": "Facility",
            "recordId": residenceID,
            "requestName": "UpsertRecordReq",
            "inputParamters": {
                "Entity": {
                    "AdminFeeSstatus": "585"
                }
            }
        }
            , (message: string, resp: any) => {
                hideLoader();
                if (type == "payNow") {
                    // makePayment(resp.outputParameters.UpsertResponse.recordId);
                    navigate('/payment-options')

                } else {
                    sessionStorage.removeItem("residenceID")
                    sessionStorage.removeItem("residenceName")
                    navigate('/dashboard')
                }
            }, (message: string) => {
                hideLoader();
                toast.error(message);
            });
    }

    const makePayment = (recordId: any) => {
        // console.log({recordId}) 
        // return;
        showLoader();
        commonApi.post({
            "entityName": "Facility",
            "recordId": recordId,
            "requestName": "MakePaymentReq"
        }
            , (message: string, resp: any) => {
                setPayFastData(resp.outputParameters)
                // callPayFastApi(resp.outputParameters)
                hideLoader();
                //  navigate('/')
            }, (message: string) => {
                hideLoader();
                toast.error(message);
            });
    }

    const getPaymentValue = () => {
        residenceApi.getPaymentValue({
            FacilityId: residenceID
        }, (message: string, resp: any) => {
            setAmount(resp[0].amount ? resp[0].amount : 250)
        }, (message: string) => {
        });
    }

    useEffect(() => {
        getPaymentValue();
       
    }, [])

    useEffect(() => {
        if (payFastData) {
            payFastForm.current.submit();
        }
    }, [payFastData])

    return (
        <React.Fragment>
            <div className="dashboard">
                <Header />
                <section className="container">
                    <div className="custome-container-inner d-flex">
                        <LeftPanel page={5}/>
                    <div className="white-block p-3">
                        <div className="payment-screen-panel">
                            <p>An inspector will be assigned to inspect your student residence for accreditation.<br/><br/><b>Please note that the amount stipulated is non-refundable</b></p>
                            <h2 className="mb-3">R{amount.toFixed(2)}</h2>
                            <p>You have an option to pay inspection fee now or later.</p>
                            <div className="d-flex case-details-btns mt-4 w-100">
                                <div className="w-100 m-1">
                                    <button type="button" className="btn btn-primary w-100 " data-bs-toggle="button" onClick={() => createInvoice("payNow")} >Pay Now</button>
                                </div>
                                <div className="w-100 m-1">
                                    <button type="button" className="btn btn-outline-secondary w-100" data-bs-toggle="button" onClick={() => createInvoice("payLater") + 'invoice created'} >Pay Later</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    
                </section>
                {payFastData ?
                    <form action={payFastData.action} ref={payFastForm} method="post">
                        <input type="hidden" name="merchant_id" value={payFastData.merchant_id} />
                        <input type="hidden" name="merchant_key" value={payFastData.merchant_key} />
                        <input type="hidden" name="amount" value={payFastData.amount} />
                        <input type="hidden" name="item_name" value={payFastData.item_name} />
                        <input type="hidden" name="return_url" value={APP_URL + URLS.SUCCESS_PAGE + '?amount=' + payFastData.amount + (regrade?'&regrade=true':'')} />
                        <input type="hidden" name="cancel_url" value={APP_URL + URLS.CANCEL_PAGE + '?amount=' + payFastData.amount + (regrade?'&regrade=true':'')} />
                        <input type="hidden" name="notify_url" value={APP_URL + URLS.NOTIFY_PAGE + '?amount=' + payFastData.amount + (regrade?'&regrade=true':'')} />
                        <input type="hidden" name="name_first" value={payFastData.name_first} />
                        <input type="hidden" name="name_last" value={payFastData.name_last} />
                        <input type="hidden" name="email_address" value={payFastData.email_address} />
                        <input type="hidden" name="cell_number" value={payFastData.cell_number} />
                        <input type="hidden" name="m_payment_id" value={payFastData.m_payment_id} />
                        <input type="hidden" name="item_description" value={payFastData.item_description} />
                        <input type="submit" hidden />
                    </form>
                    : null
                }
            </div>
        </React.Fragment>
    );
}