import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE } from '../../_config'
import { CallApi } from './api/callApi';

const API_BASE_URL = process.env.REACT_APP_API_URL;

export function* post(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_BASE_URL+'/ExecuteRequest', data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.isSuccess  && resp.data.outputParameters) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* get(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_BASE_URL+'/ExecuteRequest', data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.isSuccess  && resp.data.outputParameters) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getCaseDetails(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.CASES.DETAILS, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
export function* getFacilityPricing(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_FACILITY_PRICING, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
