import { Box, CircularProgress } from '@mui/material';
import React from 'react';

function LoadingPage() {
  return (
    <Box sx={{ p: 5, display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress />
    </Box>
  );
}

export default LoadingPage;
