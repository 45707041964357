
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Row, Col } from 'react-bootstrap';
import { DIGITS_ONLY_PATTERN, INPUT_MAX_LENGTHS } from "../../_config"; 
import { InputField } from "../InputField";
interface incomingProps {
  onSubmitIndividualProviderForm: any;
  userDetails: any;
}

export default function UpdateIndividualProviderForm({ onSubmitIndividualProviderForm, userDetails }: incomingProps) {

  const { register, handleSubmit, formState: { errors, isDirty }, reset } = useForm();


  const onSubmit = (data: any) => {
    if (isDirty) {
      let params: any = {
        ...data
      }
      onSubmitIndividualProviderForm(params)
    } else {
      onSubmitIndividualProviderForm()
    }
  }

  useEffect(() => {
    if (userDetails) {
      reset({
        FirstName: (userDetails?.name).split(' ')[0],
        LastName: (userDetails?.name).split(' ')[1] ? (userDetails?.name).split(' ')[1] : "",
        IDNumber: userDetails?.idNumber,
        Mobile: userDetails?.mobile,
        Telephone: userDetails?.telephone,
        Email: userDetails?.email
      })
    }
  }, [userDetails])

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tab_scrollcontent">
          <div className="mb-2">
            <label htmlFor="exampleFormControlInput1" className={"form-label " + errors.FirstName ? 'is-invalid' : ''}>First name</label>
            <input type="text" className="form-control" id="exampleFormControlInput1" {...register("FirstName", { required: true, maxLength: INPUT_MAX_LENGTHS.firstName })} />
            {errors?.FirstName ? <div className="invalid-feedback">Required</div> : null}
          </div>
          <div className="mb-2">
            <label htmlFor="exampleFormControlInput2" className={"form-label " + errors.LastName ? 'is-invalid' : ''}>Last name</label>
            <input type="text" className="form-control" id="exampleFormControlInput2" {...register("LastName", { required: true, maxLength: INPUT_MAX_LENGTHS.lastName })} />
            {errors?.LastName ? <div className="invalid-feedback">Required</div> : null}
          </div>
          <div className="mb-2">
            <label htmlFor="exampleFormControlInput3" className={"form-label " + errors.IDNumber ? 'is-invalid' : ''}>ID number</label>
            <input type="text" className="form-control" id="exampleFormControlInput3" {...register("IDNumber", { required: true, maxLength: INPUT_MAX_LENGTHS.idNumber })} />
            {errors?.IDNumber ? <div className="invalid-feedback">Required</div> : null}
          </div>

          <div className="mb-2">
            <label htmlFor="exampleFormControlInput5" className={"form-label " + errors.Mobile ? 'is-invalid' : ''}>Mobile</label>
            <input type="text" className="form-control" id="exampleFormControlInput5" {...register("Mobile", { required: true, maxLength: INPUT_MAX_LENGTHS.mobile })} />
            {errors?.Mobile ? <div className="invalid-feedback">Required</div> : null}
          </div>
          <div className="mb-2">
            {/* <InputField
              type="tel"
              label="Telephone"
              id="Telephone"
              errors={errors}
              register={register} 
              required
              registerParams={{
                maxLength: {
                  value: INPUT_MAX_LENGTHS.telephone,
                  message: `Must be at most ${INPUT_MAX_LENGTHS.telephone} characters`,
                },
                pattern: {
                  value: DIGITS_ONLY_PATTERN,
                  message: 'Numbers only',
                },
              }}
            /> */}
          </div>
          <div className="mb-2">
            <label htmlFor="exampleFormControlInput7" className={"form-label " + errors.Email ? 'is-invalid' : ''}>Email</label>
            <input type="text" className="form-control" id="exampleFormControlInput7" {...register("Email", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
            {errors?.Email ? <div className="invalid-feedback">
              {errors.Email.type === "required" ? 'Required' : null}
              {errors.Email.type === "pattern" ? 'Invalid email address' : null}
            </div> : null}
          </div>
        </div>

        <Row className="align-items-center">
          <Col md={6}>
            <div className="mobbottom_fixbtn">
              <button className="btn btn-primary w-50 mt-2" type="submit">Next</button>
            </div>
          </Col>
        </Row>

      </form>

    </React.Fragment>
  );
}
