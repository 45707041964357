import { LockOpenOutlined, LockOutlined } from '@mui/icons-material';
import { Button, IconButton, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

const SectionHeader = ({ title, subtitle, locked, onLockClick }: { title: string; subtitle?: string, locked?: boolean, onLockClick?: () => void }) => (
  <>
    <Typography color="#344054" fontWeight="bold" fontSize={14}>
      {title} {Boolean(onLockClick) && <Tooltip arrow title={`The section is ${locked ? 'locked' : 'unlocked'}`} ><IconButton size='small' onClick={onLockClick}>{locked? <LockOutlined /> : <LockOpenOutlined />}</IconButton></Tooltip>}
      {locked ? <Button  className="w-50 " type="button"onClick={onLockClick}>Receive OTP</Button> : null}
    </Typography>
    <Typography color="#475467" fontSize={14} pb={2}>
      {subtitle}
    </Typography>
  </>
);

export default SectionHeader;
