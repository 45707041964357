import {
  Avatar,
  Box,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import LegacyPageLayout from "../../../../components/LegacyPageLayout";
import PageHeadingText from "../../components/PageHeadingText";
import { EXECUTE_REQUEST_PATH } from "../../constants";
import { IApiResponse } from "../../types/IApiResponse";
import { DEFAULT_PAGE_SIZE } from "../../../../constants";
import useCurrentUser from "../../hooks/useCurrentUser";
import { API } from "../../services/API";
import { useAppLoader } from "../../../../_common/hooks/common/appLoaderHook";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { URLS } from "../../../../_config";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import React from 'react';

type CheckInListingResponseType = {
  Summary: {
    totalCases: number;
    openCases: number;
    closedCases: number;
    onHoldCases: number;
  };
  PageSize: number;
  RecordCount: number;
  Pages: number;
  Listing: {
    address: string;
    checkInId: string;
    checkInStatus: 'Pending' | "Draft" | "Complete";
    mobile: string;
    month:
      | "January"
      | "February"
      | "March"
      | "April"
      | "May"
      | "June"
      | "July"
      | "August"
      | "September"
      | "October"
      | "November"
      | "December";
    studentResidence: string;
  }[];
};

function StudentCheckInListingPage() {
  const currentUser = useCurrentUser();
  const { showLoader, hideLoader } = useAppLoader();
  const [details, setDetails] = useState<CheckInListingResponseType>();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPage = searchParams.get("page")
    ? parseInt(searchParams.get("page") as string)
    : 1;
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchedText, setSearchedText] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(
    initialPage > 0 ? initialPage : 1
  );

  const getApiData = async (_currentPage: number) => {
    if (!currentUser) {
      return;
    }
    try {
      showLoader();
      const caseListingRequest: IApiResponse<CheckInListingResponseType> =
        await API.post(EXECUTE_REQUEST_PATH, {
          entityName: "CheckIn",
          requestName: "RetrieveCheckInListing",
          inputParamters: {
            UserId: currentUser.supplierId
              ? currentUser.supplierId
              : currentUser.recordId,
            SearchText: searchedText,
            PageNumber: currentPage,
            PageSize: DEFAULT_PAGE_SIZE,
          },
        });
      console.log(caseListingRequest.outputParameters);
      setDetails(caseListingRequest.outputParameters);
    } catch (e) {
      console.error(e);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getApiData(currentPage);
  }, [currentPage, searchedText]);

  return (
    <LegacyPageLayout paddingTop={3}>
      <Box sx={{ px: { xs: 2, md: 4, lg: 8, xl: 16 } }}>
        <PageHeadingText text="Check - ins" />

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead
                sx={{
                  backgroundColor: "#F9FAFB",
                }}
              >
                <TableRow>
                  <TableCell>Property</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {details?.Listing.map((entry) => (
                  <TableRow
                    key={entry.checkInId}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <ApartmentIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={entry.studentResidence}
                          secondary={entry.checkInStatus}
                        />
                      </ListItem>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="text"
                        disabled={entry.checkInStatus !== "Pending"}
                        component={Link}
                        to={`${URLS.STUDENT.CHECK_IN_LISTING}/${entry.checkInId}`}
                        startIcon={<RoomOutlinedIcon />}
                      >
                        Check-in
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </LegacyPageLayout>
  );
}

export default StudentCheckInListingPage;
