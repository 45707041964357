import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  startOfMonth,
  endOfMonth,
  addMonths,
  subDays,
  addDays,
} from "date-fns";
import "./index.css";

// Helper to calculate the start and end of the next months
const calculateMonthStartEnd = (date: Date, monthsToAdd: number) => ({
  start: startOfMonth(addMonths(date, monthsToAdd)),
  end: endOfMonth(addMonths(date, monthsToAdd)),
});

interface MonthPeriodSelectProps {
  mode: "start" | "end";
  applicationDates?: {
    PlannedMoveOutDate?: Date;
  };
  selectedDate?: Date;
  onChange: (date: Date | null) => void;
  label?: string;
  minDate?: Date;
}

const today = new Date();

const DateMonthPeriodSelect = React.forwardRef<
  HTMLDivElement,
  MonthPeriodSelectProps
>(
  (
    {
      mode,
      applicationDates,
      selectedDate,
      onChange,
      label,
      minDate,
      ...props
    }: MonthPeriodSelectProps,
    ref
  ) => {
    const defaultMinDate =
      mode === "start" ? new Date() : applicationDates?.PlannedMoveOutDate;

    const [startDate, setStartDate] = useState<Date | null>(new Date());

    // const highlightWithRanges = [
    //   {
    //     "react-datepicker__day--highlighted-custom-1": [
    //       subDays(new Date(), 4),
    //       subDays(new Date(), 3),
    //       subDays(new Date(), 2),
    //       subDays(new Date(), 1),
    //     ],
    //   },
    // ];

    const movingDateObj = minDate ? new Date(minDate) : new Date();

    useEffect(() => {
      if (movingDateObj && movingDateObj > today) {
        setStartDate(movingDateObj);
      } else {
        setStartDate(today);
      }
    }, []);

    return (
      <div ref={ref} className="date-picker-container">
        {label && <label>{label}</label>}
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          dateFormat="dd MMMM yyyy"
          showIcon
          toggleCalendarOnIconClick
          // highlightDates={highlightWithRanges}
          // includeDateIntervals={[
          //   {
          //     start: subDays(new Date(movingDateObj), 0),
          //     end: addDays(new Date("2024-11-30"), 0),
          //   },
          // ]}
          {...props}
        />
      </div>
    );
  }
);

export default DateMonthPeriodSelect;
