import { Dialog } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import React, { useEffect } from "react";

function SignWellWindow({
  url,
  show,
  onClose,
}: {
  url: string;
  show: boolean;
  onClose: () => void;
}) {
  const isOpenedRef = React.useRef(false);
  useEffect(() => {
    if (isOpenedRef.current || !show || !url) {
      return;
    }
    const signWellEmbed: any = new window.SignWellEmbed({
      url,
      events: {
        completed: (e: any) => {
          console.log("completed event: ", e);
        },
        closed: (e: any) => {
          console.log("closed event: ", e);
          onClose();
          window.location.reload(); //refresh page on complete
        },
      },
    });
    signWellEmbed.open();
    isOpenedRef.current = true;
  }, [url]);

  if (!show) {
    return null;
  }

  return <Backdrop open>&nbsp; &nbsp;</Backdrop>;
}

export default SignWellWindow;
