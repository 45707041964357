import { CheckCircleOutline, InfoOutlined, MailOutline } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";

const FeatureIcon = ({ icon }: { icon: "mail" | "checkmark" | ReactNode }) => (
  <Box sx={{ border: "1px solid #EAECF0", p: 1, borderRadius: 2, display: 'inline-block' }}>
    { icon === "mail" ? <MailOutline /> : icon === "checkmark" ? <CheckCircleOutline />  : icon }
  </Box>
);

const FeatureText = ({ children }: { children: string }) => (
  <Typography
    align="center"
    sx={{
      color: "#101828",
      fontSize: 24,
      fontFamily: "Inter",
      weight: 600,
      lineHeight: "32px",
      my: 1,
    }}
  >
    {children}
  </Typography>
);

const FeatureSubText = ({ children }: { children: string }) => (
  <Typography
    align="center"
    sx={{
      color: "#475467",
      fontSize: 16,
      fontFamily: "Inter",
      weight: 600,
      lineHeight: "24px",
      my: 1,
    }}
  >
    {children}
  </Typography>
);

const FeatureBlocks = {
  Icon: FeatureIcon,
  Text: FeatureText,
  SubText: FeatureSubText,
};

export default FeatureBlocks;
