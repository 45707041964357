import { createTheme } from "@mui/material/styles";

export const ThemeColors = {
  lightGrey: "#F5F5F5",
  primaryOrange: "#F88A0B",
  dircoYellow: "#F9671C",
  white: "#ffffff",
  disabled: "#F9FAFB",
  neutralGrey: "#94A3B8",
  textTertiary: "#475467",
  textSecondary: "#344054",
  successSecondary: "#17B26A",
  bgPrimary: "#F2F4F7",
  textPrimary: "#101828",
  utilitySuccessBorder: "#ABEFC6",
  utilityErrorBorder: "#FECDCA",
  utilitySuccessBackground: "#ECFDF3",
  utilityErrorBackground: "#FEF3F2",
  utilitySuccessText: "#067647",
  utilityErrorText: "#B42318",
  primaryPurple: "#6941C6",
  legacyOrange: "#D36E28",
  borderPrimary: "#D0D5DD",
};

export const LegacyCompatOverrides = {
  button: {
    sx: { borderRadius: 0, "&:hover": { color: ThemeColors.white } },
    disableElevation: true,
  },
};

export const SharedStyles = {
   primaryBorderStyles : {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: ThemeColors.legacyOrange, // Change the border color
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: ThemeColors.legacyOrange, 
  },
  }
}

const tableOverrides = {
  MuiDataGrid: {
    styleOverrides: {
      root: {
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: ThemeColors.disabled,
        },
        "& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within":
          {
            outline: "none",
          },
      },
    },
  },
} as any;

const theme = createTheme({
  palette: {
    primary: {
      main: ThemeColors.primaryOrange,
      contrastText: ThemeColors.white,
    },
    secondary: {
      main: "#475467",
    },
  },
  typography: {
    fontFamily: ["Inter", "Arial", "sans-serif"].join(","),
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minHeight: "44px",
          fontSize: "1rem",
          fontWeight: 600,
          lineHeight: "1.5rem",
          fontFamily: "Inter",
          borderRadius: 6,
        },
      },
    },
    ...tableOverrides,
  },
});
export default theme;
