import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Carousel } from 'react-bootstrap';
import Slider from "react-slick";
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
interface incomingProps {

}

export default function IntroSlider({ }: incomingProps) {

	const [currentSlideNo, setCurrentSlideNo] = useState<number>(0)

	const onSlide = (e:any) => {
		setCurrentSlideNo(e)
	}


	return (
		<React.Fragment>
			<div className={"register_right bg" + currentSlideNo}>
				<div className="msg-slider">
					<div className="area">
						<Carousel onSlide={onSlide}>
							<Carousel.Item>
								<div className="intro-slide">
									<p>Register your student residence with NSFAS we will help you find the right students for your student residence facility. Just a few easy steps.</p>
									<ol type="1">
										<li>Register your student residence as an AP.</li>
										<li>Pay for an inspection, after inspection it will be approved.</li>
										<li>Once approved and accredited it will be visible for students to apply.</li>
										<li>Upon approval of application, you will sign a lease agreement.</li>
									</ol>
								</div>
							</Carousel.Item>
							<Carousel.Item>
								<div className="intro-slide black">
									<p>The National Student Financial Aid Scheme and the Department of Higher Education and Training take very stringent measures to ensure our
										students have accommodation that is conducive to learning. A standardised approach is applied to the inspection and grading of all properties
										for accreditation. All accommodation is accredited against a grading system based on the “Policy on the Minimum Norms and Standards for
										Student Housing at Public Universities” published by the Department of Higher Education and Training in gazette No 39238 on 29 September
										2015
									</p>
								</div>
							</Carousel.Item>
							<Carousel.Item>
								<div className="intro-slide black">
									<ul style={{ listStyle: "none" }}>
										<li>Are you a NSFAS funded student? Follow these easy steps to apply for accommodation</li>
										<li>Register your profile on the portal</li>
										<li>Apply for accommodation</li>
										<li>Upon approval from an AP, you will sign lease agreement</li>
										{/* <li>Visit <a href="https://www.nsfas.org.za" target="_blank">www.nsfas.org.za</a></li> */}
									</ul>
								</div>
							</Carousel.Item>
						</Carousel>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
