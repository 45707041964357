import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Select from "react-select";
import { useCommonApi } from "../../_common/hooks/api/common/commonApiHook";
import { useAppLoader } from "../../_common/hooks/common/appLoaderHook";
import { useUserSelector } from "../../_common/hooks/selectors/userSelector";
import Header from "./components/header";
import "../../styles/student/myprofile.scss";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import UploadPropertyDocuments from "../uploadPropertyDocuments";
import { useDocumentTypeModal } from "../../hooks/useDocumentTypeModal";
import { SelectOption } from "../../_common/interfaces";
import { MdAddCircle } from "react-icons/md";
import { FaCheckCircle, FaTimes } from "react-icons/fa";
import { RequiredAsterisk } from "../../components/blocks/requiredAsterisk";
import UpdateDocumentsForm from "../../components/update/updateDocumentsForm";

import { FormLabel, Tab, Tabs } from "react-bootstrap";

const REQUIRED_DOCS = [886, 950, 951];

interface incomingProps {
  onSubmitAddressForm: any;
  tab: any;
  userType: "individual" | "enterprise"; // Add userType prop
}

const MyProfile = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    getValues,
    watch,
    reset,
  } = useForm({ mode: "onChange" });
  const institution = watch("CompanyId");

  const user: any = useUserSelector();
  const commonApi = useCommonApi();
  const { showLoader, hideLoader } = useAppLoader();

  const [edit, setEdit] = useState<boolean>(false);
  const [institutions, setInstitutions] = useState<any>(null);
  const [campuses, setCampuses] = useState<any>(null);
  const [yearOfStudy, setYearOfStudy] = useState<any>(null);
  const [setPrevCampus, set_setPrevCampus] = useState<any>(null);

  const [files, setFiles] = useState<any>([]);
  const [documentOptions, setDocumentOptions] = useState<SelectOption[]>([]);
  const getAttachment = (code: number) =>
    files.find((file: Record<string, unknown>) => file.DocumentTypeId === code);
  const {
    modalComponent,
    setOnClickContinue,
    closeModal,
    showModal,
    setSelectedDocumentType,
  } = useDocumentTypeModal({ documentOptions, getAttachment });

  const [idNum, setIdNum] = useState<any>();

  const documents: any = watch("Documents");
  useEffect(() => {
    let _files: any = [...files];
    if (documents?.length) {
      for (let i = 0; i < documents?.length; i++) {
        let file: any = documents[i];
        checkIsSelected(file.name)
          .then(() => {
            if (file.size <= 2 * 1024 * 1024) {
              setOnClickContinue(() => (docTypeCode: number) => {
                if (!docTypeCode) {
                  toast.error("Select document type");
                  return;
                }
                toBase64(file).then((b64: any) => {
                  _files.push({
                    FileName: file.name,
                    FileExtention: file.name.split(".").pop(),
                    DocumentTypeId: docTypeCode, //892,
                    FileContent: b64.split(",")[1],
                  });
                  // if (_files.length == documents?.length) {
                  setFiles(_files);
                  setOnClickContinue(undefined);
                  closeModal();
                  setSelectedDocumentType(undefined);
                });
              });
              showModal();
            } else {
              toast.error("File size should be less 2MB");
            }
          })
          .catch(() => {
            console.log("already selected");
          });
      }
    } else {
      setFiles(_files);
    }
  }, [documents]);

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const checkIsSelected = (name: string) => {
    return new Promise((resolve: any, reject: any) => {
      files.map((file: any) => {
        if (file.FileName == name) {
          reject();
        }
      });
      resolve();
    });
  };
  //
  const onSubmitDocuments = (docs: unknown) => {
    console.log(docs);
    if (!user?.supplierId) {
      return;
    }
    showLoader();
    commonApi.post(
      {
        entityName: "Supplier",
        requestName: "CreateDocumentExecuteRequest",
        recordId: user?.supplierId,
        inputParamters: {
          Documents: docs,
        },
      },
      (message: string, resp: any) => {
        hideLoader();
        toast.success(message);
        // navigate(
        //   `${URLS.UPDATE_PROFILE}/${URLS.UPDATE_PROFILE_SECTIONS.documents}`
        // );
      },
      (message: string) => {
        hideLoader();
        toast.error(message);
      }
    );
  };
  const getDocTypes = () => {
    commonApi.post(
      {
        entityName: "Picklist",
        requestName: "RetrieveSelectOptionExecuteRequest",
        inputParamters: {
          SelectOption: {
            OptionText: "DocTypes",
          },
        },
      },
      (message: string, resp: any) => {
        const options = resp.outputParameters.data.map((doc: any) => {
          return {
            value: doc.optionValue,
            label: doc.optionText,
          };
          return console.log(doc.optionText); // Returns array object with doc 'options'
        });
        setDocumentOptions(
          options.filter((option: SelectOption) =>
            REQUIRED_DOCS.includes(option.value)
          )
        );

        const requiredDocumentOptions = options.filter((option: SelectOption) =>
          REQUIRED_DOCS.includes(option.value)
        );

        setDocumentOptions(requiredDocumentOptions);
      },
      (message: string) => {}
    );
  };
  // ***********************************************************************************

  const getStudentDetails = () => {
    showLoader();
    //call api reset form values via reset()
    commonApi.retreive(
      {
        Entity: "Employee",
        Top: 100,
        ReturnCols: [
          "FirstName",
          "LastName",
          "IdNumber",
          "EmployeeNo",
          "CompanyId",
          "CompanyIdName",
          "CampusId",
          "CampusIdName",
          "Mobile",
          "Email",
          "TermTypeId",
        ],
        FilterOn: {
          Type: "And",
          Where: [
            {
              filterCol: "EmployeeId",
              FilterCondition: "Equals",
              FilterValue: user.recordId,
            },
          ],
        },
      },
      (message: string, resp: any) => {
        hideLoader();
        let prevData = JSON.parse(resp)[0];
        console.log("PrevData", prevData);
        set_setPrevCampus(prevData.CampusId);
        reset({
          FirstName: prevData.FirstName,
          LastName: prevData.LastName,
          IDNumber: prevData.IdNumber,
          EmoployeeNo: prevData.EmployeeNo,
          CompanyId: getSelectDataById(prevData.CompanyId, institutions),
          // CampusId: prevData.CampusId,
          YearOfStudyId: getSelectDataById(prevData.TermTypeId, yearOfStudy),
          Mobile: prevData.Mobile,
          Telephone: prevData.Telephone,
          Email: prevData.Email,
        });
      },
      (message: string) => {
        hideLoader();
        toast.error(message);
      }
    );
  };

  const getSelectDataById = (id: any, data: any) => {
    let option = null;
    data?.map((_option: any) => {
      if (_option.value == id) {
        option = _option;
      }
    });
    return option;
  };

  const getInstitutions = () => {
    commonApi.retreiveInstitution(
      {},
      (message: string, resp: any) => {
        let options: any = [];
        resp.map((ins: any) => {
          if (ins.recordId.search("000000") !== 0) {
            ins.value = ins.recordId;
            ins.label = ins.mainText;
            options.push(ins);
          }
        });
        setInstitutions(options);
      },
      (message: string) => {}
    );
  };

  const getCampus = (id: any = institution?.value) => {
    return new Promise((resolve: any, reject: any) => {
      commonApi.getCampus(
        {
          filters: "InstitutionId eq '" + id + "'",
          ViewName: "LookUpView",
        },
        (message: string, resp: any) => {
          let options: any = [];
          resp.map((ins: any) => {
            if (ins.recordId.search("000000") !== 0) {
              ins.value = ins.recordId;
              ins.label = ins.mainText;
              options.push(ins);
            }
          });
          setCampuses(options);
          setTimeout(() => {
            resolve();
          }, 300);
        },
        (message: string) => {
          reject();
        }
      );
    });
  };

  const getYearOfStudy = () => {
    commonApi.post(
      {
        entityName: "Picklist",
        requestName: "RetrieveSelectOptionExecuteRequest",
        inputParamters: {
          SelectOption: {
            OptionText: "TermTypes",
          },
        },
      },
      (message: string, resp: any) => {
        let data = resp.outputParameters.data;
        let options: any = [];
        data.map((ins: any) => {
          ins.value = ins.optionValue;
          ins.label = ins.optionText;
          options.push(ins);
        });
        setYearOfStudy(options);
      },
      (message: string) => {}
    );
  };

  const idValidation = () => {
    const number = "/^[0-9]+$";
    if (number.match(number)) {
      toast.success("Valid Input");
    } else {
      toast.error("Invalid Input");
    }
  };

  const onSubmit = (data: any, e: any) => {
    let params: any = {
      FirstName: data.FirstName,
      LastName: data.LastName,
      EmoployeeNo: data.EmoployeeNo,
      CompanyId: data.CompanyId.recordId,
      CampusId: data.CampusId.recordId,
      TermTypeId: data.YearOfStudyId.optionValue,
      Mobile: data.Mobile,
      Email: data.Email,
    };
    if (idNum?.length > 13) {
      e.preventDeafault();
      toast.error("Invalid ID Number");
    } 
    showLoader();
    commonApi.post(
      {
        entityName: "Employee",
        recordId: user?.recordId,
        requestName: "UpsertRecordReq",
        inputParamters: {
          Entity: params,
        },
      },
      (message: string, resp: any) => {
        toast.success(message);
        hideLoader();
        setEdit(false);
      },
      (message: string) => {
        toast.error(message);
        hideLoader();
        setEdit(false);
      }
    );
  };

  useEffect(() => {
    if (user && institutions && yearOfStudy) {
      getStudentDetails();
    }
  }, [user, institutions, yearOfStudy]);

  useEffect(() => {
    if (campuses) {
      if (setPrevCampus) {
        let opt: any = getSelectDataById(setPrevCampus, campuses);
        set_setPrevCampus(null);
        reset({
          ...getValues(),
          CampusId: opt,
        });
      }
    }
  }, [campuses]);

  useEffect(() => {
    getInstitutions();
    getYearOfStudy();
  }, []);

  useEffect(() => {
    if (institution) {
      getCampus();
    }
  }, [institution]);

  const deleteLocal = (file: any) => {
    let _files: any = [];
    files.map((_file: any) => {
      if (_file.FileName != file.FileName) {
        _files.push(_file);
      }
    });

    setFiles(_files);
  };

  useEffect(() => {
    // getProvinces();
    getDocTypes();
  }, []);

  // *********************************************************************
  const tabsContent = {
    details: (
      <div className="tab-content" id="myTabContent">
        <div id="profile" role="tabpanel" aria-labelledby="profile-tab">
        <form
              className={!edit ? "disabled-select" : ""}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Row>
                <Col sm={6}>
                  <div>
                    <label htmlFor="exampleFormControlInput1">First Name</label>
                  </div>
                  <input
                    disabled={!edit}
                    type="text"
                    id="exampleFormControlInput1"
                    placeholder=""
                    {...register("FirstName", {
                      required: true,
                      maxLength: 80,
                    })}
                    required
                  />
                </Col>
                <Col sm={6}>
                  <div>
                    <label htmlFor="exampleFormControlInput2">Campus</label>
                  </div>
                  <Controller
                    name={"CampusId"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => {
                      return (
                        <Select
                          className={
                            "form-control p-0" +
                            (errors?.CampusId ? " is-invalid" : "")
                          }
                          options={campuses}
                          placeholder={""}
                          onChange={onChange}
                          value={value}
                          defaultValue={value}
                          isDisabled={!edit}
                        />
                      );
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div>
                    <label htmlFor="exampleFormControlInput3">Last Name</label>
                  </div>
                  <input
                    disabled={!edit}
                    type="text"
                    id="exampleFormControlInput3"
                    placeholder=""
                    {...register("LastName", { required: true, maxLength: 80 })}
                    required
                  />
                </Col>
                <Col sm={6}>
                  <div>
                    <label htmlFor="exampleFormControlInput4">
                      Academic Term
                    </label>
                  </div>
                  <Controller
                    name={"YearOfStudyId"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => {
                      return (
                        <Select
                          className={
                            "form-control p-0" +
                            (errors?.YearOfStudyId ? " is-invalid" : "")
                          }
                          options={yearOfStudy}
                          placeholder={""}
                          onChange={onChange}
                          value={value}
                          defaultValue={value}
                          isDisabled={!edit}
                        />
                      );
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div>
                    <label htmlFor="exampleFormControlInput5">ID Number</label>
                  </div>
                  <input
                    disabled={true}
                    type="text"
                    id="exampleFormControlInput5"
                    placeholder=""
                    {...register("IDNumber", { required: true })}
                    required
                    onChange={(e) => {
                      setIdNum(e.target.value);
                    }}
                  />
                </Col>
                <Col sm={6}>
                  <div>
                    <label htmlFor="exampleFormControlInput6">
                      Mobile phone
                    </label>
                  </div>
                  <input
                    disabled={!edit}
                    type="text"
                    id="exampleFormControlInput6"
                    placeholder=""
                    {...register("Mobile", { required: true })}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div>
                    <label htmlFor="exampleFormControlInput7">
                      Student Number
                    </label>
                  </div>
                  <input
                    disabled={!edit}
                    type="text"
                    id="exampleFormControlInput7"
                    placeholder=""
                    {...register("EmoployeeNo", { required: true })}
                    required
                  />
                </Col>
                <Col sm={6}>
                  <div>
                    <label htmlFor="exampleFormControlInput8">Email</label>
                  </div>
                  <input
                    disabled={!edit}
                    type="email"
                    id="exampleFormControlInput8"
                    placeholder=""
                    {...register("Email", { required: true })}
                    required
                    style={{
                      width: "100%",
                      background: "#fff",
                      border: "1px solid #d36e28",
                      borderRadius: "5px",
                      padding: "7px 15px",
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div>
                    <label htmlFor="exampleFormControlInput9">
                      Institution
                    </label>
                  </div>
                  <Controller
                    name={"CompanyId"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => {
                      return (
                        <Select
                          className={
                            "form-control p-0" +
                            (errors?.CompanyId ? " is-invalid" : "")
                          }
                          options={institutions}
                          placeholder={""}
                          onChange={onChange}
                          value={value}
                          defaultValue={value}
                          isDisabled={!edit}
                        />
                      );
                    }}
                  />
                </Col>
              </Row>

              {edit ? (
                <Row className="flex-column-reverse">
                
                 

                  <Col sm={12} className="save-btn">
                    <Button
                      type="submit"
                      style={{
                        marginTop: "1rem",
                        height: "4rem",
                        borderRadius: "5px",
                      }}
                    >
                      Save
                    </Button>
                  </Col>
       
                  {modalComponent}
                </Row>
              ) : null}
            </form>
        </div>
      </div>
    ),

    documents: (
      <div>
        <form action="">
            <UpdateDocumentsForm
              tab={null}
              type={"201"}
              isBankingOnlyMode={false}
              onSubmitDocuments={onSubmitDocuments}
              userDetails={user}
            />
        </form>
      </div>
    ),
  };


  return (
    <React.Fragment>
      <div
        className="dashboard my-profile-wrapper"
        style={{ paddingTop: "0px" }}
      >
        <Header />
        <Container>

          <div className="white-block p-3 white-box-wide">
            <div className="text-center mb-3">
              <h1>My Profile</h1>
            </div>
            <Row>
            {edit ? 
                   <Tabs>
                   <Tab title="Profile Details" eventKey='details'>
                        <Col sm={6} style={{marginTop:'1rem'}}> Personal Information</Col>
                        {tabsContent.details}
                   </Tab>
                   <Tab title = "Documents" eventKey="documents" >
                    {tabsContent.documents}
                   </Tab>
               </Tabs>
            :
            null
            }
             {edit ? null : <Col sm={6}>Personal Information</Col>} 
              
              {!edit ? (
                <Col sm={6} className="text-end edit-btn">
                  <Button onClick={() => setEdit(true)}>Edit</Button>
                </Col>
              ) : null}
            </Row>
            {edit ? null
            :
             <form
              className={!edit ? "disabled-select" : ""}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Row>
                <Col sm={6}>
                  <div>
                    <label htmlFor="exampleFormControlInput1">First Name</label>
                  </div>
                  <input
                    disabled={!edit}
                    type="text"
                    id="exampleFormControlInput1"
                    placeholder=""
                    {...register("FirstName", {
                      required: true,
                      maxLength: 80,
                    })}
                    required
                  />
                </Col>
                <Col sm={6}>
                  <div>
                    <label htmlFor="exampleFormControlInput2">Campus</label>
                  </div>
                  <Controller
                    name={"CampusId"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => {
                      return (
                        <Select
                          className={
                            "form-control p-0" +
                            (errors?.CampusId ? " is-invalid" : "")
                          }
                          options={campuses}
                          placeholder={""}
                          onChange={onChange}
                          value={value}
                          defaultValue={value}
                          isDisabled={!edit}
                        />
                      );
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div>
                    <label htmlFor="exampleFormControlInput3">Last Name</label>
                  </div>
                  <input
                    disabled={!edit}
                    type="text"
                    id="exampleFormControlInput3"
                    placeholder=""
                    {...register("LastName", { required: true, maxLength: 80 })}
                    required
                  />
                </Col>
                <Col sm={6}>
                  <div>
                    <label htmlFor="exampleFormControlInput4">
                      Academic Term
                    </label>
                  </div>
                  <Controller
                    name={"YearOfStudyId"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => {
                      return (
                        <Select
                          className={
                            "form-control p-0" +
                            (errors?.YearOfStudyId ? " is-invalid" : "")
                          }
                          options={yearOfStudy}
                          placeholder={""}
                          onChange={onChange}
                          value={value}
                          defaultValue={value}
                          isDisabled={!edit}
                        />
                      );
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div>
                    <label htmlFor="exampleFormControlInput5">ID Number</label>
                  </div>
                  <input
                    disabled={true}
                    type="text"
                    id="exampleFormControlInput5"
                    placeholder=""
                    {...register("IDNumber", { required: true })}
                    required
                    onChange={(e) => {
                      setIdNum(e.target.value);
                    }}
                  />
                </Col>
                <Col sm={6}>
                  <div>
                    <label htmlFor="exampleFormControlInput6">
                      Mobile phone
                    </label>
                  </div>
                  <input
                    disabled={!edit}
                    type="text"
                    id="exampleFormControlInput6"
                    placeholder=""
                    {...register("Mobile", { required: true })}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div>
                    <label htmlFor="exampleFormControlInput7">
                      Student Number
                    </label>
                  </div>
                  <input
                    disabled={!edit}
                    type="text"
                    id="exampleFormControlInput7"
                    placeholder=""
                    {...register("EmoployeeNo", { required: true })}
                    required
                  />
                </Col>
                <Col sm={6}>
                  <div>
                    <label htmlFor="exampleFormControlInput8">Email</label>
                  </div>
                  <input
                    disabled={!edit}
                    type="email"
                    id="exampleFormControlInput8"
                    placeholder=""
                    {...register("Email", { required: true })}
                    required
                    style={{
                      width: "100%",
                      background: "#fff",
                      border: "1px solid #d36e28",
                      borderRadius: "5px",
                      padding: "7px 15px",
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div>
                    <label htmlFor="exampleFormControlInput9">
                      Institution
                    </label>
                  </div>
                  <Controller
                    name={"CompanyId"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => {
                      return (
                        <Select
                          className={
                            "form-control p-0" +
                            (errors?.CompanyId ? " is-invalid" : "")
                          }
                          options={institutions}
                          placeholder={""}
                          onChange={onChange}
                          value={value}
                          defaultValue={value}
                          isDisabled={!edit}
                        />
                      );
                    }}
                  />
                </Col>
              </Row>

              {edit ? (
                <Row className="flex-column-reverse">
                
                 

                  <Col sm={12} className="save-btn">
                    <Button
                      type="submit"
                      style={{
                        marginTop: "1rem",
                        height: "4rem",
                        borderRadius: "5px",
                      }}
                    >
                      Save
                    </Button>
                  </Col>
                  {/* <Col sm={12}>
                    <div className="mt-2">
                      {files.map((file: any, i: any) => (
                        <div className="doc-file mb-2" key={i}>
                          <FaTimes
                            className="delete-me"
                            onClick={() => {
                              deleteLocal(file);
                            }}
                          />
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            id="exampleFormControlInput3"
                            placeholder={file.FileName}
                          />
                        </div>
                      ))}
                    </div>
                    <UpdateDocumentsForm
                      tab={null}
                      type={"201"}
                      isBankingOnlyMode={false}
                      onSubmitDocuments={onSubmitDocuments}
                      userDetails={user}
                    />
                  </Col> */}
                  {modalComponent}
                </Row>
              ) : null}
            </form>
            }
         
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MyProfile;
