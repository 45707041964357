import React, { useEffect, useState } from "react";
import Header from "./student/components/header";
import Box from "@mui/material/Box";
import FormGridItem from "../ui/v2/components/FormGridItem";
import CasePost from "../ui/v2/pages/ViewCase/CasePost";
import TextArea from "../ui/v2/components/TextArea";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { IApiResponse } from "../ui/v2/types/IApiResponse";
import { API } from "../ui/v2/services/API";
import {
  EXECUTE_REQUEST_PATH,
  UPLOADS_ACCEPTED_FILE_TYPES,
} from "../ui/v2/constants";
import { useParams } from "react-router-dom";
import { ThemeColors } from "../ui/v2/styles/theme";
import { useForm } from "react-hook-form";
import { TNewDocumentUpload } from "../ui/v2/types/TDocumentUpload";
import FileDropzone from "../ui/v2/components/FileDropzone";
import { UTILS } from "../utils";
import { Close, Description } from "@mui/icons-material";
import LegacyPageLayout from "../components/LegacyPageLayout";
import ContentPage from "../ui/v2/components/DashboardPageLayout/ContentPage";
import OutlinedBanner from "../ui/v2/components/OutlinedBanner";

type TCasesResponse = {
  CaseInformation: {
    casesId: string;
    customerIdName: string;
    submitted: string;
    lastUpdated: string;
    casesStatus: string;
    caseNumber: string;
  }[];
  InvestigationLines: {
    investigationId: string;
    name: string;
    dateOfSubmission: string;
    description: string;
    documents:
      | null
      | {
          investigationLineDocLibId: string;
          fileName: string;
          fileContent: string;
          fileExtention: string;
          documentTypeId: number;
        }[];
  }[];
};

const InfoRow = ({ label, value }: { label: string; value: string }) => {
  return (
    <>
      <Typography fontSize={16} color={ThemeColors.neutralGrey}>
        {label}
      </Typography>
      <Typography fontSize={14} fontWeight="bold" pb={3}>
        {value}
      </Typography>
    </>
  );
};

const FileTile = ({
  name,
  onRemove,
  href,
}: {
  name: string;
  onRemove?: () => void;
  href?: string;
}) => {
  return (
    <Box
      sx={{
        cursor: Boolean(href) ? "pointer" : "default",
        display: "inline-flex",
        maxWidth: 300,
        alignItems: "center",
        justifyContent: "space-between",
        mr: 1,
        mb: 1,
        px: 1,
        border: "1px solid #aaa",
        backgroundColor: "#f5f5f5",
        borderRadius: 2,
        textDecoration: "none",
        color: ThemeColors.textPrimary,
      }}
      component={Link}
      href={href}
      target="_blank"
    >
      <Description color="info" />
      <Typography noWrap sx={{ fontSize: 14 }}>
        {name}
      </Typography>
      {Boolean(onRemove) && (
        <IconButton color="error" onClick={onRemove}>
          <Close />
        </IconButton>
      )}
    </Box>
  );
};

function CaseDetails_TEMPORARY() {
  const [caseDetails, setCaseDetails] = useState<TCasesResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = React.useState<TNewDocumentUpload<697>[]>([]);

  const { id: caseId } = useParams();

  const getCaseDetails = async () => {
    try {
      setIsLoading(true);
      const response: IApiResponse<TCasesResponse> = await API.post(
        EXECUTE_REQUEST_PATH,
        {
          entityName: "Cases",
          requestName: "RetrieveCaseDetailsReq",
          inputParamters: {
            CasesId: caseId,
          },
        }
      );

      setCaseDetails(response.outputParameters);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (caseId) {
      getCaseDetails();
    }
  }, [caseId]);

  const { handleSubmit, register, setValue } = useForm({
    defaultValues: {
      Description: "",
    },
  });

  const onSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      await API.post(EXECUTE_REQUEST_PATH, {
        entityName: "InvestigationLine",
        requestName: "UpsertRecordReq",
        inputParamters: {
          Entity: {
            Description: data.Description,
            CaseId: caseId,
            Direction: 560,
          },
          Documents: files.map((_) => ({ ..._, id: undefined })),
        },
      });
      setFiles([]);
      setValue("Description", "");
    } catch (e) {
      console.log(e);
    } finally {
      getCaseDetails();
    }
  };

  const isCaseClosed =
    caseDetails?.CaseInformation[0]?.casesStatus === "Resolved";

  return (
    <LegacyPageLayout
      bgColor={ThemeColors.bgPrimary}
      padding={3}
      paddingTop={0}
      breadcrumbData={[
        { label: "Home", path: "/" },
        { label: "Cases", path: "/cases" },
        { label: "Case Details" },
      ]}
    >
      <Grid container alignItems="start">
        <Grid item md={4}>
          <Box sx={{ pl: 3, pt: 4 }}>
            <Typography fontWeight="bold" fontSize={20} pb={4}>
              Case Information
            </Typography>
            <InfoRow
              label="Requestor"
              value={caseDetails?.CaseInformation[0]?.customerIdName || ""}
            />
            <InfoRow
              label="Submitted"
              value={caseDetails?.CaseInformation[0]?.submitted || ""}
            />
            <InfoRow
              label="Last Updated"
              value={caseDetails?.CaseInformation[0]?.lastUpdated || ""}
            />
            <InfoRow
              label="Status/Priority"
              value={caseDetails?.CaseInformation[0]?.casesStatus || ""}
            />
          </Box>
        </Grid>
        <Grid
          item
          md={8}
          container
          component={"form"}
          onSubmit={handleSubmit(onSubmit)}
        >
          <ContentPage
            title={`Case ${caseDetails?.CaseInformation[0].caseNumber || ""} `}
            subtitle="View your cases."
          >
            {isCaseClosed && (
              <Box sx={{ pr: 2, pb: 2 }}>
                <OutlinedBanner message="This case is closed" />
              </Box>
            )}
            {caseDetails?.InvestigationLines.map((line) => (
              <FormGridItem md={12}>
                <CasePost
                  name={line.name}
                  caseContent={line.description}
                  date={line.dateOfSubmission}
                />
                {line.documents && !!line.documents.length && (
                  <Box sx={{ mt: 0.5 }}>
                    {line.documents.map(
                      ({ investigationLineDocLibId, fileName }) => (
                        <FileTile
                          key={investigationLineDocLibId}
                          name={fileName}
                          href={UTILS.getImageSource(
                            investigationLineDocLibId,
                            "InvestigationLineDocLib"
                          )}
                        />
                      )
                    )}
                  </Box>
                )}
              </FormGridItem>
            ))}
            <FormGridItem md={12} mt={2}>
              <TextArea
                minRows={4}
                placeholder="Add reply..."
                disabled={isLoading || isCaseClosed}
                {...register("Description", { required: true })}
              />
            </FormGridItem>
            <FormGridItem md={12}>
              {files.map(({ id, FileName }) => (
                <FileTile
                  key={id}
                  name={FileName}
                  onRemove={() => {
                    setFiles((current) => current.filter((el) => el.id !== id));
                  }}
                />
              ))}
            </FormGridItem>
            <FormGridItem md={7}>
              <FileDropzone
                disabled={isLoading || isCaseClosed}
                acceptedFiles={{ "plain/text": UPLOADS_ACCEPTED_FILE_TYPES }}
                onDrop={(validFiles) => {
                  const b64filesPromises = validFiles.map(async (file) => {
                    return new Promise<TNewDocumentUpload<697>>((resolve) => {
                      UTILS.convertFileToBase64(file).then((b64) => {
                        resolve({
                          id: UTILS.getId().toString(),
                          FileName: file.name,
                          FileExtention: file.name.split(".").pop() || "",
                          DocumentTypeId: 697,
                          FileContent: b64.split("base64,")[1],
                        });
                      });
                    });
                  });
                  Promise.all(b64filesPromises).then((b64files) => {
                    setFiles((current) => [...current, ...b64files]);
                  });
                }}
              />
            </FormGridItem>
            <FormGridItem>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                startIcon={isLoading ? <CircularProgress size={20} /> : null}
                disabled={isLoading || isCaseClosed}
              >
                Reply
              </Button>
            </FormGridItem>
          </ContentPage>
        </Grid>
      </Grid>
    </LegacyPageLayout>
  );
}

export default CaseDetails_TEMPORARY;
