import { Box, Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import Header from '../components/header';
import { Button } from 'react-bootstrap';
import { DevTool } from '@hookform/devtools';
import { useCommonApi } from '../_common/hooks/api/common/commonApiHook';
import { useUserSelector } from '../_common/hooks/selectors/userSelector';
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from './student/components/breadcrumbs';


const CreateUser = () => {

    const { 
      register, 
      handleSubmit, 
      control, 
      setError, 
      formState: { errors }, 
      watch, 
      reset } = useForm();

    const commonApi = useCommonApi();
    const user: any = useUserSelector();
    const { showLoader, hideLoader } = useAppLoader();
    const navigate = useNavigate();

 
    const onSubmit = (data:any) =>{
        showLoader();
        commonApi.post({
          "entityName": "APUser",
          "requestName": "UpsertRecordReq",
          // "recordId": id,
          "inputParamters":
          {
            "Entity": {
                Name:data.firstName,
                FirstName:data.firstName,
                LastName:data.lastName,
                Email:data.email,
                Mobile:data.contact,
                institutionId:user.institutionId,
                supplierId:user.supplierId,

           }
          }
        }, (message: string, resp: any) => {
            
    
          toast.success(message);
          hideLoader();

          setTimeout(()=>{
            navigate('/user-list')  
          },1000)
          
          // hideLoader()
          // console.log({ resp })
        }, (message: string) => {
          toast.error(message)
          hideLoader()
          // console.log(message);
        });
    }
    
   

    // useEffect(()=>{
    //   setError("firstName",{
    //     type:"manual",
    //     message:"First Name Required",
    //   })
    // },[setError])

  return (
    <div>
         <Box>
            <Header/>
        </Box>
        <Box sx={{padding:'2rem'}}>
          <Box sx={{marginTop:'4rem'}}>
            <Breadcrumbs
                data={[
                  { label: 'Home', path: '/' },
                  { label: 'User List', path: '/user-list' },
                  { label: 'Create User' },
                ]}
            />
          </Box>
            <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center', marginTop:'7rem'}}>
                <Typography
                  sx={{
                    color: "#101828",
                    fontFamily: "Inter",
                    fontSize: "30px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "38px",
                    marginBottom:"20px"
                  }}
                >
                  Create User
                </Typography>
            </Box>
            <Paper sx={{ width: "100%", overflow: "hidden", padding:'3rem' }}>
                  <form action="" onSubmit={handleSubmit(onSubmit)}>
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: "2rem", flexDirection:{xs:'column',md:'row'}, width:{xs:'50%',md:'100%'}, marginLeft:{xs:'auto'},marginRight:{xs:'auto'} }}
                    >
                      <div
                        className="mb-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          marginTop: "1rem",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          htmlFor="exampleFormControlInput1a"
                          className={"form-label "}
                        >
                         First Name
                        </label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1a"
                          placeholder="John"
                          style={{ width: "60%" }}

                          {...register("firstName")}
                          // defaultValue={studentDetail.Name}
                        />
                        
                      </div>
                      <div
                        className="mb-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          marginTop: "1rem",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          htmlFor="exampleFormControlInput1a"
                          className={"form-label "}
                        >
                         Last Name
                        </label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1a"
                          placeholder="Doe"
                          style={{ width: "60%" }}
                          {...register("lastName")}
                          // defaultValue={studentDetail.Name}
                        />
                      </div>
                      
                    </Box>
                    {/*  */}
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: "2rem", flexDirection:{xs:'column',md:'row'}, width:{xs:'50%',md:'100%'}, marginLeft:{xs:'auto'},marginRight:{xs:'auto'} }}
                    >
                      <div
                        className="mb-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          marginTop: "1rem",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          htmlFor="exampleFormControlInput1a"
                          className={"form-label "}
                        >
                         Contact No.
                        </label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1a"
                          placeholder="0813245566"
                          style={{ width: "60%" }}
                          {...register("contact")}
                          // defaultValue={studentDetail.Name}
                        />
                      </div>
                      <div
                        className="mb-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          gap: "1rem",
                          marginTop: "1rem",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          htmlFor="exampleFormControlInput1a"
                          className={"form-label "}
                          style={{ textAlign: "left" }}
                        >
                          Email 
                        </label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1a"
                          placeholder="Example@email.com"
                          style={{ width: "60%", textAlign: "left" }}
                          {...register("email")}
                        />
                      </div>
                    </Box>
                    {/* ******************** */}
                    

                    <Box sx={{
                      marginTop:'5rem',
                      display:'flex',
                      justifyContent:'end',
                      gap:'2rem'
                      }}
                      >
                      
                      <Button 
                        variant="danger" 
                        style={{ width: "10%" }}
                        onClick={()=>{navigate('/user-list')}}
                      >
                        Back
                      </Button>
                      <Button
                       
                        variant="primary"
                        style={{ width: "10%" }}
                        type="submit"
                      >
                        Create
                      </Button>
                    </Box>
                    
                  </form>
            </Paper>
            
            <DevTool control={control} />
        </Box>
    </div>
  )
}

export default CreateUser