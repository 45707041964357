import { Close } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  DialogContent,
  IconButton,
  LinearProgress,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect } from "react";
import { API } from "../../../services/API";
import {
  EXECUTE_REQUEST_PATH,
  GET_AP_SIGNING_URL_PATH,
  GET_SURVEY_RESPONSE_PATH,
} from "../../../constants";
import { IApiResponse } from "../../../types/IApiResponse";
import { showErrorAlert } from "../../../../../components/Notify";

type TQuestion = {
  surveyQuestionId: string;
  name: string;
  answerTypeId: 638 | 641;
  possibleAnswers: TPossibleAnswers[];
};
type TPossibleAnswers = {
  surveyQuestionAnswerId: string;
  surveyQuestionId: string;
  name: string;
};

type TSurveyResponseBody = {
  Response: {
    surveyResponseId: string;
    survey: {
      surveyId: string;
      name: string;
      topics: {
        surveyTopicId: string;
        name: string;
        questions: TQuestion[];
      }[];
    };
  };
};

type TSurveyUpsertBody = {
  entityName: "SurveyResponseAnswer";
  requestName: "UpsertRecordReq";
  inputParamters: {
    Entity: {
      SurveyQuestionId: string;
      SurveyQuestionAnswerId: string;
      AnswerSingleLine: "";
      AnswerMultiLine: "";
      Score: "-100";
      RegardingId: string; // checkinId
      RegardingIdObjectTypeCode: "CheckIn";
      SurveyResponseId: string;
    };
  };
};

type TStep = {
  titleText: string;
  description?: string;
  answerTypeId: TQuestion["answerTypeId"];
  possibleAnswers: TPossibleAnswers[];
};
type TSteps = TStep[];

const Wizard = ({
  steps,
  surveyResponseId,
  checkInId,
  onClose,
  onCompleted,
}: {
  steps: TSteps;
  surveyResponseId: string;
  checkInId: string;
  onClose: () => void;
  onCompleted: () => void;
}) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [isPosting, setIsPosting] = React.useState(false);

  const calcProgress = () => ((activeStep + 1) / steps.length) * 100;

  const getNext = (answerId: string, questionId: string) => async () => {
    try {
      setIsPosting(true);
      const surveyAnswerPostResponse: IApiResponse<unknown> =
        await API.post<TSurveyUpsertBody>(EXECUTE_REQUEST_PATH, {
          entityName: "SurveyResponseAnswer",
          requestName: "UpsertRecordReq",
          inputParamters: {
            Entity: {
              SurveyQuestionId: questionId,
              SurveyQuestionAnswerId: answerId,
              AnswerSingleLine: "",
              AnswerMultiLine: "",
              Score: "-100",
              RegardingId: checkInId,
              RegardingIdObjectTypeCode: "CheckIn",
              SurveyResponseId: surveyResponseId,
            },
          },
        });
      if (!surveyAnswerPostResponse.isSuccess) {
        showErrorAlert(
          surveyAnswerPostResponse.clientMessage || "An error occurred"
        );
      }

      if (activeStep + 1 === steps.length) {
        onCompleted();
        return;
      }
      setActiveStep(activeStep + 1);
    } catch (e) {
      console.log(e);
      showErrorAlert("An error occurred");
    } finally {
      setIsPosting(false);
    }
  };

  const getUserInputComponent = () => {
    const _activeStep = steps[activeStep];
    switch (_activeStep.answerTypeId) {
      case 638:
        return (
          <>
            {_activeStep.possibleAnswers.map((el) => (
              <Button
                disabled={isPosting}
                key={el.surveyQuestionAnswerId}
                onClick={getNext(
                  el.surveyQuestionAnswerId,
                  el.surveyQuestionId
                )}
                variant="outlined"
                color="secondary"
                size="small"
                sx={{ mr: 1 }}
              >
                {el.name}
              </Button>
            ))}
            {isPosting ? (
              <Box sx={{ display: "inline-block" }} component="div">
                <CircularProgress />{" "}
              </Box>
            ) : null}
          </>
        );
      default:
        return <>&nbsp;</>;
    }
  };

  return (
    <>
      <LinearProgress variant="determinate" value={calcProgress()} />
      <Typography sx={{ textAlign: "right", fontSize: 14 }}>
        {Math.floor(calcProgress())}%
      </Typography>
      <Typography sx={{ fontSize: 12, fontFamily: "Inter", py: 1 }}>
        Question {activeStep + 1} of {steps.length}
      </Typography>
      <Typography
        variant="h5"
        sx={{
          fontSize: 24,
          fontFamily: "Inter",
          pt: 2,
          pb: 3,
          fontWeight: 600,
        }}
      >
        {steps[activeStep].titleText}
      </Typography>
      <Typography sx={{ pb: 8 }}>{steps[activeStep].description}</Typography>

      <Box
        flex={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
          pb: 4,
          minWidth: { xs: 0, md: 520 },
        }}
      >
        <Box display="flex" alignItems="center">
          {getUserInputComponent()}
        </Box>
      </Box>
    </>
  );
};

function useRoomInspectionWizardDialog({
  onClose,
  fullScreen: _fullScreen,
  checkInId,
  onCompleted,
}: {
  onClose?: () => void;
  onCompleted: () => void;
  fullScreen?: boolean;
  subtitle?: string;
  checkInId?: string;
}) {
  const [_open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [survey, setSurvey] = React.useState<TSurveyResponseBody["Response"]>();

  useEffect(() => {
    if (!checkInId) {
      console.debug("No checkInId provided");
      return;
    }
    const getSteps = async () => {
      const recordIdResponse: {
        entityImage: string;
        mainText: string;
        subText: string;
        recordId: string;
        entityName: string;
      }[] = await API.get(`${GET_SURVEY_RESPONSE_PATH} '${checkInId}'`);

      const recordId = recordIdResponse[1].recordId;
      const surveyResponse: IApiResponse<TSurveyResponseBody> = await API.post(
        EXECUTE_REQUEST_PATH,
        {
          entityName: "SurveyResponse",
          recordId: recordId,
          requestName: "GetSurveyExecuteRequest",
        }
      );
      if (!surveyResponse.isSuccess) {
        showErrorAlert(surveyResponse.clientMessage || "An error occurred");
      }
      setSurvey(surveyResponse.outputParameters.Response);
      setIsLoading(false);
    };
    getSteps();
  }, [checkInId]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleClose = (e: unknown, reason?: string) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setOpen(false);
    onClose?.();
  };
  const handleCompleted = () => {
    onCompleted();
    handleClose(null, "");
  }

  const component = !checkInId ? null : (
    <Dialog
      key={_open ? 2 : 1}
      fullScreen={_fullScreen || fullScreen}
      open={_open}
      onClose={handleClose}
    >
      <AppBar sx={{ position: "relative" }} elevation={0} color="transparent">
        <Toolbar>
          <IconButton
            edge="start"
            onClick={handleClose}
            disabled={isLoading}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography variant="h6" component="div">
            {survey?.survey.name || "Survey"}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!survey ? (
          isLoading && (
            <Box
              sx={{
                p: 4,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )
        ) : (
          <Wizard
            steps={survey.survey.topics[0].questions.map((q) => ({
              titleText: q.name,
              answerTypeId: q.answerTypeId,
              possibleAnswers: q.possibleAnswers,
            }))}
            checkInId={checkInId}
            surveyResponseId={survey.surveyResponseId}
            onClose={()=>handleClose(null, "")}
            onCompleted={handleCompleted}
          />
        )}
      </DialogContent>
    </Dialog>
  );

  const open = () => setOpen(true);

  return { open, component };
}

export default useRoomInspectionWizardDialog;
