import { Button, ButtonProps } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { InputField } from '../InputField';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaPlay } from 'react-icons/fa';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { useUserApi } from '../../_common/hooks/api/UserApiHook';
import { DIGITS_ONLY_PATTERN, INPUT_MAX_LENGTHS } from '../../_config';

interface CoolOffButtonInterface extends ButtonProps {
  label: string;
  time: number;
  attempts: number;
}

const CoolOffButton = ({
  label,
  time,
  attempts,
  onClick,
  ...otherProps
}: CoolOffButtonInterface) => {
  const [attemptsLeft, setAttemptsLeft] = useState(attempts);
  const [secondsLeft, setSecondsLeft] = useState<number>(time);

  useEffect(() => {
    const coolOffInterval = setInterval(() => {
      setSecondsLeft((curr) => {
        if (curr > 1) {
          return curr - 1;
        }
        return 0;
      });
    }, 1000);
    return () => {
      clearInterval(coolOffInterval);
    };
  }, [time]);

  return (
    <Button
      onClick={(e) => {
        if (attemptsLeft > 1) {
          toast(
            `Resending code... ${attemptsLeft - 1} of ${attempts} retries left`
          );
          setAttemptsLeft(attemptsLeft - 1);
          onClick?.(e);
          setSecondsLeft(time);
        } else {
          toast.error('No more retries');
        }
      }}
      {...otherProps}
      variant="link"
      size="sm"
      disabled={secondsLeft > 0 || attemptsLeft < 1}
    >
      {label} {secondsLeft > 0 && <>({secondsLeft}s)</>}
    </Button>
  );
};

export default function PreregistrationForm({onCompletePreregistration}: {onCompletePreregistration: (email: string, mobileNumber: string)=>void}) {
  const { showLoader, hideLoader } = useAppLoader();
  const userApi = useUserApi();
  const [stage, setStage] = useState(1);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const sendOtpCode = (email: string, mobileNumber: string) => {
    showLoader();
    userApi.generateOtp(
      {
        requestName: 'GenerateOTPReq',
        inputParamters: {
          OTPInformation: {
            Email: email,
            Mobile: mobileNumber,
          },
        },
      },
      (message: string, resp: any) => {
        hideLoader();
        toast.success(resp.clientMessage || message);
        
        setStage(2);
      },
      (message: string) => {
        hideLoader();
        toast.error(message);
      }
    );
    
    
  };

  const confirmOtpCode = (email: string, mobileNumber: string, otpNo: string) => {

    showLoader();
    userApi.confirmOtp(
      {
        requestName: 'ConfirmOTPReq',
        inputParamters: {
          OTPInformation: {
            OTPNo: otpNo,
            email,
            mobile: mobileNumber,
          },
        },
      },
      (message: string, resp: any) => {
        hideLoader();
        toast.success(resp.clientMessage || message);
        onCompletePreregistration(email, mobileNumber);
      },
      (message: string) => {
        hideLoader();
        toast.error(message+"...");
      }
    );
  };

  const onSubmit = (data: any) => {
    if (data.otpCode) {
      const {email, mobileNumber, otpCode} = data;
      confirmOtpCode(email, mobileNumber, otpCode);
    } else {
      sendOtpCode(data.email, data.mobileNumber);
        
      return;
    }
  };
  
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {
        <>
          <div className="my-4">
            <p>
              Please enter a vaild mobile number and email address, You will
              receive an OTP via email and SMS.
            </p>
          </div>
          <div className="mb-2">
            <InputField
              type="tel"
              label="Phone Number"
              id="mobileNumber"
              errors={errors}
              register={register}
              disabled={false} // disable when recieving otp
              required
              registerParams={{
                maxLength: {
                  value: INPUT_MAX_LENGTHS.mobile,
                  message: `Must be at most ${INPUT_MAX_LENGTHS.mobile} characters`,
                },
                pattern: {
                  value: DIGITS_ONLY_PATTERN,
                  message: 'Numbers only',
                },
              }}
            />
          </div>
          <div className="mb-2 d-flex" style={{ alignItems: 'start' }}>
            <div style={{ flex: 1 }}>
              <InputField
                type="email"
                label="Email"
                id="email"
                errors={errors}
                required
                register={register}
                disabled={false}  // disable when recieving otp
              />
            </div>
            {stage === 1 && (
              <div>
                <label>&nbsp;</label>
                <Button
                  variant="primary"
                  className="py-2 px-3 w-100"
                  size="sm"
                  type="submit"
                >
                  Proceed <FaPlay />
                </Button>
              </div>
            )}
          </div>
        </>
      }
      {stage === 2 && (
        <>
          <div className="mb-2 mt-4">
            <InputField
              type="text"
              label="Enter the OTP to proceed with the registration"
              id="otpCode"
              errors={errors}
              required={false}
              register={register}
              registerParams={{
                pattern: {
                  value: DIGITS_ONLY_PATTERN,
                  message: 'Numbers only',
                },
              }}
            />
          </div>
          <div className="mb-3">
            Didn't receive code?
            <CoolOffButton
              onClick={() => {
                handleSubmit((data: any) => {
                  sendOtpCode(data.email, data.mobileNumber);
                })();
              }}
              label="Resend"
              attempts={5}
              time={60}
              style={{color:'red!important',zIndex:'4'}}
              className='cool-off'
            />
          </div>
          <div className="mb-2">
            <Button variant="primary" type="submit">
              Next &nbsp; &gt;
            </Button>
          </div>
        </>
      )}
    </form>
  );
}
