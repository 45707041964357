import { useState, useEffect, useRef } from "react";
import Header from "../components/header";
import { Box, Typography, Card, Paper, Button } from "@mui/material";
import onlinePaymentIcon from "../images/online-payment.png";
import eftPaymentIcon from "../images/eft-payment.png";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useResidenceApi } from "../_common/hooks/api/residenceApiHook";
import { APP_URL, URLS } from "../_config";
import { Modal } from "react-bootstrap";
import EftPaymentModalContent from "../components/eftPaymentModalContent";
import { useForm, Controller } from "react-hook-form";
import Select, { components } from "react-select";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { FaTimes } from "react-icons/fa";

interface SelectOption {
  value: number;
  label: string;
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const PaymentOptionGate = () => {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useAppLoader();
  const commonApi = useCommonApi();

  const [payFastData, setPayFastData] = useState<any>(null);
  const payFastForm = useRef<any>();
  const residenceApi = useResidenceApi();
  const residenceID = sessionStorage.getItem("residenceID");
  const [amount, setAmount] = useState<any>(0);

  const regrade: any = sessionStorage.getItem("regrade");
  const [show, setShow] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    getValues,
    watch,
    reset,
  } = useForm({ mode: "onChange" });

  const [files, setFiles] = useState<any>([]);
  const [documentOptions, setDocumentOptions] = useState<SelectOption[]>([]);
  const [selectedDocumentType, setSelectedDocumentType] =
    useState<SelectOption>();
  const [docType, setDocType] = useState<any>(null);
  const [prevFiles, setPrevFiles] = useState<any>([]);
  const [onClickContinue, setOnClickContinue] = useState<Function>();
  const [showModal, setShowModal] = useState(false);

  const documents = watch("documents");
  const [base64, setBase64] = useState<string>("");

  const [file, setFile] = useState();

  const handleFileChange = (event: any) => {
    setFile(event.target.files[0]);
  };

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const getBase64 = () => {
    commonApi.post(
      {
        entityName: "Facility",
        recordId: residenceID,
        requestName: "MakePaymentReq",
      },
      (message: string, response: any) => {
        commonApi.post(
          {
            entityName: "Invoice",
            recordId: response.results.InvoiceId,
            requestName: "DownloadRecordExecuteRequest",
          },

          (message: string, resp: any) => {
            hideLoader();

            setBase64(resp.results);
          },
          (message: string) => {
            hideLoader();
            toast.error(message);
          }
        );
      },
      (message: string) => {
        hideLoader();
        toast.error(message);
      }
    );
  };

  const payOnline = (recordId: any) => {
    showLoader();
    commonApi.post(
      {
        entityName: "Facility",
        recordId: recordId,
        requestName: "MakePaymentReq",
      },
      (message: string, resp: any) => {
        setPayFastData(resp.outputParameters);

        // callPayFastApi(resp.outputParameters)
        hideLoader();
        //  navigate('/')
      },
      (message: string) => {
        hideLoader();
        toast.error(message);
      }
    );
  };

  const getPaymentValue = () => {
    residenceApi.getPaymentValue(
      {
        FacilityId: residenceID,
      },
      (message: string, resp: any) => {
        setAmount(resp[0].amount ? resp[0].amount : 250);
      },
      (message: string) => {}
    );
  };

  useEffect(() => {
    getPaymentValue();
  }, []);

  useEffect(() => {
    if (payFastData) {
      payFastForm.current.submit();
    }
  }, [payFastData]);

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      // reader.onload = () => resolve(reader.result);
      reader.onload = () =>
        resolve({ data: reader.result, extension: file.name.split(".").pop() });
      reader.onerror = (error) => reject(error);
    });
  const checkIsSelected = (name: string) => {
    return new Promise((resolve: any, reject: any) => {
      files.map((file: any) => {
        if (file.FileName == name) {
          reject();
        }
      });
      resolve();
    });
  };

  const getAttachment = (code: number) => {
    const localFileMatch = files.find(
      (file: Record<string, unknown>) => file.DocumentTypeId === code
    );
    if (localFileMatch) {
      return localFileMatch;
    }
    return prevFiles.find(
      (file: Record<string, unknown>) => file.DocumentTypeId === code
    );
  };

  useEffect(() => {
    let _files: any = [...files];
    if (documents?.length) {
      for (let i = 0; i < documents?.length; i++) {
        let file: any = documents[i];
        checkIsSelected(file.name)
          .then(() => {
            if (file.size <= 2 * 1024 * 1024) {
              setOnClickContinue(() => (docTypeCode: number) => {
                if (!docTypeCode) {
                  toast.error("Select document type");
                  return;
                }
                toBase64(file).then((result: any) => {
                  const { data, extension } = result;
                  if (extension === "pdf" || extension === "docx") {
                    _files.push({
                      FileName: file.name,
                      FileExtention: extension,
                      DocumentTypeId: docTypeCode,
                      FileContent: data.split(",")[1],
                    });
                    setFiles(_files);
                    setOnClickContinue(undefined);
                    setShowModal(false);
                    setSelectedDocumentType(undefined);
                  } else {
                    toast.error("Only PDF and Word documents are allowed");
                  }
                });
              });

              setShowModal(true);
            } else {
              toast.error("File size should be less 2MB");
            }
          })
          .catch(() => {
            console.log("already selected");
          });
      }
    } else {
      setFiles(_files);
    }
    reset({
      documents: null,
    });
  }, [documents]);

  useEffect(() => {
    getBase64();
  }, []);
  // ******************************

  useEffect(() => {
    getPrevData();
  }, []);

  const deleteLocal = (file: any) => {
    let _files: any = [];
    files.map((_file: any) => {
      if (_file.FileName != file.FileName) {
        _files.push(_file);
      }
    });

    setFiles(_files);
  };
  const deleteFromServer = (file: any) => {
    showLoader();
    commonApi.post(
      {
        entityName: "FacilityDocLib",
        recordId: file.FacilityDocLibId,
        requestName: "RemoveRecordReq",
      },
      (message: string, resp: any) => {
        hideLoader();
        getPrevData();
      },
      (message: string) => {
        hideLoader();
        toast.error(message);
      }
    );
  };

  const getPrevData = () => {
    if (residenceID) {
      showLoader();
      //call api reset form values via reset()
      commonApi.retreive(
        {
          Entity: "FacilityDocLib",
          Top: 100,
          ReturnCols: [
            "FacilityDocLibId",
            "Name",
            "DocumentTypeId",
            "FileExtention",
            "FileContent",
          ],
          FilterOn: {
            Type: "And",
            Where: [
              {
                filterCol: "FacilityId",
                FilterCondition: "Equals",
                FilterValue: residenceID,
              },
              // TODO: add proper filter after feedback from  @SiyandaSitsha

              // , {
              //     "filterCol": "DocumentTypeId", "FilterCondition": "Equals",
              //     "FilterValue": ["892"]
              // }
            ],
          },
        },
        (message: string, resp: any) => {
          hideLoader();
          let prevData = JSON.parse(resp);
          const relevantDocs = [892];
          setPrevFiles(
            prevData.filter((doc: Record<string, number>) =>
              relevantDocs.includes(doc.DocumentTypeId)
            )
          );
        },
        (message: string) => {
          hideLoader();
          toast.error(message);
        }
      );
    } else {
      console.log("Start A New Residence");
    }
  };

  return (
    <>
      <Box className="dashboard">
        <Header />
        <Box sx={{ padding: "2rem" }}>
          <Paper sx={{ padding: "10rem" }}>
            <Typography
              sx={{
                fontSize: "30px",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              Choose Your Payment Options
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2rem",
                gap: "1rem",
              }}
            >
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="button"
                onClick={() => payOnline(residenceID)}
                style={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "center",
                  alignContent: "center",
                  width: "50%",
                }}
              >
                <div>Pay Online</div>
                <img
                  src={onlinePaymentIcon}
                  alt="online-payment-img"
                  style={{ width: "30px", color: "#fff" }}
                />
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary "
                data-bs-toggle="button"
                onClick={handleShow}
                style={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "50%",
                }}
              >
                <div>Pay Using EFT</div>
                <img
                  src={eftPaymentIcon}
                  alt="eft-payment-img"
                  style={{ width: "30px" }}
                />
              </button>
            </Box>
          </Paper>
        </Box>
        {payFastData ? (
          <form action={payFastData.action} ref={payFastForm} method="post">
            <input
              type="hidden"
              name="merchant_id"
              value={payFastData.merchant_id}
            />
            <input
              type="hidden"
              name="merchant_key"
              value={payFastData.merchant_key}
            />
            <input type="hidden" name="amount" value={payFastData.amount} />
            <input
              type="hidden"
              name="item_name"
              value={payFastData.item_name}
            />
            <input
              type="hidden"
              name="return_url"
              value={
                APP_URL +
                URLS.SUCCESS_PAGE +
                "?amount=" +
                payFastData.amount +
                (regrade ? "&regrade=true" : "")
              }
            />
            <input
              type="hidden"
              name="cancel_url"
              value={
                APP_URL +
                URLS.CANCEL_PAGE +
                "?amount=" +
                payFastData.amount +
                (regrade ? "&regrade=true" : "")
              }
            />
            <input
              type="hidden"
              name="notify_url"
              value={
                APP_URL +
                URLS.NOTIFY_PAGE +
                "?amount=" +
                payFastData.amount +
                (regrade ? "&regrade=true" : "")
              }
            />
            <input
              type="hidden"
              name="name_first"
              value={payFastData.name_first}
            />
            <input
              type="hidden"
              name="name_last"
              value={payFastData.name_last}
            />
            <input
              type="hidden"
              name="email_address"
              value={payFastData.email_address}
            />
            <input
              type="hidden"
              name="cell_number"
              value={payFastData.cell_number}
            />
            <input
              type="hidden"
              name="m_payment_id"
              value={payFastData.m_payment_id}
            />
            <input
              type="hidden"
              name="item_description"
              value={payFastData.item_description}
            />
            <input type="submit" hidden />
          </form>
        ) : null}
      </Box>

      {/* Modal For Invoice and Uploading docs */}
      <Box sx={{ paddingLeft: "5rem", paddingRight: "5rem" }}>
        <Modal show={show} onHide={handleClose} fullscreen style={{}}>
          <Modal.Header closeButton style={{ background: "" }}>
            <Modal.Title>EFT Payment </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EftPaymentModalContent base64PDF={base64} />
          </Modal.Body>
          <Modal.Footer>
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput type="file" onChange={handleFileChange} />
              </Button>

              <Button
                variant="contained"
                onClick={handleClose}
                style={{
                  width: "12rem",
                  height: "3.5rem",
                  marginLeft: "auto",
                  marginRight: "auto",
                  backgroundColor: "",
                }}
              >
                Close
              </Button>
            </Box>
          </Modal.Footer>
        </Modal>
      </Box>

      {/* Modal for Select document  */}
    </>
  );
};

export default PaymentOptionGate;
