import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const NotifyPage = () => {
  const navigate = useNavigate();
  const inIframe = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  useEffect(() => {
    // alert("notify page")
    sessionStorage.removeItem('residenceID');
    sessionStorage.removeItem('residenceName');
    sessionStorage.removeItem('regrade');
    if(inIframe()) {
      // window.parent.postMessage("notify", window.parent.location.origin)
      if((window as any).messageHandler){
        (window as any).messageHandler.postMessage("notify")
      }
    }
    else {
      setTimeout(() => {
        navigate(`/`)
      },3000)
    }
  },[])


  return (
    <section className="container">
    <div className="white-block p-3">
      <div className="payment-screen-panel">
        <h2 className="mb-3">Notify</h2>
        <p>You have been notified</p>
      </div>
    </div>
  </section>
  )
}

export default NotifyPage