import React, { useState, useEffect } from "react";
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Modal } from 'react-bootstrap';
import Header from "./student/components/header";
import ApproveModal from '../components/ApproveModal';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useCommonApi } from '../_common/hooks/api/common/commonApiHook';
import { useUserSelector } from '../_common/hooks/selectors/userSelector';
import toast from 'react-hot-toast';
import userCheckIcon from '../images/user-check-01.svg';
import RejectionModal from "../components/RejectionModal";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import { SignWellEmbedComponent } from 'react-signwell-library';
import noApplicationImg from "../images/empty-2.jpg"
import { IApiResponse } from "../ui/v2/types/IApiResponse";
import { API } from "../ui/v2/services/API";
import { EXECUTE_REQUEST_PATH } from "../ui/v2/constants";





interface OfferLetter {
    id: string;
    Address: string;
    PropertyName: string;
    TotalCapacity: string;
    OfferLettersId: string;
    OfferStatus: string;
    DocumentURL:string;
  }

interface SignDocument{
  id:string;
  SignUrl:string;
  signing_url:string

}

type signwellRetrieveUrl = {
  url: string
};
  
  interface ResidenceApplicationsProps {
    // You can add any other props if needed
  }

  const OfferLetter: React.FC<ResidenceApplicationsProps> = (props) => {
    const { id } = useParams<{ id: string }>();
    const commonApi = useCommonApi();
    const user: any = useUserSelector();
    const [show, setShow] = useState<boolean>(false);
    const [selectedOfferLetterId, setSelectedOfferLetterId] = useState<any>();
    const [facilityApplications, setFacilityApplication] = useState<OfferLetter[]>([]);
    const [apId,setApId] = useState<[]>([]);
    const [apOfferLetterResp, setApOfferLetterResp] = useState<OfferLetter[]>([]);
    const [myId,setMyId] = useState<any>(); 
    const { showLoader, hideLoader } = useAppLoader();
    const navigate = useNavigate();
    const [showSignComponent, setShowSignComponent] = useState(false);
    const [signDocumtentData,setSignDocumentData] = useState<SignDocument[]>([])
    const [signingUrl,setSigningUrl] = useState('');
    const [documentUrl,setDocumentUrl] = useState('');

    const handleClose = () => {
        setSelectedOfferLetterId(null);
        setShow(false);
      };
    
     
      const handleShow = (offerLetterId: string) => {
        // Find the selected offer letter in apOfferLetterResp
        const selectedOfferLetter = apOfferLetterResp.find(letter => letter.OfferLettersId === offerLetterId);
    
        
        if (offerLetterId) {
          setSelectedOfferLetterId(offerLetterId);
          setShow(true);
          setMyId(offerLetterId)
          setShow(true);

        }
      };
      
      
      const getLinkOnClick = async (applicationId: any) =>{
        try {
          showLoader();
          const response: IApiResponse<signwellRetrieveUrl> = await API.post(
            EXECUTE_REQUEST_PATH,
            {
              entityName: "OfferLetters",
              requestName: "RetrieveSignwellReq",
              recordId:applicationId,
              inputParamters: {
                UserType: "AP"
              },
            }
          );;
          window.open(
              response.outputParameters.url
            );
        } catch (e) {
          console.error(e);
        } finally {
          hideLoader();
        }
      }


      const getApOfferLetter = () => {
        commonApi.getApOfferLetter({
          AccomodationProviderId: user.supplierId
        }, (message: string, resp: []) => {
          
          if (resp.length > 0) {
            
            // setFacilityApplication(resp);
            setApOfferLetterResp(resp);
            
            setApId(resp);
            // setDocumentUrl(resp)
            setApId(resp)
            

            
          }
        }, (message: string) => {
        });
      };
      
      useEffect(()=>{
        getApOfferLetter();
      },[])
      

        const showSigning = () => {
          setShowSignComponent(!showSignComponent);
        };
        const closeSigning = () => {
          setShowSignComponent(false);
        };
        const handleShowModal = () => {
          showLoader();
          setShow(true);
          setTimeout(()=>{
            handleClose();
            hideLoader();
          },0)
        };

      // console.log("--->--",apOfferLetterResp); 


       
  return (
 
 

      <Box className="dashboard">
      <Header />
      <Box p={2}>
      <Typography
        sx={{ 
          color: '#101828',
          fontFamily: 'Inter',
          fontSize: '30px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: '38px',
        }}>
        Offer Letter
      </Typography>
      <p className='application-sub-heading'>Offer Letter Details</p>
      {apOfferLetterResp.length > 0 ? 
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{}} >
        <Table sx={{ minWidth: 1050 }} aria-label="simple table">
          <TableHead
            sx={{
              backgroundColor: '#F9FAFB'
            }}
          >
            <TableRow>
              <TableCell>Property Name</TableCell>
              <TableCell >Address</TableCell>
              <TableCell>Total Capacity</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align='right'>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {apOfferLetterResp.map((offerLetterResp) => (
              <TableRow
                key={offerLetterResp.OfferLettersId}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
              >
                <TableCell component="th" scope="row">
                  {offerLetterResp.PropertyName}
                </TableCell>
                <TableCell className='table-data'> {offerLetterResp.Address}</TableCell>
                <TableCell className='table-data'> {offerLetterResp.TotalCapacity}</TableCell>
                <TableCell className='table-data'> {offerLetterResp.OfferStatus}</TableCell>

                {
                  showSignComponent === true ? <SignWellEmbedComponent embeddedSigningUrl={offerLetterResp.DocumentURL}/> : false
                }
                {/* <button onClick={closeSigning}>Close</button> */}
                <TableCell sx={{
                  display: 'flex',
                  gap: '1rem',
                  justifyContent: 'right',
                }} >
                
                  <Button
                    onClick={() => {
                      getLinkOnClick(offerLetterResp.OfferLettersId)
                      
                    }}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '8px',
                      border: '1px solid #D0D5DD',
                      color: '#344054',
                    }}>
                    <img src={userCheckIcon} alt="user-check-icon"/>
                    View
                  </Button>
                  
                </TableCell>
                <Modal show={show} onHide={handleClose}>
                 

                  <Modal.Body>
                 
                    {
                      <SignWellEmbedComponent embeddedSigningUrl={offerLetterResp.DocumentURL}/>
                    }
                  </Modal.Body>
                  
                </Modal>
              </TableRow>

              // ******************
              
              // ******************
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
    :
    <Box style={{display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'column'}}>
      <Typography sx={{fontWeight:'600'}}>No Offer Letters</Typography> 
      <img src={noApplicationImg} alt="no-applications-image" width={'500px'} />
    </Box> 
       }
      </Box>
    </Box>
  )
}

export default OfferLetter