import React from "react";
import Select, { TSelectProps } from "../Select";
import { startOfMonth } from "date-fns/startOfMonth";
import { addMonths } from "date-fns/addMonths";
import { format } from "date-fns/format";
import { endOfMonth } from "date-fns/endOfMonth";

const DATE_TODAY = new Date();

const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

// the 1st day of each of the next 12 months
// TODO remove this function
const START_OPTIONS = months.map((m) => {
  const startingDate = startOfMonth(DATE_TODAY);
  const movingMonth = addMonths(startingDate, m);
  const movingDate = startOfMonth(movingMonth);
  return {
    label: format(movingDate, "d MMMM yyyy"),
    value: format(movingDate, "yyyy-MM-dd"),
  };
});

// TODO remove this function
const END_OPTIONS = months.map((m) => {
  const startingDate = startOfMonth(DATE_TODAY);
  const movingMonth = addMonths(startingDate, m);
  const movingDate = endOfMonth(movingMonth);
  return {
    label: format(movingDate, "d MMMM yyyy"),
    value: format(movingDate, "yyyy-MM-dd"),
  };
});

const MonthPeriodSelect = React.forwardRef(
  (
    {
      mode,
      applicationDates,
      ...props
    }: Omit<TSelectProps, "options"> & {
      mode: "start" | "end";
      applicationDates?: any;
    },
    ref: any
  ) => {
    return (
      <Select
        ref={ref}
        {...props}
        options={
          mode === "start"
            ? applicationDates.PlannedMoveInDate
            : applicationDates?.PlannedMoveOutDate
        }
      />
    );
  }
);

export default MonthPeriodSelect;
