import { LeaseStatus, StudentVerificationStatus } from "../../types/shared";
import { TStatusStyleFormatter } from "./types";

const getStatusStyle = (status: string) => {
  if (!status) {
    return "success";
  }
  switch (status.toLowerCase()) {
    case "active":
      return "success";
    case "terminated":
      return "error";
    case "lapsed":
      return "error";
    default:
      return "success";
  }
};
const getStudentVerificationStatusStyle = (
  status: StudentVerificationStatus
) => {
  if (!status) {
    // sometimes api may return now, this while prevent white-screen
    return "success";
  }
  switch (status) {
    case StudentVerificationStatus.PendingVerification:
    case StudentVerificationStatus.Unverified:
      return "error";
    default:
      return "success";
  }
};

const getStudentVerificationStatusLabel = (
  status: StudentVerificationStatus
) => {
  if (!status) {
    // sometimes api may return now, this while prevent white-screen
    return "";
  }
  switch (status) {
    case StudentVerificationStatus.PendingVerification:
      return "Pending Verification";
    case StudentVerificationStatus.Unverified:
      return "Unverified";
    case StudentVerificationStatus.Verified:
      return "Verified";
    default:
      return "";
  }
};

const getLeaseStatusStyle = (status: LeaseStatus) => {
  if (!status) {
    return "success";
  }
  switch (status) {
    case LeaseStatus.Active:
      return "success";
    case LeaseStatus.PendingRenewal:
      return "success";
    case LeaseStatus.Terminated:
      return "error";
    case LeaseStatus.Lapsed:
      return "error";
    default:
      return "success";
  }
};

const getLeaseStatusLabel = (status: LeaseStatus) => {
  if (!status) {
    return "";
  }
  switch (status) {
    case LeaseStatus.Active:
      return "Active";
    case LeaseStatus.PendingRenewal:
      return "Pending Renewal";
    case LeaseStatus.Terminated:
      return "Terminated";
    case LeaseStatus.Lapsed:
      return "Lapsed";
    default:
      return "";
  }
};

const getNsfasStatusStyle = (status: string) => {
  if (!status) {
    return "error";
  }
  switch (status.toLowerCase()) {
    case "verified":
      return "success";
    default:
      return "error";
  }
}

const nsfasStatusFormatter: TStatusStyleFormatter = (status) => ({
  variant: getNsfasStatusStyle(status as any),
  label: status as any,
});

const leaseStatusFormatter: TStatusStyleFormatter = (status) => ({
  variant: getLeaseStatusStyle(status as any),
  label: getLeaseStatusLabel(status as any),
});

export const ApUtils = {
  getStatusStyle,
  getStudentVerificationStatusStyle,
  getStudentVerificationStatusLabel,
  statusFormatters: {
    nsfas: nsfasStatusFormatter,
    lease: leaseStatusFormatter,
  },
};
