import Resizer from "react-image-file-resizer";
import Box from "@mui/material/Box";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { TSelectedFilters } from "../ui/v2/components/GenericListingScreen/useSearchAndFiltersBar/SearchAndFiltersBar";
import { FieldErrors } from "react-hook-form";
import { TermType } from "../ui/v2/types/shared";

const resizeFile = (file: Blob, size = 300) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      size,
      size,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

const getPlaceholderImage = (text: string) => {
  return `https://images.placeholders.dev?text=${encodeURI(
    text
  )}&width=600&height=300&fontSize=12`;
};

const getGetImageFromBase64 = (base64: string | undefined | null) => {
  if (!base64) {
    return getPlaceholderImage("No image");
  } else {
    return `data:image/png;base64,${base64}`;
  }
};
const getObjectURLfromBase64 = (base64String: string) => {
  // Split the base64 string to get content type and data
  const parts = base64String.split(",");
  const contentType = parts[0].replace("data:", "").replace(";base64", "");
  const base64Data = parts[1];

  // Decode the base64 data
  const byteString = atob(base64Data);

  // Create a Blob object
  const blob = new Blob([byteString], { type: contentType });

  // Generate an object URL from the Blob
  return URL.createObjectURL(blob);
};

function* getIdGenerator() {
  let i = 0;
  while (true) {
    yield i++;
  }
}

const ids = getIdGenerator();

export const getId = (): number => ids.next().value || 0;

const getSvgIconComponent = (
  path?: string,
  fallbackFaIcon = "",
  overrideProps: SvgIconProps = {}
) => {
  if (!path) {
    if (!fallbackFaIcon) return null;
    return (
      <Box
        component={"i"}
        sx={{ fontSize: overrideProps.fontSize ?? 32 }}
        className={fallbackFaIcon}
      />
    );
  }
  return (
    <SvgIcon {...overrideProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        stroke="currentColor"
      >
        <path
          d={path}
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#0F172A"
          strokeWidth="2"
        />
      </svg>
    </SvgIcon>
  );
};

const extractBase64FromDataUrl = (dataUrl: string) => {
  return dataUrl.split(",")[1];
};

const extractFilterValue = (filters: TSelectedFilters, filterName: string) => {
  return filters.find((filter) => filter.filterNameValue === filterName)?.value;
};

const getImageSource = (docId: string, folder: string) => {
  return (
    process.env.REACT_APP_API_DOMAIN +
    "/api/v1/entities/imageview?id=" +
    docId +
    "&folder=" +
    folder +
    "&apikey=" +
    process.env.REACT_APP_API_KEY
  );
};

const replaceAll = function (
  string: string = "",
  match: string,
  replace: string
): string {
  return string.replace(new RegExp(match, "g"), () => replace);
};

const getFileExtension = (filename: string) =>
  filename.toLowerCase().split(".").pop();

const getInitials = (firstName: string, lastName?: string) => {
  if (firstName === null) {
    return "";
  }
  if (firstName && lastName) {
    return `${firstName.charAt(0)} ${lastName.charAt(0)}`;
  } else if (firstName.includes(" ")) {
    const parts = firstName.split(" ");
    const _firstName = parts.shift();
    const _lastName = parts.pop();
    if (_firstName && _lastName) {
      return `${_firstName.charAt(0)} ${_lastName.charAt(0)}`;
    }
    return _firstName?.charAt(0);
  }
  return firstName.charAt(0);
};

function convertDates(dates: string[]): { label: string; value: string }[] {
  // Handle empty array input
  if (dates.length === 0) {
    return [];
  }

  return dates.map((date) => {
    const currentDate = new Date(date);

    // Format the label as "DD Month YYYY"
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const label = currentDate.toLocaleDateString("en-US", options);

    // Format the value as "YYYY-MM-DD"
    const value = currentDate.toISOString().split("T")[0];

    return {
      label: label,
      value: value,
    };
  });
}

function cleanAddressString(...parts: (string | undefined)[]): string {
  return parts
    .filter((part) => part !== "") // Filter out undefined and empty strings
    .join(" "); // Join remaining parts with a space
}

const getDatesFromNextMonth = (dates: { value: string; label: string }[]) => {
  const currentDate = new Date();
  const nextMonth = currentDate.getMonth() + 2; // getMonth() is zero-indexed
  const nextYear = currentDate.getFullYear();

  return dates.filter(({ value }) => {
    const [day, month, year] = value.split("-").map(Number);
    return year > nextYear || (year === nextYear && month >= nextMonth);
  });
};

function getTermTypeLabel(termType: string): string {
  switch (termType) {
    case TermType.Annual:
      return "Annual";
    case TermType.Trimester:
      return "Trimester";
    case TermType.Semester:
      return "Semester";
    default:
      return "Unknown Term Type"; // Handle unknown term types
  }
}

export const UTILS = {
  resizeFile,
  convertFileToBase64,
  getPlaceholderImage,
  getGetImageFromBase64,
  getId,
  getSvgIconComponent,
  extractBase64FromDataUrl,
  extractFilterValue,
  getImageSource,
  replaceAll,
  getFileExtension,
  getInitials,
  convertDates,
  cleanAddressString,
  getDatesFromNextMonth,
  getTermTypeLabel,
};
