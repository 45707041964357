import React from 'react'
import { Button } from 'react-bootstrap';
import IntroSlider from '../components/introSlider';
import { useNavigate } from 'react-router-dom';

const ResetPasswordSuccess = () => {

	const navigate = useNavigate();


  return (
    <React.Fragment>
			<div className="register_page login_page">
				<div className="register_left">
					<div className="mob_logo">
						<a href="#" className="logo_left"><img src="/assets/img/logo.png" /></a>
					</div>
					<div className="logo_txt">
						Password reset successful.
					</div>
					<p className="text-center">
						Your password has been successfully reset.
						You can now login with your new passowrd.
						<br/><br/>

					</p>
					<div className="mobbottom_fixbtn" style={{display:'flex',justifyContent:'center'}}>
						<Button variant="primary" className="w-50 mt-2" type="button" onClick={() => navigate('/login')}>Login</Button>
					</div>
				</div>
				<IntroSlider />
			</div>
		</React.Fragment>
  )
}

export default ResetPasswordSuccess