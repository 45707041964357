import Swal from 'sweetalert2';

export const showErrorAlert = (message: any) => {
  return Swal.fire({
    icon: 'error',
    confirmButtonColor: '#F88A0B',
    text: message,
  });
};
export const showSuccessAlert = (message: any) => {
  return Swal.fire({
    icon: 'success',
    text: message,
  });
};

const Toast = Swal.mixin({
  toast: true,
  position: 'center',
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export const signedInAlert = () => {
  Toast.fire({
    icon: 'success',
    title: 'Signed in successfully',
  });
};
