import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import AppLoader from '../_common/pageLoader/appLoader';

const redirectPage = () => {

    // const navigate = useNavigate();

    // const gotoRedirect = () => {
    //     AppLoader();
    //     navigate('https://xgprod.nsfas.org.za/login?type=student')    
    // }

  return (
    <div className='redirect-container'>
        <div className="mob_logo">
            <a href="#" className="logo_left"><img src="/assets/img/logo.png" /></a>
        </div>
        <h1 className='text-center'>Click The Button Below To Go To The Login Page</h1>
        <Button className='redirect-btn' href='https://xgprod.nsfas.org.za/login?type=student'>Redirect</Button>
    </div>
  )
}

export default redirectPage