
export const STUDENT_STATUS_OPTIONS = [
  { label: 'Pending Verification', value: '866' },
  { label: 'Verified', value: '867' },
  { label: 'Expelled by Institution', value: '868' },
  { label: 'Suspended by institution', value: '869' },
  { label: 'Invalid', value: '870' },
]

export const ID_TYPE = [
    { label: 'RSA ID', value: '862' },
    { label: 'Password', value: '871' },
    { label: 'Company Registration Number', value: '863' },
]

export const ETHNICITY_OPTIONS = [
    { label: 'African', value: '782' },
    { label: 'Colored', value: '784' },
    { label: 'White', value: '783' },
    { label: 'Indian/Asian', value: '785' },

]
/**@deprecated use v2 */
export const GENDER_OPTIONS = [
    { label: 'Male', value: '1' },
    { label: 'Female', value: '2' },

]
export const NATIONALITY_OPTIONS = [
    { label: 'South Africa', value: '898d1d4f-5b02-434a-a37f-e947ced5f6e1' },
    { label: 'Lesotho', value: 'e3aca800-1f9c-4978-8268-500e4fb08ccb' },
    { label: 'Zimbabwe', value: '01d8df33-11bf-4c49-a89b-536319c12b80' },
    { label: 'Mozambique', value: 'abc974d3-3a03-493f-a9bc-73289b5aaec7' },
    { label: 'Botswana', value: '1f224faf-82c8-4fd4-9e84-b0fc4b3a66b3' },

]

/**@deprecated use v2 */
export const genderOptions = [
    {value : '1' , label:'Male'},
    {value : '2', label:'Female'},
]
export const DISABLED_STATUS_OPTIONS = [
    { label: 'Yes', value: '636' },
    { label: 'No', value: '637' },

]

export const DEFAULT_PAGE_SIZE = 12;

export const TABLE_DATE_FORMAT = 'yyyy-MM-dd hh:mm a';

export const SELFIE_DOCUMENT_TYPE = 951;