import React, { useEffect, useState } from "react";
import {  format } from 'date-fns'

function usePageTimer(duration: number) {
  const [timeLeft, setTimeLeft] = useState(duration);

  const lockTimerRef = React.useRef<NodeJS.Timeout | null>(null);

  const onTimerCompleteRef = React.useRef<() => void>(() => {});

  const startTimer = (onTimerComplete: () => void) => {
    onTimerCompleteRef.current = onTimerComplete;
    lockTimerRef.current = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev === 0) {
          clearInterval(lockTimerRef.current!);
          onTimerCompleteRef.current();
          onTimerCompleteRef.current = () => {};
          return duration;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const cancelTimer = () => {
    clearInterval(lockTimerRef.current!);
    setTimeLeft(duration);
  }

  useEffect(
    () => () => {
      if (lockTimerRef.current) {
        clearInterval(lockTimerRef.current);
      }
    },
    []
  );

  const formattedTimeLeft = format((timeLeft * 1000), 'mm:ss')

  return { timeLeft, formattedTimeLeft, startTimer, cancelTimer };
}

export default usePageTimer;
