import { useEffect, useRef, useState } from "react";
import { ThemeColors } from "../../styles/theme";
import { Grid } from "@mui/material";
import { css } from "@emotion/css";

const OtpInput = ({
    onChange,
    length,
  }: {
    onChange: (otp: string) => void;
    length: 4 | 6;
  }) => {
    const [value, setValue] = useState("");
    const inputRefs = useRef<any>([]);
  
    const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Backspace") {
        e.preventDefault();
        setValue((value) => value.slice(0, value.length - 1));
        const previousInputRef = inputRefs.current[value.length - 1];
        if (previousInputRef) {
          previousInputRef.focus();
        }
      }
    };
  
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const newValue = e.target.value;
      setValue((value) => `${value}${newValue}`);
  
      const nextInputRef = inputRefs.current[value.length + 1];
  
      if (nextInputRef) {
        window.setTimeout(() => nextInputRef.focus(), 100);
      }
    };
    const handleOnClick = (index: number) => (e: unknown) => {
      console.log(index, inputRefs.current[value.length]);
      if (inputRefs.current[value.length]) {
        window.setTimeout(() => inputRefs.current[value.length].focus(), 100);
      }
    };
  
    useEffect(() => {
      onChange(value);
    }, [value]);
  
    const emptyArray = Array.from(Array(length).keys());
    const inputs = emptyArray.map((index) => {
      const isNotCurrent = value.length !== index;
      const borderColor =
        value.length >= index ? ThemeColors.primaryOrange : "#999";
      const borderWidth =
        value.length === index ||
        (value.length === length && index === length - 1)
          ? 2
          : 1;
  
      return (
        <Grid item xs={length === 4 ? 3 : 2} key={index} sx={{ p: 1 }}>
          <input
            className={css({
              width: "100%",
              height: 50,
              fontSize: 24,
              textAlign: "center",
              color: ThemeColors.primaryOrange,
              background: ThemeColors.white,
              border: `${borderWidth}px solid ${borderColor} !important`,
              borderRadius: 4,
              outline: "none",
            })}
            onClick={handleOnClick(index)}
            onKeyDown={handleOnKeyPress}
            ref={(element) => (inputRefs.current[index] = element)}
            readOnly={isNotCurrent}
            maxLength={1}
            value={value.split("")[index] ?? ""}
            onChange={handleOnChange}
          />
        </Grid>
      );
    });
  
    return (
      <Grid item container xs={12}>
        {inputs}
      </Grid>
    );
  };

  export default OtpInput;