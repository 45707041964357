import { ChevronRight, FilterList, SearchOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Fade,
  Grid,
  Paper,
  Popper,
  Badge,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import TextField from "../../../TextField";
import { ThemeColors } from "../../../../styles/theme";
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";
import Select from "../../../Select";

export type TListingFilter = {
  type: "dropdown";
  name: string;
  value: string;
  multiple: boolean;
  options: { label: string; value: string }[];
  initialValue: string;
};

export type TSelectedFilters = { filterNameValue: string; value: string }[];

function SearchAndFiltersBar({
  activeFilters,
  setSearchInput,
  onSearchClick,
  filters,
  value,
  onFiltersChange,
  showSubline: showSubLine = false,
}: {
  activeFilters: TSelectedFilters;
  onFiltersChange: (filters: TSelectedFilters) => void;
  setSearchInput: (text: string) => void;
  onSearchClick: () => void;
  filters: TListingFilter[];
  value: string;
  showSubline?: boolean;
}) {
  const [_filters, setFilters] = useState<TSelectedFilters>(activeFilters);
  return (
    <Grid container display="flex" width="100%" alignItems="center" flex={1}>
      <Grid item flex={1}>
        <PopupState variant="popper" popupId="demo-popup-popper">
          {(popupState) => (
            <div>
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                disabled={filters.length === 0}
                startIcon={<FilterList />}
                sx={{pr: 3}}
                {...bindToggle(popupState)}
                endIcon={
                  <Badge sx={{ml:1}} badgeContent={activeFilters.length} variant="standard" color="primary" max={9}/> 
                }
              >
                Filters
              </Button>
              <Popper
                {...bindPopper(popupState)}
                placement="bottom-start"
                transition
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                      <Card>
                        <CardContent>
                          {filters.map((filter) => {
                            return (
                              <Box
                                sx={{
                                  p: 1,
                                  width: { xs: 240, md: 400 },
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Select
                                  disabled={filter.options.length === 0}
                                  label={filter.name}
                                  options={filter.options}
                                  showEmptyOption={false}
                                  value={_filters.find((_) => _.filterNameValue === filter.value)?.value ?? filter.initialValue}
                                  onChange={(e) => {
                                    setFilters([
                                      ..._filters.filter(
                                        (_) => _.filterNameValue !== filter.value
                                      ),
                                      {
                                        filterNameValue: filter.value,
                                        value: e.target.value as string,
                                      },
                                    ]);
                                  }}
                                  size="small"
                                />
                              </Box>
                            );
                          })}
                          <CardActions
                            sx={{
                              alignSelf: "stretch",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-start",
                            }}
                          >
                            <Button
                              size="small"
                              variant="outlined"
                              color="secondary"
                              {...bindToggle(popupState)}
                            >
                              Close
                            </Button>
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => {
                                onFiltersChange(_filters);
                                popupState.close();
                              }}
                            >
                              Apply
                            </Button>
                          </CardActions>
                        </CardContent>
                        <Divider />
                      </Card>
                    </Paper>
                  </Fade>
                )}
              </Popper>
              {showSubLine && <Box>
                <Typography variant="caption">
                  {activeFilters.map((filter) => (
                    <>
                      {filter.filterNameValue}: {filter.value} &nbsp;
                    </>
                  ))}
                </Typography>
              </Box>}
            </div>
          )}
        </PopupState>
      </Grid>
      <Grid item xs={12} md={3} textAlign="right">
        <TextField
          placeholder="Search"
          size="small"
          value={value}
          sx={{ background: ThemeColors.white }}
          onChange={(e) => setSearchInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") onSearchClick();
          }}
          InputProps={{
            // startAdornment: (
            //   <InputAdornment position="start">
            //     <SearchOutlined />
            //   </InputAdornment>
            // ),
            endAdornment: (
              <Button
                type="button"
                variant="text"
                size="small"
                sx={{ mr: -1.5 }}
                color="secondary"
                onClick={onSearchClick}
              >
                <SearchOutlined />
              </Button>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
}

export default SearchAndFiltersBar;
