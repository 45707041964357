import { Box, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import Header from '../components/header';
import Select from 'react-select';
import { useCommonApi } from '../_common/hooks/api/common/commonApiHook';
import { Button } from 'react-bootstrap';
import { DevTool } from '@hookform/devtools';
import toast from 'react-hot-toast';
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from './student/components/breadcrumbs';
import { useUserSelector } from '../_common/hooks/selectors/userSelector';



const AddCampus = () => {

    const { register, handleSubmit, control, formState: { errors }, watch, reset } = useForm();
    const commonApi = useCommonApi();
    const [provinces, setProvinces] = useState<any>(null); // state to hold provinces
    const [institutions, setInstitutions] = useState<any>(null);
    const institution = watch("institution");
    const { showLoader, hideLoader } = useAppLoader();
    const navigate = useNavigate();
    const user:any = useUserSelector();
    const institutionId = user?.institutionId;
    console.log(user);
    
    

    const getProvinces = () => {
      commonApi.post(
        {
          entityName: "Picklist",
          requestName: "RetrieveSelectOptionExecuteRequest",
          inputParamters: {
            SelectOption: {
              OptionText: "Province",
            },
          },
        },
        (message: string, resp: any) => {
          if (resp.outputParameters.Count > 0) {
            // Filter provinces here to exclude 'Limpopo'
            const filteredProvinces = resp.outputParameters.data.filter(
              (data: any) => data.optionText !== ""
            );
    
            setProvinces(
              filteredProvinces.map((data: any) => ({
                label: data.optionText,
                value: data.optionValue,
              }))
            );
          }
       
        },
        (message: string) => {
          console.log(message);
          toast.error(message)
        }
      );
    };

    const getInstitutions = () => {
      commonApi.retreiveInstitution(
        {},
        (message: string, resp: any) => {
          let options: any = [];
          resp.map((ins: any) => {
            if (ins.recordId.search("000000") !== 0) {
              ins.value = ins.recordId;
              ins.label = ins.mainText;
              options.push(ins);
            }
          });
          console.log(options);
          setInstitutions(options);
          console.log("😴",institutions);
          
        },
        (message: string) => {
          toast.error(message)
        }
      );
    };
  
    useEffect(()=>{
      getProvinces();
      getInstitutions();
  
    },[])


    const onSubmit = (data:any) =>{
        console.log("🧒 🎅",data);
        showLoader();

        commonApi.post({
          "entityName": "Campus",
          "requestName": "UpsertRecordReq",
          // "recordId": id,
          "inputParamters":
          {
            "Entity": {
                Name:data.name,
                Line1:data.address,
                InstitutionId:data.institution.value,
                ProvinceId:data.province.value,
                Suburb:data.surburb,
                PostalCode:data.postalCode,
            }
          }
          
        }, (message: string, resp: any) => {
            hideLoader();
            
          toast.success(message);
          setTimeout(()=>{
            navigate('/institution-dashboard')
          },1000)
          // hideLoader()
        }, (message: string) => {
          toast.error(message)
          hideLoader()
        });
    }

      // setUserDetails(user)

  return (
    <div>
        <Box>
            <Header/>
        </Box>
        <Box sx={{padding:'2rem'}}>
            <Box sx={{marginTop:'4rem', marginBottom:'2rem'}}>
              <Breadcrumbs
                  data={[
                    { label: 'Home', path: '/' },
                    { label: 'Dashboard', path: '/institution-dashboard' },
                    { label: 'Add Campus' },
                  ]}
              />
            </Box>
            <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center', marginTop:'7rem'}}>
                <Typography
                  sx={{
                    color: "#101828",
                    fontFamily: "Inter",
                    fontSize: "30px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "38px",
                    marginBottom:"20px",
                  }}
                >
                  Add Campus Details
                </Typography>
            </Box>
            <Paper sx={{ width: "100%", overflow: "hidden", padding:'3rem' }}>
                  <form action="" onSubmit={handleSubmit(onSubmit)}>
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: "2rem", flexDirection:{xs:'column',md:'row'}, width:{xs:'50%',md:'100%'}, marginLeft:{xs:'auto'},marginRight:{xs:'auto'} }}
                    >
                      <div
                        className="mb-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          marginTop: "1rem",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          htmlFor="exampleFormControlInput1a"
                          className={"form-label "}
                        >
                          Name
                        </label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1a"
                          placeholder="E.g Barberton Tvet"
                          style={{ width: "60%" }}
                          {...register("name")}
                          // defaultValue={studentDetail.Name}
                        />
                      </div>
                      <div
                        className="mb-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          gap: "1rem",
                          marginTop: "1rem",
                          justifyContent: "space-between",
                        }}
                      >
                        
                        <div
                        className="mb-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          gap: "1rem",
                          marginTop: "1rem",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          htmlFor="exampleFormControlInput1a"
                          className={"form-label "}
                          style={{ textAlign: "left" }}
                        >
                          Institution
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1a"
                          placeholder=""
                          style={{ width: "60%", textAlign: "left" }}
                          {...register("institution")}
                          required
                          value={institutions?.value}
                          disabled

                        />
                      </div>
                      </div>
                    </Box>
                    {/*  */}
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: "2rem", flexDirection:{xs:'column',md:'row'}, width:{xs:'50%',md:'100%'}, marginLeft:{xs:'auto'},marginRight:{xs:'auto'} }}
                    >
                      <div
                        className="mb-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          marginTop: "1rem",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          htmlFor="exampleFormControlInput1a"
                          className={"form-label "}
                        >
                          Province
                        </label>
                        <div className="" style={{ width: "60%" }}>
                          <Controller
                            

                            name={"province"}
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange, onBlur } }) => {
                                return (<Select
                                  className={"form-control p-0" + (errors?.Province ? ' is-invalid' : '')}
                                  options={provinces}
                                  
                                  placeholder={"Select a Province"}
                                  onChange={onChange}
                                  value={value}
                                  // defaultValue={value}
                              />)
                            }}
                        />
                      </div>
                        
                      </div>
                      <div
                        className="mb-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          gap: "1rem",
                          marginTop: "1rem",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          htmlFor="exampleFormControlInput1a"
                          className={"form-label "}
                          style={{ textAlign: "left" }}
                        >
                          Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1a"
                          placeholder="E.g 12 Hellen Road"
                          style={{ width: "60%", textAlign: "left" }}
                          {...register("address")}
                          required

                        />
                      </div>
                    </Box>
                    {/* ******************** */}
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: "2rem", flexDirection:{xs:'column',md:'row'}, width:{xs:'50%',md:'100%'}, marginLeft:{xs:'auto'},marginRight:{xs:'auto'} }}
                    >
                      <div
                        className="mb-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          marginTop: "1rem",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          htmlFor="exampleFormControlInput1a"
                          className={"form-label "}
                        >
                          Surburb
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1a"
                          placeholder="E.g Middlestown"
                          style={{ width: "60%" }}
                          {...register("surburb")}
                          // defaultValue={studentDetail.Name}
                        />
                      </div>
                      <div
                        className="mb-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          gap: "1rem",
                          marginTop: "1rem",
                          justifyContent: "space-between",
                        }}
                      >
                        <label
                          htmlFor="exampleFormControlInput1a"
                          className={"form-label "}
                          style={{ textAlign: "left" }}
                        >
                          Postal Code
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1a"
                          placeholder="E.g 2309"
                          style={{ width: "60%", textAlign: "left" }}
                          {...register("postalCode")}
                        />
                      </div>
                    </Box>

                    <Box sx={{
                      marginTop:'5rem',
                      display:'flex',
                      justifyContent:'end',
                      gap:'2rem'
                      }}
                      >
                      <Button 
                        variant="danger" 
                        style={{ width: "10%" , cursor:'pointer'}}
                        href='/institution-dashboard'
                      >
                        Back
                      </Button>
                      <Button
                        variant="primary"
                        style={{ width: "10%" }}
                        type="submit"
                      >
                        Create
                      </Button>
                     
                    </Box>
                    
                  </form>
            </Paper>
            </Box>
            <DevTool control={control}/>
    </div>
  )
}

export default AddCampus