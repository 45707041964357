import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Toaster } from 'react-hot-toast';
import AppLoader from './_common/pageLoader/appLoader';
import { store, persistor } from './store';
import { ACTIONS, NAVIGATIONS, STORAGE } from './_config';
// import axios from 'axios';

import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApplicationLoader } from './context/ApplicationLoader';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const user = sessionStorage.getItem(STORAGE);
const navigations = sessionStorage.getItem(NAVIGATIONS) || '[]';

function render() {
  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <React.Fragment>
          <Toaster position="top-center" />
          <ApplicationLoader.Provider>
          <App />
          </ApplicationLoader.Provider>
        </React.Fragment>
      </PersistGate>
    </Provider>
  );
}

/* function tryLogin(url: string): Promise<any> {
	return new Promise((resolve, reject) => {
		try {
			axios({
				method: 'POST',
				url: url,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}).then(success => {
				if (success && success.data && success.data.user_details) {
					return resolve(success.data.user_details)
				} else {
					return reject(null)
				}
			}).catch(err => {
				return reject(null)
			})
		} catch (e) {
			return reject(null)
		}
	})
} */

if (user) {
  try {
    store.dispatch({
      type: ACTIONS.USER.LOGIN,
      payload: {
        user: JSON.parse(user),
        token: null,
        navigations: JSON.parse(navigations),
      },
    });
    render();
  } catch (e) {
    sessionStorage.removeItem(STORAGE);
    render();
  }
} else {
  render();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
