import React from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import Header from '../components/header';
import { URLS } from '../_config';
import { MdAddCircle } from 'react-icons/md';

const InstitutionView = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className="dashboard">
        <Header />
        <div onClick={() => navigate(URLS.INSTITUTION_VIEW_LIST)}>InstitutionView</div>
        <div className="container dashboard-view">
          <div className="white-block p-3 white-box-wide institution-view-wrapper">
              <Row>
                  <Col>
                      <h5>Upload Residences</h5>
                  </Col>
                  <Col className='text-end'>
                      <span>
                          <Button className="icon-btn"><MdAddCircle /></Button>
                      </span>
                  </Col>
              </Row>
              <hr></hr>
              <div className="institution-view-table">
                <table>
                  <tr>
                    <td>
                      <p>Student number</p>
                      <input type="text" />
                    </td>
                    <td>
                      <p>First name</p>
                      <input type="text" />
                    </td>
                    <td>
                      <p>Last name</p>
                      <input type="text" />
                    </td>
                    <td>
                      <p>Institution</p>
                      <input type="text" />
                    </td>
                    <td>
                      <p>Campus</p>
                      <input type="text" />
                    </td>
                    <td>
                      <p>First choice</p>
                      <input type="text" />
                    </td>
                    <td>
                      <p>Second choice</p>
                      <input type="text" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Student number</p>
                      <input type="text" />
                    </td>
                    <td>
                      <p>First name</p>
                      <input type="text" />
                    </td>
                    <td>
                      <p>Last name</p>
                      <input type="text" />
                    </td>
                    <td>
                      <p>Institution</p>
                      <input type="text" />
                    </td>
                    <td>
                      <p>Campus</p>
                      <input type="text" />
                    </td>
                    <td>
                      <p>First choice</p>
                      <input type="text" />
                    </td>
                    <td>
                      <p>Second choice</p>
                      <input type="text" />
                    </td>
                  </tr>
                </table>
              </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default InstitutionView