import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

function PersonIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M33.3334 35C33.3334 32.6741 33.3334 31.5111 33.0463 30.5648C32.4 28.4341 30.7327 26.7667 28.602 26.1204C27.6557 25.8333 26.4927 25.8333 24.1667 25.8333H15.8334C13.5075 25.8333 12.3445 25.8333 11.3982 26.1204C9.26751 26.7667 7.60015 28.4341 6.95381 30.5648C6.66675 31.5111 6.66675 32.6741 6.66675 35M27.5001 12.5C27.5001 16.6421 24.1422 20 20.0001 20C15.8579 20 12.5001 16.6421 12.5001 12.5C12.5001 8.35786 15.8579 5 20.0001 5C24.1422 5 27.5001 8.35786 27.5001 12.5Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}

export default PersonIcon;
