import React, { useEffect, useState } from "react";
import { Button as BootstrapButton, Dropdown, Modal } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { useUserApi } from "../../../_common/hooks/api/UserApiHook";
import { useAlertsSelector, useUserSelector, useNavigationSelector } from "../../../_common/hooks/selectors/userSelector";
import { ACTIONS, URLS, NAVIGATIONS } from "../../../_config";

import { FaBell, FaInfoCircle } from "react-icons/fa";
import { useCommonApi } from "../../../_common/hooks/api/common/commonApiHook";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';

import HouseIcon from '@mui/icons-material/House';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import nsfasLogo from '../../../images/nsfas-dark-logo.png';
import { Alert, Avatar, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

type Props = {
  /** Used to hide alerts for v2 components that already have their own handling for alerts */
  hideAlerts?: boolean;
}

export default function Header({hideAlerts}: Props) {

	const [open, setOpen] = React.useState(false);
	const navigation: any = useNavigationSelector()
    const navigate = useNavigate();
	const [navigations, setNavigations] = useState<any>(null)

	const toggleDrawer = (newOpen: boolean) => () => {
	  setOpen(newOpen);
	};

	useEffect(() => {
        if (navigation == undefined) {
            let storedNavigation = sessionStorage.getItem(NAVIGATIONS) || '[]';
            storedNavigation = JSON.parse(storedNavigation);
            setNavigations(storedNavigation)
        } else {
            setNavigations(navigation)
        }
    }, [navigation])
  
	const DrawerList = (
		<Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
			<Box sx ={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'1rem'}}>
				<img className="" src={nsfasLogo} alt="NSFAS" />

			</Box>
      {navigations && navigations.length > 0
                ? navigations.map((items: any, index: number) => (
                  <ListItem className="drawer-item">
                      <Link
                        className="drawer-icons"
                        aria-current="page"
                        to={items.navigate}
                      >
                        {items.title}
                      </Link>
                      </ListItem>
                  ))
                : null}
		  <List>
			{/* <ListItem className="drawer-item">
				<HouseIcon/>
				<Link className="drawer-icons" to ="/student">Home</Link>
			</ListItem>
			<ListItem className="drawer-item">
				<MapsHomeWorkIcon/>
				<Link className="drawer-icons" to="/student/search-residence">2024 Accommodations</Link>
			</ListItem>
			<ListItem className="drawer-item">
				<AttachFileIcon/>
				<Link className="drawer-icons" to="/student/my-applications/home">My Applications</Link>
			</ListItem> */}
			
		  </List>
		</Box>
	  );


	const dispatch = useDispatch()
	const userApi = useUserApi();
	const commonApi = useCommonApi();
	const alerts = useAlertsSelector();
	const user:any = useUserSelector();
	
	const [showTermsModal, setShowTermsModal] = useState(false)
	
	const [userInitials, setUserInitials] = useState<any>()
	const logout = (e?: any) => {

		e?.preventDefault();
		e?.stopPropagation();
		userApi.logout({}, (message: string, resp: any) => {
		
		}, (message: string) => {

		})
	}

	const updateProfile = () => {
    if (user.relatedObjectIdObjectTypeCode == 'Employee'){
      navigate(`${URLS.STUDENT.BASE}/edit-profile`);
    }
		if (user.relatedObjectIdObjectTypeCode == 'Supplier'){
      navigate(`${URLS.AP.BASE}/edit-profile`);
    }
	}

	const userTermsAccepted = user?.tsAndCsAccepted ?? null;

	useEffect(()=>{ 
		if(userTermsAccepted === false){
			setShowTermsModal(true);
		}
	}, [userTermsAccepted])


	const getNotifications = () => {
    if (!user.externalLogonId) {
      return;
    }
    commonApi.getNotifications(
      user.externalLogonId
    );
  };

	useEffect(() => {
		// let userDetails: any = sessionStorage.getItem('nsfas')
		// userDetails = JSON.parse(userDetails)
		// console.log(userDetails)
		if(!user){
			return;
		}
		getNotifications();
		let initials = user?.name
		if(initials.split(' ').length > 1){
			let firstInitials = initials.split(' ')[0]
			let secondInitials = initials.split(' ')[1]
			setUserInitials(firstInitials[0]+secondInitials[0])
		}
		else {
			setUserInitials(initials[0])
		}
	},[]);

	const acceptTerms = () => {
		if(!user.externalLogonId){
			toast.error("An error occurred");
			return;
		}
		 commonApi.post(	
				 {
				"entityName": "ExternalLogOn",
				"recordId": user.externalLogonId,
				"requestName": "UpsertRecordReq",
				"inputParamters":  
					{   
					  "Entity":  {
						 "TsAndCsAccepted":"true"
					  }
					}
			  }
			, (message: string, resp: any) => {

				
				userApi.acceptTerms();


        }, (message: string) => {
           toast.error(message)
        });


		 setShowTermsModal(false);
	}



	const unreadAlerts = alerts?.filter(alert => alert.read !== true);
	
	const notificationCount = unreadAlerts?.length || 0;

	const openNotification = (actionLink: string) => () => {
		dispatch({
			type: ACTIONS.USER.READ_NOTIFICATION,
			payload: actionLink,
		})
		navigate(`/${actionLink}`);
	}

	let a = sessionStorage.getItem("dashboardNavigations")

	return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-orange navbar-height-control">
        <div className="container-fluid">
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <a className="navbar-brand" href="/student">
              <img
                className="header-logo"
                src="/assets/img/logo-white.png"
                alt="NSFAS"
              />
            </a>
            <i
              className="fa fa-bars burger-icon"
              aria-hidden="true"
              onClick={toggleDrawer(true)}
            ></i>

            <ul
              className="navigation-items-menu"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <li
                className="header-items-container"
                style={{
                  color: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "2rem",
                }}
              >
                <Link
                  className="nav-link active header-item"
                  aria-current="page"
                  to={URLS.HOME}
                >
                  Home
                </Link>
              </li>

              {navigations && navigations.length > 0
                ? navigations.map((items: any, index: number) => (
                    <li
                      key={items.title}
                      className="header-items-container"
                      style={{
                        color: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "2rem",
                      }}
                    >
                      <Link
                        className="nav-link active header-item"
                        aria-current="page"
                        to={items.navigate}
                      >
                        {items.title}
                      </Link>
                    </li>
                  ))
                : null}
            </ul>
            <ul className="navbar-nav me-auto ms-auto mb-2 mb-lg-0">
              <li
                className="nav-item"
                style={{
                  color: "#fff",
                  display: "flex",
                  justifyContent: "start",
                }}
              ></li>
            </ul>
            <div className="d-flex align-items-center">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className="mx-2">
                  &nbsp;
                  <FaBell /> ({notificationCount})&nbsp;&nbsp;
                </Dropdown.Toggle>
                {unreadAlerts && (
                  <Dropdown.Menu>
                    {unreadAlerts.map((alert) => {
                      return (
                        <Dropdown.Item
                          key={alert.actionLink}
                          onClick={openNotification(alert.actionLink)}
                        >
                          <span className="text-muted">
                            <FaInfoCircle />
                          </span>{" "}
                          &nbsp; {alert.message}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                )}
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle>
                  <Avatar alt={userInitials}>{userInitials}</Avatar>
                </Dropdown.Toggle>
                {/* <Avatar alt={userInitials}>{userInitials}</Avatar> */}

                <Dropdown.Menu>
                  <Dropdown.Item onClick={updateProfile}>
                    Edit Profile
                  </Dropdown.Item>
                  <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </nav>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
      <Modal
        show={showTermsModal}
        onHide={() => logout()}
        backdrop="static"
        keyboard={false}
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <TermsAndConditions /> */}
          {/* <iframe src="https://ncert.nic.in/textbook/pdf/keip108.pdf"/> */}
          <iframe
            src="https://assets.ezra360.com/images/images/Terms and Conditions.pdf"
            width={"100%"}
            height={"100%"}
          />
        </Modal.Body>
        <Modal.Footer>
          <BootstrapButton variant="danger" onClick={(e) => logout(e)}>
            Cancel
          </BootstrapButton>
          {/* add link */}
          <BootstrapButton
            variant="primary"
            onClick={() => {
              acceptTerms();
            }}
          >
            Accept
          </BootstrapButton>
        </Modal.Footer>
      </Modal>

      {hideAlerts? <Box sx={{height: 72}} /> : (
        <Box pt={3}>
          {unreadAlerts && unreadAlerts.length > 0 && (
            <Alert
              severity="error"
              icon={<InfoOutlined />}
              action={
                <Button
                  size="small"
                  variant="text"
                  color="inherit"
                  sx={{ paddingTop: "0 !important" }}
                  onClick={openNotification(unreadAlerts[0].actionLink)}
                >
                  Update Now
                </Button>
              }
            >
              <Typography>{unreadAlerts[0].message}</Typography>
            </Alert>
          )}
        </Box>
      )}
    </>
  );
}