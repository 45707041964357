import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { URLS } from '../../_config';
import Dashboard from './dashboard';
import SearchResidence from './searchResidence';
import AccomodationDetails from './accomodationDetails';
import MyApplications from './myApplications';
import MyResidences from './myResidences';
import MyProfile from './myProfile';
import MyResidenceDetails from './myResidenceDetails';
import MyCases from './myCases';
import MyCasesDetails from './myCasesDetails';
import PageNotFound from './pageNotFound';
import MyApplicationDetails from './myApplicationDetails';

export default function StudentWeb(){

    return (
        <Routes>
            <Route path={URLS.STUDENT.DASHBOARD} element={<Dashboard />} />
            <Route path={URLS.STUDENT.SEARCH_RESIDENCE} element={<SearchResidence />} />
            <Route path={URLS.STUDENT.ACCOMODATION_DETAILS} element={<AccomodationDetails />} />
            <Route path={URLS.STUDENT.MY_APPLICATIONS} element={<MyApplications />} />
            <Route path={URLS.STUDENT.MY_APPLICATION_DETAILS} element={<MyApplicationDetails />} />
            <Route path={URLS.STUDENT.MY_RESIDENCES} element={<MyResidences />} />
            <Route path={URLS.STUDENT.MY_PROFILE} element={<MyProfile />} />
            <Route path={URLS.STUDENT.MY_RESIDENCE_DETAILS} element={<MyResidenceDetails />} />
            <Route path={URLS.STUDENT.MY_CASES} element={<MyCases />} />
            <Route path={URLS.STUDENT.MY_CASES_DETAILS} element={<MyCasesDetails />} />
            <Route path={URLS.STUDENT.NOT_FOUND} element={<PageNotFound />} />
        </Routes>
    )
}