

import React, { useEffect, useMemo, useState } from 'react';
import { Container, FormLabel, Tab, Tabs } from 'react-bootstrap';
import toast from 'react-hot-toast'; 
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Header from "./student/components/header";
import UpdateAddressForm from '../components/update/updateAddressForm';
import UpdateEntityProviderForm from '../components/update/updateEntityProviderForm';
import UpdateIndividualProviderForm from '../components/update/updateIndividualProviderForm';
import { store } from '../store';
import { useCommonApi } from '../_common/hooks/api/common/commonApiHook';
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useUserSelector } from '../_common/hooks/selectors/userSelector';
import { ACTIONS, STORAGE, URLS } from '../_config';
import queryString from 'query-string';
import UpdateBankingForm from '../components/update/updateBankingForm'; 
import UpdateDocumentsForm from '../components/update/updateDocumentsForm';

const UpdateProfile = () => {
  const user: any = useUserSelector();
  const commonApi = useCommonApi();
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useAppLoader();
  const { profileSection } = useParams();
  const [searchParams] = useSearchParams();
 
  const bankingQueryValue = (searchParams.get('banking'));
  const isVerifyBankingMode = Boolean(bankingQueryValue);

  const defaultTabKey = isVerifyBankingMode
    ? profileSection?.toLowerCase() === "documents"
      ? "documents"
      : "banking"
    : profileSection?.toLowerCase() || "details";

  const [userDetails, setUserDetails] = useState<any>(user);

 
  

  useState<any>(null);
  const [addressData, setAddressData] = useState<any>(null);
  const [apMode, setTab] = useState<864 | 865 | null>(null);
  const [activeKey, setActiveKey] = useState<
    'details' | 'addresses' | 'banking' | 'documents'
  >('details');
  const [step, setStep] = useState<number>(1);
  const [addressId, setAddressId] = useState<any>(null);

  useEffect(() => {
    setActiveKey(defaultTabKey as any);
  }, [defaultTabKey]);

  const onSubmitEntityProviderForm = (data?: any) => {
    if (data) {
      if (apMode === 865) {
        updateEntity(data);
      } else {
        updateIndividual(data);
      }
    }
  };

  const onSubmitIndividualProviderForm = (data?: any) => {
    if (data) {
      updateIndividual(data);
    }
  };

  const onSubmitAddressForm = (data?: any) => {
    if (data) {
      setAddressData(data);
      navigate(
        `${URLS.UPDATE_PROFILE}/${URLS.UPDATE_PROFILE_SECTIONS.banking}`
      );
    }
  };

  const updateIndividual = (individualProviderData: unknown) => {
    let params: any = {
      entityName: 'Supplier',
      recordId: user?.supplierId,
      requestName: 'UpsertRecordReq',
      inputParamters: {
        Entity: individualProviderData,
      },
    };
    showLoader();
    commonApi.post(
      params,
      (message: string, resp: any) => {
        hideLoader();
        toast.success(message);
        getUserDetails();
      },
      (message: string) => {
        hideLoader();
        toast.error(message);
      }
    );
  };

  const updateEntity = (entityProviderData: unknown) => {
    let params: any = {
      entityName: 'Supplier',
      recordId: user?.supplierId,
      requestName: 'UpsertRecordReq',
      inputParamters: {
        Entity: entityProviderData,
      },
    };
    showLoader();
    commonApi.post(
      params,
      (message: string, resp: any) => {
        hideLoader();
        toast.success(message);
        navigate(
          `${URLS.UPDATE_PROFILE}/${URLS.UPDATE_PROFILE_SECTIONS.addresses}`
        );
        getUserDetails();
      },
      (message: string) => {
        hideLoader();
        toast.error(message);
      }
    );
  };

  const updateAddress = () => {
    let params: any = {
      entityName: 'Address',
      requestName: 'UpsertRecordReq',
      inputParamters: { Entity: addressData },
    };

    if (addressId) {
      params.recordId = addressId;
    } else {
      params.inputParamters = {
        Entity: {
          ...addressData,
          ...{
            RelatedObjectId: user.supplierId,
            RelatedObjectIdObjectTypeCode: 'Supplier',
          },
        },
      };
    }
    showLoader();
    commonApi.post(
      params,
      (message: string, resp: any) => {
        hideLoader();
        toast.success(message);
        navigate(
          `${URLS.UPDATE_PROFILE}/${URLS.UPDATE_PROFILE_SECTIONS.banking}`
        );
      },
      (message: string) => {
        hideLoader();
        toast.error(message);
      }
    );
  };

  const getUserDetails = () => {
    showLoader();
    commonApi.retreive(
      {
        Entity: 'Supplier',
        Top: 100,
        ReturnCols: [
          'APtypeId',
          'FirstName',
          'LastName',
          'Name',
          'RegistrationName',
          'RegistrationNo',
          'VATNumber',
          'Mobile',
          // 'Telephone',
          'Email',
        ],
        FilterOn: {
          Type: 'And',
          Where: [
            {
              filterCol: 'SupplierId',
              FilterCondition: 'Equals',
              FilterValue: user.supplierId,
            },
          ],
        },
      },
      (message: string, resp: any) => {
        hideLoader();
        let userNewData: any = JSON.parse(resp)[0];
        console.log('userNewData =>', userNewData);
        let margedUserData: any = { ...user, ...userNewData };
        console.log('margedUserData =>', margedUserData);
        sessionStorage.setItem(STORAGE, JSON.stringify(margedUserData));
        store.dispatch({
          type: ACTIONS.USER.LOGIN,
          payload: {
            user: JSON.parse(margedUserData),
            token: null,
          },
        });

        setUserDetails(margedUserData);
      },
      (message: string) => {
        hideLoader();
      }
    );
  };

  useEffect(() => {
    if (user) {
      setUserDetails(user);
      setTab(user?.aPtypeId);
    }
  }, [user]);

  useEffect(() => {
    if (addressData) {
      updateAddress();
    }
  }, [addressData, addressId]);

  const onSubmitBankingDetails = (data: unknown, recordId: string) => {
    showLoader();
    if (!userDetails?.supplierId) {
      return;
    }
    const params: Record<string, string | any> = {
      entityName: 'BankAccount',
      recordId,
      requestName: 'UpsertRecordReq',
      inputParamters: {
        Entity: data,
      },
    };
    // if no record Id, a new entry will be created
    if (!recordId) {
      params.RelatedObjectId = userDetails?.supplierId;
      params.RelatedObjectIdObjectTypeCode = 'Supplier';
    }
    commonApi.post(
      params,
      (message: string, resp: any) => {
        hideLoader();
        toast.success(message);
        navigate(
          `${URLS.UPDATE_PROFILE}/${URLS.UPDATE_PROFILE_SECTIONS.documents}`
        );
      },
      (message: string) => {
        hideLoader();
        toast.error(message);
      }
    );
  };

  const onSubmitDocuments = (docs: unknown) => {
    console.log(docs);
    if (!userDetails?.supplierId) {
      return;
    }
    showLoader();
    commonApi.post(
      {
        entityName: 'Supplier',
        requestName: 'CreateDocumentExecuteRequest',
        recordId: userDetails?.supplierId,
        inputParamters: {
          Documents: docs,
        },
      },
      (message: string, resp: any) => {
        hideLoader();
        toast.success(message);
        navigate(
          `${URLS.UPDATE_PROFILE}/${URLS.UPDATE_PROFILE_SECTIONS.documents}`
        );
      },
      (message: string) => {
        hideLoader();
        toast.error(message);
      }
    );
  };

  const tabsContent = {
    864: {
      details: (
        <div className="tab-content" id="myTabContent">
          <div id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <UpdateIndividualProviderForm
              onSubmitIndividualProviderForm={onSubmitIndividualProviderForm}
              userDetails={userDetails}
            />
          </div>
        </div>
      ),
      addresses: (
        <div id="home" role="tabpanel" aria-labelledby="home-tab">
          <UpdateAddressForm
            tab={apMode}
            onSubmitAddressForm={onSubmitAddressForm}
            userDetails={userDetails}
            setAddressId={setAddressId}
          />
        </div>
      ),
      banking: (
        <div>
          <UpdateBankingForm
            tab={apMode}
            onSubmitBankingDetails={onSubmitBankingDetails}
            userDetails={userDetails}
          />
        </div>
      ),
      documents: (
        <div> 
            <UpdateDocumentsForm
              tab={apMode}
              type={user.aPtypeId}
              isBankingOnlyMode={isVerifyBankingMode}
              onSubmitDocuments={onSubmitDocuments}
              userDetails={userDetails}
            /> 
        </div>
      ),
    },
    865: {
      details: (
        <div className="tab-content" id="myTabContent">
          <div id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <UpdateEntityProviderForm
              onSubmitEntityProviderForm={onSubmitEntityProviderForm}
              userDetails={userDetails}
            />
          </div>
        </div>
      ),
      addresses: (
        <div id="home" role="tabpanel" aria-labelledby="home-tab">
          <UpdateAddressForm
            tab={apMode}
            onSubmitAddressForm={onSubmitAddressForm}
            userDetails={userDetails}
            setAddressId={setAddressId}
          />
        </div>
      ),
      banking: (
        <div>
          <UpdateBankingForm
            tab={apMode}
            onSubmitBankingDetails={onSubmitBankingDetails}
            userDetails={userDetails}
          />
        </div>
      ),
      documents: (
        <div>
          <UpdateDocumentsForm
            tab={apMode}
            isBankingOnlyMode={false}
            type={user.aPtypeId}
            onSubmitDocuments={onSubmitDocuments}
            userDetails={userDetails}
          />
        </div>
      ),
    },
  };

  return (
    <React.Fragment>
      <div className="dashboard">
        <Header />
        {userDetails ? (
          <Container style={{ maxWidth: '720px' }}>
            <div className="mob_logo">
              <a href="#" className="logo_left">
                <img src="/assets/img/logo.png" />
              </a>
            </div>
            <div className="logo_txt">Update Profile</div>
            <div>
             
                { (
                  <Tabs
                   activeKey={activeKey}
                   onSelect={(k) => navigate(`${URLS.UPDATE_PROFILE}/${k}?${queryString.stringify({banking: bankingQueryValue }, {skipEmptyString: true})}`)}
                   id="uncontrolled-tab"
                   className="mb-5"
                   justify
                 >
                {!isVerifyBankingMode && 
                
                <Tab eventKey="details" title="Details">
                  {apMode && tabsContent[apMode].details}
                </Tab>}
                {!isVerifyBankingMode && <Tab eventKey="addresses" title="Addresses">
                  {apMode && tabsContent[apMode].addresses}
                </Tab> }
                <Tab eventKey="banking" title="Banking">
                  {apMode && tabsContent[apMode].banking}
                </Tab>
                <Tab eventKey="documents" title="Documents">
                  {apMode && tabsContent[apMode].documents}
                </Tab>
                </Tabs>)}
                
             
            </div>

            <div className="register_tab">
              {/* <nav className="dual-menu">
                            <a className={"btn-menu " + (tab == 'regi' ? 'active' : '')} onClick={() => { setTab('regi'); setStep(1) }} >Registered entity</a>
                            <a className={"btn-menu " + (tab == 'indi' ? 'active' : '')} onClick={() => { setTab('indi'); setStep(1) }} >Individual</a>
                        </nav> */}
            </div>
          </Container>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default UpdateProfile;
