import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { useCommonApi } from '../_common/hooks/api/common/commonApiHook';
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';

interface TenantsRoomModalProps {
  handleClose: () => void;
  shouldShow: boolean;
  facilityId: string;
}


const TenantsRoomModal = ({ shouldShow, handleClose, facilityId }: TenantsRoomModalProps) => {

  const { handleSubmit, control, formState: { errors } } = useForm();

  const commonApi = useCommonApi()
  const { showLoader, hideLoader } = useAppLoader()

  const [facilityRooms, setFacilityRooms] = useState<any>(null)


  const getFacilityRooms = () => {
    return new Promise((resolve: any, reject: any) => {
      commonApi.getRooms({
        filters: "FacilityId eq '" + facilityId + "'",
        ViewName: 'LookUpView'
      }, (message: string, resp: any) => {
        let options: any = [];
        resp.map((ins: any) => {
          if (ins.recordId.search('000000') !== 0) {
            ins.value = ins.recordId;
            ins.label = ins.mainText;
            options.push(ins);
          }
        })
        setFacilityRooms(options)
        setTimeout(() => {
          resolve()
        }, 300)
      }, (message: string) => {
        reject()
      });
    })
  }

  const onSubmit = (data: any) => {
    if (facilityId !== undefined && facilityId !== null && facilityId !== "") {
      showLoader()
      commonApi.post({
        "entityName": "Tenant",
        "requestName": "UpsertRecordReq",
        "recordId": facilityId,
        "inputParamters":
        {
          "Entity": {
            "RoomId": data.room.recordId
          }
        }
      }, (message: string, resp: any) => {
        toast.success(message)
        handleClose()
        hideLoader()
      }, (message: string) => {
        toast.error(message);
        handleClose()
        hideLoader()
      });
    }
    else {
    }
  }

  useEffect(() => {
    getFacilityRooms();
  }, [])

  return (
    <>
      <Modal show={shouldShow} onHide={handleClose}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Please note!</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="tenant-modal">
            <h3>Assign room to student</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                {/* <label>Description</label> */}
                {/* <input placeholder='Placeholder' type={'text'} className='form-control' /> */}
                <Controller
                  name={"room"}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, onBlur } }) => {
                    return (<Select
                      className={"form-control p-0" + (errors?.room ? ' is-invalid' : '')}
                      options={facilityRooms}
                      placeholder={""}
                      onChange={onChange}
                      value={value}
                      defaultValue={value}
                    />)
                  }}
                />
              </div>
              <button type='submit' className='full-width-yellow-btn'>Next</button>
            </form>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  )
}

export default TenantsRoomModal