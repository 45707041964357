import { ReactNode, useEffect, useMemo, useState } from "react";
import DashboardPageLayout from "../../../components/DashboardPageLayout";
import { BreadcrumbData } from "../../../components/Breadcrumbs/types";
import { URLS } from "../../../../../_config";
import ContentPage from "../../../components/DashboardPageLayout/ContentPage";
import Box from "@mui/material/Box";
import {
  Avatar,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { ThemeColors } from "../../../styles/theme";
import StatusPill from "../../../components/StatusPill";
import { ApUtils } from "../util";
import OutlinedBanner from "../../../components/OutlinedBanner";
import ResponsiveGridSpacer from "../../../components/ResponsiveGridSpacer";
import { useNavigate, useParams } from "react-router-dom";
import { IApiResponse } from "../../../types/IApiResponse";
import { EXECUTE_REQUEST_PATH } from "../../../constants";
import { API } from "../../../services/API";
import { showErrorAlert, showSuccessAlert } from "../../../components/Notify";
import {
  LeaseStatus,
  StudentVerificationStatus,
  TermType,
} from "../../../types/shared";
import { UTILS } from "../../../../../utils";
import { TStatusStyleFormatter } from "../types";
import CircleLoader from "../../../components/CircleLoader";
import useCurrentUser from "../../../hooks/useCurrentUser";
import NiceModal from "@ebay/nice-modal-react";
import { NiceApproveModal } from "../../../../../components/ApproveModal";
import SignWellWindow from "../../../../../components/SignWellWindow";
import { EnumDeclaration } from "typescript";
import { useAppLoader } from "../../../../../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";

type StudentApActions =
  | "Invite"
  | "Accept Risk"
  | "Accept"
  | "Reject"
  | "View Lease"
  | "Renew";

type ResponseType = {
  StudentDetails: {
    name: string;
    email: string;
    status: StudentVerificationStatus;
    termType: TermType;
    plannedMoveInDate: string;
  };
  LeaseDetails: {
    title: string;
    value: string;
    status: LeaseStatus;
  }[];
  FundingStatus: {
    title: string;
    status: string;
    value: string;
  }[];
  Notice: {
    noticeType: "Warning" | "Success" | "Error";
    consentMessage: string;
    message: string;
    consentRequired: boolean;
  }[];
  Actions: StudentApActions[];
};

const supportedCollectionEntryPoints = [
  "students",
  "accomodation-applications",
  "tenants",
];

const getEntityName = (collection?: string) => {
  switch (collection?.toLowerCase()) {
    case "accomodation-applications":
      return "AccomodationApplications";
    case "tenants":
      return "Tenant";
    default:
      return "Employee";
  }
};

const BREADCRUMB_DATA: BreadcrumbData = [
  {
    label: "Dashboard",
    path: URLS.DASHBOARD,
  },
  {
    label: "Residences Applications",
    path: URLS.RESIDENCES,
  },
  {
    label: "Student Applications",
    path: URLS.AP.ACCOMODATION_APPLICATIONS,
  },
];

type TDetail = {
  title: string;
  value: string;
  status: string;
};

const DetailsRow = ({
  title,
  value,
  status,
  statusStyleFormatter,
}: TDetail & {
  statusStyleFormatter: TStatusStyleFormatter;
}) => {
  const { variant, label: statusLabel } = statusStyleFormatter(status);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderBottom: `1px solid ${ThemeColors.borderPrimary}`,
        py: 2,
      }}
    >
      <Box>
        <Typography sx={{ color: ThemeColors.textTertiary }}>
          {title}
        </Typography>
        <Typography sx={{ color: ThemeColors.textPrimary }}>{value}</Typography>
      </Box>
      <Box>
        <StatusPill variant={variant}>{statusLabel}</StatusPill>
      </Box>
    </Box>
  );
};

const DetailsBox = ({
  title,
  details,
  statusStyleFormatter,
  type,
}: {
  title: string;
  details: TDetail[];
  statusStyleFormatter: TStatusStyleFormatter;
  type: "lease" | "funding";
}) => {
  return (
    <Box
      sx={{
        background: ThemeColors.bgPrimary,
        borderRadius: 3,
        border: `1px solid ${ThemeColors.borderPrimary}`,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography sx={{ fontWeight: 600, fontSize: 24 }}>{title}</Typography>
      </Box>
      {details.length > 0 ? (
        <Box
          sx={{
            p: 2,
            background: ThemeColors.white,
            minHeight: 48,
            borderBottomLeftRadius: 3,
            borderBottomRightRadius: 3,
          }}
        >
          {details.map((detail) => (
            <DetailsRow
              key={detail.title}
              {...detail}
              statusStyleFormatter={statusStyleFormatter}
            />
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            p: 2,
            background: ThemeColors.white,
            minHeight: 48,
            borderBottomLeftRadius: 3,
            borderBottomRightRadius: 3,
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              {type === "lease" && "No lease history found"}
              {type === "funding" && "No funding status found"}
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                pt: 1,
                textAlign: "center",
                px: 10,
              }}
            >
              {type === "lease" &&
                "This student does not have any lease history"}
              {type === "funding" &&
                "This student does not have any funding history"}
            </Typography>
          </Box>
        </Box>
      )}
      {/* <Box
        sx={{
          p: 2,
          background: ThemeColors.white,
          minHeight: 48,
          borderBottomLeftRadius: 3,
          borderBottomRightRadius: 3,
        }}
      >
        {details.map((detail) => (
          <DetailsRow
            key={detail.title}
            {...detail}
            statusStyleFormatter={statusStyleFormatter}
          />
        ))}
      </Box> */}
    </Box>
  );
};

const SectionBox = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => (
  <Box
    sx={{
      borderRadius: 3,
      padding: 2,
      minHeight: 80,
      background: ThemeColors.bgPrimary,
    }}
  >
    <Typography sx={{ fontSize: 24, fontWeight: 600, my: 1 }}>
      {title}
    </Typography>
    {children}
  </Box>
);

const ActionsBox = ({
  actions,
  entityId,
  plannedMoveInDate,
}: {
  plannedMoveInDate: string;
  entityId: string;
  actions: StudentApActions[];
}) => {
  const currentUser = useCurrentUser();

  const [{ isWorking, actionIndex }, setWorkingStatus] = useState<{
    isWorking: boolean;
    actionIndex: number;
  }>({
    isWorking: false,
    actionIndex: -1,
  });

  const { showLoader, hideLoader } = useAppLoader();

  //format date to yyy-mm-dd
  const [day, month, year] = plannedMoveInDate
    ? plannedMoveInDate.split("/")
    : ["", "", ""];

  const formattedDate = `${year}/${month}/${day}`;

  const InviteHandler = async (index: number) => {
    setWorkingStatus({
      isWorking: true,
      actionIndex: index,
    });
    try {
      if (!currentUser) {
        return;
      }
      const response: IApiResponse<ResponseType> = await API.post(
        EXECUTE_REQUEST_PATH,
        {
          entityName: "Employee",
          requestName: "InviteStudent",
          recordId: entityId,
          inputParamters: {
            accomodationProviderId: currentUser.supplierId,
          },
        }
      );
      if (!response.isSuccess) {
        showErrorAlert(response.clientMessage);
        return;
      }
      showSuccessAlert("Student invited successfully");
    } catch (e) {
      showErrorAlert("An error occured");
    } finally {
      setWorkingStatus({
        isWorking: false,
        actionIndex: -1,
      });
    }
  };

  const ViewLease = async (index: number) => {
    try {
      setWorkingStatus({
        isWorking: true,
        actionIndex: index,
      });
      const response: IApiResponse<{ url: string }> = await API.post(
        EXECUTE_REQUEST_PATH,
        {
          entityName: "AccomodationApplications",
          requestName: "RetrieveSignwellReq",
          recordId: entityId,
          inputParamters: {
            UserType: "AP",
          },
        }
      );
      if (!response.isSuccess) {
        showErrorAlert(response.clientMessage);
        return;
      }
      window.open(response.outputParameters.url);
    } catch (e) {
      showErrorAlert("An error occured");
    } finally {
      setWorkingStatus({
        isWorking: false,
        actionIndex: -1,
      });
    }
  };

  const ApproveHandler = async (index: number) => {
    console.log("entityId", entityId);

    NiceModal.show(NiceApproveModal, {
      applicationId: entityId,
      plannedMoveInDate: new Date(formattedDate),
      onApprove: (signWellUrl: string) => {
        console.log("signWellUrl", signWellUrl);
        if (signWellUrl) {
          setSignWellState({ show: true, url: signWellUrl });
        } else {
          setSignWellState({ show: false, url: signWellUrl });
        }
      },
      onClose: () => {
        console.log("Closed");
      },
    });
  };

  const RejectHandler = async (index: number) => {
    try {
      showLoader();
      const response: IApiResponse<ResponseType> = await API.post(
        EXECUTE_REQUEST_PATH,
        {
          entityName: "AccomodationApplications",
          requestName: "UpsertRecordReq",
          recordId: entityId,
          inputParamters: {
            Entity: {
              StatusId: 70,
            },
          },
        }
      );
      if (!response.isSuccess) {
        showErrorAlert(
          response.clientMessage || "Failed to reject application"
        );
      }

      toast.success("Successfully rejected.", {
        duration: 3000,
      });
      window.location.reload();
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
    } finally {
      hideLoader();
    }
  };

  const getActionHandler = (action: StudentApActions, index: number) => () => {
    switch (action) {
      case "Invite":
        InviteHandler(index);
        break;
      case "Accept Risk":
        ApproveHandler(index);
        break;
      case "Accept":
        ApproveHandler(index);
        break;
      case "Reject":
        RejectHandler(index);
        break;
      case "View Lease":
        ViewLease(index);
        break;
      case "Renew":
        break;
    }
  };

  const [{ show: showSignWellWindow, url: signWellUrl }, setSignWellState] =
    useState({
      show: false,
      url: "",
    });

  return (
    <>
      <SectionBox title="Actions">
        <Divider sx={{ my: 2 }} />
        <Grid container>
          {actions.map((action, i) => {
            return (
              <Grid item sx={{ p: 1 }} key={"action"}>
                <Button
                  type="button"
                  disabled={isWorking}
                  endIcon={
                    isWorking && actionIndex === i ? <CircleLoader /> : null
                  }
                  onClick={getActionHandler(action, i)}
                  fullWidth
                  variant={i === 0 ? "contained" : "outlined"}
                  color="primary"
                  sx={{
                    background: i !== 0 ? ThemeColors.white : undefined,
                    minWidth: 200,
                  }}
                >
                  {action}
                </Button>
              </Grid>
            );
          })}
        </Grid>
      </SectionBox>
      <SignWellWindow
        key={signWellUrl}
        show={showSignWellWindow}
        url={signWellUrl}
        onClose={() => {
          setSignWellState({ show: false, url: "" });
        }}
      />
    </>
  );
};

const NoticeBox = ({
  notices,
}: {
  notices: {
    noticeType: "Warning" | "Success" | "Error";
    consentMessage: string;
    message: string;
    description?: string;
    consentRequired: boolean;
  }[];
}) => {
  const { displayNotices, requiresConsent } = useMemo(() => {
    const displayNotices = notices.map((notice, index) => ({
      ...notice,
      id: index,
    }));
    const requiresConsent = displayNotices.some(
      (notice) => notice.consentRequired
    );
    return {
      displayNotices,
      requiresConsent,
    };
  }, [notices]);

  return (
    <SectionBox title="Notice">
      {displayNotices.map((notice) => {
        return (
          <Box key={notice.id} sx={{ my: 1 }}>
            <OutlinedBanner
              sx={{ background: ThemeColors.white, py: 2, px: 3 }}
              message={
                <Typography>
                  <strong>{notice.message}</strong> {notice.description}
                </Typography>
              }
            />
          </Box>
        );
      })}
      {requiresConsent && (
        <Box>
          <FormControlLabel
            control={<Checkbox />}
            label={notices[0].consentMessage}
          />
        </Box>
      )}
    </SectionBox>
  );
};

function ViewStudentProfilePage() {
  const breadcrumbData: BreadcrumbData = useMemo(() => {
    return [...BREADCRUMB_DATA]; // TODO add current student name
  }, []);

  const [{ details, isLoading }, setPageState] = useState<{
    isLoading: boolean;
    details?: ResponseType;
  }>({
    isLoading: true,
  });

  const params = useParams();

  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (
      params.collection &&
      supportedCollectionEntryPoints.includes(
        params.collection.toLowerCase()
      ) &&
      currentUser
    ) {
      const loadStudentDetails = async (id: string) => {
        const response: IApiResponse<ResponseType> = await API.post(
          EXECUTE_REQUEST_PATH,
          {
            entityName: getEntityName(params.collection),
            requestName: "StudentDetailsReq",
            recordId: id,
            inputParamters: {
              accomodationProviderId: currentUser.supplierId,
            },
          }
        );
        if (!response.isSuccess) {
          showErrorAlert(response.clientMessage);
          navigate(URLS.AP.STUDENT_SEARCH);
        }
        setPageState({
          isLoading: false,
          details: response.outputParameters,
        });
      };
      if (params.id) {
        loadStudentDetails(params.id)
          .catch((e) => {
            showErrorAlert("An error occured");
            navigate(URLS.AP.STUDENT_SEARCH);
          })
          .finally(() => {
            setPageState((current) => ({
              ...current,
              isLoading: false,
            }));
          });
      }
    } else {
      navigate(URLS.DASHBOARD);
    }
  }, [params]);

  return (
    <DashboardPageLayout showLoader={isLoading}>
      {details && (
        <ContentPage title="" padding="lg" breadcrumbData={breadcrumbData}>
          <Box
            display="flex"
            justifyContent="start"
            alignItems="centers"
            columnGap={2}
          >
            <Avatar alt="ZM" sx={{ height: 64, width: 64 }}>
              {UTILS.getInitials(details.StudentDetails.name)}
            </Avatar>
            <Box>
              <Typography
                sx={{
                  color: ThemeColors.textPrimary,
                  fontSize: 24,
                  fontWeight: 600,
                }}
              >
                {details.StudentDetails.name}
              </Typography>
              <Typography sx={{ color: ThemeColors.textTertiary }}>
                {details.StudentDetails.email}
                {details.StudentDetails.termType ? (
                  <>
                    {" "}
                    &bull;
                    {UTILS.getTermTypeLabel(
                      details.StudentDetails.termType.toString()
                    )}{" "}
                    student{" "}
                  </>
                ) : null}
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Grid container rowGap={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  borderRadius: 3,
                  padding: 2,
                  minHeight: 80,
                  background: ThemeColors.bgPrimary,
                }}
              >
                <Grid container sx={{ rowGap: 2 }}>
                  <Grid item xs={12} md={6} sx={{ pr: 1 }}>
                    {details.LeaseDetails ? (
                      <DetailsBox
                        title="Lease History"
                        details={details.LeaseDetails}
                        statusStyleFormatter={ApUtils.statusFormatters.lease}
                        type="lease"
                      />
                    ) : (
                      <Typography>No details found</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ pr: 1 }}>
                    <DetailsBox
                      title="Funding Status"
                      details={details.FundingStatus}
                      statusStyleFormatter={ApUtils.statusFormatters.nsfas}
                      type="funding"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ pr: 1 }}>
              <NoticeBox notices={details.Notice} />
            </Grid>
            {details.Actions.length > 0 && (
              <Grid item xs={12} sx={{ pr: 1 }}>
                {params.id && (
                  <ActionsBox
                    actions={details.Actions}
                    entityId={params.id}
                    plannedMoveInDate={details.StudentDetails.plannedMoveInDate}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </ContentPage>
      )}
    </DashboardPageLayout>
  );
}

export default ViewStudentProfilePage;
