import { ArrowBack, Close, RadioButtonUnchecked } from "@mui/icons-material";
import {
  Box,
  DialogContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import React from "react";

import Webcam from "react-webcam";
import { showErrorAlert } from "../../../../../components/Notify";

const videoConstraints = {
  width: 720,
  height: 1280,
  facingMode: "user",
};
function useSelfieDialog({
  onClose,
  fullScreen: _fullScreen,
  title,
  subtitle,
}: {
  onClose: (selfie: string | null) => void;
  fullScreen?: boolean;
  title?: string;
  subtitle?: string;
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [_open, setOpen] = React.useState(false);
  const webcamRef = React.useRef<any>(null);
  const handleClose = (e: unknown, reason?: string) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setOpen(false);
    onClose(null);
  };
  const takeSelfie = React.useCallback(() => {
    if (!webcamRef.current) {
      showErrorAlert(
        "Error getting camera. Please check and make sure that your browser has permissions to use the camera"
      );

      return;
    }
    const imageSrc = webcamRef.current.getScreenshot();
    if (!imageSrc) {
      showErrorAlert(
        "Error getting camera. Please check and make sure that your browser has permissions to use the camera"
      );
      return;
    }
    onClose(imageSrc);
    setOpen(false);
  }, [webcamRef]);

  const component = (
    <Dialog
      key={_open ? 2 : 1}
      fullScreen={_fullScreen || fullScreen}
      open={_open}
      onClose={handleClose}
      sx={{ background: "#000", color: "#fff" }}
    >
      <Box sx={{ background: "#000" }}>
        <AppBar sx={{ position: "relative" }} elevation={0} color="transparent">
          <Toolbar>
            <IconButton edge="start" onClick={handleClose} aria-label="close">
              <ArrowBack sx={{ color: "#fff" }} color="inherit" />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1, color: "#fff", textAlign: "center" }}
              variant="h6"
              component="div"
            >
              {title || "Take a selfie"}
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <DialogContent sx={{ background: "#000", color: "#fff" }}>
        <Typography sx={{ color: "#fff" }}>{subtitle}</Typography>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          mirrored={true}
          style={{ width: "100%", height: "80%" }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,
            minWidth: { xs: 0, md: 480 },
          }}
        >
          <IconButton
            sx={{ background: "#fff", p: 0, height: 48, width: 48 }}
            onClick={takeSelfie}
          >
            <RadioButtonUnchecked sx={{ color: "#222", fontSize: 46 }} />
          </IconButton>
        </Box>
      </DialogContent>
    </Dialog>
  );

  const open = () => setOpen(true);
  const close = () => setOpen(false);

  return { open, close, component };
}

export default useSelfieDialog;
