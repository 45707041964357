import React, { useEffect, useState } from 'react'
import { Carousel, Col, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import Header from '../components/header'
import { useCommonApi } from '../_common/hooks/api/common/commonApiHook'
import { useAppLoader } from '../_common/hooks/common/appLoaderHook'
import { useUserSelector } from '../_common/hooks/selectors/userSelector'
import Breadcrumbs from './student/components/breadcrumbs'

const Leases = () => {
    const commonApi = useCommonApi()
    const user: any = useUserSelector()
    const { id, type } = useParams()
    const {showLoader, hideLoader} = useAppLoader()

    const [leasesList, setLeasesList] = useState<any>(null)
    const [leasesStatus, setLeasesStatus] = useState<any>(null)

    const getLeases = () => {
        showLoader()
        commonApi.post({
            "recordId": id,
            "requestName": "RetrieveLeaseDetails",
            "EntityName": type
        }, (message: string, resp: any) => {
            // console.log({ resp })
            hideLoader()
            if (resp.outputParameters.Leases.length > 0) {
                setLeasesList(resp.outputParameters.Leases)
            }
        }, (message: string) => {
            console.log(message);
            hideLoader()
        });
    }

    const getLeasesStatus = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "ContractStatus"

                }
            }
        }, (message: string, resp: any) => {
            console.log({ resp })
            if (resp.outputParameters.Count > 0) {
                setLeasesStatus(resp.outputParameters.data)
            }
        }, (message: string) => {
            console.log(message);
        });
    }

    const getFilteredLeasesStatus = (status: any) => {
        const filteredData = leasesStatus.filter((item: any) => item.optionValue == status);
        // console.log(filteredData[0])
        return <div className="tag-status"><span className="badge" style={{ backgroundColor: filteredData[0]?.colorCode ? filteredData[0]?.colorCode : '#F3981E' }}><>{filteredData[0]?.optionText ? filteredData[0]?.optionText : "Draft"}</></span></div>
    }

    const checkImageBase64Code = (url: any) => {
        if (url.search('data:image/') == 0) {
            return url;
        } else {

            return 'data:image/jpeg;base64,' + url
        }
    }

    useEffect(() => {
        if (id && type) {
            getLeases()
        }
    }, [id, type])

    useEffect(() => {
        getLeasesStatus()
    }, [])

    return (
        <React.Fragment>
            <div className="dashboard">
                <Header />
                <section className="container dashboard-view">
                    {type == 'Facility' ?
                        < Breadcrumbs data={[
                            { label: 'Home', path: '/' },
                            { label: 'Residence Details', path: `/residence-details/${id}` },
                            { label: 'Leases' }
                        ]} />
                        :
                        <Breadcrumbs data={[
                            { label: 'Home', path: '/' },
                            { label: 'Leases' }
                        ]} />
                    }
                    <div className="open-case-main-wrapper">
                        <h2>My Lease Agreements @ {user?.name}</h2>
                        <div className="open-case-block-wrapper row">
                            {leasesList && leasesList.length > 0 && leasesStatus && leasesStatus.length > 0 ?
                                leasesList.map((item: any, i: number) => (
                                    <div className="col-sm-4" key={i}>
                                        <div className="open-case-block2">
                                            <div className="open-case-pic">
                                                {item?.images && item?.images.length > 0 ?
                                                    <Carousel>
                                                        {item?.images.map((pic: any, i: number) => (
                                                            <Carousel.Item key={i}>
                                                                <img
                                                                    src={checkImageBase64Code(pic?.fileContent)}
                                                                    alt="img"
                                                                />
                                                            </Carousel.Item>
                                                        ))}
                                                    </Carousel>
                                                    :
                                                    <img src="/assets/img/no-image-available.jpg" alt="img" />
                                                }
                                                <span className="active-status">{getFilteredLeasesStatus(item?.status)}</span>
                                            </div>
                                            <h3>SouthPoint Building 3</h3>
                                            <div className="open-case-feature-block-wrapper">
                                                <div className="open-case-feature-block">
                                                    <h5>Owner/Lessor</h5>
                                                    <h3>{item?.owner}</h3>
                                                </div>
                                                <div className="open-case-feature-block">
                                                    <h5>Tenant(s)</h5>
                                                    <h3>{item?.tenant}</h3>
                                                </div>
                                                <div className="open-case-feature-block">
                                                    <h5>Term</h5>
                                                    <h3>{item?.term}</h3>
                                                </div>
                                                <div className="open-case-feature-block">
                                                    <h5>Occ.Date</h5>
                                                    <h3>{item?.occDate}</h3>
                                                </div>
                                            </div>
                                            <button className="full-width-yellow-btn" onClick={() => window.open(item?.leaseLink, '_blank')}>{item.status == 0 ? "Draft" : leasesStatus && leasesStatus.length > 0 ?
                                                leasesStatus.map((data: any) => (
                                                    data.optionValue == item.status ? data.optionText : ""
                                                )) : null}</button>
                                        </div>
                                    </div>
                                )) :
                                <h4>No Leases</h4>
                            }
                        </div>
                    </div>
                </section>
            </div>

            <Row>
                <Col md={4} className="class-name"></Col>
            </Row>
        </React.Fragment>
    )
}

export default Leases