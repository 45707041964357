import React, { useEffect, useState } from "react";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import { Row, Col, Button } from "react-bootstrap";
import IntroSlider from "../components/introSlider";
import { useForm } from "react-hook-form";
import { useSearchParams, useNavigate, Link } from "react-router-dom"; 
import Select from "react-select";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import toast from "react-hot-toast"; 


const SIGN_IN_OPTIONS = [
  { value: "RSAID", label: "RSA ID Number" },
  { value: "EMAIL", label: "Email Address" },
  { value: "MOBILE", label: "Mobile" },
];

export default function Home() {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useAppLoader();
  const userApi = useUserApi();
  const [loginError, setLoginError] = useState<string | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type"); 
  const commonApi = useCommonApi();
  const [isExisting, setIsExisting] = useState<boolean>();
  const [regsiterMessage, setRegisterMessage] = useState<string>("");

  // store id number
  const [idNumber, setIdNumber] = useState<string | undefined>();

  const onSubmit = (data: any) => {
    let params: any = {
      entityName: "ExternalLogon",
      requestName: "LoginExecuteRequest",
      inputParamters: {
        ExternalLogon: {
          Username: data.email,
          Password: data.password,
          Channel: signInSelection.value,
        },
      },
    };

    showLoader();
    userApi.login(
      params,
      (message: string, resp: any) => {
        hideLoader();
        // Check if the login was successful

        if (resp.success) {
          // Redirect to the "test" page
          navigate("/test");
        } else {
          // Handle login error
          setLoginError("Login failed. Please check your credentials.");
        }

      }, (message: string) => {
        setLoginError(message);
        setRegisterMessage(message)
        hideLoader();

      });
  }

  const checkExistingStudent = (data: any) => {
    showLoader();
    commonApi.post({
      "entityName": "Employee",
      "requestName": "ExistingStudentCheck",
      "inputParamters":
      {
        "StudentCheck": {
          "IdNumber": data.email
        }
      }

    },

      (message: string) => {
        hideLoader();
        toast.success(message)
        setIsExisting(true);
      },
      (message: string) => {
        hideLoader();
        setIsExisting(false); 
        setRegisterMessage(message);
      }
    );
  };

  // *****************************************
  const handleFailedProceed = (data: any) => { 

    showLoader();
    commonApi.post(
      {
        entityName: "Employee",
        requestName: "ExistingStudentCheck",
        inputParamters: {
          StudentCheck: {
            IdNumber: data.email,
          },
        },
      },

      (message: string) => {
        hideLoader();
        toast.success(message);
 
        setIsExisting(true);  
      },
      (message: string) => {
        hideLoader();

        setIsExisting(false); 
        setRegisterMessage(message);
        navigate("/student-reg");
      }
    );
  };
  // *********************************

  const handleFailed = () => {
    try {
      handleSubmit(handleFailedProceed)();
    } catch {
      navigate("/student-reg");
    }
  };

  const handleProceedClick = () => {
    // Call the checkExistingStudent function here
    handleSubmit(checkExistingStudent)();
  };

  useEffect(() => {
    if (type == null) {
      navigate("/entry-type");
    }
  }, []);

  const [signInSelection, setSignInSelection] = useState(
    type === "p" ? SIGN_IN_OPTIONS[1] : SIGN_IN_OPTIONS[0]
  );
  const handleSelectChange = (signInSelection: any) => {
    setSignInSelection(signInSelection); // Set the selected option in the state
  };

  const handleKeyPress = (event: any) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/^[0-9]+$/.test(keyValue)) {
      event.preventDefault();
    }
  };

  const [errorMessage, setErrorMessage] = useState<string>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => { 
    const inputValue = event.target.value;
    setIdNumber(inputValue);
    if (inputValue.length < 13) {
      setErrorMessage("Input must be at least 13 characters long.");
    } else {
      setErrorMessage("");
    }
  };

  const userLoginDetails = {
    type: signInSelection.value,
    value: watch("email")
  };


  return (
    <React.Fragment>
      <div className="register_page login_page">
        <div className="register_left">
          <div className="mob_logo">
            <a href="#" className="logo_left">
              <img src="/assets/img/logo.png" />
            </a>
          </div>
          <div className="logo_txt">Sign in to your session</div>
          <form className="register_tab" onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="exampleFormControlInput1" className="">
              Sign In Using:
            </label>
            <Select
              className={"form-control p-0"}
              options={SIGN_IN_OPTIONS}
              onChange={handleSelectChange}
              defaultValue={type === "p" ? SIGN_IN_OPTIONS[1] : SIGN_IN_OPTIONS[0]}
              isDisabled={true}
/>
            {
              <div className="mb-2 mt-2">
                <label htmlFor="exampleFormControlInput1" className="">
                  {signInSelection.label}
                </label>

                {type === "s" ? (
                  <div>

                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1"
                      maxLength={13}
                      minLength={13}
                      onKeyPress={handleKeyPress}
                      placeholder={signInSelection.label}
                      {...register("email", { required: true, minLength: 13 })}
                      onChange={handleChange} // Add this line to bind the handleChange function
                    />

                    <p>{errorMessage}</p>
                  </div>
                ) : (
                  type === "p" && (
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder={signInSelection.label}
                      {...register("email", { required: true })}
                    />
                  )
                )}
              </div>
            }
            {isExisting === false ? <h6 style={{ color: 'green', fontSize: '12px', width: '90%' }}>{regsiterMessage}</h6> : null}

            {isExisting === true && type === 's' ?
              <div className="mb-2">
                <label htmlFor="exampleFormControlInput1" className={"form-label" + (loginError || errors?.password ? ' is-invalid' : '')}>Password</label>
                <input type="password" className="form-control" id="exampleFormControlInput1" placeholder="Password" {...register("password", { required: true, maxLength: 80 })} />
                {errors?.password ? <div className="invalid-feedback">Required</div> : null}
                {loginError ? <div className="invalid-feedback">{loginError}</div> : null}
              </div>
              : type === 'p' ?
                <div className="mb-2">
                  <label htmlFor="exampleFormControlInput1" className={"form-label" + (loginError || errors?.password ? ' is-invalid' : '')}>Password</label>
                  <input type="password" className="form-control" id="exampleFormControlInput1" placeholder="Password" {...register("password", { required: true, maxLength: 80 })} />
                  {errors?.password ? <div className="invalid-feedback">Required</div> : null}
                  {loginError ? <div className="invalid-feedback">{loginError}</div> : null}
                </div> : null
            }

            {type === 's' && isExisting ?
              <Row className="mb-2">
                <Col md={5}>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckCheckedDisabled" {...register("remember", {})} />
                    <label className="form-check-label" htmlFor="flexCheckCheckedDisabled">
                      Remember me
                    </label>
                  </div>
                </Col>
                { }
                <Col md={7} className="text-end small">
                  <Link to="/forgot-password?type=s" state={{ userLoginDetails }}>Forgot Password?</Link>
                </Col>
              </Row>
              :
              type === 'p' &&
              <Row className="mb-2">
                <Col md={5}>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckCheckedDisabled" {...register("remember", {})} />
                    <label className="form-check-label" htmlFor="flexCheckCheckedDisabled">
                      Remember me
                    </label>
                  </div>
                </Col>
                { }
                <Col md={7} className="text-end small">
                  <a href="/forgot-password?type=p">Forgot Password?</a>

                </Col>
              </Row>
            }
            {type === "s" ? (
              isExisting === false ? (
                <div className="">
                  <div className="">
                    <Button
                      variant="primary"
                      className="w-50 mt-2"
                      type="button"
                      onClick={() => {
                        handleFailed();
                      }}
                    >
                      Proceed
                    </Button>
                  </div>
                </div>
              ) : isExisting === true ? (
                <div className="">
                  <Button variant="primary" className="w-50 mt-2" type="submit">
                    Login
                  </Button>
                </div>
              ) : (
                <div className="">
                  <Button
                    variant="primary"
                    className="w-50 mt-2"
                    type="button"
                    onClick={() => handleProceedClick()}
                  >
                    Proceed
                  </Button>
                </div>
              )
            ) : (
              isExisting &&
              isExisting === true && (
                <div className="">
                  <Button variant="primary" className="w-50 mt-2" type="submit">
                    Login
                  </Button>
                </div>
              )
            )}

            {type === "p" && (
              <div className="">
                <Button variant="primary" className="w-50 mt-2" type="submit">
                  Login
                </Button>
              </div>
            )}
          </form> 
        </div>
        <IntroSlider />
      </div>
    </React.Fragment>
  );
}
