import {
  Avatar,
  Box,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import LegacyPageLayout from "../../../../components/LegacyPageLayout";
import PageHeadingText from "../../components/PageHeadingText";
import { EXECUTE_REQUEST_PATH } from "../../constants";
import { IApiResponse } from "../../types/IApiResponse";
import { DEFAULT_PAGE_SIZE } from "../../../../constants";
import useCurrentUser from "../../hooks/useCurrentUser";
import { API } from "../../services/API";
import { useAppLoader } from "../../../../_common/hooks/common/appLoaderHook";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { TTenantListingResponse } from "../AP/TenantManagement/types";

function StudentResidenceListingPage() {
  const currentUser = useCurrentUser();
  const { showLoader, hideLoader } = useAppLoader();
  const [details, setDetails] = useState<TTenantListingResponse>();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPage = searchParams.get("page")
    ? parseInt(searchParams.get("page") as string)
    : 1;
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchedText, setSearchedText] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(
    initialPage > 0 ? initialPage : 1
  );

  const getApiData = async (_currentPage: number) => {
    if (!currentUser) {
      return;
    }
    try {
      showLoader();
      const response: IApiResponse<TTenantListingResponse> = await API.post(
        EXECUTE_REQUEST_PATH,
        {
          entityName: "Employee",
          requestName: "StudentListingReg",
          inputParamters: {
            UserId: currentUser.supplierId
              ? currentUser.supplierId
              : currentUser.recordId,
            SearchText: searchedText,
            PageNumber: currentPage,
            PageSize: DEFAULT_PAGE_SIZE,
          },
        }
      );
      setDetails(response.outputParameters);
    } catch (e) {
      console.error(e);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getApiData(currentPage);
  }, [currentPage, searchedText]);

  const handleAction = (isAccepted: boolean, recordId: string) => async () => {
    try {
      showLoader();
      await API.post(EXECUTE_REQUEST_PATH, {
        entityName: "PreApprovedApplication",
        requestName: "UpsertRecordReq",
        recordId,
        inputParamters: {
          Entity: {
            status: isAccepted ? 69 : 70,
          },
        },
      });
      getApiData(currentPage);
    } catch (e) {
      console.error(e);
    } finally {
      hideLoader();
    }
  };

  return (
    <LegacyPageLayout paddingTop={3}>
      <Box sx={{ px: { xs: 2, md: 4, lg: 8, xl: 16 } }}>
        <PageHeadingText text="My Residences" />

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead
                sx={{
                  backgroundColor: "#F9FAFB",
                }}
              >
                <TableRow>
                  <TableCell>Property</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {details?.Listing.map((entry) => (
                  <TableRow
                    key={entry.facilityIdName}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <ApartmentIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={entry.facilityIdName}
                          secondary={entry.leaseStatus}
                        />
                      </ListItem>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={handleAction(
                          true,
                          entry.preApprovedApplicationId
                        )}
                        variant="outlined"
                        disabled={entry.leaseStatus !== "Pending Renewal"}
                      >
                        Approve
                      </Button>
                      &nbsp;
                      <Button
                        disabled={entry.leaseStatus !== "Pending Renewal"}
                        onClick={handleAction(
                          false,
                          entry.preApprovedApplicationId
                        )}
                        variant="outlined"
                        color="secondary"
                      >
                        Reject
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </LegacyPageLayout>
  );
}

export default StudentResidenceListingPage;
