import { useState, useEffect, useRef } from "react";
import Header from "./student/components/header";
import { Box, Typography, Card, Paper, Button } from "@mui/material";
import onlinePaymentIcon from "../images/online-payment.png";
import eftPaymentIcon from "../images/eft-payment.png";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useResidenceApi } from "../_common/hooks/api/residenceApiHook";
import { APP_URL, URLS } from "../_config";
import { Modal } from "react-bootstrap";
import EftPaymentModalContent from "../components/eftPaymentModalContent";
import { useForm, Controller } from "react-hook-form";
import Select, { components } from "react-select";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { FaTimes } from "react-icons/fa";
import { MdAddCircle } from "react-icons/md";
import nsfasLogo from "../images/nsfas-logo.png";
import moment from "moment";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
import { grey } from "@mui/material/colors";

interface SelectOption {
  value: number;
  label: string;
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const PaymentOptions = () => {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useAppLoader();
  const commonApi = useCommonApi();
  const user: any = useUserSelector();

  const [payFastData, setPayFastData] = useState<any>(null);
  const payFastForm = useRef<any>();
  const residenceApi = useResidenceApi();
  const residenceID = sessionStorage.getItem("residenceID");
  const [amount, setAmount] = useState<any>(0);
  const [InvoiceId, setInvoiceId] = useState();
  const regrade: any = sessionStorage.getItem("regrade");
  const [show, setShow] = useState<boolean>(false);

  // const { register, handleSubmit, control, formState: { errors, isValid }, getValues, watch, reset } = useForm({ mode: "onChange" });

  const [selectedDocumentType, setSelectedDocumentType] =
    useState<SelectOption>();
  const [docType, setDocType] = useState<any>(null);
  const [prevFiles, setPrevFiles] = useState<any>([]);
  const [onClickContinue, setOnClickContinue] = useState<Function>();
  const [showModal, setShowModal] = useState(false);

  // const documents = watch('documents');
  const [base64, setBase64] = useState<string>("");
  // const [files, setFiles] = useState<any>([]);

  const handleFileChange = (event: any) => {
    setFile(event.target.files[0]);
  };

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm();

  const [file, setFile] = useState();

  const requiredDocumentCount = 1;

  const [files, setFiles] = useState<any>([]);
  const REQUIRED_DOCS = [886];
  const getAttachment = (code: number) =>
    files.find((file: Record<string, unknown>) => file.DocumentTypeId === code);
  const [documentOptions, setDocumentOptions] = useState<SelectOption[]>([]);

  const hasEveryRequiredDocument = REQUIRED_DOCS.every((documentCode) =>
    getAttachment(documentCode)
  );
  // const {modalComponent, setOnClickContinue, closeModal, showModal, setSelectedDocumentType } = useDocumentTypeModal({ getAttachment})

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const documents: any = watch("Documents");

  const getDocTypes = () => {
    commonApi.post(
      {
        entityName: "Picklist",
        requestName: "RetrieveSelectOptionExecuteRequest",
        inputParamters: {
          SelectOption: {
            OptionText: "DocTypes",
          },
        },
      },
      (message: string, resp: any) => {
        const options = resp.outputParameters.data.map((doc: any) => {
          return {
            value: doc.optionValue,
            label: doc.optionText,
          };
          return console.log(doc.optionText); // Returns array object with doc 'options'
        });
        setDocumentOptions(
          options.filter((option: SelectOption) =>
            REQUIRED_DOCS.includes(option.value)
          )
        );

        // const requiredDocumentOptions = options.filter((option: SelectOption) =>
        //     REQUIRED_DOCS.slice(0, requiredDocumentCount).includes(option.value)
        // );

        const requiredDocumentOptions = options.filter((option: SelectOption) =>
          REQUIRED_DOCS.slice(0, requiredDocumentCount).includes(option.value)
        );

        setDocumentOptions(requiredDocumentOptions);
      },
      (message: string) => {}
    );
  };

  useEffect(() => {
    let _files: any = [...files];
    if (documents?.length) {
      for (let i = 0; i < documents?.length; i++) {
        let file: any = documents[i];
        checkIsSelected(file.name)
          .then(() => {
            if (file.size <= 2 * 1024 * 1024) {
              const fileType = file.type;
              if (
                fileType === "application/pdf" ||
                fileType === "application/msword" ||
                fileType ===
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ) {
                // setOnClickContinue(() => (docTypeCode: number) => {
                // if (!docTypeCode) {
                //   toast.error("Select document type");
                //   return;
                // }
                toBase64(file).then((b64: any) => {
                  _files.push({
                    FileName: file.name,
                    FileExtention: file.name.split(".").pop(),
                    // "DocumentTypeId": docTypeCode,
                    FileContent: b64.split(",")[1],
                  });
                  setFiles(_files);
                  // setOnClickContinue(undefined);
                  // closeModal();
                  // setSelectedDocumentType(undefined);
                });
                // });
                // showModal();
              } else {
                toast.error(
                  "Invalid file type. Please upload a valid PDF or Word document."
                );
              }
            } else {
              toast.error("File size should be less than 2MB");
            }
          })
          .catch(() => {});
      }
    } else {
      setFiles(_files);
    }
  }, [documents]);

  const checkIsSelected = (name: string) => {
    return new Promise((resolve: any, reject: any) => {
      files.map((file: any) => {
        if (file.FileName == name) {
          reject();
        }
      });
      resolve();
    });
  };

  const deleteLocal = (file: any) => {
    let _files: any = [];
    files.map((_file: any) => {
      if (_file.FileName != file.FileName) {
        _files.push(_file);
      }
    });

    setFiles(_files);
  };

  // ************************************************

  // useEffect(()=>{
  //   getDocTypes();
  // },[])

  const onSubmit = () => {
    showLoader();
    commonApi.post(
      {
        entityName: "PaymentReceipt",
        requestName: "UpsertRecordReq",

        inputParamters: {
          Entity: {
            InvoiceId: InvoiceId,
            PaymentMode: 633,
            ReceiptStatusId: 666,
            TransactionDate: moment().format(),
          },
          Documents: files,
        },
      },
      (message: string, resp: any) => {
        hideLoader();

        setTimeout(() => {
          navigate("/");
        }, 3000);
        toast.success(message);
        // '9a5e08f9-3f45-417e-a5ee-3010448b5961'
        // console.log({ resp })
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  // ************************************************

  useEffect(() => {
    getDocTypes();
  }, []);

  const getBase64 = () => {
    commonApi.post(
      {
        entityName: "Facility",
        recordId: residenceID,
        requestName: "MakePaymentReq",
      },
      (message: string, response: any) => {
        setInvoiceId(response.results.InvoiceId);

        commonApi.post(
          {
            entityName: "Invoice",
            recordId: response.results.InvoiceId,
            requestName: "DownloadRecordExecuteRequest",
          },

          (message: string, resp: any) => {
            hideLoader();

            setBase64(resp.results);
          },
          (message: string) => {
            hideLoader();
            toast.error(message);
          }
        );
      },
      (message: string) => {
        hideLoader();
        toast.error(message);
      }
    );
  };

  const payOnline = (recordId: any) => {
    showLoader();
    commonApi.post(
      {
        entityName: "Facility",
        recordId: recordId,
        requestName: "MakePaymentReq",
      },
      (message: string, resp: any) => {
        setPayFastData(resp.outputParameters);

        // callPayFastApi(resp.outputParameters)
        hideLoader();
        //  navigate('/')
      },
      (message: string) => {
        hideLoader();
        toast.error(message);
      }
    );
  };

  const getPaymentValue = () => {
    residenceApi.getPaymentValue(
      {
        FacilityId: residenceID,
      },
      (message: string, resp: any) => {
        setAmount(resp[0].amount ? resp[0].amount : 250);
      },
      (message: string) => {}
    );
  };

  useEffect(() => {
    getPaymentValue();
  }, []);

  useEffect(() => {
    if (payFastData) {
      payFastForm.current.submit();
    }
  }, [payFastData]);

  useEffect(() => {
    getBase64();
  }, []);
  // ******************************

  useEffect(() => {
    getPrevData();
  }, []);

  const getPrevData = () => {
    if (residenceID) {
      showLoader();
      //call api reset form values via reset()
      commonApi.retreive(
        {
          Entity: "FacilityDocLib",
          Top: 100,
          ReturnCols: [
            "FacilityDocLibId",
            "Name",
            "DocumentTypeId",
            "FileExtention",
            "FileContent",
          ],
          FilterOn: {
            Type: "And",
            Where: [
              {
                filterCol: "FacilityId",
                FilterCondition: "Equals",
                FilterValue: residenceID,
              },
              // TODO: add proper filter after feedback from @SiyandaSitsha

              // , {
              //     "filterCol": "DocumentTypeId", "FilterCondition": "Equals",
              //     "FilterValue": ["892"]
              // }
            ],
          },
        },
        (message: string, resp: any) => {
          hideLoader();
          let prevData = JSON.parse(resp);
          const relevantDocs = [892];
          setPrevFiles(
            prevData.filter((doc: Record<string, number>) =>
              relevantDocs.includes(doc.DocumentTypeId)
            )
          );
        },
        (message: string) => {
          hideLoader();
          toast.error(message);
        }
      );
    } else {
    }
  };

  return (
    <>
      <Box className="dashboard">
        <Header />
        <Box sx={{ padding: "2rem" }}>
          <Paper
            sx={{
              padding: "10rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                position: "relative",
              }}
            ></Box>
            <div
              className=""
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <img
                src="/assets/img/logo.png"
                alt="nsfas-logo"
                style={{
                  width: "100px",
                  position: "absolute",
                  top: "20%",
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
              />
            </div>
            <h4
              className="payment-options-text"
              style={{
                fontSize: "25px",
                fontWeight: "600",
                textAlign: "center",
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              Choose Your Payment Options
            </h4>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2rem",
                gap: "1rem",
                width: "100%",
              }}
            >
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="button"
                onClick={() => payOnline(residenceID)}
                style={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "center",
                  alignContent: "center",
                  width: "200px",
                }}
              >
                <div>Pay Online</div>
                <img
                  src={onlinePaymentIcon}
                  alt="online-payment-img"
                  style={{ width: "30px", color: "#fff" }}
                />
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary "
                data-bs-toggle="button"
                onClick={handleShow}
                style={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "200px",
                }}
              >
                <div>Pay Using EFT</div>
                <img
                  src={eftPaymentIcon}
                  alt="eft-payment-img"
                  style={{ width: "30px" }}
                />
              </button>
            </Box>
          </Paper>
        </Box>
        {payFastData ? (
          <form action={payFastData.action} ref={payFastForm} method="post">
            <input
              type="hidden"
              name="merchant_id"
              value={payFastData.merchant_id}
            />
            <input
              type="hidden"
              name="merchant_key"
              value={payFastData.merchant_key}
            />
            <input type="hidden" name="amount" value={payFastData.amount} />
            <input
              type="hidden"
              name="item_name"
              value={payFastData.item_name}
            />
            <input
              type="hidden"
              name="return_url"
              value={
                APP_URL +
                URLS.SUCCESS_PAGE +
                "?amount=" +
                payFastData.amount +
                (regrade ? "&regrade=true" : "")
              }
            />
            <input
              type="hidden"
              name="cancel_url"
              value={
                APP_URL +
                URLS.CANCEL_PAGE +
                "?amount=" +
                payFastData.amount +
                (regrade ? "&regrade=true" : "")
              }
            />
            <input
              type="hidden"
              name="notify_url"
              value={
                APP_URL +
                "?amount=" +
                payFastData.amount +
                (regrade ? "&regrade=true" : "")
              }
            />
            <input
              type="hidden"
              name="name_first"
              value={payFastData.name_first}
            />
            <input
              type="hidden"
              name="name_last"
              value={payFastData.name_last}
            />
            <input
              type="hidden"
              name="email_address"
              value={payFastData.email_address}
            />
            <input
              type="hidden"
              name="cell_number"
              value={payFastData.cell_number}
            />
            <input
              type="hidden"
              name="m_payment_id"
              value={payFastData.m_payment_id}
            />
            <input
              type="hidden"
              name="item_description"
              value={payFastData.item_description}
            />
            <input type="submit" hidden />
          </form>
        ) : null}
      </Box>
      {/* <div className="documents-fld-panel">
                                {prevFiles && prevFiles.length > 0 ?
                                    prevFiles.map((file: any, i: any) => (
                                        <div className="doc-file mb-2" key={i}>
                                            <FaTimes className="delete-me" onClick={()=>{deleteFromServer(file)}} />
                                            <input type="text" className="form-control" disabled id="exampleFormControlInput3"
                                                placeholder={file.Name} />
                                        </div>
                                    ))
                                    : null}
                                {files.map((file: any, i: any) => (
                                    <div className="doc-file mb-2" key={i}>
                                        <FaTimes className="delete-me" onClick={()=>{deleteLocal(file)}} />
                                        <input type="text" className="form-control" disabled id="exampleFormControlInput3"
                                            placeholder={file.FileName} />
                                    </div>
                                ))}
                            </div> */}

      {/* Modal For Invoice and Uploading docs */}
      <Box sx={{ paddingLeft: "5rem", paddingRight: "5rem" }}>
        <Modal show={show} onHide={handleClose} fullscreen style={{}}>
          <Modal.Header closeButton style={{ backgroundColor: "#D36E28" }}>
            <Modal.Title>EFT Payment </Modal.Title>
          </Modal.Header>
          <Box>
            <div className="mt-2" style={{ width: "30%", paddingLeft: "1rem" }}>
              {files.length > 0 ? (
                <div className="proof-of-payment-label">
                  Your Proof Of Payment:
                </div>
              ) : (
                <div className="proof-of-payment-label">
                  Upload Your Proof Of Payment:
                </div>
              )}

              {files.map((file: any, i: any) => (
                <div className="doc-file mb-2" key={i}>
                  <FaTimes
                    className="delete-me"
                    onClick={() => {
                      deleteLocal(file);
                    }}
                  />
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    id="exampleFormControlInput3"
                    placeholder={file.FileName}
                  />
                </div>
              ))}
            </div>
          </Box>
          <Modal.Body>
            <EftPaymentModalContent base64PDF={base64} />
          </Modal.Body>
          <Modal.Footer>
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <div
                  className={`upload-documents  ${
                    hasEveryRequiredDocument ? "disabled" : ""
                  }`}
                  style={{ backgroundColor: "#D36E28" }}
                >
                  <label style={{ fontWeight: "bold", cursor: "pointer" }}>
                    <MdAddCircle className="icon" />
                    &nbsp; Add Proof Of Payment
                    <input
                      style={{ cursor: "pointer" }}
                      type="file"
                      id="general_documents"
                      disabled={hasEveryRequiredDocument}
                      multiple={true}
                      accept=".pdf, .doc, .docx"
                      // onChange={handleFileChange} // Add this line
                      {...register("Documents", { required: true })}
                    />
                  </label>
                </div>
                {/* <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} onClick={onSubmit} style={{ padding:'20px', backgroundColor:'#D36E28'}}>
                            Submit
                        </Button> */}
                <Button
                  variant="contained"
                  onClick={onSubmit}
                  disabled={files.length === 0} // Disable if no document is added
                  style={{
                    padding: "20px",
                    backgroundColor: files.length === 0 ? "grey" : "#D36E28",
                    color: "#fff",
                  }}
                  className="eft-btns"
                >
                  Submit
                </Button>
                <Button
                  className="eft-btns"
                  variant="contained"
                  onClick={handleClose}
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    padding: "20px",
                    backgroundColor: "#D36E28",
                  }}
                >
                  Close
                </Button>
              </Box>
            </Box>
          </Modal.Footer>
        </Modal>
      </Box>

      {/* Modal for Select document  */}
    </>
  );
};

export default PaymentOptions;
