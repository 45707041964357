import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useUserApi } from '../_common/hooks/api/UserApiHook';
import toast from 'react-hot-toast';
import { Container, Row, Col, Button } from 'react-bootstrap';
import EntityProviderForm from '../components/registration/entityProviderForm';
import IndividualProviderForm from '../components/registration/individualProviderForm';
import AddressForm from '../components/registration/addressForm';
import IntroSlider from '../components/introSlider';
import { AiOutlineLeft } from 'react-icons/ai';
import BankDetailsForm from '../components/registration/bankDetailsForm';
import PreregistrationForm from '../components/registration/preregistrationForm';
import { URLS } from '../_config';
import Select from 'react-select'


const ProgressStepper = ({ step = 1 }: { step: 1 | 2 | 3 }) => {
  return (
    <>
      <ul>
        <li>
          <div className="form-check no-pointer-events">
            <input
              className="form-check-input"
              type="radio"
              readOnly
              name="flexRadioDefault"
              id="flexRadioDefault2"
              checked={step === 1}
              style={step === 1 ? { background: '#D36E28' } : undefined}
            />
            <label className="form-check-label">Details</label>
          </div>
        </li>
        <li>
          <div className="form-check no-pointer-events">
            <input
              className="form-check-input"
              type="radio"
              readOnly
              name="flexRadioDefault"
              id="flexRadioDefault2"
              checked={step === 2}
              style={step === 2 ? { background: '#D36E28' } : undefined}
            />
            <label className="form-check-label">Addresses</label>
          </div>
        </li>{' '}
        <li>
          <div className="form-check no-pointer-events">
            <input
              className="form-check-input"
              type="radio"
              readOnly
              name="flexRadioDefault"
              id="flexRadioDefault3"
              checked={step === 3}
              style={step === 3 ? { background: '#D36E28' } : undefined}
            />
            <label className="form-check-label">Banking</label>
          </div>
        </li>
      </ul>
      <div className="progress_area mb-3">
        <div className="progress" style={{ marginTop: 16, height: 8 }}>
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${(step / 3) * 100}%` }}
          ></div>
        </div>
      </div>
    </>
  );
}; 

export default function Home() {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useAppLoader();
  const userApi = useUserApi();
  const [
    { isPreregComplete, confirmedEmail, confirmedMobileNumber },
    setPreregState,
  ] = useState({
    isPreregComplete: false,
    confirmedEmail: '',
    confirmedMobileNumber: '',
  });
  const [tab, setTab] = useState<string>('regi');
  const [step, setStep] = useState<number>(1);

  const [entityProviderData, setEntityProviderData] = useState<any>(null);
  const [individualProviderData, setIndividualProviderData] =
    useState<any>(null);
  const [addressData, setAddressData] = useState<any>(null);
  const [documentData, setDocumentData] = useState<any>(null);
  const [bankingData, setBankingData] = useState(null);

  const onSubmitEntityProviderForm = (data: any) => {
    setEntityProviderData(data);
    setStep(2);
  };
  const onSubmitIndividualProviderForm = (data: any) => {

    setIndividualProviderData(data);

    setStep(2);
  };
  
  const onSubmitAddressForm = (data: any, documents: any) => {
    setDocumentData(documents);
    setAddressData(data);
    setStep(3);
  };

  const onSubmitBankingDetails = (BankingDetails: any, documents: any) => {
    const updated = [...documentData,documents[0]];
    setDocumentData((current:any []) => {
       const upperdated = [...current, documents[0]];
      return upperdated;
    });
    console.log('Docdata:', updated);
    if (selection.value == 'indi') {
            registerIndividual({BankingDetails},updated); // when user clicks the individual tab
          } else {
            registerEntity({BankingDetails},updated);
          }
  };

  
  const registerEntity = ({BankingDetails}: {BankingDetails: Record<string, unknown>},updated :any )  => {
    /* 
     {
    
              "TradingName": "Flexi Suite API ",
              "RegistrationName": "Flexis",
              "RegistrationNumber": "54555456",
              "APTypeId": "865",
              "IDTypeId": "862",
              "IDNumber": "2555656666",
              "VATNumber": "12323",
              "Mobile": "0125623",
              "Telephone": "251565",
              "Email": "siyandas@emial.com",
              "Password": "1234678"
            },
    
            {
              "StreetNumber": "102",
              "StreetName": "Lynwood Road",
              "Surburb": "Hatfield",
              "City": "Pretoria",
              "Province": "176",
              "PostalCode": "0158"
            },
    */
    let params: any = {
      entityName: 'Supplier',
      requestName: 'CreateRecordExecuteRequest',
      inputParamters: {
        AccomodationProviderInfo: entityProviderData,
        Address: addressData,
        Documents: updated,
        BankingDetails,
      },
    };
    showLoader();
    userApi.registerEntity(
      params,
      (message: string, resp: any) => {
        hideLoader();
        toast.success(message);
        navigate('/registration-successful');
        // navigate('/login')
      },
      (message: string) => {
        // login error..
        hideLoader();
        toast.error(message);
      }
    );
  };

  const registerIndividual = ({BankingDetails}: {BankingDetails: Record<string, unknown>},updated:any) => {
    let params: any = {
      entityName: 'Supplier',
      requestName: 'CreateRecordExecuteRequest',
      inputParamters: {
        AccomodationProviderInfo: individualProviderData,
        Address: addressData,
        Documents: updated,
        BankingDetails
      },
    };
    showLoader();
    userApi.registerIndividual(
      params,
      (message: string, resp: any) => {
        hideLoader();
        toast.success(message);
        navigate('/registration-successful');
        // navigate('/login')
      },
      (message: string) => {
        // login error..
        hideLoader();
        toast.error(message);
      }
    );
  };

  // Using a useEffect here is not ideal as there can be other unwanted side effects. This effect also "assumes" that bankingData is already set.
  // Recommmend: Refactor and call registration during onSubmitBankingDetails
  // useEffect(() => {
  //   if (addressData) {
  //     if (selection.value == 'indi') {
  //       registerIndividual(); // when user clicks the individual tab
  //     } else {
  //       registerEntity();
  //     }
  //   }
  // }, [bankingData]);

  const renderBankSection = () => (
    <div
      className={'tab-pane fade' + (step === 3 ? ' show active' : '')}
      id="home"
      role="tabpanel"
      aria-labelledby="home-tab"
    >
      <div className="reg_detailaddress">
        <AiOutlineLeft className="addressBack" onClick={() => setStep(2)} />
        <ProgressStepper step={3} />
      </div>
      <BankDetailsForm
        key={selection.value}
        tab={selection.value}
        onSubmitBankingDetails={onSubmitBankingDetails}
      />
    </div>
  );


  const options = [
		{value:'regi',label:'Registered Entity'},
		{value:'indi',label:'Individual'},
	]
	const [selection,setSelection] = useState(options[0]);

  const handleSelectChange = (accommodationTypeSelection:any) =>{
		setSelection(accommodationTypeSelection);
    if(selection.value == 'regi'){
        setStep(1);
    }
    else if(selection.value == 'indi'){
      setStep(1)
    }
	}
    

  return (
    <React.Fragment>
      <div className="register_page">
        <div className="register_left  ">
          <div className="mob_logo">
            <Link className="logo_left" to="/entry-type">
              {' '}
              <img src="/assets/img/logo.png" />{' '}
            </Link>
          </div>
          <div className="logo_txt">Register your account</div>
          <div className="register_tab">
            <h6 style={{fontWeight:'600'}}>Choose to register as:</h6>

            {/* <nav className="dual-menu">
              <a
                className={'btn-menu ' + (tab == 'regi' ? 'active' : '')}
                onClick={() => {
                  setTab('regi');
                  setStep(1);
                }}
              >
                Registered entity
              </a>
              <a
                className={'btn-menu ' + (tab == 'indi' ? 'active' : '')}
                onClick={() => {
                  setTab('indi');
                  setStep(1);
                }}
              >
                Individual
              </a>
            </nav> */}
            <Select
									className={"form-control p-0"}
									options={options}
									placeholder={'Select Your Accommodation Type'}
									onChange={handleSelectChange}
									defaultValue={options[0]}
									// value={signInSelection}
									// value={signInSelection}
                  // isDisabled = { step == 1 ? true : false}
								/>
            {!isPreregComplete ? (
              <div>
                <PreregistrationForm
                  onCompletePreregistration={(
                    email: string,
                    mobileNumber: string
                  ) => {
                    setPreregState({
                      isPreregComplete: true,
                      confirmedEmail: email,
                      confirmedMobileNumber: mobileNumber,
                    })
                  }}
                />
              </div>
            ) : (
              <>
                {selection.value == 'indi' ? (
                  <div className="tab-content" id="myTabContent">
                    <div
                      className={
                        'tab-pane fade ' + (step == 1 ? ' show active' : '')
                      }
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <div className="reg_detailaddress">
                        <ProgressStepper step={1} />
                      </div>
                      <IndividualProviderForm
                       email={confirmedEmail}
                        mobileNumber={confirmedMobileNumber}
                        onSubmitIndividualProviderForm={
                          onSubmitIndividualProviderForm
                        }
                      />
                    </div>
                    <div
                      className={
                        'tab-pane fade' + (step == 2 ? ' show active' : '')
                      }
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div className="reg_detailaddress">
                        <AiOutlineLeft
                          className="addressBack"
                          onClick={() => setStep(1)}
                        />
                        <ProgressStepper step={2} />
                      </div>
                      <AddressForm
                        tab={selection.value}
                        userType={'individual'}
                        onSubmitAddressForm={onSubmitAddressForm}
                      />
                    </div>
                    {renderBankSection()}
                  </div>
                ) : (
                  <div className="tab-content" id="myTabContent">
                    <div
                      className={
                        'tab-pane fade ' + (step == 1 ? ' show active' : '')
                      }
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <div className="reg_detailaddress">
                        <ProgressStepper step={1} />
                      </div>
                      <EntityProviderForm
                        email={confirmedEmail}
                        mobileNumber={confirmedMobileNumber}
                        onSubmitEntityProviderForm={onSubmitEntityProviderForm}
                      />
                    </div>
                    <div
                      className={
                        'tab-pane fade' + (step == 2 ? ' show active' : '')
                      }
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div className="reg_detailaddress">
                        <AiOutlineLeft
                          className="addressBack"
                          onClick={() => setStep(1)}
                        />
                        <ProgressStepper step={2} />
                      </div>
                      <AddressForm
                        key={selection.value}
                        tab={selection.value}
                        // docValue={887} //doc value for  CIPC Doc
                        userType={'enterprise'}
                        onSubmitAddressForm={onSubmitAddressForm}
                      />
                    </div>
                    {renderBankSection()}
                  </div>
                )}
              </>
            )}
          <div>
              Already have an account? <Link to={`${URLS.LOGIN}?type=p`}>Login</Link>
          </div>
          </div>
         
        </div>
        <IntroSlider />
      </div>
    </React.Fragment>
  );
}
