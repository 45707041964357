import React, { useEffect } from "react";
import Select, { TSelectProps } from "../Select";
import { API_URL } from "../../../../_config";
import { API } from "../../services/API";
import { IApiResponse } from "../../types/IApiResponse";
import { EXECUTE_REQUEST_PATH } from "../../constants";

const RoomSelect = React.forwardRef(
  (props: Omit<TSelectProps, "options"> & { checkInId: string }, ref: any) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [options, setOptions] = React.useState<
      { label: string; value: string }[]
    >([]);

    useEffect(() => {
      (async () => {
        try {
          setIsLoading(true);
          const response: IApiResponse<{
            RoomTypes: { capacityId: string; name: string }[];
          }> = await API.post(EXECUTE_REQUEST_PATH, {
            entityName: "CheckIn",
            requestName: "GetCheckInRoomTypes",
            recordId: props.checkInId,
          });

          if (response.isSuccess) {
            setOptions(
              response.outputParameters.RoomTypes.map((roomType) => ({
                label: roomType.name,
                value: roomType.capacityId,
              }))
            );
          } else {
            setOptions([]);
          }
        } catch (e) {
          console.error(e);
        } finally {
          setIsLoading(false);
        }
      })();
    }, []);

    return (
      <Select
        ref={ref}
        {...props}
        options={options}
        disabled={isLoading || props.disabled}
      />
    );
  }
);

RoomSelect.displayName = "RoomSelect";

export default RoomSelect;
