import Breadcrumbs from '@mui/material/Breadcrumbs';

import React from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { BreadcrumbData } from './types';
import HomeIcon from '../../icons/HomeIcon';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import { ThemeColors } from '../../styles/theme';

type Props = {
  data?: BreadcrumbData;
};

function CustomBreadcrumbs({ data = [] }: Props) {
  return (
    <Box display="flex" alignItems="center">
      <MuiLink component={Link} underline="hover" color="inherit" to="/">
        <HomeIcon />
      </MuiLink>
      <NavigateNextIcon fontSize="small" />
      <Breadcrumbs maxItems={2} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {data.map((breadcrumb, i) => {
          return (
            <MuiLink
              key={breadcrumb.label}
              component={Link}
              underline="hover"
              color={i === data.length - 1? ThemeColors.primaryOrange : "inherit"}
              to={breadcrumb.path || '/'}>
              {breadcrumb.label}
            </MuiLink>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
}

export default CustomBreadcrumbs;
