import { useUserSelector } from "../../../_common/hooks/selectors/userSelector";
import { TermType } from "../types/shared";

export type TCurrentUser = {
  recordId: string;
  name: string;
  firstName: string;
  lastName: string;
  idNumber: string | null;
  studentNumber: string | null;
  mobile: string;
  telephone: string | null;
  email: string;
  institutionId: string;
  institutionIdName: string;
  campusId: string | null;
  campusIdName: string;
  yearOfStudyId: string | null;
  isActive: boolean;
  isExpired: boolean;
  tsAndCsAccepted: boolean;
  externalLogonId: string;
  relatedObjectIdObjectTypeCode: 'Employee' | 'Supplier';
  gender: string;
  supplierId: string | null;
  disabilityStatusId: string;
  termTypeId: TermType;
};

export default function useCurrentUser() {
  const user = useUserSelector();
  return user as TCurrentUser | null;
}
