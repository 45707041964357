import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useCommonApi } from "../../../_common/hooks/api/common/commonApiHook";
import { useAppLoader } from "../../../_common/hooks/common/appLoaderHook";
import { useUserSelector } from "../../../_common/hooks/selectors/userSelector";
import { useNavigate, useParams } from "react-router-dom";
import { showErrorAlert } from "../../../components/Notify";
import Select from "../../../ui/v2/components/Select";
import { Button } from "@mui/material";
import MonthPeriodSelect from "../../../ui/v2/components/MonthPeriodSelect";
import useFormFieldErrorProps from "../../../ui/v2/hooks/useFormFieldErrorProps";
import { isBefore } from "date-fns";

interface TenantsRoomModalProps {
  handleClose: () => void;
  shouldShow: boolean;
  residence: any;
  applicationDates?: any;
}
type TFormProps = {
  PlannedMoveInDate: string;
  PlannedMoveOutDate: string;
  PreferredRoomTypeId: string;
};
const StudentRoomModal = ({
  shouldShow,
  handleClose,
  residence,
  applicationDates,
}: TenantsRoomModalProps) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<TFormProps>();
  const { getFieldErrorProps } = useFormFieldErrorProps<TFormProps>(errors);

  const commonApi = useCommonApi();
  const { showLoader, hideLoader } = useAppLoader();
  const user: any = useUserSelector();
  const navigate = useNavigate();

  const [studentId, setStudentId] = useState<string>("");
  const { id } = useParams();

  const [roomTypes, setRoomTypes] = useState<any>(null);

  console.log("MonthPeriodSelect", applicationDates);

  const getApplicationRoomTypes = () => {
    if (residence?.rooms.length > 0) {
      let opt: any = [];
      residence.rooms.map((o: any) => {
        opt.push({ label: o.iconName, value: o.capacityId });
      });
      setRoomTypes(opt);
    }
  };

  const onSubmit = ({
    PlannedMoveInDate,
    PreferredRoomTypeId,
    PlannedMoveOutDate,
  }: TFormProps) => {
    if (PreferredRoomTypeId) {
      showLoader();
      commonApi.post(
        {
          entityName: "AccomodationApplications",
          requestName: "UpsertRecordReq",
          inputParamters: {
            Entity: {
              studentId: studentId,
              facilityId: id,
              capacityId: PreferredRoomTypeId,
              PreferredRoomTypeId: PreferredRoomTypeId,
              PlannedMoveInDate,
              PlannedMoveOutDate,
            },
          },
        },
        (message: string, resp: any) => {
          toast.success(message);
          handleClose();
          hideLoader();
          navigate(`/student/my-applications/success`);
        },
        (message: string) => {
          showErrorAlert(message);
          handleClose();
          hideLoader();
        }
      );
    } else {
      console.log("Please select a room");
    }
  };

  useEffect(() => {
    if (user) {
      setStudentId(user.recordId);
      getApplicationRoomTypes();
    }
  }, [user]);

  useEffect(() => {
    if (residence) {
      getApplicationRoomTypes();
    }
  }, [residence]);

  return (
    <Modal show={shouldShow} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Apply for Residence</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <MonthPeriodSelect
              mode="start"
              label="Planned Move-in Date"
              applicationDates={applicationDates}
              size="small"
              {...register("PlannedMoveInDate", { required: true })}
              {...getFieldErrorProps("PlannedMoveInDate")}
            />
          </div>
          <div>
            <MonthPeriodSelect
              mode="end"
              label="Planned Move-out Date"
              size="small"
              applicationDates={applicationDates}
              {...register("PlannedMoveOutDate", {
                required: true,
                validate: (value, values) => {
                  if (
                    isBefore(
                      new Date(value),
                      new Date(values.PlannedMoveInDate)
                    )
                  ) {
                    return "Move-out date should be after move-in date";
                  }
                  return true;
                },
              })}
              {...getFieldErrorProps("PlannedMoveOutDate")}
            />
          </div>
          <div className="my-2">
            <Select
              {...register("PreferredRoomTypeId", { required: true })}
              label="Preferred Room Type"
              size="small"
              options={roomTypes}
              {...getFieldErrorProps("PreferredRoomTypeId")}
            />
          </div>

          <div>
            <Button fullWidth variant="contained" type="submit">
              Confirm
            </Button>
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={handleClose} fullWidth>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StudentRoomModal;
