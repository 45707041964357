import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import {
  AddCircleOutline,
  BusinessCenterOutlined,
  Close,
  Download,
} from "@mui/icons-material";
import { IApiResponse } from "../ui/v2/types/IApiResponse";
import { API } from "../ui/v2/services/API";
import { EXECUTE_REQUEST_PATH } from "../ui/v2/constants";
import { DEFAULT_PAGE_SIZE } from "../constants";

import { Viewer } from "@react-pdf-viewer/core";

import "@react-pdf-viewer/core/lib/styles/index.css";
import { format } from "date-fns";
import { URLS } from "../_config";
import ListingTable from "../ui/v2/components/GenericListingScreen/ListingTable";
import LegacyPageLayout from "../components/LegacyPageLayout";
import { ThemeColors } from "../ui/v2/styles/theme";
import useSearchAndFiltersBar from "../ui/v2/components/GenericListingScreen/useSearchAndFiltersBar";
import PageHeaderTexts from "../ui/v2/components/GenericListingScreen/PageHeaderTexts";
import SummaryCard from "../ui/v2/components/GenericListingScreen/SummaryCard";
import NoItemsPaper from "../ui/v2/components/GenericListingScreen/NoItemsPaper";
import { TSelectedFilters } from "../ui/v2/components/GenericListingScreen/useSearchAndFiltersBar/SearchAndFiltersBar";
import { UTILS } from "../utils";

type CaseListingResponseType = {
  Summary: {
    totalCases: number;
    openCases: number;
    closedCases: number;
    onHoldCases: number;
    totalCasesTrend: number;
    openCasesTrend: number;
    closedCasesTrend: number;
    onHoldCasesTrend: number;
  };
  PageSize: number;
  RecordCount: number;
  Pages: number;
  Listing: {
    casesId: string;
    caseNumber: string;
    classification: string;
    regarding: string;
    caseStatus: string;
    createdOn: string;
  }[];
};

type YearResponseType = {
  Count: number;
  data: {
    optionValue: number;
    optionText: string;
    colorCode: null;
  }[];
};

function BasicMenu({
  invoiceId,
  invoiceNumber,
}: {
  invoiceId: string;
  invoiceNumber: string;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [
    { isModalOpen, invoiceFileName, fileContent, fileExtension },
    setModalState,
  ] = useState<{
    isModalOpen: boolean;
    invoiceFileName?: string;
    isLoading?: boolean;
    fileContent?: string;
    fileExtension?: string;
  }>({
    isModalOpen: false,
  });

  const getFile = async (format: "PDF" | "XLSX") => {
    try {
      setModalState((curr) => ({ ...curr, isLoading: true }));
      const downloadResult: IApiResponse<{
        Filename: string;
        FileExtension: string;
      }> = await API.post(EXECUTE_REQUEST_PATH, {
        entityName: "Invoice",
        recordId: invoiceId,
        requestName: "DownloadRecordExecuteRequest",
        inputParamters: {
          Format: format,
        },
      });
      return {
        name: downloadResult.outputParameters.Filename,
        extension: downloadResult.outputParameters.FileExtension || "pdf",
        content: downloadResult.results,
      };
    } catch (e) {
      console.error(e);
    } finally {
      setModalState((curr) => ({ ...curr, isLoading: false }));
    }
  };

  const downloadFile = async (data: {
    name: string;
    extension: string;
    content: unknown;
  }) => {
    const a = document.createElement("a");
    a.download = data.name + "." + data.extension;
    a.href = `data:application/${data.extension};base64,${data.content}`;
    a.click();
  };

  const handleView = async () => {
    setModalState({
      isModalOpen: true,
      invoiceFileName: "Loading...",
      isLoading: true,
    });
    handleClose();
    const file = await getFile("PDF");
    if (!file) return;
    setModalState({
      isModalOpen: true,
      invoiceFileName: file.name,
      isLoading: false,
      fileContent: file.content as any,
      fileExtension: file.extension,
    });
  };
  const handleDownload = async () => {
    handleClose();
    const file = await getFile("PDF");
    if (!file) return;
    downloadFile(file);
  };

  const handleDownloadReport = async () => {
    handleClose();
    try {
      const fileName = `${invoiceNumber}_InvoiceReport.xlsx`;
      const response = await API.download(
        `Entities/Invoice/ExportExcelSubGridData/688A16F1-6D5F-44BA-9DD0-92D94944FC32/${invoiceId}?&filename=${fileName}`
      );
      const href = URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <div>
        <Button
          variant="outlined"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          Actions
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleView}>View Invoice</MenuItem>
          <MenuItem onClick={handleDownload}>Download Invoice</MenuItem>
          <MenuItem onClick={handleDownloadReport}>Download Report</MenuItem>
        </Menu>
      </div>
      <Dialog
        fullScreen
        open={isModalOpen}
        onClose={() => {
          setModalState({
            isModalOpen: false,
            invoiceFileName: "",
            isLoading: false,
          });
        }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setModalState({
                  isModalOpen: false,
                  invoiceFileName: "",
                  isLoading: false,
                });
              }}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {invoiceFileName}
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() =>
                downloadFile({
                  content: fileContent,
                  extension: fileExtension || "PDF",
                  name: invoiceFileName!,
                })
              }
              startIcon={<Download />}
            >
              Download
            </Button>
          </Toolbar>
        </AppBar>
        <Box>
          {fileContent ? (
            <Viewer
              fileUrl={`data:application/${fileExtension};base64,${fileContent}`}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                p: 4,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />{" "}
            </Box>
          )}
        </Box>
      </Dialog>
    </>
  );
}

const YEAR_TODAY = new Date().getFullYear().toString();

const Cases = () => {
  const commonApi = useCommonApi();

  const user: any = useUserSelector();

  const [applications, setApplications] = useState<any[]>([]);
  const { showLoader, hideLoader } = useAppLoader();

  const getFacilityApplicationDetails = (supplierId: string) => {
    commonApi.getFacilityApplicationDetails(
      {
        AccomodationProviderId: supplierId,
      },
      (message: string, resp: any) => {
        if (resp.length > 0) {
          setApplications(resp);
        }
      },
      () => {}
    );
  };

  useEffect(() => {
    if (user.supplierId) {
      getFacilityApplicationDetails(user.supplierId);
    }
  }, []);

  const [details, setDetails] = useState<CaseListingResponseType>();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPage = searchParams.get("page")
    ? parseInt(searchParams.get("page") as string)
    : 1;

  const [currentPage, setCurrentPage] = useState<number>(
    initialPage > 0 ? initialPage : 1
  );

  const getApiData = async (
    _currentPage: number,
    activeFilters: TSelectedFilters
  ) => {
    try {
      showLoader();
      const caseListingRequest: IApiResponse<CaseListingResponseType> =
        await API.post(EXECUTE_REQUEST_PATH, {
          entityName: "Cases",
          requestName: "CasesListingReq",
          inputParamters: {
            UserId: user.supplierId ? user.supplierId : user.recordId,
            SearchText: searchedText,
            PageNumber: currentPage,
            PageSize: DEFAULT_PAGE_SIZE,
            Status: UTILS.extractFilterValue(activeFilters, "Status"),
          },
        });

      setDetails(caseListingRequest.outputParameters);
    } catch (e) {
      console.error(e);
    } finally {
      hideLoader();
    }
  };

  const [, setModalState] = useState<{
    show: boolean;
    selectedFacilityId?: string;
    selectedApplicationId?: string;
  }>({
    show: false,
  });

  const navigate = useNavigate();

  const {
    searchAndFiltersBarComponent,
    searchedTextBannerComponent,
    activeFilters,
    searchedText,
  } = useSearchAndFiltersBar({
    filters: [
      {
        name: "Status",
        initialValue: "All",
        options: [
          { label: "All", value: "All" },
          { label: "Open", value: "Open" },
          { label: "On Hold", value: "OnHold" },
          { label: "Closed", value: "Closed" },
        ],
        multiple: false,
        type: "dropdown",
        value: "Status",
      },
    ],
    onSearch: (searchInput) => {
      setCurrentPage(1);
      setSearchParams({ page: "1" });
    },
  });

  useEffect(() => {
    getApiData(currentPage, activeFilters);
  }, [currentPage, searchedText, activeFilters]);

  return (
    <LegacyPageLayout
      bgColor={ThemeColors.bgPrimary}
      padding={3}
      paddingTop={0}
      breadcrumbData={[{ label: "Home", path: "/" }, { label: "Cases" }]}
    >
      <Box sx={{ mt: 1, pt: 2 }}>{searchAndFiltersBarComponent}</Box>
      <Grid item xs={12} sx={{ my: 6 }}>
        <PageHeaderTexts
          heading="My Cases"
          actions={
            <Button
              startIcon={<AddCircleOutline />}
              variant="contained"
              type={"button"}
              onClick={() => navigate(URLS.CREATE_CASE_TEMPORARY)}
            >
              Create Case
            </Button>
          }
        />
      </Grid>

      <Grid container sx={{ my: 2 }}>
        <Grid item xs={12} md={3} container>
          <SummaryCard
            label="Total Cases"
            icon={<BusinessCenterOutlined />}
            value={details?.Summary.totalCases || 0}
            trend={details?.Summary.totalCasesTrend || 0}
          />
        </Grid>
        <Grid item xs={12} md={3} container>
          <SummaryCard
            label="Open"
            icon={<BusinessCenterOutlined />}
            value={details?.Summary.openCases || 0}
            trend={details?.Summary.openCasesTrend || 0}
          />
        </Grid>
        <Grid item xs={12} md={3} container>
          <SummaryCard
            label="On Hold"
            icon={<BusinessCenterOutlined />}
            value={details?.Summary.onHoldCases || 0}
            trend={details?.Summary.onHoldCasesTrend || 0}
          />
        </Grid>
        <Grid item xs={12} md={3} container>
          <SummaryCard
            label="Closed Cases"
            icon={<BusinessCenterOutlined />}
            value={details?.Summary.closedCases || 0}
            trend={details?.Summary.closedCasesTrend || 0}
          />
        </Grid>
      </Grid>

      {searchedTextBannerComponent}
      {details?.Listing.length && details?.Listing.length > 0 ? (
        <>
          <ListingTable
            sx={{ minWidth: 1050 }}
            title="Cases"
            totalPages={details?.Pages || 1}
            currentPage={currentPage}
            onPageChange={(e, newPage) => {
              setCurrentPage(newPage);
              setSearchParams({ page: `${newPage}` });
            }}
          >
            <TableHead
              sx={{
                backgroundColor: "#F9FAFB",
              }}
            >
              <TableRow>
                <TableCell>Case Number</TableCell>
                <TableCell>Classification</TableCell>
                <TableCell>Regarding</TableCell>
                <TableCell>Case Status</TableCell>
                <TableCell>Created On</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {details?.Listing.map((entry) => (
                <TableRow
                  key={entry.caseNumber}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {entry.caseNumber}
                  </TableCell>
                  <TableCell className="table-data">
                    <div>{entry.classification}</div>
                  </TableCell>
                  <TableCell className="table-data">
                    <div>{entry.regarding}</div>
                  </TableCell>
                  <TableCell className="table-data">
                    {entry.caseStatus}
                  </TableCell>
                  <TableCell className="table-data">
                    {format(entry.createdOn, "yyyy-MM-dd")}
                  </TableCell>
                  <TableCell align="center">
                    {/* <BasicMenu
                            invoiceId={entry.invoiceId}
                            invoiceNumber={entry.invoiceNumber}
                          /> */}
                    <Button
                      variant="outlined"
                      component={Link}
                      to={`${URLS.CASES}/${entry.casesId}`}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </ListingTable>
        </>
      ) : (
        <NoItemsPaper />
      )}
    </LegacyPageLayout>
  );
};

export default Cases;
