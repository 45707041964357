import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import Select, { components } from 'react-select';
import { useForm, Controller } from "react-hook-form";
import { CheckNumber } from "../_common/functions";
import Header from './student/components/header';
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useResidenceApi } from "../_common/hooks/api/residenceApiHook";
import { access } from "fs/promises";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import { URLS } from "../_config";
import LeftPanel from "../components/leftPanel";
import PriceTable from "../components/PriceTable";
import { TextField, Tooltip } from "@mui/material";
import QueryStatsSharpIcon from '@mui/icons-material/QueryStatsSharp';
import QueryStatsSharp from "@mui/icons-material/QueryStatsSharp";
import { useCasesApi } from "../_common/hooks/api/casesApiHook";
import ApproveModal from "../components/ApproveModal";
import LegacyPageLayout from "../components/LegacyPageLayout";

export default function ResidenceSummery() {

    const navigate = useNavigate();
    const { showLoader, hideLoader } = useAppLoader();
    const commonApi = useCommonApi();
    const residenceApi = useResidenceApi();

    const casesApi = useCasesApi();



    const residenceID = sessionStorage.getItem('residenceID');
    const [rooms, setRooms] = useState<any>(null);
    const [genders, setGenders] = useState<any>(null);
    const [caterings, setCaterings] = useState<any>(null);
    const [capacities, setCapacities] = useState<any>(null);
    const [accesses, setAccesses] = useState<any>(null);
    const [numOfBeds,setNumOfBeds] = useState<any>(null);
    const [inspectionFee,setInspectionFee] = useState<any>();

    const name = sessionStorage.getItem('residenceName');


    // function gets the facility
    const getFacilityPricing = () => {
        
        showLoader()
        casesApi.getFacilityPricing({
            FacilityId:residenceID
        }, (message: string, resp: any) => {
            
            
            if (resp.length > 0) {
                // setCaseDetails(resp[0])
                setNumOfBeds(resp[0].TotalCapacity)
                setInspectionFee(resp[0].AdminFee)

            }
            hideLoader()
        }, (message: string) => {
            hideLoader();
        });

    }
    useEffect(()=>{
        getFacilityPricing();
    },[])
    



    

    const getResidenceRooms = () => {
        residenceApi.getResidenceRooms({
            $filter: "FacilityId eq '" + residenceID +"'",
            "entity": "Capacity",
            "$select": 'CapacityId, Name, Quantity, GenderId, PricePerBed, DisabilityAccessId, CateringId, AmenityId, AmenityTypeId, AmenityIdName ',
        }, (message: string, resp: any) => {
            let data: any = [];

            resp[0].data.map((room: any) => {
                if (room.AmenityTypeId == 859) {
                    data.push(room)
                }
            })
            setRooms(data);
        }, (message: string) => {
        });
    
    }

    const getCaterings = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "Catering"
                }
            }
        }
            , (message: string, resp: any) => {
                setCaterings(resp.outputParameters.data.map((data: any) => {
                    data.label = data.optionText;
                    data.value = data.optionValue;
                    return data
                }))
            }, (message: string) => {
            });
    }

    const getGender = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "Gender"

                }
            }
        }
            , (message: string, resp: any) => {
                setGenders(resp.outputParameters.data)
            }, (message: string) => {
            });
    }

    const getAccesses = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "YesNo"

                }
            }
        }
            , (message: string, resp: any) => {

                setAccesses(resp.outputParameters.data.map((data: any) => {
                    data.label = data.optionText;
                    data.value = data.optionValue;
                    return data
                }))
            }, (message: string) => {
            });
    }

    const getCapacities = () => {
        commonApi.getCapacities({ ViewName: 'Room_Type_Lookup_View' }, (message: string, resp: any) => {
            let opt: any = [];
            resp.map((o: any) => {
                if (o.recordId.search('000000') !== 0) {
                    o.label = o.mainText;
                    o.value = o.recordId;
                    opt.push(o)
                }
            })
            setCapacities(opt);
        }, (message: string) => {
        });
    }

    const getCateringName = (id: any) => {
        let name = '';
        caterings.map((cat: any) => {
            if (cat.value == id) {
                name = cat.label
            }
        });
        return name;
    }

    const getGenderName = (id: any) => {
        let name = '';
        genders.map((gen: any) => {
            if (gen.optionValue == id) {
                name = gen.optionText
            }
        });
        return name;
    }

    const getAccessName = (id: any) => {
        let name = '';
        accesses.map((access: any) => {
            if (access.value == id) {
                name = access.label
            }
        });
        return name;
    }

    const editRoom = (room:any)=>{
        navigate('/add-room?room='+room.CapacityId);
    }

    useEffect(() => {
        if (residenceID == null) {
            navigate('/add-residence')
        } else {
            getGender();
            getAccesses();
            getCaterings();
            getCapacities();
            getResidenceRooms();
        }
    }, [])
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <React.Fragment>
                        {/* Modal for prices */}
            
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Inspection Fee</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                    <PriceTable/>
                </Modal.Body>
                
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} style={{width:'200px', marginLeft:'auto',marginRight:'auto'}}>
                        Close
                    </Button>
                
                </Modal.Footer>
            </Modal>
            <LegacyPageLayout>
                <section className="container">
                    <div className="custome-container-inner d-flex">
                        <LeftPanel page={2}/>
                    <div className="white-block p-3" style={{width:"100%"}}>
                        
                        <div className="row">
                            <div className="col">
                                <h1>{name && `${name} - `}Bed Capacity</h1>
                            </div>
                        </div>
                        <hr />
                        <div className="tab_scrollcontent">
                        {/*  */}
                        <div className="beds-and-inspcection-summary">
                            
                            <div className="row mb-2">
                                <div className="col-6">
                                    <h5 style={{fontSize:'15px'}}>Total Number Of Beds</h5>
                                </div>
                                <div className="col-6 text-end" style={{display:'flex', justifyContent:'end'}}>
                                    {/* <h6 style={{marginRight:'1rem'}}>{getAccessName(room.DisabilityAccessId)}</h6> */}
                                    {(inspectionFee && numOfBeds) ? 
                                    <div className="" style={{marginRight:'3.5rem', marginLeft:0 ,width:'15rem'}} >
                                        <TextField
                                            sx={{width:'100%'}}
                                            disabled
                                            id="standard-disabled"
                                            defaultValue={numOfBeds}
                                            variant="standard"
                                            inputProps={{
                                            style: {fontSize: 15}
                                        }}
                                        />
                                    </div>
                                    :null}
                                </div>
                            </div>
                            <div className="row mb-2" style={{alignItems:'center'}}>
                                <div className="col-6">
                                    <h5 style={{fontSize:'15px'}}>Inspection Fee</h5>
                                </div>
                                <div className="col-6 text-end" style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                    {/* <h6 style={{marginRight:'1rem'}}>{getAccessName(room.DisabilityAccessId)}</h6> */}
                                 
                                    {(inspectionFee && numOfBeds) ? 
                                    <div className="" style={{marginRight:'2rem' ,width:'100%'}}>
                                        <TextField
                                            sx={{width:'100%'}}

                                            disabled
                                            id="standard-disabled"
                                            defaultValue={"R"+inspectionFee}
                                            variant="standard"
                                            inputProps={{
                                                style: {fontSize: 15 }
                                        }}
                                        />
                                    </div>
                                    :null}
                                    <Tooltip title="View Prices" sx={{marginLeft:'2rem'}}>
                                        <QueryStatsSharpIcon sx={{cursor:'pointer', }} onClick={handleShow}/>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        {/*  */}
                            <div className="residence-summary-panel">
                                {(rooms && genders && caterings && accesses && capacities) ?
                                    <React.Fragment>
                                        {rooms.map((room: any, i: any) => (
                                            <div className="residence-summary-block mb-2" key={i} onClick={()=>{editRoom(room)}}>
                                                <h4>{getGenderName(room.GenderId)} Rooms</h4>
                                                <div className="row mb-2">
                                                    <div className="col-8">
                                                        <h5>No. Of Rooms</h5>
                                                    </div>
                                                    <div className="col-4 text-end">
                                                        <h6>{room.Quantity}</h6>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-4">
                                                        <h5>Room Type</h5>
                                                    </div>
                                                    <div className="col-8 text-end">
                                                        <h6>{room.AmenityIdName}</h6>
                                                    </div>
                                                </div>
                                                {/* <div className="row mb-2">
                                                    <div className="col-8">
                                                        <h5>No. Of Beds</h5>
                                                    </div>
                                                    <div className="col-4 text-end">
                                                        <h6>{numO * room.Quantity}</h6>
                                                    </div>
                                                </div> */}
                                                <div className="row mb-2">
                                                    <div className="col-4">
                                                        <h5>Catering</h5>
                                                    </div>
                                                    <div className="col-8 text-end">
                                                        <h6>{getCateringName(room.CateringId)}</h6>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-8">
                                                        <h5>Disability friendly</h5>
                                                    </div>
                                                    <div className="col-4 text-end">
                                                        <h6>{getAccessName(room.DisabilityAccessId)}</h6>
                                                    </div>
                                                </div>
                                             
                                                
                                            </div>
                                            
                                        ))}
                                    </React.Fragment>
                                    : null}

                                <div className="residence-summary-add">
                                    <Link to="/add-room">
                                        <img src="/assets/img/plus-icon.png" />
                                        Add another room type
                                    </Link>
                                </div>
                                
                            </div>
                        </div>
                        <div className="mt-4">
                            
                            <Link to="/describe-rooms">
                                <button type="button" className="btn btn-primary w-100 mt-2" data-bs-toggle="button" >Next</button>
                            </Link>
                         
                            <Link to={URLS.ADD_RESIDENCE}>
                                <button 
                                    type="button" 
                                    className="btn btn-outline-secondary mt-2 w-100 back-btn-add-prop" 
                                    data-bs-toggle="button" 
                                    style={{
                                        // display:'flex',
                                        // justifyContent:'center',
                                        // alignItems:'center',
                                        // height:'35px',
                                        backgroundColor:'#fff'
                                    }}
                                >
                                    Back
                                </button>
                            </Link>
                        </div>
                    </div>
                    </div>
                    
                </section>
            </LegacyPageLayout>
        </React.Fragment>
    );
}