import Grid from "@mui/material/Grid";
import React from "react";
import ResponsiveGridSpacer from "../../../components/ResponsiveGridSpacer";
import TextField from "../../../components/TextField";
import Select from "../../../components/Select";
import { useFormContext } from "react-hook-form";
import RoomSelect from "../../../components/RoomSelect";
import { useParams } from "react-router-dom";

const ResponsiveGridItem = ({ children }: { children: React.ReactNode }) => (
  <Grid container item xs={12}>
    <ResponsiveGridSpacer width={3} />
    <Grid item xs={12} md={6} sx={{ mt: 2 }}>
      {children}
    </Grid>
  </Grid>
);

function InitialForm() {
  const { register } = useFormContext();
  const { id: checkInId } = useParams<{ id: string }>();
  if (!checkInId) {
    return null;
  }
  return (
    <Grid container>
      <ResponsiveGridItem>
        <TextField
          label="Room name/number"
          type="text"
          placeholder="001"
          {...register("roomName")}
        />
      </ResponsiveGridItem>
      <ResponsiveGridItem>
        <RoomSelect
          checkInId={checkInId}
          label="Room type"
          {...register("roomType")}
        />
      </ResponsiveGridItem>
      <ResponsiveGridItem>
        <TextField
          label="Number Of Beds In the Room"
          type="number"
          placeholder="12"
          {...register("NumberOfBeds")}
        />
      </ResponsiveGridItem>
      {/* <ResponsiveGridItem>
        <TextField
          label="Floor number"
          type="number"
          inputProps={{ step: 1 }}
          placeholder="10"
          {...register("floorNumber")}
        />
      </ResponsiveGridItem> */}
    </Grid>
  );
}

export default InitialForm;
