import React from "react";

function Illustration() {
  return (
    <svg width="220" height="160" viewBox="0 0 220 160" fill="none">
      <circle cx="110" cy="80" r="80" fill="#EAECF0" />
      <circle cx="26" cy="20" r="8" fill="#F2F4F7" />
      <circle cx="198" cy="126" r="6" fill="#F2F4F7" />
      <circle cx="25" cy="138" r="10" fill="#F2F4F7" />
      <circle cx="210" cy="46" r="10" fill="#F2F4F7" />
      <circle cx="191" cy="11" r="7" fill="#F2F4F7" />
      <g filter="url(#filter0_dd_3123_3979)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M113.486 16C96.7495 16 81.9448 24.2701 72.9354 36.9466C69.9934 36.2528 66.9253 35.8857 63.7714 35.8857C41.8063 35.8857 24 53.692 24 75.6571C24 97.6223 41.8063 115.429 63.7714 115.429H163.2C182.42 115.429 198 99.8481 198 80.6286C198 61.4091 182.42 45.8286 163.2 45.8286C161.835 45.8286 160.488 45.9072 159.164 46.0601C151.546 28.3784 133.961 16 113.486 16Z"
          fill="#F9FAFB"
        />
        <circle
          cx="63.7714"
          cy="75.6572"
          r="39.7714"
          fill="url(#paint0_linear_3123_3979)"
        />
        <circle
          cx="113.486"
          cy="65.7143"
          r="49.7143"
          fill="url(#paint1_linear_3123_3979)"
        />
        <circle
          cx="163.2"
          cy="80.6286"
          r="34.8"
          fill="url(#paint2_linear_3123_3979)"
        />
      </g>
      <g filter="url(#filter1_b_3123_3979)">
        <path
          d="M82 112C82 96.536 94.536 84 110 84C125.464 84 138 96.536 138 112C138 127.464 125.464 140 110 140C94.536 140 82 127.464 82 112Z"
          fill="#344054"
          fill-opacity="0.4"
        />
        <path
          d="M120.5 122.5L116.417 118.417M119.333 111.417C119.333 116.893 114.893 121.333 109.417 121.333C103.94 121.333 99.5 116.893 99.5 111.417C99.5 105.94 103.94 101.5 109.417 101.5C114.893 101.5 119.333 105.94 119.333 111.417Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_3123_3979"
          x="4"
          y="16"
          width="214"
          height="139.429"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_3123_3979"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3123_3979"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_3123_3979"
          />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="12" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_3123_3979"
            result="effect2_dropShadow_3123_3979"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_3123_3979"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b_3123_3979"
          x="74"
          y="76"
          width="72"
          height="72"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_3123_3979"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_3123_3979"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3123_3979"
          x1="33.2326"
          y1="49.3796"
          x2="103.543"
          y2="115.429"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D0D5DD" />
          <stop offset="0.350715" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3123_3979"
          x1="75.3122"
          y1="32.8673"
          x2="163.2"
          y2="115.428"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D0D5DD" />
          <stop offset="0.350715" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3123_3979"
          x1="136.479"
          y1="57.6358"
          x2="198"
          y2="115.429"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D0D5DD" />
          <stop offset="0.350715" stop-color="white" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Illustration;
