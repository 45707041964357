import LegacyPageLayout from "../../../../components/LegacyPageLayout";

import VerifyOtpFlow from "./VerifyOtpFlow";

function StudentCheckInDetailsPage() {
  return (
    <LegacyPageLayout padding={4} bgColor="#F8FAFC">
      <VerifyOtpFlow />
    </LegacyPageLayout>
  );
}

export default StudentCheckInDetailsPage;
