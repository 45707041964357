import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Row, Col, Button } from "react-bootstrap";
import { useCommonApi } from "../../_common/hooks/api/common/commonApiHook";
import { INPUT_MAX_LENGTHS, DIGITS_ONLY_PATTERN } from "../../_config/index";
import { InputField } from "../InputField";
import { useAppLoader } from "../../_common/hooks/common/appLoaderHook";
import { toast } from "react-hot-toast";
import Select from "react-select";

interface incomingProps {
  onSubmitBankingDetails: any;
  tab: any;
  userDetails: Record<string, any>;
}

interface Bank {
  bankId: string;
  name: string;
  branchCode: string;
}

export default function UpdateBankingForm({
  onSubmitBankingDetails,
  userDetails,
}: incomingProps) {
  const commonApi = useCommonApi();

  ////////// ACCOUNT TYPES //////////

  const [accountTypes, setAccountTypes] = useState<any>(null);


  // State to track if Verify button is clicked
  const [verifyClicked, setVerifyClicked] = useState<boolean>(false); 

 

  const getAccountTypes = () => {
    commonApi.post(
      {
        entityName: "Picklist",
        requestName: "RetrieveSelectOptionExecuteRequest",
        inputParamters: {
          SelectOption: {
            OptionText: "AccountType",
          },
        },
      },
      (message: string, resp: any) => {
        const options = resp.outputParameters.data.map((doc: any) => {
          return {
            value: doc.optionValue,
            label: doc.optionText,
          };
        });
        setAccountTypes(options);
      },
      (message: string) => {
        console.log(message);
      }
    );
  };

  useEffect(() => {
    getAccountTypes();
  }, []);

  /////////////////////////////

  const { showLoader, hideLoader } = useAppLoader();
  const [bankOptions, setBankOptions] = useState<Bank[]>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    reset,
  } = useForm();

  const onSubmit = (data: any) => {
    //const bank = bankOptions.find((bank) => bank.bankId === data.Bank.value);

    const bankingDetails = {
      Name: data.bankName,
      AccountNo: data.accountNumber,
      AccountHolder: data.accountHolder,
      BranchName: data.branchName,
      BranchNo: data.branchNumber,
      RelatedObjectId: userDetails?.supplierId,
      RelatedObjectIdObjectTypeCode: "Supplier",

      ////////// Account Type //////////

      AccountTypeId: data.AccountType.value,
      BankingTandC:data.termsAndConditions,
    };
    onSubmitBankingDetails(bankingDetails, data.bankAccountId);
  };

  // Inside the functional component
  // Inside the functional component
  // const { register, handleSubmit, formState: { errors }, control, reset, watch } = useForm();

  // Watch all form fields and the termsAndConditions checkbox
  const watchAllFields = watch([
    "bankName",
    "branchName",
    "branchNumber",
    "accountHolder",
    "accountNumber",
    "AccountTypeId",
    "termsAndConditions",
  ]);

  // Check if all fields are populated and the checkbox is checked
  const isFormValid =
    watchAllFields.every((field) => field !== "") &&
    watch("termsAndConditions");

  // Disable the button if the form is not valid
  const verifyButtonDisabled = !isFormValid;

  const buttonDisabled = !isFormValid || !verifyClicked; // Disable "Next" button if the form is not valid or "Verify" button is not clicked

  const handleVerifyClick = () => {
    setVerifyClicked(true); // Set verifyClicked to true when "Verify" button is clicked
  };

  const getPrevData = () => {
    if (userDetails?.supplierId) {
      showLoader();
      //call api reset form values via reset()
      commonApi.retreive(
        {
          Entity: "BankAccount",
          Top: 100,
          ReturnCols: [
            "BankAccountId",
            "Name",
            "AccountNo",
            "BranchName",
            "BranchNo",
            "AccountHolder",
            "AccountTypeId",
            "BankingTandC"
          ],
          FilterOn: {
            Type: "And",
            Where: [
              {
                filterCol: "RelatedObjectId",
                FilterCondition: "Equals",
                FilterValue: userDetails.supplierId,
              },
            ],
          },
        },
        (message: string, resp: any) => {
          hideLoader();
          const prevData = JSON.parse(resp)[0];
          let accountTypeLabel = '';
        switch(prevData?.AccountTypeId) {
          case 315:
            accountTypeLabel = 'Credit';
            break;
          case 316:
            accountTypeLabel = 'Cheque';
            break;
          case 317:
            accountTypeLabel = 'Savings';
            break;
          default:
            accountTypeLabel = '';
        }
          reset({
            bankName: prevData?.Name,
            branchName: prevData?.BranchName,
            branchNumber: prevData?.BranchNo,
            accountHolder: prevData?.AccountHolder,
            accountNumber: prevData?.AccountNo,
            bankAccountId: prevData?.BankAccountId,
            AccountType: { value: prevData?.AccountTypeId, 
              label: accountTypeLabel},
            termsAndConditions: prevData?.BankingTandC || false
          });
        },
        (message: string) => {
          hideLoader();
          console.log({ message });
          // toast.error(message);
        }
      );
    }
  };

  useEffect(() => {
    if (userDetails) {
      getPrevData();
    }
  }, [userDetails]);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tab_scrollcontent">
          <div className="mb-2">
            <InputField
              label="Bank Name"
              id="bankName"
              errors={errors}
              register={register}
              placeholder="First National Bank"
              required
            />
            {/*<Controller
              name={"Bank"}
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => {
                  return (<Select
                      isDisabled={bankOptions.length< 1}
                      className={"form-control p-0" + (errors?.Bank ? ' is-invalid' : '')}
                      options={bankOptions.map((bank: Bank) => ({ value: bank.bankId, label: bank.name }))}
                      placeholder={"Select a Bank"}
                      onChange={onChange}
                      value={value}
                      defaultValue={value}
                  />)
              }}
                  />*/}
          </div>

          <div className="mb-2">
            <InputField
              label="Branch Name"
              id="branchName"
              errors={errors}
              register={register}
              placeholder="Centurion"
              required
            />
          </div>
          <div className="mb-2">
            <InputField
              label="Branch Number"
              id="branchNumber"
              errors={errors}
              register={register}
              registerParams={{
                maxLength: {
                  value: INPUT_MAX_LENGTHS.accountNumber,
                  message: "Must be at most 50 characters",
                },
                pattern: {
                  value: DIGITS_ONLY_PATTERN,
                  message: "Numbers only",
                },
              }}
              placeholder="250655"
              required
            />
          </div>
          <div className="mb-2">
            <InputField
              label="Account Holder"
              id="accountHolder"
              errors={errors}
              register={register}
              placeholder="Xiquel Group (Pty) Ltd"
              required
            />
          </div>
          <div className="mb-2">
            <InputField
              label="Account number"
              id="accountNumber"
              errors={errors}
              register={register}
              registerParams={{
                maxLength: {
                  value: INPUT_MAX_LENGTHS.accountNumber,
                  message: "Must be at most 50 characters",
                },
                pattern: {
                  value: DIGITS_ONLY_PATTERN,
                  message: "Numbers only",
                },
              }}
              placeholder="64000000000"
              required
            />

            {/* Add Account Type Dropdown Here */}
            {/* <div className="mb-2">
                        <label className="form-label">Account Type</label>

                        <Controller
                            name={"AccountType"}
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange, onBlur } }) => {
                                return (<Select
                                    className={"form-control p-0" + (errors?.AccontType ? ' is-invalid' : '')}
                                    options={accountTypes}
                                    
                                    placeholder={"Select Account Type"}
                                    onChange={onChange}
                                    value={value}
                                    defaultValue={value}
                                />)
                            }}
                        />
                        
                        {errors?.AccontType ? <div className="invalid-feedback">Required</div> : null}
                    </div> */}

            <div className="mb-2">
              <label className="form-label">Account Type</label>

              <Controller
                name={"AccountType"}
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => {
                  return (
                    <Select
                      className={
                        "form-control p-0" +
                        (errors?.AccontType ? " is-invalid" : "")
                      }
                      options={accountTypes}
                      placeholder={"Select Account Type"}
                      onChange={onChange}
                      value={value}
                      defaultValue={value}
                      // required
                    />
                  );
                }}
              />

              {errors?.AccontType ? (
                <div className="invalid-feedback">Required</div>
              ) : null}
            </div>

            <div className="mb-2">
              <input
                type="checkbox"
                id="termsAndConditions"
                {...register("termsAndConditions", { required: true })}
              />
              <label htmlFor="termsAndConditions" className="ms-2">
                I agree with the terms and conditions
              </label>
            </div>
          </div>
        </div>
        <Row className="align-items-center">
          {/* <Col md={6}>
            <div className="mobbottom_fixbtn">
              <Button
                variant="primary"
                className="w-50 mt-2"
                type="button"
                id="verify"
                disabled={verifyButtonDisabled} // Disable "Verify" button if the form is not valid or "Verify" button is clicked
                onClick={handleVerifyClick} // Set verifyClicked to true when "Verify" button is clicked
              >
                Verify
              </Button>
            </div>
          </Col> */}
          <Col md={6}>
            <div className="mobbottom_fixbtn">
              <Button
                variant="primary"
                className="w-50 mt-2"
                type="submit"
                id="next"
                disabled={verifyButtonDisabled} // Disable "Next" button if the form is not valid or "Verify" button is not clicked
              >
                Next
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
}
