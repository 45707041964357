import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col, Button, FormLabel } from 'react-bootstrap';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { INPUT_MAX_LENGTHS, DIGITS_ONLY_PATTERN } from '../../_config/index';
import toast from 'react-hot-toast';
import { InputField } from '../InputField';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { SelectOption } from '../../_common/interfaces';
import { FaDownload, FaTimes } from 'react-icons/fa';

const BANKING_DOCS = [905]

const BASE_REQUIRED_DOCS = [ 886, 904, ...BANKING_DOCS];

const ENTITY_REQUIRED_DOCS = [ ...BASE_REQUIRED_DOCS, 887 ];

const INDIVIDUAL_REQUIRED_DOCS = [...BASE_REQUIRED_DOCS];

const STUDENT_REQUIRED_DOCS = [886,950,951];


const requiredDocsMap = {
  '865': ENTITY_REQUIRED_DOCS,
  '864': INDIVIDUAL_REQUIRED_DOCS,
  '201': STUDENT_REQUIRED_DOCS,
};

interface incomingProps {
  onSubmitDocuments: any;
  tab: any;
  userDetails: Record<string, any>;
  type: '865' | '864' |'201'// entity | individual
  isBankingOnlyMode: boolean;
}

const makePreviewFileHandler = (fileContent: any, name: string, extension: string) => async () => {
  const blob = await fetch(`data:application/octet-stream;base64,${fileContent}`).then(res => res.blob());
  const href = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', `${name}.${extension}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}
export default function UpdateDocumentsForm({
  onSubmitDocuments,
  userDetails,
  type,
  isBankingOnlyMode,
}: incomingProps) {
  const commonApi = useCommonApi();

  const { showLoader, hideLoader } = useAppLoader();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm();

  const requiredDocs = isBankingOnlyMode? BANKING_DOCS : requiredDocsMap[type];

  const [files, setFiles] = useState<any>([]);
  const [documentOptions, setDocumentOptions] = useState<SelectOption[]>([]);
  const getAttachment = (code: number) =>
    files.find(
      (file: Record<string, unknown>) =>
        file.DocumentTypeId === code && file.FileContent
    );

  const checkIsSelected = (name: string) => {
    return new Promise((resolve: any, reject: any) => {
      files.map((file: any) => {
        if (file.FileName == name) {
          reject();
        }
      });
      resolve();
    });
  };

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const getDocTypes = () => {
    commonApi.post(
      {
        entityName: 'Picklist',
        requestName: 'RetrieveSelectOptionExecuteRequest',
        inputParamters: {
          SelectOption: {
            OptionText: 'DocTypes',
          },
          Entity: 'AccomodationProviderDocs',
        },
      },
      (message: string, resp: any) => {
        const options = resp.outputParameters.data.map((doc: any) => {
          return {
            value: doc.optionValue,
            label: doc.optionText,
          };
        });
        setDocumentOptions(
          options.filter((option: SelectOption) =>
            requiredDocs.includes(option.value)
          )
        );
      },
      (message: string) => {}
    );
  };

  useEffect(() => {
    getDocTypes();
  }, []);

  const onSubmit = () => {
    onSubmitDocuments(
      files.map((f: any) => {
        return {
          FileName: f.Name,
          DocumentTypeId: f.DocumentTypeId,
          FileContent: f.FileContent,
          FileExtention: f.FileExtention,
          EntityDocumentId: f.SupplierDocLibId,
        };
      })
    );
  };

  const getPrevData = () => {
    if (userDetails?.supplierId) {
      showLoader();
      //call api reset form values via reset()
      commonApi.retreive(
        {
          Entity: 'SupplierDocLib',
          Top: 100,
          ReturnCols: [
            'SupplierDocLibId',
            'Name',
            'DocumentTypeId',
            'FileExtention',
            'FileContent',
          ],
          FilterOn: {
            Type: 'And',
            Where: [
              {
                filterCol: 'SupplierId',
                FilterCondition: 'Equals',
                FilterValue: userDetails.supplierId,
              },
            ],
          },
        },
        (message: string, resp: any) => {
          hideLoader();
          const prevData = JSON.parse(resp);

          const _files = requiredDocs.map((code) => {
            return prevData.find(
              (file: Record<string, number>) => file.DocumentTypeId === code
            );
          }).filter((file) => Boolean(file));
          setFiles(_files);
        },
        (message: string) => {
          hideLoader();
          toast.error(message);
        }
      );
    }
    if (userDetails?.relatedObjectIdObjectTypeCode == 'Employee') {
      showLoader();
      //call api reset form values via reset()
      commonApi.retreive(
        {
          Entity: 'EmployeeDocLib',
          Top: 100,
          ReturnCols: [
            'EmployeeDocLibId',
            'Name',
            'DocumentTypeId',
            'FileExtention',
            'FileContent',
          ],
          FilterOn: {
            Type: 'And',
            Where: [
              {
                filterCol: 'EmployeeId',
                FilterCondition: 'Equals',
                FilterValue: userDetails.recordId,
              },
            ],
          },
        },
        (message: string, resp: any) => {
          hideLoader();
          const prevData = JSON.parse(resp);

          const _files = requiredDocs.map((code) => {
            return prevData.find(
              (file: Record<string, number>) => file.DocumentTypeId === code
            );
          }).filter((file) => Boolean(file));
          setFiles(_files);
        },
        (message: string) => {
          hideLoader();
          toast.error(message);
        }
      );
    }
  };

  useEffect(() => {
    if (userDetails) {
      getPrevData();
    }
  }, [userDetails]);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tab_scrollcontent mb-2">
          {documentOptions.map((doc, i: number) => {
            const _file = getAttachment(doc.value);
            const hasFile = Boolean(_file);
            return (
              <div key={i}>
                <div>
                  <FormLabel>{doc.label}</FormLabel>
                </div>
                <div className="doc-file mb-2" key={i}>
                  {hasFile && (
                    <>
                      {hasFile && <FaDownload className="preview-me" onClick={makePreviewFileHandler(_file.FileContent, _file.Name, _file.FileExtention)}  />}
                      <FaTimes
                        className="delete-me"
                        onClick={() => {
                          setFiles((curr: any) =>
                            curr.map((f: any) => {
                              if (f.DocumentTypeId === doc.value) {
                                return {
                                  ...f,
                                  FileContent: null,
                                };
                              }
                              return f;
                            })
                          );
                        }}
                      />
                    </>
                  )}
                  <input
                    style={{}}
                    
                    type={hasFile ? 'text' : 'file'}
                    className="form-control custom-file-input"
                    disabled={hasFile}
                    id="exampleFormControlInput3"
                    placeholder={`${_file?.Name || doc.label}`}
                    onChange={(e) => {
                      const file = e.target?.files?.[0];
                      if (!file) {
                        return;
                      }
                      if (file.size <= 2 * 1024 * 1024) {
                        toBase64(file).then((b64: any) => {
                          setFiles((_files: any) => {
                            const oldVersion = _files.find(
                              (f: any) => f.DocumentTypeId === doc.value
                            );
                            const updatedFiles = oldVersion
                              ? _files.filter(
                                  (f: any) => f.DocumentTypeId !== doc.value
                                )
                              : _files;
                            return [
                              ...updatedFiles,
                              {
                                Name: file.name,
                                FileExtention: file.name.split('.').pop(),
                                DocumentTypeId: doc.value,
                                FileContent: b64.split(',')[1],
                                SupplierDocLibId: oldVersion?.SupplierDocLibId,
                              },
                            ];
                          });
                        });
                      } else {
                        toast.error('File size should be less 2MB');
                      }
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <Row className="align-items-center mb-5">
          <Col md={6}>
            <div className="mobbottom_fixbtn">
              <Button variant="primary" className="w-50 mt-2" type="submit">
                Update
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
}
