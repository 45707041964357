import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import { useEffect, useState } from "react";
import PageActionsBar from "../../PageActionsBar";
import SectionHeader from "../../SectionHeader";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import FileUploadInput from "../../../../../../pages/student/components/FileUploadInput";
import { IApiResponse } from "../../../../types/IApiResponse";
import {
  EXECUTE_REQUEST_PATH,
  UploadDocumentType,
} from "../../../../constants";
import { API } from "../../../../services/API";
import {
  TDocumentRetrieve,
  TDocumentUpload,
} from "../../../../types/TDocumentUpload";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import { useAppLoader } from "../../../../../../_common/hooks/common/appLoaderHook";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../components/Notify";
import CircleLoader from "../../../../components/CircleLoader";

enum DocumentType {
  idDocument = "idDocument",
  cipcDocuments = "cipcDocuments",
  proofOfAddress = "proofOfAddress",
  proofOfBanking = "proofOfBanking",
}

type TDocumentsForm = {
  idDocument: TDocumentUpload;
  cipcDocuments: TDocumentUpload;
  proofOfAddress: TDocumentUpload;
  proofOfBanking: TDocumentUpload;
};

const fieldNameToDocumentCodeMap: Record<number, DocumentType> = {
  886: DocumentType.idDocument,
  887: DocumentType.cipcDocuments,
  904: DocumentType.proofOfAddress,
  905: DocumentType.proofOfBanking,
};

function ApDocuments() {
  const currentUser = useCurrentUser();
  const { showLoader, hideLoader } = useAppLoader();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { register, setValue, handleSubmit, control, reset } =
    useFormContext<TDocumentsForm>();

  // Store initial form values
  const [initialValues, setInitialValues] = useState<TDocumentsForm | null>(
    null
  );
  const [isEdited, setIsEdited] = useState(false); // Track if form is edited

  // Watch form values for changes
  const formValues = useWatch({ control });

  const getDocuments = async () => {
    try {
      showLoader();
      const response: IApiResponse<{ Documents: TDocumentRetrieve[] }> =
        await API.post(EXECUTE_REQUEST_PATH, {
          entityName: "SupplierDocLib",
          requestName: "RetrieveDocument",
          inputParamters: {
            RelatedRecordId: currentUser?.supplierId,
            Base64: false,
          },
        });

      const documentsDetails = response.outputParameters.Documents;

      response.outputParameters.Documents.forEach((document) => {
        setValue(fieldNameToDocumentCodeMap[document.documentTypeId], {
          FileName: document.fileName,
          DocumentTypeId: document.documentTypeId,
          FileExtention: document.fileExtention,
          FileContent: document.fileContent,
          RecordId: document.recordId, // Save the recordId for updates
        });
      });
    } catch (e) {
      console.error(e);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (currentUser) {
      getDocuments();
    }
  }, [currentUser]);

  // Check if the form has been edited
  useEffect(() => {
    if (initialValues) {
      const isFormEdited =
        JSON.stringify(formValues) !== JSON.stringify(initialValues);
      setIsEdited(isFormEdited); // Set form as edited if values have changed
    }
  }, [formValues, initialValues]);

  const checkForDuplicateFiles = (data: TDocumentsForm): boolean => {
    const fileNames = [
      data.idDocument?.FileName,
      data.cipcDocuments?.FileName,
      data.proofOfAddress?.FileName,
      data.proofOfBanking?.FileName,
    ].filter((name) => !!name);

    const uniqueFileNames = new Set(fileNames);

    if (fileNames.length !== uniqueFileNames.size) {
      showErrorAlert(
        "You cannot upload the same file for different document types."
      );
      return true;
    }

    return false;
  };

  const onSubmit = async (data: TDocumentsForm) => {
    if (checkForDuplicateFiles(data)) {
      return;
    }
    try {
      if (!currentUser) {
        return;
      }
      setIsSubmitting(true);

      // Filter documents to include only those with content, and remove RecordId for new documents
      const documentsToSubmit = [
        data.idDocument,
        data.cipcDocuments,
        data.proofOfAddress,
        data.proofOfBanking,
      ]
        .filter((doc) => !!doc && !!doc.FileContent) // Only include documents with file content
        .map((doc) => ({
          ...doc,
          RecordId: doc.RecordId || undefined, // Remove RecordId for new documents
        }));

      // We make an API call for each individual page because a user could land on the page/tab
      // directly via URL. In future we will migrate to Redux ToolKit where caching and state would be handled
      const response = await API.post(EXECUTE_REQUEST_PATH, {
        entityName: "Supplier",
        recordId: currentUser.supplierId,
        requestName: "CreateDocumentExecuteRequest",
        inputParamters: {
          Documents: documentsToSubmit.map((doc) => {
            // If the RecordId exists, keep it. If not, remove the field from the request.
            const { RecordId, ...rest } = doc;
            return RecordId ? { EntityDocumentId: RecordId, ...rest } : rest;
          }),
        },

        // inputParamters: {
        //   Documents: [
        //     data.cipcDocuments,
        //     data.idDocument,
        //     data.proofOfAddress,
        //     data.proofOfBanking,
        //   ].filter((_) => !!_),
        // },
      });
      if (!response.isSuccess) {
        throw new Error("An error occurred while updating your details");
      }

      showSuccessAlert("Profile updated successfully");
      setInitialValues(data); // Reset initial values after successful save
      setIsEdited(false); // Mark form as not edited
    } catch (e) {
      showErrorAlert("An error occurred while updating your details");
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleOnSaveClick = () => handleSubmit(onSubmit)();

  const handleOnCancelClick = () => {
    if (initialValues) {
      reset(initialValues); // Reset form to the initial values
      setIsEdited(false); // Mark form as not edited
    }
  };

  return (
    <>
      <PageActionsBar
        title="Documents"
        subtitle="View your supporting documents here."
        onSaveClick={handleOnSaveClick}
        disabled={isSubmitting}
      />
      <Grid item xs={12} py={2}>
        <Divider />
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} md={4}>
          <SectionHeader
            title="Supporting documents"
            subtitle="These will not be displayed on your profile."
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          display="flex"
          flexDirection="column"
          rowGap={2}
        >
          <Controller
            {...register(DocumentType.idDocument, {
              required: true,
              disabled: isSubmitting,
            })}
            render={({ field }) => {
              return (
                <FileUploadInput
                  label="ID Document"
                  selectFileText="Upload file"
                  documentTypeId={UploadDocumentType.IDDocument}
                  {...field}
                />
              );
            }}
          />

          <Controller
            {...register(DocumentType.cipcDocuments, {
              required: true,
              disabled: isSubmitting,
            })}
            render={({ field }) => (
              <FileUploadInput
                label="CIPC Documents"
                selectFileText="Upload file"
                documentTypeId={UploadDocumentType.CIPCDocument}
                {...field}
              />
            )}
          />

          <Controller
            {...register(DocumentType.proofOfAddress, {
              required: true,
              disabled: isSubmitting,
            })}
            render={({ field }) => (
              <FileUploadInput
                label="Proof of Address"
                selectFileText="Upload file"
                documentTypeId={UploadDocumentType.ProofOfAddress}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} py={2}>
        <Divider />
      </Grid>
      <Grid item xs={12} py={2}>
        <Divider />
      </Grid>
      <Grid textAlign="right" item xs={12}>
        <Box display="inline-flex" gap={1}>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            disabled={!isEdited || isSubmitting} // Enable Cancel only if the form is edited
            onClick={handleOnCancelClick}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            type="button"
            color="primary"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircleLoader /> : undefined}
            onClick={handleOnSaveClick}
          >
            Save
          </Button>
        </Box>
      </Grid>
    </>
  );
}

export default ApDocuments;
