import { Box, Button, Grid, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";

import "@react-pdf-viewer/core/lib/styles/index.css";
import { EMPTY_ARRAY, EXECUTE_REQUEST_PATH } from "../../../constants";
import LegacyPageLayout from "../../../../../components/LegacyPageLayout";
import PageHeaderTexts from "../../../components/GenericListingScreen/PageHeaderTexts";
import { IApiResponse } from "../../../types/IApiResponse";
import { API } from "../../../services/API";
import useSearchAndFiltersBar from "../../../components/GenericListingScreen/useSearchAndFiltersBar";
import { ThemeColors } from "../../../styles/theme";
import useCurrentUser from "../../../hooks/useCurrentUser";
import StatusPill from "../../../components/StatusPill";
import PopupState, { bindMenu, bindToggle } from "material-ui-popup-state";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../components/Notify";
import { URLS } from "../../../../../_config";
import ListingGrid from "../../../components/GenericListingScreen/ListingGrid";
import { TListingGridProps } from "../../../components/GenericListingScreen/ListingGrid/types";
import useApiFilters from "../../../components/GenericListingScreen/useApiFilters";
import useFetchListingFilters from "../../../components/GenericListingScreen/useFetchListingFilters";
import { TTenantListingResponse } from "./types";

type TProperty = {
  Name: string;
  FacilityId: string;
};

const getStatusStyle = (status: string) => {
  if (!status) {
    return "success";
  }
  switch (status.toLowerCase()) {
    case "active":
      return "success";
    case "terminated":
      return "error";
    case "lapsed":
      return "error";
    default:
      return "success";
  }
};

const COLUMNS: TListingGridProps["columns"] = [
  {
    field: "tenant",
    headerName: "Tenant",
    flex: 1,
    minWidth: 180,
  },
  {
    field: "facility",
    headerName: "Facility",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    minWidth: 180,
  },
  {
    field: "phone",
    headerName: "Phone Number",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "room",
    headerName: "Room Number",
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    minWidth: 130,
    renderCell: ({ value }) => {
      return (
        value && (
          <StatusPill variant={getStatusStyle(value)}>{value}</StatusPill>
        )
      );
    },
  },
  {
    flex: 1,
    field: "actions",
    headerName: "Actions",
    renderCell: ({ value: Component }) => Component,
  },
];

const TenantManagement = () => {
  const currentUser = useCurrentUser();

  const listingFilters = useFetchListingFilters({
    pageRoute: "/ap/tenants",
    pageName: "Tenant Management",
  });

  const { bulkActionsFilter, generalFilters, extractFiltersForPosting } =
    useApiFilters(listingFilters);

  const {
    searchAndFiltersBarComponent,
    searchedTextBannerComponent,
    activeFilters,
    searchedText,
  } = useSearchAndFiltersBar({
    filters: generalFilters.map((item) => ({
      type: "dropdown",
      value: item.id,
      name: item.label,
      multiple: false,
      options: item.options,
      initialValue: " ",
    })),
    onSearch: (searchInput) => {},
  });

  const handleViewLeaseClick = (url: string, menuClose: Function) => {
    return () => {
      window.open(url, "_blank");
      menuClose();
    };
  };

  const handleRenewClick =
    (tenantId: string, menuClose: Function) => async () => {
      try {
        menuClose?.();
        const res: IApiResponse<unknown> = await API.post(
          EXECUTE_REQUEST_PATH,
          {
            entityName: "Tenant",
            requestName: "RenewTenant",
            inputParamters: {
              Action: "Single",
              TenantIds: tenantId,
            },
          }
        );

        if (res.isSuccess) {
          showSuccessAlert(res.clientMessage).then(() => {
            // TODO: getApiData(currentPage, activeFilters)
          });
        } else {
          showErrorAlert(res.clientMessage);
        }
      } catch (e) {
        console.error(e);
      }
    };

  const navigate = useNavigate();

  const handleTerminateClick = (tenantId: string) => () => {
    navigate(URLS.CREATE_CASE_TEMPORARY, {
      state: { tenantId, type: "Termination of lease" },
    });
  };

  const dataFetchFn: TListingGridProps["dataFetchFn"] = async (params) => {
    try {
      const {
        bulkActionFilter: _bulkActionFilter,
        generalFilters: _generalFilters,
      } = extractFiltersForPosting(
        params.bulkActionFilter,
        params.activeFilters
      );

      const response: IApiResponse<TTenantListingResponse> = await API.post(
        EXECUTE_REQUEST_PATH,
        {
          entityName: "Tenant",
          requestName: "TenantListingReq", // TenantRenewalListReq
          inputParamters: {
            AccommodationProviderId: currentUser?.supplierId,
            SearchText: params.searchedText,
            PageNumber: params.page + 1,
            PageSize: params.pageSize,
            Filters: _bulkActionFilter
              ? [_bulkActionFilter, ..._generalFilters]
              : _generalFilters,
          },
        }
      );
      return {
        data: response.outputParameters.Listing.map((entry) => {
          return {
            id: entry.tenantId,
            tenant: entry.studentIdName,
            facility: entry.facilityIdName,
            email: entry.studentEmail,
            phone: entry.mobile,
            room: entry.roomNumber,
            status: entry.leaseStatus,
            actions: (
              <div>
                <PopupState variant="popper" popupId="demo-popup-popper">
                  {(popupState) => (
                    <>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{ height: 32, minHeight: 0 }}
                        {...bindToggle(popupState)}
                      >
                        Actions
                      </Button>
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem
                          onClick={handleViewLeaseClick(
                            entry.leaseUrl,
                            popupState.close
                          )}
                        >
                          View Lease
                        </MenuItem>
                        <MenuItem
                          onClick={handleRenewClick(
                            entry.tenantId,
                            popupState.close
                          )}
                        >
                          Renew
                        </MenuItem>
                        <MenuItem
                          onClick={handleTerminateClick(entry.tenantId)}
                        >
                          Terminate
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </PopupState>
              </div>
            ),
          };
        }),
        totalCount: response.outputParameters.RecordCount,
        hasError: false,
      };
    } catch (e) {
      console.error(e);
      return {
        hasError: true,
      };
    }
  };
  return (
    <LegacyPageLayout
      bgColor={ThemeColors.bgPrimary}
      padding={3}
      paddingTop={0}
      breadcrumbData={[{ label: "Home", path: "/" }, { label: "Leases" }]}
    >
      <Box sx={{ mt: 1, pt: 2 }}>{searchAndFiltersBarComponent}</Box>

      <Grid item xs={12} sx={{ my: 6 }}>
        <PageHeaderTexts
          heading="Tenant Management"
          subHeading={`View all leases for your selected properties here`}
        />
      </Grid>

      {searchedTextBannerComponent}

      {(bulkActionsFilter || bulkActionsFilter === null) && (
        <ListingGrid
          title="Leases"
          columns={COLUMNS}
          dataFetchFn={dataFetchFn}
          searchedText={searchedText}
          activeFilters={activeFilters}
          bulkActionsFilter={bulkActionsFilter}
          onBulkAction={async (
            requestName,
            values,
            bulkFilter,
            otherFilters
          ) => {
            const {
              bulkActionFilter: _bulkActionFilter,
              generalFilters: _generalFilters,
            } = extractFiltersForPosting(bulkFilter, otherFilters);
            const res: IApiResponse<unknown> = await API.post(
              EXECUTE_REQUEST_PATH,
              {
                entityName: "Tenant",
                requestName,
                inputParamters: {
                  Action: values === "ALL" ? "All" : "Bulk",
                  TenantIds: values === "ALL" ? EMPTY_ARRAY : values,
                  AccommodationProviderId: currentUser?.supplierId,
                  SearchText: searchedText,
                  Filters: _bulkActionFilter
                    ? [_bulkActionFilter, ..._generalFilters]
                    : _generalFilters,
                },
              }
            );

            if (res.isSuccess) {
              showSuccessAlert(res.clientMessage).then(() => {
                //  TODO: getApiData(currentPage, activeFilters)
              });
            } else {
              showErrorAlert(res.clientMessage);
            }
          }}
        />
      )}
    </LegacyPageLayout>
  );
};

export default TenantManagement;
