import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { MdAddCircle } from 'react-icons/md';
import Header from '../components/header';
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import { useResidenceApi } from "../_common/hooks/api/residenceApiHook";
import { APP_URL, URLS } from "../_config";

export default function MakePayment() {
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useAppLoader();
    const commonApi = useCommonApi();
    const [payFastData, setPayFastData] = useState<any>(null);
    const payFastForm = useRef<any>();
    const { id } = useParams();

    const makePayment = () => {
        // console.log({recordId}) 
        // return;
        showLoader();
        commonApi.post({
            "entityName": "Facility",
            "recordId": id,
            "requestName": "MakePaymentReq"
        }
            , (message: string, resp: any) => {
                sessionStorage.setItem('residenceID',id ? id : '')
                setPayFastData(resp.outputParameters)
                // callPayFastApi(resp.outputParameters)
                hideLoader();
                //  navigate('/')
            }, (message: string) => {
                hideLoader();
                // toast.error(message);
                // window.parent.postMessage("invalid recordId", window.parent.location.origin)
                if ((window as any).messageHandler) {
                    (window as any).messageHandler.postMessage("invalid recordId")
                }
            });
    }


    useEffect(() => {
        if (id == null) {
            navigate('/');
        }
        else {
            makePayment()
        }
    }, [id])

    useEffect(() => {
        if (payFastData) {
            payFastForm.current.submit();
        }
    }, [payFastData])

    return (
        <React.Fragment>
            <div>
                {payFastData ?
                    <form action={payFastData.action} ref={payFastForm} method="post">
                        <input type="hidden" name="merchant_id" value={payFastData.merchant_id} />
                        <input type="hidden" name="merchant_key" value={payFastData.merchant_key} />
                        <input type="hidden" name="amount" value={payFastData.amount} />
                        <input type="hidden" name="item_name" value={payFastData.item_name} />
                        <input type="hidden" name="return_url" value={APP_URL + URLS.SUCCESS_PAGE + '?amount=' + payFastData.amount} />
                        <input type="hidden" name="cancel_url" value={APP_URL + URLS.CANCEL_PAGE + '?amount=' + payFastData.amount} />
                        <input type="hidden" name="notify_url" value={APP_URL + URLS.NOTIFY_PAGE + '?amount=' + payFastData.amount} />
                        <input type="hidden" name="name_first" value={payFastData.name_first} />
                        <input type="hidden" name="name_last" value={payFastData.name_last} />
                        <input type="hidden" name="email_address" value={payFastData.email_address} />
                        <input type="hidden" name="cell_number" value={payFastData.cell_number} />
                        <input type="hidden" name="m_payment_id" value={payFastData.m_payment_id} />
                        <input type="hidden" name="item_description" value={payFastData.item_description} />
                        <input type="submit" hidden />
                    </form>
                    : null
                }
            </div>
        </React.Fragment>
    );
}