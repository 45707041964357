import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { CheckNumber } from "../_common/functions";
import Header from "./student/components/header";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import { useResidenceApi } from "../_common/hooks/api/residenceApiHook";
import { INPUT_MAX_LENGTHS, URLS } from "../_config";
import LeftPanel from "../components/leftPanel";
import { DevTool } from "@hookform/devtools";
import Address from "../components/global/address";
import LegacyPageLayout from "../components/LegacyPageLayout";

interface InstitutionType {
  InstitutionId: string;
  InstitutionIdName: string;
}

export default function AddResidence() {
  const user: any = useUserSelector();
  const commonApi = useCommonApi();
  const residenceApi = useResidenceApi();
  const navigate: any = useNavigate();
  const { showLoader, hideLoader } = useAppLoader();
  const [provinces, setProvinces] = useState<any>(null);
  const [institutions, setInstitutions] = useState<any>([]);
  const [campuses, setCampuses] = useState<any>(null);
  const [setPrevCampus, set_setPrevCampus] = useState<any>(null);

  const {
    register,
    trigger,
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
    getValues,
    watch,
    reset,
  } = useForm({ mode: "onChange" });

  const campus = watch("campus");
  const province = watch("Province");
  const institution = watch("institution");

  useEffect(() => {
    if (province) {
      setValue("institution", "");
      getCampus();
    }
  }, [province]);

  useEffect(() => {
    if (campuses) {
      if (setPrevCampus) {
        let opt: any = getSelectDataById(setPrevCampus, campuses);
        set_setPrevCampus(null);
        reset({
          ...getValues(),
          campus: opt,
        });
      }
    }
  }, [campuses]);

  useEffect(() => {
    if (campus) {
      if (!campus.recordId) {
        return;
      }
      commonApi.getCampusInstitution(
        { CampusId: campus.recordId },
        (message: string, resp: any) => {
          setValue("institution", resp?.[0] || {});
        },
        (message: string) => {
          console.log(message);
        }
      );
    }
  }, [campus]);

  useEffect(() => {
    getProvinces();
  }, []);

  useEffect(() => {
    if (provinces && institutions) {
      getPrevData();
    }
  }, [provinces, institutions]);

  const getProvinces = () => {
    //Filter Fix
    const provincesToExclude = [
      "Limpopo",
      "Gauteng",
      "Free State",
      "Western Cape",
      "Northern Cape",
      "North West",
      "Eastern Cape ",
    ];

    commonApi.post(
      {
        entityName: "Picklist",
        requestName: "RetrieveSelectOptionExecuteRequest",
        inputParamters: {
          SelectOption: {
            OptionText: "Province",
          },
        },
      },
      (message: string, resp: any) => {
        if (resp.outputParameters.Count > 0) {
          // Filter provinces here to exclude 'Limpopo'
          const filteredProvinces = resp.outputParameters.data.filter(
            (data: any) => !provincesToExclude.includes(data.optionText)
          );

          setProvinces(
            filteredProvinces.map((data: any) => ({
              label: data.optionText,
              value: data.optionValue,
            }))
          );
        }
      },
      (message: string) => {
        console.log(message);
      }
    );
  };

  const getCampus = (id: any = province?.value) => {
    return new Promise((resolve: any, reject: any) => {
      commonApi.getCampus(
        {
          filters: "ProvinceId eq '" + id + "'",
          ViewName: "LookUpView",
        },
        (message: string, resp: any) => {
          let options: any = [];
          resp.map((ins: any) => {
            if (ins.recordId.search("000000") !== 0) {
              ins.value = ins.recordId;
              ins.label = ins.mainText;
              options.push(ins);
            }
          });
          setCampuses(options);
          setTimeout(() => {
            resolve();
          }, 300);
        },
        (message: string) => {
          // console.log(message);
          reject();
        }
      );
    });
  };

  const onSubmit = (data: any) => {
    let params: any = {
      Name: data.Name,
      ProvinceId: data.Province.value,
      InstitutionId: data.institution.InstitutionId,
      CampusId: data.campus.value,
      KMToCampus: data.KMToCampus,
      AccomodationProviderId: user?.supplierId,
      Address: data.Address,
      FacilityStatusId: 872,
    };
    let residenceId = sessionStorage.getItem("residenceID");
    if (residenceId) {
      // console.log("if")
      showLoader();
      commonApi.post(
        {
          entityName: "Facility",
          requestName: "UpsertRecordReq",
          recordId: residenceId,
          inputParamters: { Entity: params },
        },
        (message: string, resp: any) => {
          hideLoader();
          sessionStorage.setItem(
            "residenceID",
            resp?.outputParameters?.UpsertResponse.recordId
          );
          sessionStorage.setItem("residenceName", data?.Name);
          getResidenceRooms(residenceId);
          // navigate('/add-room')
        },
        (message: string) => {
          hideLoader();
        }
      );
    } else {
      showLoader();
      commonApi.post(
        {
          entityName: "Facility",
          requestName: "UpsertRecordReq",
          inputParamters: { Entity: params },
        },
        (message: string, resp: any) => {
          hideLoader();
          sessionStorage.setItem(
            "residenceID",
            resp.outputParameters.UpsertResponse.recordId
          );
          sessionStorage.setItem("residenceName", data.Name);
          navigate("/add-room");
        },
        (message: string) => {
          hideLoader();
          toast.error(message);
        }
      );
    }
  };

  const getPrevData = () => {
    let residenceId = sessionStorage.getItem("residenceID");
    if (residenceId) {
      showLoader();
      commonApi.retreive(
        {
          Entity: "Facility",
          Top: 100,
          ReturnCols: [
            "FacilityId",
            "Name",
            "ProvinceId",
            "InstitutionId",
            "CampusId",
            "KMToCampus",
            "Address",
          ],
          FilterOn: {
            Type: "And",
            Where: [
              {
                filterCol: "FacilityId",
                FilterCondition: "Equals",
                FilterValue: residenceId,
              },
            ],
          },
        },
        (message: string, resp: any) => {
          hideLoader();
          let prevData = JSON.parse(resp)[0];
          set_setPrevCampus(prevData.CampusId);
          reset({
            Name: prevData.Name,
            Province: getSelectDataById(prevData.ProvinceId, provinces),
            institution: getSelectDataById(
              prevData.InstitutionId,
              institutions
            ),
            KMToCampus: prevData.KMToCampus,
            Address: prevData.Address,
          });
        },
        (message: string) => {
          hideLoader();
          toast.error(message);
        }
      );
    } else {
    }
  };

  const getSelectDataById = (id: any, data: any) => {
    let option = null;
    data?.map((_option: any) => {
      if (_option.value == id) {
        option = _option;
      }
    });
    return option;
  };

  const getResidenceRooms = (residenceId: any) => {
    residenceApi.getResidenceRooms(
      {
        $filter: "FacilityId eq '" + residenceId + "'",
        entity: "Capacity",
        $select:
          "CapacityId, Name, Quantity, GenderId, PricePerBed, DisabilityAccessId, CateringId",
      },
      (message: string, resp: any) => {
        if (resp[0].data && resp[0].data.length > 0) {
          navigate(URLS.RESIDENCE_SUMMERY);
        } else {
          navigate(URLS.ADD_ROOM);
        }
      },
      (message: string) => {
        console.log(message);
      }
    );
  };

  return (
    <LegacyPageLayout>
      <section className="container">
        <div className="custome-container-inner d-flex">
          <LeftPanel page={1} />
          <div className="white-block p-3">
            <div className="row">
              <div className="col">
                <h1>Add Residence</h1>
              </div>
            </div>
            <hr />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="tab_scrollcontent">
                <div className="mb-2">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Name of Property
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder=""
                    {...register("Name", { required: true, maxLength: 80 })}
                  />
                </div>

                <div className="mb-2">
                  <label className="form-label">Province</label>
                  <Controller
                    name={"Province"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Select
                          className={
                            "form-control p-0" +
                            (errors?.Province ? " is-invalid" : "")
                          }
                          id="province"
                          options={provinces}
                          placeholder={""}
                          onChange={onChange}
                          value={value}
                          defaultValue={value}
                        />
                      );
                    }}
                  />
                </div>

                <div className="mb-2">
                  <label className="form-label">
                    Target Campus
                    {!province && (
                      <>
                        <br />
                        <sub>
                          Select a province before you can select a campus
                        </sub>
                      </>
                    )}
                  </label>
                  {/* TODO: Match figma Icon */}
                  <Controller
                    name={"campus"}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => {
                      return (
                        <Select
                          id="campus"
                          className={
                            "form-control p-0" +
                            (errors?.campus ? " is-invalid" : "")
                          }
                          isDisabled={!province}
                          options={campuses}
                          placeholder={""}
                          onChange={onChange}
                          value={value}
                          defaultValue={value}
                        />
                      );
                    }}
                  />
                </div>

                <div className="mb-2">
                  <label className="form-label">Target Institution</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    readOnly
                    value={institution?.InstitutionIdName}
                    {...register("institution")}
                  />
                </div>

                <div className="mb-2">
                  <label
                    htmlFor="exampleFormControlInput5"
                    className="form-label"
                  >
                    KM From Campus
                  </label>
                  <input
                    type="number"
                    step="0.1"
                    className="form-control"
                    id="exampleFormControlInput5"
                    placeholder=""
                    {...register("KMToCampus", { required: true })}
                    max={INPUT_MAX_LENGTHS.kmFromCampus}
                    onChange={CheckNumber}
                  />
                </div>
                <div className="mb-2" key={getValues()?.Address} id="address">
                  <label
                    htmlFor="exampleFormControlInput6"
                    className="form-label"
                  >
                    Address
                  </label>
                  <Address
                    name="Address"
                    required={true}
                    trigger={trigger}
                    setValue={setValue}
                    initialAdress={getValues()?.Address}
                    onAddressDataChange={(values) =>
                      console.log("values", values)
                    }
                    control={control}
                  />
                </div>
              </div>
              <div className="">
                <button
                  type="submit"
                  className="btn btn-primary w-100 mt-2"
                  disabled={!isValid}
                  data-bs-toggle="button"
                >
                  Next
                </button>
                <Link to="/">
                  <button
                    type="button"
                    className="btn btn-outline-secondary mt-2 w-100 back-btn-add-prop"
                    data-bs-toggle="button"
                    style={{
                      backgroundColor: "#fff",
                    }}
                  >
                    Back
                  </button>
                </Link>
              </div>
            </form>
            <DevTool control={control} />
          </div>
        </div>
      </section>
    </LegacyPageLayout>
  );
}
