import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useResidenceApi } from '../_common/hooks/api/residenceApiHook';
import { URLS } from '../_config';

import '../styles/left-panel.scss';
import { css } from '@emotion/css';

interface incomingProps {
    page: number;
    lockToPage?: boolean;
}

const LeftPanel = ({ page, lockToPage }: incomingProps) => {

    const navigate = useNavigate();
    const residenceApi = useResidenceApi();
    let residenceId = sessionStorage.getItem('residenceID');

    const [rooms, setRooms] = useState<any>(null);

    const getResidenceRooms = () => {
        residenceApi.getResidenceRooms({
            $filter: "FacilityId eq '" + residenceId + "'",
            "entity": "Capacity",
            "$select": 'CapacityId, Name, Quantity, GenderId, PricePerBed, DisabilityAccessId, CateringId, AmenityId, AmenityTypeId',
        }, (message: string, resp: any) => {
            let data: any = [];

            resp[0].data.map((room: any) => {
                if (room.AmenityTypeId == 859) {
                    data.push(room)
                }
            })
            setRooms(data);
        }, (message: string) => {
        });
    }

    const handleClick = (params: string) => {
        if(lockToPage){
            return;
        }
        if (residenceId) {
            if (params === 'residence') {
                navigate(URLS.ADD_RESIDENCE)
            }
            else if (params === 'room') {
                if (rooms.length === 0) {
                    navigate(URLS.ADD_ROOM)
                }
                else {
                    navigate(URLS.RESIDENCE_SUMMERY)
                }
            }
            else if (params === 'amenity') {
                navigate(URLS.DESCRIBE_ROOMS)
            }
            else if (params === 'document') {
                navigate(URLS.UPLOAD_PROPERTY_IMAGES)
            }
            else if (params === 'payments') {
                navigate(URLS.PAYMENT_INFO)
            }
        }
    }

    useEffect(() => {
        getResidenceRooms()
    }, [])

    const defaultNavClass = lockToPage ? css({color: '#aaa !important', '& h4, & p': {color: '#aaa !important'}}) : '';

    return (
        <div className="fixed-left-nav-panel">
            <ul>
                <li className={page == 1 ? 'active' : defaultNavClass} onClick={() => handleClick('residence')}>
                    <div>
                        <h4>Residence details</h4>
                        <p>Add details of your residence</p>
                    </div>
                    <span>
                        <i className="fa fa-home" aria-hidden="true" />
                    </span>
                </li>
                <li className={page == 2 ? 'active' : defaultNavClass} onClick={() => handleClick('room')}>
                    <div>
                        <h4>Room details</h4>
                        <p>Add details of the rooms/beds in your residence</p>
                    </div>
                    <span>
                        <i className="fa fa-bed" aria-hidden="true" />
                    </span>
                </li>
                <li className={page == 3 ? 'active' : defaultNavClass} onClick={() => handleClick('amenity')}>
                    <div>
                        <h4>Amenity details</h4>
                        <p>Further describe your residence</p>
                    </div>
                    <span>
                        <i className="fa fa-home" aria-hidden="true" />
                    </span>
                </li>
                <li className={page == 4 ? 'active' : defaultNavClass} onClick={() => handleClick('document')}>
                    <div>
                        <h4>Documents upload</h4>
                        <p>Upload supporting documents</p>
                    </div>
                    <span>
                        <i className="fa fa-folder" aria-hidden="true" />
                    </span>
                </li>
                <li className={page == 5 ? 'active' : defaultNavClass} onClick={() => handleClick('payments')}>
                    <div>
                        <h4>Payments</h4>
                        <p>Finalise the process by making payment</p>
                    </div>
                    <span>
                        <i className="fa fa-money" aria-hidden="true" />
                    </span>
                </li>
            </ul>
        </div>

    )
}

export default LeftPanel