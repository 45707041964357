import React, { useState, useEffect } from "react";
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Modal } from 'react-bootstrap';
import Header from '../components/header';
import ApproveModal from '../components/ApproveModal';
import { useParams } from 'react-router-dom';
import { useCommonApi } from '../_common/hooks/api/common/commonApiHook';
import { useUserSelector } from '../_common/hooks/selectors/userSelector';
import toast from 'react-hot-toast';
import userCheckIcon from '../images/user-check-01.svg';
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import noApplicationImg from "../images/empty-2.jpg"

interface FacilityApplication {
  id: string;
  CampusIdName: string;
  InstitutionIdName: string;
  StudentName: string;
  AccomodationApplicationsId: string;
  Gender: string;
  Status: string;
  OptionText:string;
  APSigningURL:string;
  facilityId: string;
}

interface ResidenceApplicationsProps {
  // You can add any other props if needed
}

const ResidenceApplications: React.FC<ResidenceApplicationsProps> = (props) => {
  const { id } = useParams<{ id: string }>();
  const commonApi = useCommonApi();
  const user: any = useUserSelector();
  const [{show, facilityId, selectedApplicationId}, setModalState] = useState<{
    show: boolean;
    facilityId?: string;
    selectedApplicationId?:string;
  }>({
    show: false, 
  });
  const [facilityApplications, setFacilityApplication] = useState<FacilityApplication[]>([]);
  const [roomTypes, setRoomTypes] = useState<any[]>([]);
  const { showLoader, hideLoader } = useAppLoader();
  const [studentStatus,setStudentStatus] = useState<any>();


  useEffect(() => {
    getFacilityApplications();
    getApplicationRoomTypes();
  }, []);

  const handleClose = () => {
    setModalState({show: false});
  };

  const handleShow = (applicationId: string, facilityId: string) => {
    setModalState({show: true, facilityId, selectedApplicationId: applicationId});
  };

  const getFacilityApplications = () => {
    commonApi.getFacilityApplication({
      FacilityId: id
    }, (message: string, resp: FacilityApplication[]) => {
      console.log("--++",resp);
      resp.map((data)=>{
        setStudentStatus(data.OptionText)
      })

      if (resp.length > 0) {
        
        setFacilityApplication(resp);
      }
    }, (message: string) => {
    });
  };

  const getApplicationRoomTypes = () => {
    commonApi.getApplicationRoomTypes({
      FacilityId: id
    }, (message: string, resp: any) => {
      
      if (resp.length > 0) {
        setRoomTypes(resp);
      }
    }, (message: string) => {
    });
  };

  const rejectApplication = (applicationId: any) => {
    showLoader();

    commonApi.post({
      "entityName": "AccomodationApplications",
      "requestName": "UpsertRecordReq",
      "recordId": applicationId,
      "inputParamters": {
        "Entity": {
          "StatusId": 70,
        }
      }
    }, (message: string, resp: any) => {

      showLoader();
      setTimeout(()=>{
        
        toast.success(message ,{ duration:3000})
        hideLoader()
        window.location.reload()

      },5000)

      // toast.success(message)
      // getFacilityApplications();
    }, (message: string) => {
      toast.error(message);
    });
  };
  
  console.log("stu-status",studentStatus);
  

  return (
    <div style={{ paddingLeft: '2rem', paddingRight: '2rem' }}>

       <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Approval Confirmation</Modal.Title>
        </Modal.Header>

        <Modal.Body>
         {selectedApplicationId && facilityId && <ApproveModal onClose={()=>{}} onApprove={(_)=>{}} applicationId={selectedApplicationId} facilityId={facilityId} />}
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={handleClose} style={{ width: '100%', height: '3.5rem', marginLeft: 'auto', marginRight: 'auto', backgroundColor: '' }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Header />
      <Typography
        sx={{
          marginTop: '6rem',
          color: '#101828',
          fontFamily: 'Inter',
          fontSize: '30px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: '38px',
        }}>
        Student Accommodation Applications
      </Typography>
      <p className='application-sub-heading'>Student Accommodation Applications In Your Property Awaiting Your Approval</p>
      {facilityApplications.length > 0  ? 
        
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{}} >
          <Table sx={{ minWidth: 1050 }} aria-label="simple table" >
            <TableHead
              sx={{
                backgroundColor: '#F9FAFB'
              }}
            >
              <TableRow>
                <TableCell>Campus / Institution</TableCell>
                <TableCell >
                  
                  Funding Status
                 
                  
                </TableCell>
                <TableCell>Student Name</TableCell>
                <TableCell>Gender</TableCell>
                <TableCell>Application Status</TableCell>
                <TableCell align='right'>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {facilityApplications.map((facilityApplication: FacilityApplication) => (
                <TableRow
                  key={facilityApplication.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{facilityApplication.CampusIdName}</TableCell>
                  <TableCell className='table-data' >
                    <div>
                      {facilityApplication.OptionText}
                    </div>
                    {/* <Tooltip title={facilityApplication.OptionText === "Unverified" ? "NSFAS awaiting registration information from institution. You can only approve accommodation once verified. Visit portal" : "NSFAS has verified student. You can now Approve accommodation"}>
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip> */}

                  </TableCell>
                  <TableCell className='table-data'>{facilityApplication.StudentName}</TableCell>
                  <TableCell className='table-data'>{facilityApplication.Gender}</TableCell>
                  <TableCell className='table-data'>{facilityApplication.Status}</TableCell>

                  <TableCell sx={{
                    display: 'flex',
                    gap: '1rem',
                    justifyContent: 'right',
                  }} >
                     <Tooltip title={facilityApplication.OptionText === "Unverified" ? "NSFAS awaiting registration information from institution. You can only approve accommodation once verified." : "NSFAS has verified this student. You can now Approve accommodation"}>
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    {
                      facilityApplication.Status === "Approved" ? 
                        <Button className="ap-sign"  onClick={()=>{window.open(facilityApplication.APSigningURL,)}}>
                           <div className="" style={{display:'flex',gap:'1rem',justifyContent:'center',alignItems:'center'}}>
                              <i className="fa fa-pencil" aria-hidden="true"></i>
                              <div className="">Sign Lease</div>
                          </div>
                        </Button> :
                      <div style={{display:'flex', gap:'.5rem'}}>
                        <Button
                          onClick={() => handleShow(facilityApplication.AccomodationApplicationsId,facilityApplication.facilityId )}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '8px',
                            border: '1px solid #D0D5DD',
                            color: '#344054',
                          }}
                          disabled={(() => {
                            switch (`${facilityApplication.OptionText}-${facilityApplication.Status}`) {
                              case "Unverified-Approved":
                                return true;
                              case "Verified-Pending Approval":
                                return false;
                              case "Verified-Declined":
                                return true;
                              case "Verified-Approved":
                              return true;
                              case "Unverified-Declined":
                              return true;
                              case "Unverified-Pending Approval":
                              return true;
                              default:
                                return false;
                            }
                          })()}
                          
                          >
                          <img src={userCheckIcon} alt="user-check-icon"/>
                          Approve
                        </Button>
                        <Button
                          onClick={() => { rejectApplication(facilityApplication.AccomodationApplicationsId)}}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '8px',
                            border: '1px solid #D0D5DD',
                            color: '#F88A0B',
                            gap: '2rem',
                            
                          }}
                          // disabled={
                          //   (facilityApplication.OptionText === "Unverified" && facilityApplication.Status === "Approved" ?  true : false) ||
                          //   (facilityApplication.OptionText === "Verified" && facilityApplication.Status === "Pending Approval" ?  false : true) ||
                          //   (facilityApplication.OptionText === "Verified" && facilityApplication.Status === "Declined" ?  true : false) ||
                          //   (facilityApplication.OptionText === "Unverified" && facilityApplication.Status === "Approved" ?  true : false) ||
                          //   (facilityApplication.OptionText === "Unverified" && facilityApplication.Status === "Declined" ?  true : false)

                          // }
                          disabled={(() => {
                            switch (`${facilityApplication.OptionText}-${facilityApplication.Status}`) {
                              case "Unverified-Approved":
                                return true;
                              case "Verified-Pending Approval":
                                return false;
                              case "Verified-Declined":
                                return true;
                              case "Verified-Approved":
                              return true;
                              case "Unverified-Declined":
                              return true;
                              case "Unverified-Pending Approval":
                                return true;
                              default:
                                return false;
                            }
                          })()}
                          
                        >
                          Reject
                        </Button>
                       
                    </div>
                    } 
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      :
      <Box style={{display:'flex',justifyContent:'center',alignItems:'center', flexDirection:'column'}}>
        <Typography sx={{fontWeight:'600'}}>No Applications Under This Property</Typography> 
        <img src={noApplicationImg} alt="no-applications-image" width={'500px'} />
      </Box> 
      }

      


    </div>
  );
};

export default ResidenceApplications;
