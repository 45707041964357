import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col, Button } from 'react-bootstrap';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { MdAddCircle } from 'react-icons/md';
import toast from 'react-hot-toast';
import { RequiredAsterisk } from '../blocks/requiredAsterisk';
import { INPUT_MAX_LENGTHS, DIGITS_ONLY_PATTERN } from '../../_config/index';
import { useDocumentTypeModal } from '../../hooks/useDocumentTypeModal';
import { InputField } from '../InputField';
import { SelectOption } from '../../_common/interfaces';
import { useNavigate } from 'react-router-dom';


const REQUIRED_DOCS = [905];
interface incomingProps {
  onSubmitBankingDetails: any;
  tab: any;
  attachments?: boolean;
}

export default function BankDetailsForm({
  onSubmitBankingDetails,
  attachments = true,
}: incomingProps) {
  const commonApi = useCommonApi();

  const [files, setFiles] = useState<any>([]);
  const [documentOptions, setDocumentOptions] = useState<SelectOption[]>([]);
  const getAttachment = (code: number) =>
    files.find((file: Record<string, unknown>) => file.DocumentTypeId === code);
  const {
    modalComponent,
    setOnClickContinue,
    closeModal,
    setSelectedDocumentType,
    showModal,
  } = useDocumentTypeModal({ documentOptions, getAttachment });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const navigate = useNavigate();

  const documents: any = watch('Documents');
  
  useEffect(() => {
    let _files: any = [...files];
  
    const isPDFOrWord = (file: any) => {
      const allowedExtensions = ['pdf', 'doc', 'docx'];
      const extension = file.name.split('.').pop()?.toLowerCase();
      return allowedExtensions.includes(extension);
    };
  
    if (documents?.length) {
      for (let i = 0; i < documents?.length; i++) {
        let file: any = documents[i];
  
        // Check if the file is a PDF or Word document
        if (isPDFOrWord(file)) {
          checkIsSelected(file.name)
            .then(() => {
              if (file.size <= 2 * 1024 * 1024) {
                if (REQUIRED_DOCS.length === 1) {
                  toBase64(file).then((b64: any) => {
                    _files.push({
                      FileName: file.name,
                      FileExtention: file.name.split('.').pop(),
                      DocumentTypeId: REQUIRED_DOCS[0],
                      FileContent: b64.split(',')[1],
                    });
                    setFiles(_files);
                    setOnClickContinue(undefined);
                    closeModal();
                    setSelectedDocumentType(undefined);
                  });
                  return;
                }
  
                setOnClickContinue(() => (docTypeCode: number) => {
                  if (!docTypeCode) {
                    toast.error('Select the document type');
                    return;
                  }
                  toBase64(file).then((b64: any) => {
                    _files.push({
                      FileName: file.name,
                      FileExtention: file.name.split('.').pop(),
                      DocumentTypeId: docTypeCode,
                      FileContent: b64.split(',')[1],
                    });
                    setFiles(_files);
                    setOnClickContinue(undefined);
                    closeModal();
                    setSelectedDocumentType(undefined);
                  });
                });
                showModal();
              } else {
                toast.error('File size should be less than 2MB');
              }
            })
            .catch(() => {
            });
        } else {
          toast.error('Unsupported file format. Please upload only PDF or Word documents.');
        }
      }
    } else {
      setFiles(_files);
    }
  }, [documents]);
  

  
  

  const checkIsSelected = (name: string) => {
    return new Promise((resolve: any, reject: any) => {
      files.map((file: any) => {
        if (file.FileName == name) {
          reject();
        }
      });
      resolve();
    });
  };

  const deleteLocal = (file: any) => {
    let _files: any = [];
    files.map((_file: any) => {
      if (_file.FileName != file.FileName) {
        _files.push(_file);
      }
    });

    setFiles(_files);
  };

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onSubmit = (data: any) => {
    const bankingDetails = {
      Name: data.bankName,
      AccountNo: data.accountNumber,
      AccountHolder: data.accountHolder,
      Branch: data.branchName,
      BranchNumber: data.branchNumber,
    };
    const documentsData = files;
    if (data.Documents?.length) {
      for (let i = 0; i < data.Documents?.length; i++) {
        let file: any = data.Documents[i];
        if (file.size <= 2 * 1024 * 1024) {
          onSubmitBankingDetails(bankingDetails, files);
        }
      }
    } else {
      onSubmitBankingDetails(bankingDetails, documentsData);
    }
  };

  const getDocTypes = () => {
    commonApi.post(
      {
        entityName: 'Picklist',
        requestName: 'RetrieveSelectOptionExecuteRequest',
        inputParamters: {
          SelectOption: {
            OptionText: 'DocTypes',
          },
        },
      },
      (message: string, resp: any) => {
        const options = resp.outputParameters.data.map((doc: any) => {
          return {
            value: doc.optionValue,
            label: doc.optionText,
          };
        });
        setDocumentOptions(
          options.filter((option: SelectOption) =>
            REQUIRED_DOCS.includes(option.value)
          )
        );
      },
      (message: string) => {}
    );
  };

  useEffect(() => {
    getDocTypes();
  }, []);

  const hasEveryRequiredDocument = REQUIRED_DOCS.every((documentCode) =>
    getAttachment(documentCode)
  );


  // Upload only pdf and word:
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event:any) => {
    const file = event.target.files[0];

    // Check if a file is selected
    if (file) {
      // Check the file type
      const fileType = file.type;
      if (fileType === 'application/pdf' || fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        // File type is PDF or Word document, set the selected file
        setSelectedFile(file);
      } else {
        // File type is not supported, show an error message or take appropriate action
        toast.error('Please upload a valid PDF or Word document.');
      }
    }
  
}

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="">
          <div className="mb-2">
            <InputField
              label="Bank Name"
              id="bankName"
              errors={errors}
              register={register}
              placeholder="First National Bank"
              required
            />
          </div>
          <div className="mb-2">
            <InputField
              label="Branch Name"
              id="branchName"
              errors={errors}
              register={register}
              placeholder="Centurion"
              required
            />
          </div>
          <div className="mb-2">
            <InputField
              label="Branch Number"
              id="branchNumber"
              errors={errors}
              register={register}
              registerParams={{
                maxLength: {
                  value: INPUT_MAX_LENGTHS.accountNumber,
                  message: 'Must be at most 50 characters',
                },
                pattern: {
                  value: DIGITS_ONLY_PATTERN,
                  message: 'Numbers only',
                },
              }}
              placeholder="250655"
              required
            />
          </div>
          <div className="mb-2">
            <InputField
              label="Account Holder"
              id="accountHolder"
              errors={errors}
              register={register}
              placeholder="Xiquel Group (Pty) Ltd"
              required
            />
          </div>
          <div className="mb-2">
            <InputField
              label="Account number"
              id="accountNumber"
              errors={errors}
              register={register}
              registerParams={{
                maxLength: {
                  value: INPUT_MAX_LENGTHS.accountNumber,
                  message: 'Must be at most 50 characters',
                },
                pattern: {
                  value: DIGITS_ONLY_PATTERN,
                  message: 'Numbers only',
                },
              }}
              placeholder="64000000000"
              required
            />
          </div>
        </div>
        {attachments && (
          <>
            <div className="upload-instruction mt-3">
              <h5>Please add the following documents below:</h5>
              <ul className="no-padding">
                {documentOptions.map((option) => (
                  <li className="no-bullet" key={option.label}>
                    <FaCheckCircle
                      color={getAttachment(option.value) ? '#0acc7e' : '#aaa'}
                    />
                    &nbsp;&nbsp;{option.label}
                    <RequiredAsterisk />
                  </li>
                ))}
              </ul>
            </div>

            <div
              className={`upload-documents mt-4 ${
                hasEveryRequiredDocument ? 'disabled' : ''
              }`}
            >
              {/* <input
                type="file"
                id="bank_details_documents"
                disabled={hasEveryRequiredDocument}
                multiple={true}
                accept=".png,.jpg,.jpeg,application/pdf"
                {...register('Documents', { required: true })}
              /> */}
              {/* <input
                type="file"
                multiple={false}
                accept=".pdf, .doc, .docx"
                {...register("Documents")}
                onChange={handleFileChange}
            /> */}
             <input
                type="file"
                id="bank_details_documents"
                disabled={hasEveryRequiredDocument}
                multiple={true}
                accept="application/pdf"
                {...register('Documents', { required: true })}
              />
              <MdAddCircle className="icon" />
              <label>Add Documents</label>
            </div>

            <div className="mt-2">
              {files.map((file: any, i: any) => (
                <div className="doc-file mb-2" key={i}>
                  <FaTimes
                    className="delete-me"
                    onClick={() => {
                      deleteLocal(file);
                    }}
                  />
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    id="exampleFormControlInput3"
                    placeholder={file.FileName}
                  />
                </div>
              ))}
            </div>
          </>
        )}
        <Row className="align-items-center">
          <Col md={6}>
            <div className="" style={{display:'flex', gap:'1rem'}}>
              <Button
                variant="primary"
                className="w-50"
                type="submit"
                disabled={attachments && !hasEveryRequiredDocument}
              >
                Next
              </Button>
              <Button
                variant="danger"
                className="w-50"
                type="submit"
                onClick={()=>{navigate('/entry-type')}}

              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </form>
      {modalComponent}
    </React.Fragment>
  );
}
