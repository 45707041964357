import React, { useState } from "react";
import IntroSlider from '../components/introSlider';

import { Button } from 'react-bootstrap';
import Select from 'react-select'

export default function EntryType() {


	const options = [
		{ value: 'company', label: 'I Am An Accommodation Provider' },
		{ value: 'student', label: 'I Am A Student' },
	]
	const [selection, setSelection] = useState(options[1]);

	const handleSelectChange = (accommodationTypeSelection: any) => {
		setSelection(accommodationTypeSelection);
	}


	return (
		<React.Fragment>
			<div className="register_page login_page">
				<div className="register_left">
					<div className="mob_logo">
						<a href="#" className="logo_left"><img src="/assets/img/logo.png" /></a>
					</div>

					<section className="container">
						<div className="white-block p-3">
							<div className="register_tab">
								<h6 style={{ fontWeight: '600' }}>Please select user type:</h6>

								<Select
									className={"form-control p-0"}
									options={options}
									placeholder={'Choose option'}
									onChange={handleSelectChange}
									value={selection}
								/>

								<br />
								
								{selection.value == 'company' ? <>
									Become an accredited NSFAS Accomodation Provider by listing your property for accommodating NSFAS funded students.
									<div className="d-flex justify-content-between">
										<Button as="a" href={selection.value == 'company' ? "/registration" : '/student-registration'} style={{ width: '48%' }} className="mt-2">Register</Button>
										<Button as="a" href={selection.value == 'company' ? "/login?type=p" : 'https://www.google.com'} style={{ width: '48%' }} className="mt-2">Login</Button>
									</div>

								</>

									: <>

										<p>If you are an NSFAS funded student that qualifies for accommodation allowance, please click Proceed to the housing portal</p>
										<p>For first time users you will need to:
											<ul>
												<li>
													Take a picture of your RSA ID
												</li>
												<li>
													Take a picture of your Proof of registration/Student Card
												</li>
												<li>
													Your Selfie (Picture of Face)
												</li>
											</ul></p>
										<p>Upon login you can search and apply for an accommodation of your choice. <b>If you are already residing in an accredited residence, please make sure to apply in the same residence</b></p>
										<p>Your details will be validated and processed upon funding confirmation</p>
										<Button as="a" href={selection.value == 'company' ? "/login?type=p" : '/login?type=s'} style={{ width: '100%' }} className="mt-2">Proceed</Button>


									</>}

								<br />
							</div>


						</div>
					</section>


				</div>
				<IntroSlider />
			</div>

		</React.Fragment>
	);
}