import React, { LegacyRef, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import Header from "./student/components/header";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useResidenceApi } from "../_common/hooks/api/residenceApiHook";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import {
  DIGITS_AND_DOT_ONLY_PATTERN,
  INPUT_MAX_LENGTHS,
  URLS,
} from "../_config";
import { useCasesApi } from "../_common/hooks/api/casesApiHook";
import { RequiredAsterisk } from "../components/blocks/requiredAsterisk";
import { InputField } from "../components/InputField";
import LeftPanel from "../components/leftPanel";
import { DevTool } from "@hookform/devtools";

interface RoomTypesData {
  Capacity: number;
  Name: string;
  Value: string;
}

export default function AddRoom(props: any) {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useAppLoader();
  const commonApi = useCommonApi();
  const residenceApi = useResidenceApi();
  const casesApi = useCasesApi();

  const residenceID = sessionStorage.getItem("residenceID");
  const residenceName = sessionStorage.getItem("residenceName");
  const [rooms, setRooms] = useState<any>(null);
  const [room, setRoom] = useState<any>(null);

  const [genders, setGenders] = useState<any>(null);
  const [caterings, setCaterings] = useState<any>(null);
  const [capacities, setCapacities] = useState<any>(null);
  const [myData, setMyData] = useState<any>(null);
  const [accesses, setAccesses] = useState<any>(null);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onChange" });

  const [searchParams] = useSearchParams();
  const roomid = searchParams.get("room");
  const [roomTypeData, setRoomTypeData] = useState<any>([]);
  const [capacity, setCapacity] = useState<any>([]);
  const [roomData, setRoomData] = useState<RoomTypesData[]>([]);
  const [cap, setCap] = useState<number>();
  const [quantity, setQuantity] = useState<number>();
  const [val, setVal] = useState<number>();
  const [beds, setBeds] = useState<any>();

  // function gets the facility
  const getFacilityPricing = () => {
    casesApi.getFacilityPricing(
      {
        FacilityId: residenceID,
      },
      (message: string, resp: any) => {
        if (resp.length > 0) {
          setBeds(resp[0].TotalCapacity);
        }
      },
      (message: string) => {}
    );
  };

  const getResidenceRooms = () => {
    residenceApi.getResidenceRooms(
      {
        $filter: "FacilityId eq '" + residenceID + "'",
        entity: "Capacity",
        $select:
          "CapacityId, Name, Quantity, GenderId, PricePerBed, DisabilityAccessId, CateringId, AmenityId, AmenityTypeId",
      },
      (message: string, resp: any) => {
        let data: any = [];

        resp[0].data.forEach((room: any) => {
          if (room.AmenityTypeId == 859) {
            data.push(room);
          }
        });
        setRooms(data);
        if (roomid) {
          data.map((_room: any) => {
            if (roomid == _room.CapacityId) {
              setRoom(_room);
              setQuantity(_room.Quantity);
              reset({
                quantity: _room.Quantity,
                price: _room.PricePerBed,
                access: getAccessById(_room.DisabilityAccessId),
                capacity: getCapacityById(_room.AmenityId),
                catering: getCateringById(_room.CateringId),
                gender: _room.GenderId,
              });
              setTimeout(() => {
                let el: any = document.getElementById(
                  "gender" + _room.GenderId
                );
                el.click();
              }, 500);
            }
          });
        }
      },
      (message: string) => {}
    );
  };

  const getCaterings = () => {
    commonApi.post(
      {
        entityName: "Picklist",
        requestName: "RetrieveSelectOptionExecuteRequest",
        inputParamters: {
          SelectOption: {
            OptionText: "Catering",
          },
        },
      },
      (message: string, resp: any) => {
        // console.log('getCaterings=>', resp.outputParameters.data)
        setCaterings(
          resp.outputParameters.data.map((data: any) => {
            data.label = data.optionText;
            data.value = data.optionValue;
            return data;
          })
        );
      },
      (message: string) => {
        // console.log(message);
      }
    );
  };

  const getGender = () => {
    commonApi.post(
      {
        entityName: "Picklist",
        requestName: "RetrieveSelectOptionExecuteRequest",
        inputParamters: {
          SelectOption: {
            OptionText: "Gender",
          },
        },
      },
      (message: string, resp: any) => {
        setGenders(resp.outputParameters.data);
      },
      (message: string) => {}
    );
  };

  const getAccesses = () => {
    commonApi.post(
      {
        entityName: "Picklist",
        requestName: "RetrieveSelectOptionExecuteRequest",
        inputParamters: {
          SelectOption: {
            OptionText: "YesNo",
          },
        },
      },
      (message: string, resp: any) => {
        setAccesses(
          resp.outputParameters.data.map((data: any) => {
            data.label = data.optionText;
            data.value = data.optionValue;
            return data;
          })
        );
      },
      (message: string) => {}
    );
  };

  const getCapacities = () => {
    commonApi.getCapacities(
      { ViewName: "Room_Type_Lookup_View" },
      (message: string, resp: any) => {
        let opt: any = [];
        resp.map((o: any) => {
          if (o.recordId.search("000000") !== 0) {
            o.label = o.mainText;
            o.value = o.recordId;
            opt.push(o);
          }
        });
        setCapacities(opt);
      },
      (message: string) => {}
    );
  };
  const getMyData = () => {
    commonApi.getRoomTypes(
      {},
      (message: string, resp: any) => {
        let opt: any = [];
        resp.map((o: any) => {
          if (o.recordId.search("000000") !== 0) {
            o.label = o.mainText;
            o.value = o.recordId;
            opt.push(o);
          }
        });
        setMyData(opt);
      },
      (message: string) => {
        // console.log(message);
      }
    );
  };

  // Get Room Types
  const getRoomTypes = () => {
    commonApi.getRoomTypes(
      {},
      (message: string, resp: any) => {
        if (resp.length > 0) {
          let opt: any = [];
          resp.forEach((roomType: RoomTypesData) => {
            opt.push({
              label: roomType.Name,
              value: roomType.Value,
              capacity: roomType.Capacity,
            });
          });
          setRoomTypeData(opt);
          setCapacity(opt);
          opt.map((roomType: any) => {
            setCap(roomType.capacity);
          });

          capacity.map((cap: any) => {});
        }
      },
      (message: string) => {}
    );
  };

  const calcBeds = (cap: number, quan: number) => {
    setBeds(cap * quan);
  };
  // calcBeds(24,2);
  useEffect(() => {
    if (val !== undefined && quantity !== undefined) {
      calcBeds(val, quantity);
    }
  }, [val, quantity]);

  const getGenderName = (id: any) => {
    let name = "";
    genders.map((gen: any) => {
      // console.log(gen, id)
      if (gen.optionValue == id) {
        name = gen.optionText;
      }
    });
    return name;
  };

  const onSubmit = (data: any) => {
    let params: any = {
      Quantity: data.quantity,
      GenderId: data.gender,
      PricePerBed: data.price,
      DisabilityAccessId: data.access.value,
      Capacity: data.capacity.label,
      AmenityId: data.capacity.value,
      CateringId: data.catering.value,
      FacilityId: residenceID,
      Beds: data.beds,
      AmenityTypeId: 859,
      // "NumberOfBeds": data.beds,
    };

    let dataParams: any = {
      entityName: "Capacity",
      requestName: "UpsertRecordReq",
      inputParamters: { Entity: params },
    };
    if (room) {
      dataParams.recordId = room.CapacityId;
    }
    // console.log(params)
    showLoader();
    commonApi.post(
      dataParams,
      (message: string, resp: any) => {
        hideLoader();
        navigate("/residence-summery");
      },
      (message: string) => {
        hideLoader();
        toast.error(message);
      }
    );
  };

  const getCateringById = (id: any) => {
    let res = null;
    caterings.map((cat: any) => {
      if (cat.value == id) {
        res = cat;
      }
    });
    return res;
  };

  const getCapacityById = (id: any) => {
    let res = null;
    capacities.map((capacity: any) => {
      if (capacity.value == id) {
        res = capacity;
      }
    });
    return res;
  };

  const getAccessById = (id: any) => {
    let res = null;
    accesses.map((access: any) => {
      if (access.value == id) {
        res = access;
      }
    });
    return res;
  };

  useEffect(() => {
    if (residenceID == null) {
      navigate("/add-residence");
    } else {
      getGender();
      getAccesses();
      getCaterings();
      getCapacities();
      getRoomTypes();
      getMyData();
      getFacilityPricing();
      // getResidenceRooms();
    }
  }, []);
  useEffect(() => {
    if (genders && accesses && capacities && caterings) {
      getResidenceRooms();
    }
  }, [genders, accesses, capacities, caterings]);

  const [modalShow, setModalShow] = React.useState(false);
  const [myFormValue, setMyformValue] = useState<any>();

  const [formValue, setFormValue] = useState<any>();
  // const formRef = useRef();
  const formRef: LegacyRef<HTMLFormElement> = useRef(null);

  return (
    <React.Fragment>
      <div className="dashboard">
        <Header />
        <section className="container">
          <div className="custome-container-inner d-flex">
            <LeftPanel page={2} />

            <div className="white-block p-3" style={{ width: "100%" }}>
              <div className="row">
                <div className="col">
                  <h1>{residenceName} - Room Type</h1>

                  {/* <sub><sup>*</sup>Note: You can still add more room types after this</sub> */}
                </div>
              </div>
              <hr />
              <form onSubmit={handleSubmit(onSubmit)}>
                {genders ? (
                  <React.Fragment>
                    {rooms?.map((room: any, i: any) => (
                      <div
                        className="alert alert-warning orange-info"
                        role="alert"
                        key={i}
                      >
                        <div className="row">
                          <div className="col">
                            {getGenderName(room.GenderId)} rooms
                          </div>
                          <div className="col text-end">Added</div>
                        </div>
                      </div>
                    ))}
                  </React.Fragment>
                ) : null}

                <div className="tab_scrollcontent">
                  <div className="mb-2">
                    <label
                      htmlFor="exampleFormControlInput2"
                      className="form-label"
                    >
                      Gender
                      <RequiredAsterisk />
                    </label>
                    <div className="row">
                      {genders?.map((gender: any, i: any) => (
                        <div className="col" key={i}>
                          <div className="border-box">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                value={gender.optionValue}
                                id={"gender" + gender.optionValue}
                                {...register("gender", { required: true })}
                              />
                              <label
                                className="form-check-label text-center"
                                htmlFor={"gender" + gender.optionValue}
                              >
                                <span>
                                  <img
                                    src={
                                      "/assets/img/" +
                                      gender.optionText +
                                      ".png"
                                    }
                                    alt=""
                                  />
                                </span>
                                <br />
                                {gender.optionText}
                              </label>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="mb-2">
                    <label className="form-label">
                      Room Type?
                      <RequiredAsterisk />
                    </label>
                    <Controller
                      name={"capacity"}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { value, onChange, onBlur } }) => {
                        const selectedCapacity1 = value
                          ? roomTypeData.find(
                              (option: any) => option.value === value.value
                            )?.capacity
                          : 0;
                        setVal(selectedCapacity1);
                        return (
                          <Select
                            className={
                              "form-control p-0" +
                              (errors?.capacity ? " is-invalid" : "")
                            }
                            options={roomTypeData}
                            id="roomtype"
                            placeholder={""}
                            // onChange={onChange}
                            onChange={(selectedOption) => {
                              onChange(selectedOption);
                              const selectedCapacity = roomTypeData.find(
                                (option: any) =>
                                  option.value === selectedOption.value
                              )?.capacity;
                              if (selectedCapacity !== undefined) {
                                setVal(selectedCapacity);
                              }
                            }}
                            value={value}
                            defaultValue={value}
                          />
                        );
                      }}
                    />
                  </div>

                  <div className="mb-2">
                    <label className="form-label">
                      Total Number of rooms
                      <RequiredAsterisk />
                    </label>
                    <input
                      type="number"
                      min={1}
                      max={10000}
                      id="rooms"
                      className="form-control"
                      onInput={(e: any) => {
                        if (e.target.value < 0) {
                          e.target.value = 0;
                        }
                      }}
                      // onChange={
                      //     (e:any) =>  setQuantity(e.target.value)
                      // }
                      // {...register("quantity", { required: true })}
                      {...register("quantity", {
                        onChange: (e: any) => {
                          setQuantity(e.target.value);
                        },
                        required: true,
                      })}
                      value={quantity}
                    />
                  </div>
                  <div className="mb-2">
                    <label className="form-label">Total Number of beds</label>
                    <input
                      type="number"
                      max={10000}
                      className="form-control"
                      {...register("beds")}
                      value={beds}
                      disabled
                    />
                  </div>
                  {/* <div className="mb-2" style={{}}>
                                    <InputField
                                        type="number"
                                        step="0.01"
                                        label="Price Per Bed"
                                        id="price"
                                        prefix="R"
                                        errors={errors}
                                        register={register}
                                        registerParams={{
                                            maxLength: {
                                                value: INPUT_MAX_LENGTHS.default,
                                                message: 'Must be at most 50 characters',
                                            },
                                            pattern: {
                                                value: DIGITS_AND_DOT_ONLY_PATTERN,
                                                message: 'Numbers only',
                                            },
                                        }}
                                        required
                                    />
                                  
                                  
                                    
                                   
                                </div> */}

                  <div className="mb-2">
                    <label className="form-label">
                      Disability Friendly Access?
                      <RequiredAsterisk />
                    </label>
                    <Controller
                      name={"access"}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { value, onChange, onBlur } }) => {
                        return (
                          <Select
                            // className={"form-control p-0" + (errors?.access ? ' is-invalid' : '')}
                            options={accesses}
                            id="dissabilityaccess"
                            placeholder={""}
                            onChange={onChange}
                            value={value}
                            defaultValue={value}
                          />
                        );
                      }}
                    />
                  </div>

                  <div className="mb-2">
                    <label className="form-label">
                      Catering?
                      <RequiredAsterisk />
                    </label>
                    <Controller
                      name={"catering"}
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { value, onChange, onBlur } }) => {
                        return (
                          <Select
                            // className={"form-control p-0" + (errors?.catering ? ' is-invalid' : '')}
                            options={caterings}
                            id="catering"
                            placeholder={""}
                            onChange={onChange}
                            value={value}
                            defaultValue={value}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="add-room-bottom">
                  <div className="">
                    <button
                      type="submit"
                      className="btn btn-primary w-100 mt-2"
                      disabled={!isValid}
                      data-bs-toggle="button"
                      style={{ position: "relative" }}
                    >
                      Next
                    </button>
                    <div className="add-room-room-back-btn">
                      <Link to={URLS.ADD_RESIDENCE}>
                        <button
                          type="button"
                          className="btn btn-outline-secondary mt-2 w-100 back-btn-add-prop"
                          data-bs-toggle="button"
                          style={{ position: "relative" }}
                        >
                          Back
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
              <DevTool control={control} />
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
