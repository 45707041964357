// TWO


import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button } from 'react-bootstrap';
import Moment from 'react-moment';
import Header from "./student/components/header";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useResidenceApi } from "../_common/hooks/api/residenceApiHook";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import { MdAddCircle } from 'react-icons/md';
import { BsStarFill, BsStarHalf, BsStar } from 'react-icons/bs';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import Slider from "react-slick";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
import { URLS } from "../_config";

const UPDATABLE_STATUS = [872, 834];
const PHOTO_EDIT_ONLY = [834];

export default function Dashboard() {
    const user: any = useUserSelector();
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useAppLoader();
    const commonApi = useCommonApi();
    const residenceApi = useResidenceApi();

    const [residencies, setResidencies] = useState<any>(null)
    const [provinces, setProvinces] = useState<any>([])
    const [residenceStatus, setResidenceStatus] = useState<any>([])
    // const [show, setShow] = useState<boolean>(false)
    let residenceId = sessionStorage.getItem('residenceID');
    const inspectionStatus:any =  [
        {
            "optionValue": 894,
            "optionText": "Complete",
            "colorCode": null
        },
        {
            "optionValue": 845,
            "optionText": "Pending",
            "colorCode": null
        },
        {
            "optionValue": 846,
            "optionText": "Scheduled",
            "colorCode": null
        },
        {
            "optionValue": 847,
            "optionText": "Quality Assurance",
            "colorCode": null
        }
    ]

    const getInspectionStatusNameById = (id:any) => {
        let statusName:string = '';
        inspectionStatus.map((status:any)=>{
            if(status.optionValue == id){
                statusName = status.optionText;
            }
        });
        return statusName;
    }

    const getResidencies = () => {
        showLoader();
        commonApi.post({
            "requestName": "FetchRecordWithDocsReq",
            "recordId": user.supplierId

        }, (message: string, resp: any) => {
            hideLoader();
            
            
            const _residencies = resp.outputParameters.Facilities;
            
            
            if(_residencies.length === 0 && user.AcceptTsAndCsAPI === true){
                navigate(URLS.ADD_RESIDENCE)
            }
            setResidencies(_residencies);
            
        }, (message: string) => {
            toast.error(message);
            hideLoader();
        });
    }

    const getProvinces = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "Province"

                }
            }
        }, (message: string, resp: any) => {
            if (resp.outputParameters.Count > 0) {
                setProvinces(resp.outputParameters.data)
            }
        }, (message: string) => {
        });
    }

    const getResidenceStatus = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "FacilityStatus"

                }
            }
        }, (message: string, resp: any) => {
            if (resp.outputParameters.Count > 0) {
                setResidenceStatus(resp.outputParameters.data)
            }
        }, (message: string) => {
            console.log(message);
        });
    }

    const getFilteredProvince = (provinceId: any) => {
        const filteredData = provinces.filter((province: any) => province.optionValue == provinceId);
        // console.log(filteredData)
        return filteredData[0]?.optionText
    }

    const getFilteredResidenceStatus = (facilityStatusId: any) => {
        const filteredData = residenceStatus.filter((item: any) => item.optionValue == facilityStatusId);
        // console.log(filteredData[0])
                                                {/* <img src={penImage} alt="pen-icon"  style={{width:'20px'}} /> */}

        return <div className="tag-status"> <span className="badge" style={{ backgroundColor: filteredData[0]?.colorCode ? filteredData[0]?.colorCode : '#F3981E' }}><>{filteredData[0]?.optionText ? filteredData[0]?.optionText : "NA"}</></span></div>
    }


    useEffect(() => {
        if (user) {
            getResidencies();
            getProvinces();
            getResidenceStatus();
        }

    }, [user])



    const checkImageBase64Code = (url: any) => {
        if (url.search('data:image/') == 0) {
            return url;
        } else {

            return 'data:image/jpeg;base64,' + url
        }

    }

    const imageSource = (docId: any, folder:any) => {
        
        return process.env.REACT_APP_API_DOMAIN+"/api/v1/entities/imageview?id="+docId+"&folder="+folder+"&apikey="+process.env.REACT_APP_API_KEY
    }

    const withdrawal = (residence: any) => {
        return new Promise((resolve: any, reject: any) => {
            commonApi.post({
                "entityName": "Facility",
                "recordId": residence.facilityId,
                "requestName": "UpsertRecordReq",
                "inputParamters":
                {
                    "Entity": {
                        "FacilityStatusId": "872"
                    }
                }
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const deleteResidence = (residence: any) => {
        return new Promise((resolve: any, reject: any) => {
            commonApi.post({
                "entityName": "Facility",
                "recordId": residence.facilityId,
                "requestName": "RemoveRecordReq"
            }, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })
    }

    const withdrawalNotification = (residence: any) => {
        toast(
            (t) => (
                <span>
                    <b>Please note!</b>
                    <br />
                    should you withdraw your property, your payment will not be refunded.
                    <br />
                    <hr />
                    <div className="d-flex align-items-center justify-content-end">
                        <button
                            className="btn btn-danger btn-sm m-1"
                            onClick={() => {
                                toast.dismiss(t.id);
                                toast.promise(
                                    withdrawal(residence),
                                    {
                                        loading: "working on request...",
                                        success: (msg: any) => { getResidencies(); return <b>{residence.name} withdrawal Successfully!</b> },
                                        error: (msg) => { return <b>{msg}</b> },
                                    },
                                    { duration: 5000 }
                                );
                            }}
                        >
                            Withdraw
                        </button>

                        <button
                            className="btn btn-secondary btn-sm m-1"
                            onClick={() => toast.dismiss(t.id)}
                        >
                            Cancel
                        </button>
                    </div>
                </span>
            ),
            { duration: 5000, className: 'withdraw-toast' }
        );
    }

    const deleteNotification = (residence: any) => {
        toast(
            (t) => (
                <span>
                    
                    Are you sure you want to delete your property.
                    <br />
                    <hr />
                    <div className="d-flex align-items-center justify-content-end">
                        <button
                            className="btn btn-danger btn-sm m-1"
                            onClick={() => {
                                toast.dismiss(t.id);
                                toast.promise(deleteResidence(residence), {
                                    loading: "working on request...",
                                    success: (msg: any) => {
                                        getResidencies();
                                        return <b>{residence.name} Property deleted successfully</b>
                                    },
                                    error: (msg) => { return <b>{msg}</b> },
                                }, { duration: 6000 });
                            }}
                        >
                            Delete
                        </button>

                        <button
                            className="btn btn-secondary btn-sm m-1"
                            onClick={() => toast.dismiss(t.id)}
                        >
                            Cancel
                        </button>
                    </div>
                </span>
            ),
            { duration: 5000, className: 'withdraw-toast' }
        );
    }

    const editMe = (residence: any) => {  
        sessionStorage.setItem('residenceStatus', residence.facilityStatusId);
        if(PHOTO_EDIT_ONLY.includes(residence?.facilityStatusId)){ 
            sessionStorage.setItem('residenceID', residence.facilityId);
            sessionStorage.setItem('residenceName', residence?.name || residence?.Name); 
            return navigate("/upload-property-images", { state: { residence: residence, isEditUploadsOnly: true } })
        }
        if (residence?.facilityStatusId == 872 || residence?.facilityStatusId == 834) {
            sessionStorage.setItem('residenceID', residence.facilityId);
            sessionStorage.setItem('residenceName', residence?.name || residence?.Name);

            sessionStorage.setItem('regrade', 'true');
            navigate("/add-residence");
            
        }
        
    }
    const payNow = (residence: any) => {
        if (residence?.facilityStatusId == 872 || residence?.facilityStatusId == 834) {
            sessionStorage.setItem('residenceID', residence.facilityId);
            sessionStorage.setItem('residenceName', residence?.Name);

            sessionStorage.setItem('regrade', 'true');
            navigate("/payment-options");
            
        }
        
    }

    const getRating = (rating: any, totalStar: any, outOf: any) => {
        if (rating) {
            let finalRating = (rating / outOf) * totalStar;
            const checkFraction = finalRating % 1 != 0;
            if (checkFraction == false) {
                const blankStar = totalStar - finalRating;
                let span: any = []
                for (let i = 0; i < finalRating; i++) {
                    span.push(<BsStarFill className="text-primary" key={i} />)
                }
                for (let i = 0; i < blankStar; i++) {
                    span.push(<BsStar key={i} />)
                }
                return span
            } else {
                let fullStar = Math.round(finalRating * 10) / 10;
                let halfStar = Number(fullStar.toString().split(".")[1]);
                fullStar = Number(finalRating.toString().split(".")[0]);
                let span: any = []
                if (halfStar >= 5) {
                    let blankStar = totalStar - fullStar - 1;
                    for (let i = 0; i < fullStar; i++) {
                        span.push(<BsStarFill className="text-primary" key={i} />)
                    }
                    span.push(<BsStarHalf className="text-primary" />)
                    for (let i = 0; i < blankStar; i++) {
                        span.push(<BsStar key={i} />)
                    }
                    return span
                }
                else {
                    let blankStar = totalStar - fullStar + 1;
                    for (let i = 0; i < fullStar; i++) {
                        span.push(<BsStarFill className="text-primary" key={i} />)
                    }
                    for (let i = 0; i < blankStar; i++) {
                        span.push(<BsStar key={i} />)
                    }
                    return span
                }
            }
        }
    }

    const addResidence = ()=>{
        sessionStorage.removeItem('residenceID');
        sessionStorage.removeItem('residenceName');
        sessionStorage.removeItem('regrade');
        navigate("/add-residence")
    }

   

   
    const residenceID =  sessionStorage.getItem('residenceID');
    const [amount, setAmount] = useState<any>(0)

    const getPaymentValue = () => {
        
        residenceApi.getPaymentValue({
            FacilityId: residencies?.facilityId

        }, (message: string, resp: any) => {
            // console.log('rooms=> dasbrd', resp)
            setAmount(resp[0].amount ? resp[0].amount : 250)
        }, (message: string) => {
            // console.log(message);
            toast.error(message)
        });
    }
    useEffect(()=>{
        // getPaymentValue();
        
        
    },[])
    
    return (
        <React.Fragment>
            <div className="dashboard">
                <Header />
                <section className="container dashboard-view">
                    <div className="white-block p-3">
                        <div className="row">
                            <div className="col">
                                <h5>My Residences</h5>
                            </div>
                            <div className="col text-end">
                                <span onClick={addResidence}>
                                    <Button className="icon-btn"><MdAddCircle /></Button>
                                </span>

                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            {residencies && residencies.length > 0 ? residencies?.map((residence: any, i: any) => { 
                                return (
                                <div className="col-md-6 mb-2" key={i}>
                                    <div className="card" style={{height:'100%'}}>
                                        {residence.images.length > 0 ?
                                            <Slider
                                                nextArrow={<IoIosArrowForward />}
                                                prevArrow={<IoIosArrowBack />}
                                                adaptiveHeight={true}
                                                autoplay={false}
                                                autoplaySpeed={1000 * 3}>
                                                {residence.images.map((image: any, j: any) => (
                                                    <div className="slide" key={j} >
                                                        <img src={imageSource(image.facilityDocLibId,"FacilityDocLib")} alt="" style={{ width: '100%', height: '250px', objectFit: 'cover' }} />
                                                    </div>
                                                ))}
                                            </Slider>
                                            :
                                            <Slider
                                                nextArrow={<IoIosArrowForward />}
                                                prevArrow={<IoIosArrowBack />}
                                                adaptiveHeight={true}
                                                autoplay={false}
                                                autoplaySpeed={1000 * 3}>
                                                <div className="slide" >
                                                    <img  src={"/assets/img/no-image-available.jpg" }alt="" style={{ width: '100%', height: '250px', objectFit: 'cover' }} />
                                                </div>
                                            </Slider>
                                        }


                                        {/* <div className="tag-status"><span className="badge bg-secondary"><>{getFilteredResidenceStatus(residence.facilityStatusId)}</></span></div> */}
                                        <div className="card-body" >
                                            {/* Where the status appears */}
                                                <div style={{cursor:'pointer'}} >
                                                    {getFilteredResidenceStatus(residence.facilityStatusId)}
                                                </div>  
                                                
                                            <div >
                                            <div className="row"  >
                                                <div className="col-5 title">Name</div>
                                                <div className="col-7 subject text-end">{residence.name}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-5 title">Provice</div>
                                                <div className="col-7 subject text-end">{getFilteredProvince(residence.provinceId)}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-5 title">Target institution</div>
                                                <div className="col-7 subject text-end">{residence.institutionIdName}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-5 title">KM from Campus</div>
                                                <div className="col-7 subject text-end">{residence.kmToCampus} km(s)</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-5 title">Address</div>
                                                <div className="col-7 subject text-end">{residence.address}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-5 title">Total Beds</div>
                                                <div className="col-7 subject text-end">{residence.totalRooms}</div>
                                            </div>
                                           
                                            {residence?.facilityStatusId == 834 ?
                                                <div className="row">
                                                    <div className="col-5 title">Grading</div>
                                                    <div className="col-7 subject text-end">{residence.rating}</div>
                                                </div>
                                                : null}
                                            </div>

                                            {residence?.facilityStatusId == 833 ?
                                                <React.Fragment>
                                                    <div className="row">
                                                        <div className="col-5 title">Inspection Status</div>
                                                        <div className="col-7 subject text-end">{getInspectionStatusNameById(residence.inspectionStatusId)}</div>
                                                    </div>
                                                    {residence.inspectionDueDate && residence.inspectionStatusId == 846 ?
                                                        <div className="row">
                                                            <div className="col-5 title">Inspection Date</div>
                                                            <div className="col-7 subject text-end">
                                                                <Moment format="DD MMMM, YYYY">
                                                                    {residence.inspectionDueDate}
                                                                </Moment>
                                                            </div>
                                                        </div>
                                                        : null}

                                                </React.Fragment>
                                                : null
                                            }
                                            

                                            {residence?.facilityStatusId == 834 ?
                                                <div>
                                                    {/* <Button onClick={()=>{editMe(residence)}} variant="secondary" className="d-block p-1 w-100">Regrade</Button> */}
                                                </div>
                                                : null
                                            }

                                            {/*  */}
                                            {residence?.facilityStatusId == 872 && residence.payNow === false?
                                                <div className="d-flex">
                                                    {/* <Button variant="secondary" className="p-1" style={{ width: '100%', marginLeft:'4px',backgroundColor:'black' }}>Edit Me</Button>
                                                    <Button variant="danger" className="p-1" onClick={() => { deleteNotification(residence) }} style={{ width: '100%', marginLeft:'4px' }}>Delete</Button> */}

                                                
                                                </div>
                                                : null
                                            }
                                            {/*  */}

                                            {residence?.facilityStatusId == 835 || residence?.facilityStatusId == 836 ?
                                                <div>
                                                    <Link to={"/create-case/" + residence?.facilityId + '?name=' + residence.name + '&type=Appeal'} className="btn btn-secondary d-block p-1">Appeal</Link>
                                                </div>
                                                : null
                                            }
                                            {
                                                UPDATABLE_STATUS.includes(residence?.facilityStatusId) &&
                                                <div className="d-flex">
                                                    {residence?.facilityStatusId == 872 && <>
                                                        <Link to={"/payment-options"} className="btn btn-secondary d-block  p-1" style={{ width: '100%', marginRight: '4px' }} onClick={() => { payNow(residence) }}>Pay Now</Link>
                                                        <Button variant="danger" className="p-1" onClick={() => { deleteNotification(residence) }} style={{ width: '100%', marginLeft: '4px' }}>Delete</Button>
                                                    </>}
                                                    <Button variant="secondary" className="p-1" style={{ width: '100%', marginLeft: '4px' }} onClick={() => { editMe(residence) }}>Edit Me</Button>
                                                </div>
                                            }
                                            {residence?.facilityStatusId == 833 || residence?.facilityStatusId == 840 || residence?.facilityStatusId == 0 ?
                                                <div>
                                                    <Button variant="secondary" className="p-1" onClick={() => { withdrawalNotification(residence) }} style={{ width: '100%' }}>Withdrawal</Button>
                                                </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            )})
                                :
                                <div className="upload-documents" onClick={addResidence}>
                                    {/* <input type="file" id="profile_image" className={"form-control" + (errors?.pic ? ' is-invalid' : '')} multiple={true} accept="application/pdf" {...register("Documents", { required: false })} /> */}
                                    <MdAddCircle className="icon" style={{ cursor: "pointer" }} />
                                    <label>Add Residence</label>
                                </div>
                            }
                        </div>
                    </div>
                </section>
            </div>
        </React.Fragment>
    );
}