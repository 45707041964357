import React, { useCallback, useMemo } from "react";
import { TListingGridProps } from "../ListingGrid/types";
import { TBulkListingFilters } from "../../../types/TBulkListingFilters";
import { TSelectedFilters } from "../useSearchAndFiltersBar/SearchAndFiltersBar";

const defaultState = { bulkActionsFilter: null, generalFilters: [] };

function useApiFilters(filtersResponse?: TBulkListingFilters) {
  const { bulkActionsFilter, generalFilters } = useMemo((): {
    bulkActionsFilter: TListingGridProps["bulkActionsFilter"] | null;
    generalFilters: {
      label: string;
      id: string;
      options: { label: string; value: string }[];
    }[];
  } => {
    if (!filtersResponse) {
      return defaultState;
    }
    const generalFilters = filtersResponse.ListingActionFilters.filter(
      (item) => !item.isBulkAction
    ).map((el) => {
      return {
        label: el.label,
        id: el.portalListingId,
        options: [
          { label: "All", value: " " },
          ...el.actionFilters.map((item) => {
            return {
              label: item.filterName,
              value: item.portalListingFilterId,
            };
          }),
        ],
      };
    });

    for (const filter of filtersResponse.ListingActionFilters) {
      if (filter.isBulkAction) {
        return {
          bulkActionsFilter: {
            label: filter.label,
            options: filter.actionFilters.map((item) => {
              return {
                label: item.filterName,
                value: item.portalListingFilterId,
                actions: item.actions.map((action) => {
                  return {
                    label: action.actionName,
                    request: action.request,
                  };
                }),
              };
            }),
          },
          generalFilters,
        };
      }
    }
    return { bulkActionsFilter: undefined, generalFilters };
  }, [filtersResponse]);

  const extractFiltersForPosting = useCallback(
    (
      bulkActionFilter: {
        id: string;
        value: string;
      } | null,
      activeFilters: TSelectedFilters
    ) => {
      const _bulkActionFilter = bulkActionFilter?.value
        ? {
            portalListingFilterId: bulkActionFilter.id,
            value: bulkActionFilter.value.trim(),
          }
        : undefined;
      const _generalFilters = activeFilters.map((_) => ({
        portalListingId: _.filterNameValue,
        value: _.value.trim(),
      }));

      return {
        bulkActionFilter: _bulkActionFilter,
        generalFilters: _generalFilters,
      };
    },
    []
  );

  return { bulkActionsFilter, generalFilters, extractFiltersForPosting };
}

export default useApiFilters;
