import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Row, Col, Button } from 'react-bootstrap';
import { useCommonApi } from "../../_common/hooks/api/common/commonApiHook";
import Select from 'react-select';
import toast from "react-hot-toast";
import { useAppLoader } from "../../_common/hooks/common/appLoaderHook";
import { InputField } from "../InputField";
import { DIGITS_ONLY_PATTERN, INPUT_MAX_LENGTHS } from "../../_config";
interface incomingProps {
    onSubmitAddressForm: any;
    tab: any;
    userDetails: any;
    setAddressId: any;
}

export default function UpdateAddressForm({ onSubmitAddressForm, tab, userDetails, setAddressId }: incomingProps) {

    const commonApi = useCommonApi();
    
    const { register, handleSubmit, control, formState: { errors }, watch, reset } = useForm();
    const provincesToExclude = [ "Limpopo", "Gauteng", "Free State","Western Cape","Northern Cape","North West", "Eastern Cape "];
    
    // const [province, setProvince] = useState<any>(null);
    const [provinces, setProvinces] = useState<any>(null);
    const { showLoader, hideLoader } = useAppLoader();

    const onSubmit = (data: any) => { 
     
            let addressData: any = {
                "StreetNumber": data.StreetNumber,
                "StreetName": data.StreetName,
                "suburb": data.Surburb,
                "City": data.City,
                "Province": data.Province.value,
                "PostalCode": data.PostalCode
            }
            onSubmitAddressForm(addressData)
         
    }

    const getProvinces = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "Province"

                }
            }
        }, (message: string, resp: any) => {
            // if (resp.outputParameters.Count > 0) {
            //     setProvinces(resp.outputParameters.data.map((data: any) => {
            //         data.label = data.optionText;
            //         data.value = data.optionValue;
            //         return data;
            //     }))
            // }
            if (resp.outputParameters.Count > 0) {
                // Filter provinces here to exclude 'Limpopo'
                const filteredProvinces = resp.outputParameters.data.filter(
                    (data: any) => !provincesToExclude.includes(data.optionText)
                );
        
                setProvinces(
                  filteredProvinces.map((data: any) => ({
                    label: data.optionText,
                    value: data.optionValue,
                  }))
                );
              }
        }, (message: string) => {
        });
    }
    

    const getPrevData = () => {
        if (userDetails?.supplierId) {
            showLoader();
            //call api reset form values via reset()
            commonApi.retreive({
                "Entity": "Address",
                "Top":100,
                "ReturnCols":["AddressId","StreetNumber","StreetName", "suburb","City","Province","PostalCode"],
                "FilterOn":{
                "Type": "And",
                "Where":[{"filterCol": "RelatedObjectId", "FilterCondition":"Equals",
                "FilterValue":userDetails?.supplierId }]
                }
            }, (message: string, resp: any) => {
                hideLoader();
                let prevData = JSON.parse(resp)[0];
                setAddressId(prevData.AddressId);
                reset({
                    StreetNumber: prevData.StreetNumber,
                    StreetName: prevData.StreetName,
                    Address: prevData.address,
                    City: prevData.City,
                    Surburb: prevData.suburb,
                    PostalCode: prevData.PostalCode,
                    Province: getSelectDataById(prevData.Province, provinces)
                })
            }, (message: string) => {
                hideLoader();
                //toast.error(message);
            });
        } else {
        }
    }

    const getSelectDataById = (id: any, data: any) => {
        let option = null
        data?.map((_option: any) => {
            if (_option.value == id) {
                option = _option
            }
        })
        return option
    }

    useEffect(() => {
        getProvinces();
    }, [])

    useEffect(() => {
        if (provinces) {
            getPrevData()
        }
    }, [provinces])

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="tab_scrollcontent">
                    <div className="mb-2">
                        <label htmlFor="exampleFormControlInput1a" className={"form-label " + errors.StreetNumber ? 'is-invalid' : ''}>Street Number</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1a" placeholder="" {...register("StreetNumber", { required: true, maxLength: 160 })} />
                        {errors?.StreetNumber ? <div className="invalid-feedback">Required</div> : null}
                    </div>
                    <div className="mb-2">
                        <label htmlFor="exampleFormControlInput1a" className={"form-label " + errors.StreetName ? 'is-invalid' : ''}>Street Address</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1a" placeholder="" {...register("StreetName", { required: true, maxLength: 160 })} />
                        {errors?.StreetName ? <div className="invalid-feedback">Required</div> : null}
                    </div>
                    <div className="mb-2">
                        <label htmlFor="exampleFormControlInput1a" className={"form-label " + errors.City ? 'is-invalid' : ''}>City</label>
                        <input type="text" className="form-control" id="exampleFormControlInput1a" placeholder="" {...register("City", { required: true, maxLength: 160 })} />
                        {errors?.City ? <div className="invalid-feedback">Required</div> : null}
                    </div>
                    <div className="row">
                        <div className="col-9">
                            <div className="mb-2">
                                <label htmlFor="exampleFormControlInput2a" className={"form-label " + errors.Surburb ? 'is-invalid' : ''}>Surburb</label>
                                <input type="text" className="form-control" id="exampleFormControlInput2a" placeholder="" {...register("Surburb", { required: true, maxLength: 80 })} />
                                {errors?.Surburb ? <div className="invalid-feedback">Required</div> : null}
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="mb-2">
                                <InputField
                                    label="Postal Code"
                                    id="PostalCode"
                                    errors={errors}
                                    register={register}
                                    placeholder=""
                                    required
                                    registerParams={{
                                        maxLength: {
                                        value: INPUT_MAX_LENGTHS.postalCode,
                                        message: `Must be at most ${INPUT_MAX_LENGTHS.postalCode} characters`,
                                        },
                                        pattern: {
                                        value: DIGITS_ONLY_PATTERN,
                                        message: 'Numbers only',
                                        },
                                    }}
                                    />
                            </div>
                        </div>
                    </div>

                    <div className="mb-2">
                        <label className="form-label">Province</label>
                        <Controller
                            name={"Province"}
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange, onBlur } }) => {
                                return (<Select
                                    className={"form-control p-0" + (errors?.Province ? ' is-invalid' : '')}
                                    options={provinces}
                                    placeholder={""}
                                    onChange={onChange}
                                    value={value}
                                    defaultValue={value}
                                />)
                            }}
                        />
                    </div>
                </div>


                {/* <div className="upload-documents">
                    <input type="file" id="profile_image" className={"form-control" + (errors?.pic ? ' is-invalid' : '')} multiple={true} accept=".png,.jpg,.jpeg,application/pdf" {...register("Documents", { required: false })} />
                    <MdAddCircle className="icon" />
                    <label>Add Documents</label>
                </div>
                {selectedFileNames.map((name: any) => (<p className="fname">{name}</p>))}
                <div className="upload-instruction mt-3">
                    <h5>Please upload the following:</h5>
                    <ul>
                        <li>ID Copy of the director</li>
                        {tab != 864 ? <li>Company registration as per CIPC</li> : null}
                    </ul>
                </div> */}

                <Row className="align-items-center">
                    <Col md={6}>
                        <div className="mobbottom_fixbtn">
                            <Button variant="primary" className="w-50 mt-2" type="submit">   Next  </Button>
                        </div>
                    </Col>
                    {/* <Col md={6} className="text-end">
                        <a href="/">Sign in</a>
                    </Col> */}
                </Row>





            </form>

        </React.Fragment>
    );
}
