
import react, { useState } from 'react';
import { Button, Col, Row } from "react-bootstrap";
import { BsStarFill, BsStarHalf, BsStar } from 'react-icons/bs';
import { FaHome } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import parse from 'html-react-parser';
import { useCommonApi } from '../../../_common/hooks/api/common/commonApiHook';
import { useAppLoader } from '../../../_common/hooks/common/appLoaderHook';
import toast from 'react-hot-toast';

interface incomingProps {
    data?: any;
    revoke?: boolean;
    getApplications?: any;
    route?: any;
    getMyResidencies?: any;
}

export default function AccomodationCardSlider({ data, revoke = false, getApplications, route, getMyResidencies }: incomingProps) {


    const settings = {
        dots: false,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const thumbSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };

    const navigate = useNavigate();
    const commonApi = useCommonApi()
    const { showLoader, hideLoader } = useAppLoader()

    const [nav1, setNav1] = useState<any>(null);
    const [nav2, setNav2] = useState<any>(null);


    const getRating = (rating: any, totalStar: any, outOf: any) => {
        if (rating) {
            let finalRating = (rating / outOf) * totalStar;
            const checkFraction = finalRating % 1 != 0;
            if (checkFraction == false) {
                const blankStar = totalStar - finalRating;
                let span: any = []
                for (let i = 0; i < finalRating; i++) {
                    span.push(<BsStarFill className="text-primary" key={Math.random()} />)
                }
                for (let i = 0; i < blankStar; i++) {
                    span.push(<BsStarFill style={{ color: '#d4d4d4' }} key={Math.random()} />)
                }
                return span
            } else {
                let fullStar = Math.round(finalRating * 10) / 10;
                let halfStar = Number(fullStar.toString().split(".")[1]);
                fullStar = Number(finalRating.toString().split(".")[0]);
                let span: any = []
                if (halfStar >= 5) {
                    let blankStar = totalStar - fullStar - 1;
                    for (let i = 0; i < fullStar; i++) {
                        span.push(<BsStarFill className="text-primary" key={Math.random()} />)
                    }
                    span.push(<BsStarHalf className="text-primary" />)
                    for (let i = 0; i < blankStar; i++) {
                        span.push(<BsStarFill key={Math.random()} style={{ color: '#d4d4d4' }} />)
                    }
                    return span
                }
                else {
                    let blankStar = totalStar - fullStar + 1;
                    for (let i = 0; i < fullStar; i++) {
                        span.push(<BsStarFill className="text-primary" key={Math.random()} />)
                    }
                    for (let i = 0; i < blankStar; i++) {
                        span.push(<BsStarFill key={Math.random()} style={{ color: '#d4d4d4' }} />)
                    }
                    return span
                }
            }
        }
    }

    const checkImageBase64Code = (url: any) => {
        if (url.search('data:image/') == 0) {
            return url;
        } else {

            return 'data:image/jpeg;base64,' + url
        }
    }

    const imageSource = (docId: any, folder:any) => {
        
        return process.env.REACT_APP_API_DOMAIN+"/api/v1/entities/imageview?id="+docId+"&folder="+folder+"&apikey="+process.env.REACT_APP_API_KEY
    }

    const handleRevoke = (data: any) => {
        showLoader()
        return new Promise((resolve: any, reject: any) => {
            commonApi.post({
                "entityName": "AccomodationApplications",
                "recordId": data,
                "requestName": "RemoveRecordReq"
            }, (message: string, resp: any) => {
                // toast.success(message)
                resolve(message)
                hideLoader()
                navigate(`/student/my-applications/home`)
                getApplications()
                getMyResidencies()
            }, (message: string) => {
                // toast.error(message);
                reject(message)
                hideLoader()
                // getApplications()
            });
        })
    }

    const revokeNotification = (data: any) => {
        toast(
            (t) => (
                <span>

                    Are you sure you want to revoke your application ?
                    <br />
                    <hr />
                    <div className="d-flex align-items-center justify-content-end">
                        <button
                            className="btn btn-danger btn-sm m-1"
                            onClick={() => {
                                toast.dismiss(t.id);
                                toast.promise(handleRevoke(data), {
                                    loading: "working on request...",
                                    success: (msg: any) => {
                                        // getResidencies();
                                        return <b> {msg}</b>
                                    },
                                    error: (msg) => { return <b>{msg}</b> },
                                }, { duration: 10000 });
                            }}
                        >
                            Yes
                        </button>

                        <button
                            className="btn btn-secondary btn-sm m-1"
                            onClick={() => toast.dismiss(t.id)}
                        >
                            No
                        </button>
                    </div>
                </span>
            ),
            { duration: 5000, className: 'withdraw-toast' }
        );
    }

    const navigateFunction = () => {
        if (!revoke) {
            navigate(`../${route}/${data.facilityId}`)
        }
    }


    return (
        <div className="accomodation-card-slider">
            {route ?
                <div className="slider">
                    <Slider {...settings} asNavFor={nav2} ref={(slider1: any) => setNav1(slider1)} >
                        {data.images?.length > 0 ? data.images.map((image: any, i: any) => (
                            <div className="slide-item" key={i}>
                                <img src={imageSource(image.facilityDocLibId,"FacilityDocLib")} alt="" />
                            </div>
                        )) : <div className="slide-item">
                            <img src={"/assets/img/no-image-available.jpg"} alt="" />
                        </div>}


                    </Slider>

                    <Slider {...thumbSettings} className="thumb-slider" asNavFor={nav1} ref={(slider2) => setNav2(slider2)} focusOnSelect={true}>
                        {data.images?.length > 0 ? data.images.map((image: any, i: any) => (
                            <div className="slide-item" key={i}>
                                <img src={imageSource(image.facilityDocLibId,"FacilityDocLib")} />
                            </div>
                        )) : <div className="slide-item">
                            <img src={"/assets/img/no-image-available.jpg"} alt="" />
                        </div>}
                    </Slider>
                </div>
                :
                <div className="slider">
                    <Slider {...settings} asNavFor={nav2} ref={(slider1: any) => setNav1(slider1)} >
                        {data.images?.length > 0 ? data.images.map((image: any, i: any) => (
                            <div className="slide-item" key={i}>
                                <img src={imageSource(image.facilityDocLibId,"FacilityDocLib")} />
                            </div>
                        )) : <div className="slide-item">
                            <img src={"/assets/img/no-image-available.jpg"} alt="" />
                        </div>}

                    </Slider>

                    <Slider {...thumbSettings} className="thumb-slider" asNavFor={nav1} ref={(slider2) => setNav2(slider2)} focusOnSelect={true}>
                        {data.images?.length > 0 ? data.images.map((image: any, i: any) => (
                            <div className="slide-item" key={i}>
                                <img src={imageSource(image.facilityDocLibId,"FacilityDocLib")} alt="" />
                            </div>
                        )) : <div className="slide-item">
                            <img src={"/assets/img/no-image-available.jpg"} alt="" />
                        </div>}
                    </Slider>
                </div>
            }
        </div>
    )

}
