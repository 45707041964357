export enum TermType {
  Annual = "61", //842
  Trimester = "64", //834
  Semester = "62", //844
}
export enum GenderType {
  Male = "1",
  Female = "2",
}
export enum StudentVerificationStatus {
  Verified = "867",
  PendingVerification = "866",
  Unverified = "870",
}

export enum LeaseStatus {
  Active = "1032",
  Lapsed = "1033",
  Terminated = "1034",
  PendingRenewal = "1035",
}

// TODO refactr tbelow types to appropriate files

export type TAddressDetailsForm = {
  StreetNumber: string;
  StreetName: string;
  City: string;
  Suburb: string;
  PostalCode: string;
  Province: string;
  addressId: string;
};

// Define the type for an individual address component
export type AddressComponent = {
  long_name: string;
  short_name: string;
  types: string[];
};

// Define the addressComponents array type
export const addressComponents: AddressComponent[] = [
  {
    long_name: "Phuthaditjhaba",
    short_name: "Phuthaditjhaba",
    types: ["locality", "political"],
  },
  {
    long_name: "Thabo Mofutsanyana District Municipality",
    short_name: "Thabo Mofutsanyana District Municipality",
    types: ["administrative_area_level_2", "political"],
  },
  {
    long_name: "Free State",
    short_name: "FS",
    types: ["administrative_area_level_1", "political"],
  },
  {
    long_name: "South Africa",
    short_name: "ZA",
    types: ["country", "political"],
  },
  {
    long_name: "9870",
    short_name: "9870",
    types: ["postal_code"],
  },
];
