import React from 'react'
import IntroSlider from '../components/introSlider'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import passedIcon from '../images/check.png'

const ProceedLogin = () => {

	const navigate = useNavigate();
    
  return (
    <div>
        <React.Fragment>
			<div className="register_page login_page">
				<div className="register_left">
					<div className="mob_logo">
						<a href="#" className="logo_left"><img src="/assets/img/logo.png" /></a>
					</div>
                    {/* <img width='25px' src={passedIcon} alt="" style={{textAlign:'center'}} /> */}

					<div className="logo_txt">
						Security Check Successful 
					</div>
					<p className="text-center">
                        You have successfully passed the security check.
                    </p>
                    {/* <br /> */}
                    <p className='text-center'>Please click the button below to reset your password</p>
					<div className="mobbottom_fixbtn text-center" >
						<Button variant="primary" className="w-50 mt-2" type="button" onClick={() => window.location.href='/reset-password'} href='/reset-password'>Reset Password</Button>
					</div>
				</div>
				<IntroSlider />
			</div>
		</React.Fragment>
    </div>
  )
}

export default ProceedLogin