import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

const useMobileResponsiveness = () => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  function mobileViewOnly<T>(value: T) {
    if (isMobileView) {
      return value;
    }
  }
  function desktopViewOnly<T>(value: T) {
    if (!isMobileView) {
      return value;
    }
  }
  return { isMobileView, mobileViewOnly, desktopViewOnly };
};

export default useMobileResponsiveness;
