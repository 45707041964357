import React, { useEffect, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { FaBeer } from 'react-icons/fa';
import { Link, Navigate, useParams } from "react-router-dom";
import { useCommonApi } from "../../_common/hooks/api/common/commonApiHook";
import { useAppLoader } from "../../_common/hooks/common/appLoaderHook";
import { useUserSelector } from "../../_common/hooks/selectors/userSelector";
import AccomodationCard from "./components/accomdationCard";
import Breadcrumbs from "./components/breadcrumbs";
import Header from "./components/header";
import backArrow from '../../images/back-button.png'
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import noApplicationImg from "../../images/no-applications.png"

export default function MyApplications() {
    const commonApi = useCommonApi();
    const { showLoader, hideLoader } = useAppLoader();
    const [applications, setApplications] = useState<any>(null);
    const user: any = useUserSelector();
    const { type } = useParams();
    const navigate = useNavigate();
     
    

    const getApplications = () => {
        showLoader();
        commonApi.post({
            "recordId": user.recordId,
            "requestName": "RetrieveStudentApplications"
        }, (message: string, resp: any) => {
            hideLoader()
            if (resp.outputParameters.Applications) {
                setApplications(resp.outputParameters.Applications);
            }
        }, (message: string ) => {
            
            hideLoader();
        });
    }

    useEffect(() => {
        if (user) {
            getApplications()
        }
    }, [user])


    
    return (
        <React.Fragment>
            <div className="dashboard" style={{ paddingTop: "0px" }}>
                <Header />
                <div className="container">
                <div className="mb-4" >
                    
                </div>
                    {type == "success" ?
                        <Alert variant={'success'}>
                            Success! You’ve applied for this accommodation.
                        </Alert>
                        : null
                    }
                    <div style={{display:"flex",justifyContent:'space-between'}} className="my-applications-head">
                        <h4>My Applications</h4>
                        {/* <a href="/student/search-residence" style={{textDecoration:'none', color:'#fff',}}><img width={'30px'} src={backArrow}/></a> */}
                        <Button variant="outlined" onClick={()=>{navigate('/student/search-residence')}} sx={{}}>Find Accommodation</Button>
                    </div>
                    <Row className="my-applications-cards-container">
                        {applications && applications.length > 0 ? applications?.map((application: any, i: any) => (
                            <Col md={6} sm={12} lg={4}  key={i} style={{overflow:'hidden'}}>
                                <AccomodationCard data={application} revoke={true} getApplications={getApplications} route={"my-application-details"} />
                            </Col>
                        ))
                            :
                            <div style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center',marginTop:'5rem'}}>
                                <img style={{width:'25%'}} src={noApplicationImg} alt="no application image"/>
                                <h6>No Applications</h6>
                            </div>
                            // <h6>No Applications</h6>
                            // <img width="50" height="50" src="https://img.icons8.com/external-outline-andi-nur-abdillah/50/external-Empty-empty-state-(outline)-outline-andi-nur-abdillah.png" alt="external-Empty-empty-state-(outline)-outline-andi-nur-abdillah"/>
                        }
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}