import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useEffect, useState } from "react";
import Header from "./student/components/header";
import { Modal } from "react-bootstrap";
import userCheckIcon from "../images/user-check-01.svg";
import { Link, useSearchParams } from "react-router-dom";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import { useHistory } from "react-router-use-history";
import noApplicationImg from "../images/empty-2.jpg";
import {
  ApartmentOutlined,
  CancelOutlined,
  CheckCircleOutlineRounded,
  Close,
  HourglassEmptyOutlined,
  Search,
} from "@mui/icons-material";
import { IApiResponse } from "../ui/v2/types/IApiResponse";
import { API } from "../ui/v2/services/API";
import { EXECUTE_REQUEST_PATH } from "../ui/v2/constants";
import { DEFAULT_PAGE_SIZE } from "../constants";
import toast from "react-hot-toast";
import ApproveModal from "../components/ApproveModal";
import Dashcard from "../components/Dashcard";
import SignWellWindow from "../components/SignWellWindow";
import LegacyPageLayout from "../components/LegacyPageLayout";
import { ThemeColors } from "../ui/v2/styles/theme";
import useSearchAndFiltersBar from "../ui/v2/components/GenericListingScreen/useSearchAndFiltersBar";
import PageHeaderTexts from "../ui/v2/components/GenericListingScreen/PageHeaderTexts";
import SummaryCard from "../ui/v2/components/GenericListingScreen/SummaryCard";
import ListingTable from "../ui/v2/components/GenericListingScreen/ListingTable";
import NoItemsPaper from "../ui/v2/components/GenericListingScreen/NoItemsPaper";
import { TSelectedFilters } from "../ui/v2/components/GenericListingScreen/useSearchAndFiltersBar/SearchAndFiltersBar";
import { UTILS } from "../utils";
import { format, parseISO } from "date-fns";
import { URLS } from "../_config";

interface FacilityApplication {
  accomodationApplicationsId: string;
  facilityName: string;
  facilityId: string;
  studentName: string;
  institutionIdName: string;
  campusIdName: string;
  gender: string;
  studentStatus: string;
  status: string;
  apSigningURL: string;
  plannedMoveInDate: string;
}

type ResponseType = {
  Summary: {
    totalApplications: number;
    pendingApproval: number;
    approved: number;
    rejected: number;
    totalApplicationsTrend: number;
    pendingApprovalTrend: number;
    approvedTrend: number;
    rejectedTrend: number;
  };
  PageSize: number;
  RecordCount: number;
  Pages: number;
  Listing: FacilityApplication[];
};

type signwellRetrieveUrl = {
  url: string;
};

const Residences = () => {
  const history = useHistory();
  const commonApi = useCommonApi();

  const user: any = useUserSelector();
  const [applicationsData, setApplicationsData] = useState([]);
  const [arr, setArr] = useState<any[]>([]);
  const { showLoader, hideLoader } = useAppLoader();
  const [residence, setResidence] = useState<any>(null);
  const [facilityId, setFacilityId] = useState();

  // sending
  const data = { name: "John", age: 30 };
  //

  const getFacilityApplicationDetails = () => {
    commonApi.getFacilityApplicationDetails(
      {
        AccomodationProviderId: user.supplierId,
      },
      (message: string, resp: any) => {
        if (resp.length > 0) {
          setApplicationsData(resp);
          setArr(resp);
          localStorage.setItem("resp", JSON.parse(resp));
          setFacilityId(resp.Address);
        }
      },
      () => {}
    );
  };

  useEffect(() => {
    getFacilityApplicationDetails();
  }, []);

  const [details, setDetails] = useState<ResponseType>();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPage = searchParams.get("page")
    ? parseInt(searchParams.get("page") as string)
    : 1;


  const [currentPage, setCurrentPage] = useState<number>(
    initialPage > 0 ? initialPage : 1
  );
 

  const getApiData = async (_currentPage: number, activeFilters: TSelectedFilters) => {
    try {
      showLoader();
      const response: IApiResponse<ResponseType> = await API.post(
        EXECUTE_REQUEST_PATH,
        {
          entityName: "Facility",
          requestName: "AccommodationListingReq",
          inputParamters: {
            SearchText: searchedText,
            AccommodationProviderId: user.supplierId,
            PageNumber: currentPage,
            PageSize: DEFAULT_PAGE_SIZE,
            FilteredProperty: UTILS.extractFilterValue(activeFilters, "FilteredProperty"),
            Status: UTILS.extractFilterValue(activeFilters, "Status"),
          },
        }
      );
      setDetails(response.outputParameters);
    } catch (e) {
      console.error(e);
    } finally {
      hideLoader();
    }
  };

  const [{ show, selectedFacilityId, selectedApplicationId, plannedMoveInDate }, setModalState] =
    useState<{
      show: boolean;
      selectedFacilityId?: string;
      selectedApplicationId?: string;
      plannedMoveInDate?: Date;
    }>({
      show: false,
    });

  const handleClose = () => {
    setModalState({ show: false });
  };

  const handleShow = (applicationId: string, selectedFacilityId: string, plannedMoveInDate: string = "") => {
    setModalState({
      show: true,
      selectedApplicationId: applicationId,
      selectedFacilityId,
      plannedMoveInDate: plannedMoveInDate ? parseISO(plannedMoveInDate) : undefined,
    });
  };

  const getLinkOnClick = async (applicationId: any) => {
    try {
      showLoader();
      const response: IApiResponse<signwellRetrieveUrl> = await API.post(
        EXECUTE_REQUEST_PATH,
        {
          entityName: "AccomodationApplications",
          requestName: "RetrieveSignwellReq",
          recordId: applicationId,
          inputParamters: {
            UserType: "AP",
          },
        }
      );
      console.log("Response", response);
      window.open(response.outputParameters.url);
    } catch (e) {
      console.error(e);
    } finally {
      hideLoader();
    }
  };

  const rejectApplication = (applicationId: any) => {
    showLoader();

    commonApi.post(
      {
        entityName: "AccomodationApplications",
        requestName: "UpsertRecordReq",
        recordId: applicationId,
        inputParamters: {
          Entity: {
            StatusId: 70,
          },
        },
      },
      (message: string) => {
        showLoader();
        setTimeout(() => {
          toast.success(message, { duration: 3000 });
          hideLoader();
          window.location.reload();
        }, 5000);
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  const [{ show: showSignWellWindow, url: signWellUrl }, setSignWellState] =
    useState({
      show: false,
      url: "",
    });

  const {
    searchAndFiltersBarComponent,
    searchedTextBannerComponent,
    activeFilters,
    searchedText,
  } = useSearchAndFiltersBar({
    filters: [
      {
        type: "dropdown",
        value: "Status",
        name: "Status",
        multiple: false,
        options: [
          { label: "All", value: " " },
          { label: "Pending Approval", value: "PendingApproval" },
          { label: "Approved", value: "Approved" },
          { label: "Rejected", value: "Rejected" },
        ],
        initialValue: " ",
      },
      {
        type: "dropdown",
        value: "FilteredProperty",
        name: "My Properties",
        multiple: false,
        options: [
          { label: "All", value: "All" },
          ...arr.map((item) => ({
            label: item.Name,
            value: item.FacilityId,
          })),
        ],
        initialValue: "All",
      },
    ],
    onSearch: (searchInput) => { 
      setCurrentPage(1);
      setSearchParams({ page: "1" });
    },
  });

  useEffect(() => { 
    getApiData(currentPage, activeFilters);
  }, [
    currentPage,
    searchedText,
    activeFilters,
  ]);

  return (
    <LegacyPageLayout
      bgColor={ThemeColors.bgPrimary}
      padding={3}
      paddingTop={0}
      breadcrumbData={[
        { label: "Home", path: "/" },
        { label: "Residence Applications" },
      ]}
    >
      <Box sx={{ mt: 1, pt: 2 }}>{searchAndFiltersBarComponent}</Box>
      <Grid item xs={12} sx={{ my: 6 }}>
        <PageHeaderTexts heading="Residence Applications" />
      </Grid>

      <Grid container sx={{ my: 2 }}>
        <Grid item xs={12} md={3} container>
          <SummaryCard
            icon={<ApartmentOutlined />}
            label="Total Applications"
            value={details?.Summary.totalApplications || 0}
            trend={details?.Summary.totalApplicationsTrend || 0}
          />
        </Grid>
        <Grid item xs={12} md={3} container>
          <SummaryCard
            icon={<HourglassEmptyOutlined />}
            label="Pending Applications"
            value={details?.Summary.pendingApproval || 0}
            trend={details?.Summary.pendingApprovalTrend || 0}
          />
        </Grid>
        <Grid item xs={12} md={3} container>
          <SummaryCard
            icon={<CheckCircleOutlineRounded />}
            label="Approved Applications"
            value={details?.Summary.approved || 0}
            trend={details?.Summary.approvedTrend || 0}
          />
        </Grid>
        <Grid item xs={12} md={3} container>
          <SummaryCard
            icon={<CancelOutlined />}
            label="Rejected Applications"
            value={details?.Summary.rejected || 0}
            trend={details?.Summary.rejectedTrend || 0}
          />
        </Grid>
      </Grid>

      {searchedTextBannerComponent}

      {arr.length > 0 ? (
        <ListingTable
          title="Residence Applications"
          totalPages={details?.Pages || 1}
          currentPage={currentPage}
          onPageChange={(e, newPage) => {
            setCurrentPage(newPage);
            setSearchParams({ page: `${newPage}` });
          }}
          sx={{ minWidth: 1050 }}
          aria-label="simple table"
        >
          <TableHead
            sx={{
              backgroundColor: "#F9FAFB",
            }}
          >
            <TableRow>
              <TableCell>Campus / Institution</TableCell>
              <TableCell>Funding Status</TableCell>
              <TableCell>Student Name</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Application Status</TableCell>
              <TableCell>Planned Move-in</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {details?.Listing.map(
              (facilityApplication: FacilityApplication) => {
                return (
                  <TableRow
                    key={facilityApplication.accomodationApplicationsId}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {facilityApplication.campusIdName}
                    </TableCell>
                    <TableCell className="table-data">
                      <div>{facilityApplication.studentStatus}</div>
                      {/* <Tooltip title={facilityApplication.OptionText === "Unverified" ? "NSFAS awaiting registration information from institution. You can only approve accommodation once verified. Visit portal" : "NSFAS has verified student. You can now Approve accommodation"}>
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip> */}
                    </TableCell>
                    <TableCell className="table-data">
                      {facilityApplication.studentName}
                    </TableCell>
                    <TableCell className="table-data">
                      {facilityApplication.gender}
                    </TableCell>
                    <TableCell className="table-data">
                      {facilityApplication.status}
                    </TableCell>
                    <TableCell>
                      {facilityApplication.plannedMoveInDate? format(facilityApplication.plannedMoveInDate, 'dd/MM/yyyy'):null}
                    </TableCell>
                    <TableCell
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        justifyContent: "right",
                      }}
                    >
                      <Button size="small" variant="outlined" component={Link} to={`${URLS.AP.BASE}/accomodation-applications/${facilityApplication.accomodationApplicationsId}`} >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </ListingTable>
      ) : (
        <NoItemsPaper />
      )}

      <SignWellWindow
        show={showSignWellWindow}
        url={signWellUrl}
        onClose={() => {
          setSignWellState({ show: false, url: "" });
          getApiData(currentPage, activeFilters);
        }}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Approval Confirmation</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {selectedFacilityId && selectedApplicationId && (
            <ApproveModal
              onApprove={(signWellUrl: string) => {
                handleClose();
                if (signWellUrl) {
                  setSignWellState({ show: true, url: signWellUrl });
                } else {
                  setSignWellState({ show: false, url: signWellUrl });
                }
              }}
              onClose={handleClose}
              applicationId={selectedApplicationId}
              facilityId={selectedFacilityId}
              plannedMoveInDate={plannedMoveInDate}
            />
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={handleClose}
            style={{
              width: "100%",
              height: "3.5rem",
              marginLeft: "auto",
              marginRight: "auto",
              backgroundColor: "",
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* </Box> */}
    </LegacyPageLayout>
  );
};

export default Residences;
