import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Container, Row, Col, Button } from 'react-bootstrap';
import Select, { components } from 'react-select';
import { useForm, Controller } from "react-hook-form";
import Header from '../components/header';
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useResidenceApi } from "../_common/hooks/api/residenceApiHook";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
import { checkImageBase64Code } from "../_common/functions";
import { FaTimes } from "react-icons/fa";
export default function CreateCase() {
    const user: any = useUserSelector()
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useAppLoader();
    const commonApi = useCommonApi();
    const residenceApi = useResidenceApi();
    const { id } = useParams();

    const [searchParams] = useSearchParams();
    const type = searchParams.get('type');
    const name = searchParams.get('name');

    const [queryTypes, setQueryTypes] = useState<any>(null);
    const [caseTypes, setCaseTypes] = useState<any>(null);
    const [docType, setDocType] = useState<any>(null);
    const [files, setFiles] = useState<any>([]);

    const { register, handleSubmit, control, formState: { errors, isValid }, getValues, watch, reset } = useForm({ mode: "onChange" });
    const images = watch('images');

    const toBase64 = (file: any) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });


    useEffect(() => {
        let _files: any = [...files];
        if (images?.length) {
            for (let i = 0; i < images?.length; i++) {
                let file: any = images[i];
                if (file.size <= 2 * 1024 * 1024) {
                    toBase64(file).then((b64: any) => {
                        _files.push({
                            "FileName": file.name,
                            "FileExtention": file.name.split('.').pop(),
                            "DocumentTypeId": docType,
                            "FileContent": b64.split(',')[1]
                        });
                        setFiles(_files)
                    })
                } else {
                    toast.error("File size should be less 2MB")
                }
            }
            
        } else {
            setFiles(_files)
        }
    }, [images]);

    const getDocTypes = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters":
            {
                "SelectOption": {
                    "OptionText": "DocTypes"

                }
            }
        }, (message: string, resp: any) => {
            resp.outputParameters.data.map((type: any) => {
                if (type.optionText == 'Image') {
                    setDocType(type.optionValue);
                }
            })
        }, (message: string) => {
            //oast.error(message);
        });
    }

    const camelize = (str: any) => {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: any, index: number) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }

    const getQueryTypes = () => {
        residenceApi.getCaseQueryTypes({}, (message: string, resp: any) => {
            setQueryTypes(resp.map((data: any) => {
                data.label = data.Name;
                data.value = data.CaseClassificationId;
                return data
            }))
        }, (message: string) => {
            console.log(message);
        });
    }

    const getCaseTypes = () => {
        commonApi.post({
            "entityName": "Picklist",
            "requestName": "RetrieveSelectOptionExecuteRequest",
            "inputParamters": { "SelectOption": { "OptionText": "CaseType" } }
        }, (message: string, resp: any) => {
            setCaseTypes(resp.outputParameters.data.map((data: any) => {
                data.label = data.optionText;
                data.value = data.optionValue;
                return data
            }))
        }, (message: string) => {
        });
    }

    const onSubmit = (data: any) => {
        let params: any = {
            "entityName": "Cases",
            "requestName": "UpsertRecordReq",
            "inputParamters":
            {
                "Entity": {
                    "CaseClassificationId": data.query.CaseClassificationId,
                    "Subject": data.subject,
                    "Description": data.message,
                    "regardingId": id,
                    "regardingIdObjectTypeCode": "Facility",
                    "CustomerId": user.supplierId,
                    "CustomerIdObjectTypeCode": "Supplier",
                    /* "CaseType": data.case.value, */
                    "ChannelId": "649"
                },
                "Documents": files

            }
        }

        showLoader();
        commonApi.post(params, (message: string, resp: any) => {
            hideLoader();
            toast.success(message);
            navigate('/')
        }, (message: string) => {
            hideLoader();
            toast.error(message);
        });


    }

    const deleteLocal = (file:any)=>{
        let _files:any = [];
        files.map((_file:any)=>{
            if(_file.FileName != file.FileName){
                _files.push(_file)
            }
        });

        setFiles(_files)
    }

    useEffect(() => {
        getDocTypes();
        getQueryTypes();
        getCaseTypes();
    }, [])

    useEffect(() => {
        if (queryTypes) {
            let value: any = null;
            let __type = 'Appeal - Student Residence Grading Outcome';
            if (type == 'Regrade') { __type = "Upgrade - Request for Student Residence Regrading" }
            queryTypes.map((type: any) => {


                if (type.label === __type) {
                    value = type;
                }
            })
            reset({
                query: value,
                subject: type + ' - ' + name
            })
        }
    }, [queryTypes])


    return (
        <React.Fragment>
            <div className="dashboard">
                <Header />
                <section className="container">
                    <div className="white-block p-3">
                        <div className="row">
                            <div className="col">
                                <h1>CREATE CASE</h1>
                            </div>
                        </div>
                        <hr />
                        <form onSubmit={handleSubmit(onSubmit)}>



                            <div className="tab_scrollcontent">


                                <div className="mb-2">
                                    <label className="form-label">Query type</label>
                                    <Controller
                                        name={"query"}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange, onBlur } }) => {
                                            return (<Select
                                                className={"form-control p-0" + (errors?.query ? ' is-invalid' : '')}
                                                options={queryTypes}
                                                placeholder={""}
                                                onChange={onChange}
                                                value={value}
                                                defaultValue={value}
                                                isDisabled={true}
                                            />)
                                        }}
                                    />
                                </div>

                                {/* {type != 'Regrade' ?
                                    <div className="mb-2">
                                        <label className="form-label">Case</label>
                                        <Controller
                                            name={"case"}
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange, onBlur } }) => {
                                                return (<Select
                                                    className={"form-control p-0" + (errors?.case ? ' is-invalid' : '')}
                                                    options={caseTypes}
                                                    placeholder={""}
                                                    onChange={onChange}
                                                    value={value}
                                                    defaultValue={value}
                                                />)
                                            }}
                                        />
                                    </div>
                                    : null} */}


                                <div className="mb-2">
                                    <label className="form-label">Subject</label>
                                    <input type="text" disabled defaultValue={type + ' - ' + name} className="form-control" {...register('subject', { required: true })} />
                                </div>

                                <div className="mb-2">
                                    <label className="form-label">Message</label>
                                    <textarea style={{ height: '100px' }} className="form-control" {...register('message', { required: true })}></textarea>
                                </div>

                            </div>
                            <div className="residence-attachment-panel">
                                <h4>Attachments</h4>
                                <div className="residence-attachment-inner">
                                    {files.map((file: any, i: any) => (
                                        <div className="residence-attachment" key={i}>
                                            <FaTimes className="delete-me" onClick={()=>{deleteLocal(file)}} />
                                            <img data-src={checkImageBase64Code(file.FileContent)} src={checkImageBase64Code(file.FileContent)} />
                                        </div>
                                    ))}


                                </div>
                                <div className="upload-residence-attachment">
                                    <input type="file" accept="image/*" multiple {...register('images', { required: false })} />
                                    <div className="upload-residence-attachment-inner">
                                        <img src="/assets/img/upload-icon.png" />
                                        <span>Upload your attachment</span>
                                    </div>
                                </div>
                            </div>
                            <div className="mobbottom_fixbtn">
                                <button type="submit" className="btn btn-primary w-100 mt-2" disabled={!isValid} data-bs-toggle="button" >Next</button>
                                <Link to="/"><button type="button" className="btn btn-outline-secondary mt-2 w-100" data-bs-toggle="button" >Back</button></Link>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        </React.Fragment>
    );
}