import React from "react";
import Header from "../../pages/student/components/header";
import { Box, Grid } from "@mui/material";
import { BreadcrumbData } from "../../ui/v2/components/Breadcrumbs/types";
import { URLS } from "../../_config";
import Breadcrumbs from "../../ui/v2/components/Breadcrumbs";

const BREADCRUMB_DATA: BreadcrumbData = [
  {
    label: "Dashboard",
    path: URLS.DASHBOARD,
  },
];

function LegacyPageLayout({
  children,
  paddingTop = 0,
  padding = 0,
  bgColor,
  breadcrumbData,
}: {
  children: React.ReactNode;
  paddingTop?: number;
  padding?: number;
  bgColor?: string;
  breadcrumbData?: BreadcrumbData;
}) {
  return (
    <div className="dashboard">
      <Header />
      <Box
        sx={{
          paddingLeft: padding,
          paddingRight: padding,
          paddingBotton: padding,
          paddingTop: 3 + (paddingTop ?? padding),
          background: bgColor,
          minHeight: "100vh",
        }}
      >
        <Grid container>
          {breadcrumbData && (
            <Grid item xs={12}>
              <Breadcrumbs data={breadcrumbData} />
            </Grid>
          )}
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default LegacyPageLayout;
