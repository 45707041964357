import React, { useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useCommonApi } from '../_common/hooks/api/common/commonApiHook';
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';

const SuccesssPage = () => {
  const { showLoader, hideLoader } = useAppLoader();
  const navigate = useNavigate();
  const commonApi = useCommonApi();
  const residenceID = sessionStorage.getItem('residenceID');
  const [searchParams] = useSearchParams();
  const amount = searchParams.get('amount');
  const regrade = searchParams.get('regrade');


  const inIframe = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  const completePayment = () => {
    sessionStorage.removeItem('residenceID');
    sessionStorage.removeItem('residenceName');
    sessionStorage.removeItem('regrade');
    if (inIframe()) {
      if ((window as any).messageHandler) {
        (window as any).messageHandler.postMessage("success")
      }
    } else {
      setTimeout(() => {
        navigate(`/`)
      }, 1000)
    }
  }

  const paySuccess = () => {
    return new Promise<boolean>((resolve: any, reject: any) => {
      showLoader();
      commonApi.post({
        "entityName": "Facility",
        "requestName": "PaymenyReceivedReq",
        "recordId": residenceID,
        "inputParamters": {
          "AmountPaid": {
            "Amount": amount,
            "PaymentFor": regrade?'Regrade':'NewProperty',
          }
        }
      }, (message: string, resp: any) => {
        hideLoader();
        completePayment();
      }, (message: string) => {
        hideLoader();
      });
    })
  }

  useEffect(() => {
    paySuccess();
  }, [])

  return (
    <section className="container">
      <div className="white-block p-3">
        <div className="payment-screen-panel">
          <h2 className="mb-3">Successful</h2>
          <p>Payment Done Successfuly</p>
        </div>
      </div>
    </section>
  )
}

export default SuccesssPage