// import { useDispatch } from 'react-redux';
// import { extractErrorMessage } from '../../../_config/functions';
// import { useAppLoader } from './appLoaderHook';

// export function useApiCall() {

//     const dispatch = useDispatch()
//     const { showLoader, hideLoader } = useAppLoader()

//     const callApi = (sagaAction: string, dataOrParams: any = {}, callbackSuccess: Function, callbackError: Function) => {
//         //showLoader()
//         dispatch({
//             type: sagaAction,
//             payload: dataOrParams,
//             callbackSuccess: (data: any) => {
//                 let message = data && data.message ? data.message : 'Request processed successfully'
//                 let resp = data ? data : null
//                 //hideLoader()
//                 callbackSuccess && callbackSuccess(message, resp)
//             },
//             callbackError: (error: any) => {
//                 let message = extractErrorMessage(error, 'Unable to process request, please try again')
//                 let resp = error && error.data ? error.data : null
//                 //hideLoader()
//                 callbackError && callbackError(message, resp)
//             }
//         })
//     }
//     return callApi
// }

import { useDispatch } from 'react-redux';
import { extractErrorMessage } from '../../../_config/functions';
import { useAppLoader } from './appLoaderHook';

export function useApiCall() {

    const dispatch = useDispatch()
    const { showLoader, hideLoader } = useAppLoader()

    const callApi = (sagaAction: string, dataOrParams: any = {}, callbackSuccess?: Function, callbackError?: Function) => {
        //showLoader()
        dispatch({
            type: sagaAction,
            payload: dataOrParams,
            callbackSuccess: (data: any) => {
                let message = data && data.message ? data.message : 'Request processed successfully'
                let resp = data ? data : null
                //hideLoader()
                callbackSuccess && callbackSuccess(message, resp)
            },
            callbackError: (error: any) => {
                let message = extractErrorMessage(error, 'Unable to process request, please try again')
                let resp = error && error.data ? error.data : null
                //hideLoader()
                callbackError && callbackError(message, resp)
            }
        })
    }
    return callApi
}